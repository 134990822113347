/* eslint-disable react/prop-types */
import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import { RelatedPost } from "../components/blog/RelatedPosts"

const TagDetails = ({ tag }) => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-stretch gap-3">
      <p className="text-xs font-bold uppercase text-slate-400">
        <Link to="/blog/tag/all/" className="underline">
          All Topics
        </Link>
        <span className="text-slate-200"> / </span>
        <span>{tag.name}</span>
      </p>
      <div className="flex w-full flex-col-reverse items-start justify-start gap-6 md:flex-row md:justify-between">
        <div className="flex w-full max-w-3xl flex-col gap-6">
          <h1 className="text-4xl font-black leading-[48px]">{tag.name}</h1>
          {tag?.description && (
            <p className="text-xl text-slate-600">{tag?.description}</p>
          )}
        </div>
      </div>
    </div>
  )
}

const Tag = ({ data }) => {
  const posts = React.useMemo(() => {
    return data.allGhostPost.edges.map(({ node }) => node)
  }, [data])

  return (
    <Layout>
      <div className="mx-auto flex w-full flex-col gap-10 pb-[120px]">
        <div className="w-full gap-10 bg-yellow-50 px-4 py-8">
          <TagDetails tag={data.ghostTag} />
        </div>
        <div className="w-full gap-10 px-4">
          <div className="mx-auto grid w-full max-w-6xl grid-flow-row grid-cols-1 gap-x-10 gap-y-14 sm:grid-cols-2 md:grid-cols-3">
            {posts.map(node => {
              return <RelatedPost key={node.id} post={node} />
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    allGhostPost(
      sort: { order: DESC, fields: published_at }
      filter: {
        tags: {
          elemMatch: {
            slug: { eq: $slug, nin: ["hash-last9-events", "changelog"] }
          }
        }
      }
    ) {
      edges {
        node {
          title
          excerpt
          featureImage: feature_image
          slug
          published_at
          authors {
            id
            name
            profile_image
          }
          tags {
            slug
          }
          primary_tag {
            slug
          }
        }
      }
    }
    ghostTag(slug: { eq: $slug }) {
      id
      description
      slug
      name
    }
  }
`

export const Head = ({
  data: {
    ghostTag: { name },
  },
}) => (
  <Seo
    title={`All articles on ${name} ⏤ Last9`}
    description={`Posts about ${name} ⏤ Last9 Blog`}
  />
)

export default Tag
