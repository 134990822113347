import React, { useMemo } from "react"
import { hexToRgb } from "../../utils"
import clsx from "clsx"
import ClientLogo from "../common/ClientLogo"

const Quote = ({
  client = "replitcolor",
  quote = "With Last9, we eliminated the toil. It just works.",
  author = "Matt Iselin, Head of SRE, Replit",
  accent = "#EC4E02",
  img = "",
}) => {
  const rgb = useMemo(() => hexToRgb(accent), [accent])

  return (
    <div
      style={{
        "--bg-grad-start": `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, 0.05)`,
        "--bg-grad-end": `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, 0)`,
        "--text-color": accent,
      }}
      className={clsx(
        "flex w-full gap-24 rounded-2xl bg-gradient-to-b",
        "from-[var(--bg-grad-start)] to-[var(--bg-grad-end)]",
      )}
    >
      <div className="flex flex-1 flex-col items-start gap-10 p-10 md:px-20 md:py-28 lg:pr-0">
        <ClientLogo name={client} size={56} />
        <p className="font-serif text-2xl text-[var(--text-color)] md:text-3xl">
          {quote}
        </p>
        <p className="flex flex-col text-lg text-[var(--text-color)] opacity-50 before:content-['—_'] md:text-xl lg:flex-row lg:gap-1">
          {author}
        </p>
      </div>
      <div
        className={clsx(
          "relative hidden flex-1 items-center justify-center pr-20 lg:flex",
          "before:absolute before:bottom-0 before:left-0 before:z-10 before:h-1/2 before:w-full before:bg-gradient-to-t before:from-white before:to-white/0 before:content-['']",
        )}
      >
        {img}
      </div>
    </div>
  )
}

export default Quote
