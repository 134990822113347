import React from "react"
import Seo from "../components/common/seo"
import Hero from "../components/logs/Hero"
import LogoScroll from "../components/common/logoscroll"
import StickySubheader from "../components/logs/StickySubheader"
import LogsExplorer from "../components/logs/LogsExplorer"
import LogsManagement from "../components/logs/LogsManagement"
import OtherCapabilities from "../components/common/OtherCapabilities"
import PreFooter from "../components/home/PreFooter"
import Layout from "../components/common/layout"

const LogsPage = () => {
  const subheaderLinks = [
    { id: "logs-explorer-quick-mttr", title: "Quick MTTR" },
    { id: "logs-explorer-query-everything", title: "Query Everything" },
    { id: "logs-explorer-real-time-monitoring", title: "Real-time Monitoring" },
    {
      id: "logs-management-cost-effective-scale",
      title: "Cost-Effective Scale",
    },
    { id: "logs-management-filter-route", title: "Filter & Route" },
    {
      id: "logs-management-industry-standards",
      title: "Industry Standards",
    },
  ]

  return (
    <Layout>
      <main className="flex flex-col [&_a]:underline [&_a]:decoration-blue-300 [&_a]:underline-offset-2 hover:[&_a]:decoration-blue-500">
        <Hero />
        <LogoScroll />
        <StickySubheader links={subheaderLinks} />
        <LogsExplorer />
        <LogsManagement />
        <OtherCapabilities page="Logs" />
        <PreFooter />
      </main>
    </Layout>
  )
}

// This gets added in <head> tag
export const Head = () => (
  <Seo
    title="Cloud Native Logs Explorer and Logs Management"
    description="Stream, store, and analyze millions of logs per minute without worrying about things breaking, including your bank account."
    image="/thumbnails/thumbnail-last9-logs.jpg"
  />
)

export default LogsPage
