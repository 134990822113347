import React from "react"
import Seo from "../components/common/seo"
import Hero from "../components/control-plane/Hero"
import LogoScroll from "../components/common/logoscroll"
import TelemetryWarehouse from "../components/control-plane/TelemetryWarehouse"
import VisibilityAndCost from "../components/control-plane/VisibilityAndCost"
import OtherCapabilities from "../components/common/OtherCapabilities"
import PreFooter from "../components/home/PreFooter"
import Layout from "../components/common/layout"

const ControlPlanePage = () => {
  return (
    <Layout>
      <main className="flex flex-col [&_a]:underline [&_a]:decoration-blue-300 [&_a]:underline-offset-2 hover:[&_a]:decoration-blue-500">
        <Hero />
        <LogoScroll />
        <TelemetryWarehouse />
        <VisibilityAndCost />
        <OtherCapabilities page="Control Plane" />
        <PreFooter />
      </main>
    </Layout>
  )
}

// This gets added in <head> tag
export const Head = () => (
  <Seo
    title="Control Plane: Manage your Telemetry Data"
    description="A first-class single pane experience with end-to-end telemetry management to control your observability data and its costs."
    image="/thumbnails/thumbnail-last9-control-plane.jpg"
  />
)

export default ControlPlanePage
