import React from "react"
import { Link } from "gatsby"
import { ChevronRightIcon } from "@heroicons/react/solid"
import ControlPlaneImg from "../../../../static/header/navmenu-control-plane.png"

const Platform = ({ cases }) => {
  return (
    <div className="grid w-full sm:w-[640px] sm:grid-cols-3 sm:grid-rows-2 sm:gap-2 sm:p-3">
      {cases.map((item, index) => (
        <Link
          key={item.name}
          to={item.href}
          target={item.target || ""}
          className={`group relative -left-3 w-[calc(100%+24px)] rounded-lg hover:bg-slate-50 hover:shadow-sm sm:left-0 sm:w-auto sm:border sm:border-solid sm:border-slate-100 sm:bg-slate-50 sm:hover:border-blue-500 sm:hover:shadow-none ${
            index === 0 ? "row-span-2" : ""
          }`}
        >
          {index === 0 ? (
            <div className="flex flex-col gap-3 overflow-hidden p-3 sm:pb-0">
              <div className="flex items-center gap-2">
                <span className="size-5 text-blue-500">{item.icon}</span>
                <span className="flex-1 text-sm font-medium text-slate-600">
                  {item.name}
                </span>
                <ChevronRightIcon className="h-5 w-5 text-slate-400" />
              </div>

              <span className="hidden flex-1 text-xs font-medium text-slate-500 sm:block">
                {item.desc}
              </span>

              <img
                src={ControlPlaneImg}
                className="hidden h-auto w-[228px] min-w-[228px] rounded-br-lg rounded-tl-lg border-l border-t border-solid border-slate-200 sm:block"
                loading="eager"
              />
            </div>
          ) : (
            <div className="flex flex-col gap-3 overflow-hidden p-3">
              <div className="flex items-center gap-2">
                <span className="size-5 text-blue-500">{item.icon}</span>
                <span className="flex-1 text-sm font-medium text-slate-600">
                  {item.name}
                </span>
                <ChevronRightIcon className="h-5 w-5 text-slate-400" />
              </div>

              <span className="hidden flex-1 text-xs font-medium text-slate-500 sm:block">
                {item.desc}
              </span>

              {/* <img
                src={ControlPlaneImg}
                className="aspect-[2/1] h-auto w-96 min-w-96 rounded-tl-lg border-l border-t border-solid border-slate-200"
              /> */}
            </div>
          )}
        </Link>
      ))}
    </div>
  )
}

export default Platform
