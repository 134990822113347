/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-undefined */
import React, { useState, useRef, useEffect } from "react"
import Layout from "../../components/common/layout"
import { tags } from "../../components/common/data"
import { Categories } from "../../components/blog/tag"
import { graphql } from "gatsby"
import Posts from "../../components/blog/posts"
import Seo from "../../components/common/seo"
import Subscription from "../../components/blog/subscription"

const Blog = ({ data }) => {
  const [clicked, setClicked] = useState(false)
  const [keyword, setkeyword] = useState("")

  const allPosts = React.useMemo(() => {
    return data.allGhostPost.edges.map(({ node }) => node)
  }, [data])

  const featuredPosts = React.useMemo(() => {
    return allPosts.filter(node => node.featured).slice(0, 3)
  }, [allPosts])

  const searchResults = React.useMemo(() => {
    return allPosts.filter(post => {
      // Posts have the data and we filter according to the keys searched
      return (
        post.title.toLowerCase().includes(keyword.toLowerCase()) ||
        post.excerpt.toLowerCase().includes(keyword.toLowerCase())
      )
    })
  }, [allPosts, keyword])

  const getTaggedPosts = (slug, num) => {
    return allPosts.filter(node => node.primary_tag.slug === slug).slice(0, num)
  }

  useEffect(() => {
    if (clicked) inputRef.current.focus()
  }, [clicked])

  // To handle search click and close functionality
  const handleClicked = data => {
    // Update clicked to true or false
    setClicked(data)
    // On click, reset keyword to null
    setkeyword("")
  }

  // To handle keyword change functionality
  const handlekeywordchange = data => {
    setkeyword(data)
  }

  // Ref to auto focus text input on open
  const inputRef = useRef(null)

  return (
    <Layout>
      {/* <Banner /> */}
      <Categories
        tags={tags}
        clicked={clicked}
        handleClick={handleClicked}
        handleChange={handlekeywordchange}
        inputRef={inputRef}
        scrollType="scroll"
      />

      <section className="gradient-blog z-50 pb-10 md:pb-20">
        {/* Hide all posts is search term is not null */}
        {keyword === "" && (
          <div className="space-y-10 md:space-y-20">
            {/* Featured posts */}
            <div className="mx-auto mt-5 max-w-6xl">
              <Posts
                posts={featuredPosts}
                heading="Featured Posts"
                showFeaturedposts={false}
                featured={true}
                showSlider={true}
              />
            </div>
            {/* End Featured posts */}

            {/* Latest Posts */}
            <div className="mx-auto max-w-6xl">
              <Posts
                posts={allPosts.slice(0, 4)}
                heading="Latest"
                viewAllLink="/blog/all/"
                showFeaturedposts={true}
                clicked={true}
                showSlider={true}
              />
            </div>
            {/* End Latest posts */}

            {/* subscription */}
            <div className="mx-auto max-w-6xl px-4 md:px-6 xl:px-0">
              <Subscription subType="blog" />
            </div>
            {/* end subscription end */}

            {/* section */}
            <div id="levitate" className="mx-auto max-w-6xl">
              <Posts
                posts={getTaggedPosts("levitate", 4)}
                heading="Levitate"
                viewAllLink="/blog/tag/levitate/"
                showFeaturedposts={true}
                showSlider={true}
              />
            </div>
            {/* End section */}

            {/* section */}
            <div id="deep-dives" className="mx-auto max-w-6xl">
              <Posts
                posts={getTaggedPosts("deep-dives", 4)}
                heading="Deep Dives"
                viewAllLink="/blog/tag/deep-dives/"
                showFeaturedposts={true}
                showSlider={true}
              />
            </div>
            {/* End section */}
          </div>
        )}
        {/* search results */}
        {keyword !== "" && (
          <div className="is-stacked group mx-auto max-w-6xl pb-20">
            <Posts
              posts={searchResults}
              heading={`Search Results for '${keyword}'`}
              searched={true}
            />
          </div>
        )}
        {/* end search results */}
      </section>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Blog"
    description="MTTR obsession > Uptime illusionary fatigue. Want to know more on how we think of Change Intelligence, and SRE? Freshly minted
  from our stables"
  />
)

export const query = graphql`
  query AllBlogs {
    allGhostPost(
      filter: {
        tags: {
          elemMatch: { slug: { nin: ["hash-last9-events", "changelog"] } }
        }
      }
      sort: { fields: published_at, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          title
          excerpt
          featured
          published_at
          reading_time
          authors {
            slug
            id
            name
            profile_image
          }
          primary_tag {
            name
            slug
          }
          tags {
            name
            slug
          }
          feature_image
        }
      }
    }
  }
`

export default Blog
