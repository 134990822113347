import React, { useEffect, useState } from "react"
import RtaPage from "../../components/9page/rtaPage"
import Seo from "../../components/common/seo"
import LoadingBar from "react-top-loading-bar"
import Layout from "../../components/common/layout"

export default function NinePage() {
  const [pageLoaded, setPageLoaded] = useState(false)
  const loadingRef = React.useRef(null)
  useEffect(() => {
    if (loadingRef.current && !pageLoaded) {
      loadingRef.current.continuousStart()
    }
    if (loadingRef.current && pageLoaded) {
      loadingRef.current.complete()
    }
  }, [pageLoaded])
  function completePageLoad() {
    console.log("page loaded")
    setPageLoaded(true)
  }
  return (
    <Layout>
      <LoadingBar
        color="#04BF83"
        height={5}
        ref={loadingRef}
        containerStyle={{
          position: "absolute",
          top: 68,
          left: 0,
          zIndex: 40,
        }}
      />
      <RtaPage completePageLoad={completePageLoad} />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="The Last9 Ṛta - Our Ethos and Principles"
    description="Last9’s Ṛta is the ethos and reasons behind our products. We want people to consistently experience our intentions and aspirations behind how we think of products."
    image="/thumbnails/thumbnail-last9-rta.jpg"
  />
  
)
