import React from "react"
import { Link } from "gatsby"
import Balancer from "react-wrap-balancer"
import openStandards from "../../../static/icons/cost-hero.png"
import heroImg from "../../../static/microsites/reduce-monitoring-costs-hero.png"
import NoCreditCard from "../common/NoCreditCard"

const Hero = () => {
  return (
    <div className="mx-auto block w-full max-w-6xl">
      {/* icon */}
      <div className="mb-8 hidden h-16 w-12 items-center justify-center rounded-full md:flex">
        <img className="h-auto w-auto" src={openStandards} />
      </div>
      {/* end icon */}

      {/* body */}
      <div className="mb-40 flex flex-col-reverse items-center gap-8 md:flex-row">
        <div className="flex flex-col gap-8 md:basis-1/2">
          <div className="flex flex-col gap-6 text-slate-600">
            <h2 className="text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
              <Balancer>Reduce Monitoring Costs</Balancer>
            </h2>
            <div className="flex flex-col gap-4 text-base md:text-xl">
              <p>
                You’re most certainly overpaying for your Observability and
                Monitoring.
              </p>
              <p>
                Control data with Streaming Aggregations, eliminate expensive
                queries with built-in control levers and data tiering. With the
                Bring Your Own Cloud (BYOC) deployment model, reduce the total
                cost of your Monitoring setup without worrying about data growth
                and overage fees.
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center gap-3 md:items-start">
            {/* buttons */}
            <div className="flex items-center justify-center gap-4 md:justify-start">
              <Link
                to="/schedule-demo"
                className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-slate-900 px-6 text-center text-base font-bold text-white"
              >
                Talk to Us
              </Link>
              <a
                href="https://app.last9.io/"
                className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-slate-100 px-6 text-center text-base font-bold text-slate-500"
              >
                Start for Free
              </a>
            </div>
            {/* end buttons */}
            <NoCreditCard />
          </div>
        </div>

        <div className="-mx-4 basis-1/2 bg-yellow-50 md:mx-0 md:bg-transparent">
          <img
            src={heroImg}
            alt="high cardinality on levitate"
            className="max-h-full"
          />
        </div>
      </div>
      {/* end body */}
    </div>
  )
}

export default Hero
