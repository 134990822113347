import React from "react"
import { Link } from "gatsby"
import { useGhostPost } from "../../contexts/GhostPostContext"
import Avatar from "../common/Avatar"
import PropTypes from "prop-types"

const Author = ({ author }) => {
  return (
    <div className="flex w-full items-center gap-8 text-slate-400">
      <div className="flex gap-4">
        <Avatar className="h-12 w-12" size={48} src={author.profile_image} />
        <div className="flex flex-1 flex-col">
          <p className="text-base text-slate-600">
            <Link
              className="decoration-slate-200 hover:underline"
              to={`/blog/authors/${author.slug}/`}
            >
              {author.name}
            </Link>
          </p>
          <p className="text-sm text-slate-500">{author.bio}</p>
        </div>
      </div>
      {author?.twitter === null ? null : (
        <a
          href={`https://twitter.com/${author.twitter}`}
          target="_blank"
          rel="noreferrer"
          className="transition-colors hover:text-[#1d9bf0]"
        >
          <svg
            width={20}
            height={20}
            role="img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
          </svg>
        </a>
      )}
    </div>
  )
}

Author.propTypes = {
  author: PropTypes.object.isRequired,
}

const BlogFooter = () => {
  const { authors, tags } = useGhostPost()

  return (
    <div
      className={`col-start-1 col-end-13 flex flex-col gap-10 border-t border-solid border-slate-200 pt-8 lg:col-end-9 lg:row-start-5 lg:row-end-6`}
    >
      <div className="flex flex-col gap-6 lg:flex-row lg:gap-24">
        <div className="inline-block w-20 text-sm font-bold uppercase text-slate-400 lg:basis-20">
          Authors
        </div>
        <div className="flex w-full flex-col gap-6">
          {authors.map(author => (
            <Author key={author.id} author={author} />
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-6 lg:flex-row lg:gap-24">
        <div className="inline-block w-20 text-sm font-bold uppercase text-slate-400 lg:basis-20">
          Topics
        </div>
        <div className="flex w-full flex-wrap gap-1">
          {tags
            .filter(tag => !tag.slug.startsWith("hash-"))
            .map(tag => (
              <Link
                to={`/blog/tag/${tag.slug}/`}
                key={tag.id}
                className="rounded bg-slate-50 px-2 py-1 text-xs font-medium text-slate-500"
              >
                {tag.name}
              </Link>
            ))}
        </div>
      </div>
    </div>
  )
}

export default BlogFooter
