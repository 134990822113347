import clsx from "clsx"
import React from "react"
import { Link } from "gatsby"

const Button = ({ as = "button", className, size = "regular", ...rest }) => {
  const Component = as

  if (Component === "link") {
    return (
      <Link
        className={clsx(
          size === "regular" && "h-14 px-6 leading-[56px]",
          "rounded-full text-base font-bold !no-underline",
          className,
        )}
        {...rest}
      />
    )
  }

  return (
    <Component
      className={clsx(
        size === "regular" && "h-14 px-6 leading-[56px]",
        "rounded-full text-base font-bold !no-underline",
        className,
      )}
      {...rest}
    />
  )
}

export default Button
