import React from "react"
import Layout from "../../../components/common/layout"
import Cookies from "js-cookie"
import Balancer from "react-wrap-balancer"
import { Link } from "gatsby"
import bannerImage from "../../../../static/img/building-reliability.svg"
import Seo from "../../../components/common/seo"
import { ENV } from "../../../constants/env"

const Subscription = () => {
  const URL = `https://api.hsforms.com/submissions/v3/integration/submit/${ENV.HS_ACCOUNT_ID}/${ENV.HS_BLOG_SUB_FORM_ID}`
  const [isLoading, setIsLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)

  const handleFormSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)
    const formData = new FormData(event.target)

    // Building request body
    const data = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: formData.get("email"),
        },
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: formData.get("firstname"),
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: formData.get("lastname"),
        },
      ],
      context: {
        hutk: Cookies.get("hubspotutk"),
        pageUri: window && window.location.href,
        pageName: document && document.title,
      },
    }

    try {
      const response = await fetch(URL, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.ok) {
        setSuccess(true)
        setIsLoading(false)
      } else throw new Error("Something went wrong")
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      setIsLoading(false)
      setError(true)
    }
  }

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 rounded bg-[#FBFFE6] px-6 py-10 md:py-20 lg:gap-8">
      <span className="max-w-xl text-center text-3xl font-extrabold text-brand-blue-100 lg:text-5xl">
        <Balancer>Stay updated on the latest from Last9.</Balancer>
      </span>

      <div
        onSubmit={handleFormSubmit}
        className="relative mx-auto flex w-full max-w-md gap-4 p-2"
      >
        {!success && !error && (
          <form className="flex w-full flex-col justify-between gap-4 md:gap-2 ">
            <div className="flex w-full flex-col gap-4 md:flex-row md:gap-2">
              <input
                className="w-full rounded-full border border-solid border-slate-200 px-4 py-3"
                placeholder="First name"
                name="firstname"
                type="text"
                disabled={isLoading}
                required
              />
              <input
                className="w-full rounded-full border border-solid border-slate-200 px-4 py-3"
                placeholder="Last name"
                name="lastname"
                type="text"
                disabled={isLoading}
                required
              />
            </div>
            <input
              type="email"
              name="email"
              disabled={isLoading}
              placeholder="Enter your email..."
              className="w-full rounded-full border border-solid border-slate-200 px-4 py-3"
            />
            <button
              type="submit"
              disabled={isLoading}
              className="w-full rounded-full bg-brand-green-200 px-4 py-2.5 text-sm font-bold text-white md:px-6"
            >
              Subscribe
            </button>
          </form>
        )}
        {success && (
          <div className="flex h-full w-full items-center justify-center rounded-full bg-brand-green-200 p-4 text-sm font-medium text-white transition-transform md:p-2">
            Thanks! We&apos;ll keep you posted.
          </div>
        )}
        {error && (
          <div className="flex h-full w-full items-center justify-center rounded-full bg-red-600 p-4 text-sm font-medium text-white transition-transform md:p-2">
            Something went wrong. Please reload the page and try again?
          </div>
        )}
      </div>

      <span className="max-w-lg text-center text-base text-slate-600">
        <Balancer>
          While you wait for the new stuff, check out our{" "}
          <Link to="/blog/" className="underline" title="blog">
            Blog
          </Link>{" "}
          and{" "}
          <Link to="/changelog/" className="underline" title="changelog">
            Changelog
          </Link>
          .
        </Balancer>
      </span>
    </div>
  )
}

function index() {
  return (
    <Layout>
      <div className="flex w-full flex-col bg-[#FBFFE6]">
        <Subscription />
      </div>
      <img
        src={bannerImage}
        alt="building reliability"
        className="position-center"
      />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Subscribe to Last9 blog"
    description="Stay updated on the latest updates from Last9 blog"
  />
)

export default index
