import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import dateFormat from "dateformat"
import { CalendarIcon, LocationMarkerIcon } from "@heroicons/react/solid"
import { SkeletonLine } from "../common/Skeleton"

const sponsorChipStyle = {
  Gold: "bg-yellow-50 text-yellow-500",
  Silver: "bg-slate-50 text-slate-500",
  Bronze: "bg-amber-50 text-amber-500",
  Platinum: "bg-zinc-50 text-zinc-500",
  Diamond: "bg-sky-50 text-sky-500",
}

const options = {
  month: "long",
  day: "numeric",
  year: "numeric",
  timeZone: "UTC",
}

const getDateString = (date, showTime) => {
  const localeDate = date.toLocaleDateString(undefined, options)
  if (!showTime) return dateFormat(localeDate, "mmmm dS, yyyy")

  return dateFormat(localeDate, "h:MM TT, mmmm dS, yyyy")
}

const EventCard = ({ event, horizontal }) => {
  const startDate = new Date(event?.date.start)
  const endDate = event?.date.end && new Date(event?.date.end)

  return (
    <div
      className={clsx(
        "solid-light-shadow flex h-fit w-full gap-6 rounded-2xl border border-solid border-slate-100 bg-white p-6",
        horizontal ? "flex-col items-center md:flex-row" : "flex-col"
      )}
    >
      <div className="aspect-video h-60 w-full overflow-hidden rounded-lg lg:aspect-auto lg:h-80">
        <img
          className="h-full w-full object-cover"
          loading="eager"
          src={event?.imageUrl}
        />
      </div>
      <div className="flex h-fit w-full flex-col gap-6">
        <div className="flex flex-row gap-1">
          {event?.isFeatured && (
            <span
              className={clsx(
                "bg-blue-50 text-blue-500",
                "w-fit rounded-full px-3 py-1 text-xs font-bold uppercase"
              )}
            >
              Featured
            </span>
          )}
          {event?.isSponsoring && (
            <span
              className={clsx(
                sponsorChipStyle[event?.sponsor] ??
                  "bg-yellow-50 text-yellow-500",
                "w-fit rounded-full px-3 py-1 text-xs font-bold uppercase"
              )}
            >
              {`${event?.sponsor} Sponsor`}
            </span>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-2xl font-bold">
            <a href={event?.primaryCTA?.url} className="text-slate-600">
              {event?.title}
            </a>
          </h3>
          {event?.speaker?.name && (
            <p className="text-sm text-slate-500 ">
              By{" "}
              {typeof event?.speaker?.url === "string" ? (
                <a
                  href={event?.speaker?.url}
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {event?.speaker?.name}
                </a>
              ) : (
                <>{event?.speaker?.name}</>
              )}
            </p>
          )}
          {event?.description && (
            <p className="text-sm text-slate-600 line-clamp-2">
              {event?.description}
            </p>
          )}
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          {event?.location?.title && (
            <div className="flex items-center gap-1 text-xs text-slate-400">
              <LocationMarkerIcon className="h-4 w-4" />
              {event?.location.url ? (
                <a
                  href={event?.location.url}
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {event?.location?.title}
                </a>
              ) : (
                <span>{event?.location?.title}</span>
              )}
            </div>
          )}
          <div className="flex items-center gap-1 text-xs text-slate-400">
            <CalendarIcon className="h-4 w-4" />
            <p className="inline-block whitespace-nowrap">
              <span>
                {getDateString(startDate, event?.date.start?.length > 10)}
              </span>
              {endDate && (
                <span>
                  {" "}
                  - {getDateString(endDate, event?.date.end?.length > 10)}
                </span>
              )}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {event?.primaryCTA?.title && (
            <a
              href={event?.primaryCTA?.url}
              className="flex h-10 w-fit items-center justify-center rounded-full bg-brand-green-200 px-4 text-center text-sm font-semibold leading-10 text-white hover:bg-emerald-600"
              target="_blank"
              rel="noreferrer noopener"
            >
              {event?.primaryCTA?.title}
            </a>
          )}
          {event?.secondaryCTA?.title && (
            <a
              href={event?.secondaryCTA?.url}
              className="flex h-10 w-fit items-center justify-center rounded-full border-2 border-solid border-slate-200 bg-transparent px-4 text-center text-sm font-semibold leading-10 text-slate-400 hover:border-slate-300"
              target="_blank"
              rel="noreferrer noopener"
            >
              {event?.secondaryCTA?.title}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

EventCard.propTypes = {
  horizontal: PropTypes.bool,
  event: PropTypes.shape({
    imageUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isSponsoring: PropTypes.bool.isRequired,
    isFeatured: PropTypes.bool.isRequired,
    sponsor: PropTypes.string,
    speaker: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    location: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    primaryCTA: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    secondaryCTA: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    date: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      // eslint-disable-next-line camelcase
      time_zone: PropTypes.string,
    }),
  }).isRequired,
}

export const EventCardSkeleton = ({ horizontal = false }) => {
  return (
    <div
      className={clsx(
        "solid-light-shadow flex h-fit w-full gap-6 rounded-2xl border border-solid border-slate-100 bg-white p-6",
        horizontal ? "flex-col md:flex-row" : "flex-col"
      )}
    >
      <div className="shine aspect-video h-60 w-full overflow-hidden rounded-lg lg:aspect-auto lg:h-80"></div>
      <div className="flex h-fit w-full flex-col gap-6">
        <div className="flex flex-row gap-1">
          <SkeletonLine className="h-6 w-28" />
        </div>
        <div className="flex flex-col gap-2">
          <SkeletonLine className="h-6 w-full" />
          <SkeletonLine className="h-6 w-full" />
        </div>
      </div>
    </div>
  )
}

EventCardSkeleton.propTypes = {
  horizontal: PropTypes.bool,
}

export default EventCard
