import React from "react"
import { useGhostPost } from "../../contexts/GhostPostContext"

const HeroImage = () => {
  const { feature_image: featureImage, title } = useGhostPost()

  if (!featureImage)
    return (
      <div className="col-start-1 col-end-13 row-start-2 lg:col-start-1 lg:col-end-13"></div>
    )

  return (
    <img
      src={featureImage}
      alt={title}
      loading="lazy"
      className="col-start-1 col-end-13 row-start-2 block h-full w-full overflow-hidden rounded-3xl border border-solid border-blue-100/10 object-cover lg:col-start-1 lg:col-end-13 lg:max-h-[600px]"
    />
  )
}

export default HeroImage
