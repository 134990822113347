/* eslint-disable react/prop-types */
import React from "react"

const ExternalScripts = ({ children }) => {
  const listener = React.useCallback(() => {
    window.loadPixel && window.loadPixel("9ad9c871999b50bf339b677f6927ca8c")
  }, [])

  const injectScripts = async () => {
    return new Promise(resolve => {
      const script = document.createElement("script")
      script.src = "https://pxl.sprouts.ai/latest/pixel.js"

      document.head.appendChild(script)
      script.addEventListener("load", listener)

      resolve(() => {
        document.head.removeChild(script)
        script.removeEventListener("load", listener)
      })
    })
  }

  React.useLayoutEffect(() => {
    let cleanup = () => {}
    if (process.env.NODE_ENV === "production")
      (async function () {
        cleanup = await injectScripts()
      })()

    return cleanup
  }, [])

  return <>{children}</>
}

export default ExternalScripts
