/* eslint-disable react/prop-types */
import React from "react"
import Resources from "./navigation/resources"
import Platform from "./navigation/platform"
import { navPlatform, navResources } from "./data"
import { XIcon } from "@heroicons/react/outline"
import { Link } from "gatsby"

const MobileNavigation = ({ setOpenMenu }) => {
  return (
    <div className="pointer-events-auto absolute inset-x-0 top-0 h-screen overflow-y-scroll bg-[#f7f5ef] lg:pointer-events-none lg:hidden">
      <div className="sticky top-0 z-50 flex h-16 items-center gap-2 border-b border-solid border-slate-200 bg-[#f7f5ef] px-4">
        <button
          onClick={() => setOpenMenu(false)}
          className="mt-1 inline-flex items-center justify-center rounded-md p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-200"
        >
          <span className="sr-only">Close menu</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <Link to="/">
          <span className="sr-only">Last9</span>
          <img
            className="h-10 w-auto sm:h-10"
            src="/header/logo.svg"
            alt="Last9"
            loading="eager"
          />
        </Link>
      </div>
      <div className="flex flex-col items-stretch gap-8 p-6 pb-20">
        <div className="flex flex-col items-stretch gap-3 px-1">
          <span className="text-base font-medium text-slate-600">Platform</span>
          <Platform cases={navPlatform} />
        </div>
        <div className="flex flex-col items-stretch gap-3 px-1">
          <span className="text-base font-medium text-slate-600">
            Resources
          </span>
          <Resources resources={navResources} />
        </div>
        <div className="flex flex-col gap-6">
          <Link
            to="/blog"
            className="flex items-center rounded-md px-2 text-base font-medium text-slate-600"
          >
            Blog
          </Link>
          <Link
            to="/about-us"
            className="flex items-center rounded-md px-2 text-base font-medium text-slate-600"
          >
            About Us
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MobileNavigation
