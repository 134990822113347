import React from "react"
import QuoteLight from "../common/QuoteLight"
import authorImg from "../../../static/home/matt-replit.jpeg"
import { quotes } from "../../constants/quotes"

const ReplitQuote = () => {
  return (
    <QuoteLight
      clientName="replit"
      author={{
        img: authorImg,
        name: "Matt Iselin",
        company: "Head of SRE",
      }}
      quote={quotes["matt-iselin"]}
    />
  )
}

export default ReplitQuote
