import React from "react"
import { Link } from "gatsby"
import levitateHero from "../../../static/home/levitate.webp"
import NoCreditCard from "../common/NoCreditCard"

const Hero = () => {
  return (
    <div className="mx-auto block w-full max-w-6xl">
      {/* body */}
      <div className="mb-16 flex flex-col-reverse items-center gap-8 md:flex-row">
        <div className="flex flex-1 flex-col gap-8 md:basis-1/2">
          <div className="flex flex-col gap-6 text-base text-slate-600 md:text-xl">
            <h1 className="text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
              Cloud Monitoring Solution
            </h1>
            <p className="text-2xl font-bold leading-8 text-slate-600">
              <strong>Levitate</strong> is a Monitoring Platform Purpose Built
              for Modern Cloud Native Applications with High Performance.
            </p>
            <div className="flex flex-col gap-4 text-base md:text-lg">
              <p>
                Levitate is highly available cloud-native monitoring solution,
                built with support for high cardinality and long term metric
                retention.
              </p>
              <p>
                Seamlessly oversee cloud observability, server monitoring, and
                distributed systems. Get unparalleled insights without the high
                costs.
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center gap-3 md:items-start">
            {/* buttons */}
            <div className="flex items-center justify-center gap-4 md:justify-start">
              <Link
                to="/schedule-demo?utm-campaign=monitoring"
                className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-slate-900 px-6 text-center text-base font-bold text-white"
              >
                Talk to Us
              </Link>
              <a
                href="https://app.last9.io?utm-campaign=monitoring"
                className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-slate-100 px-6 text-center text-base font-bold text-slate-500"
              >
                Start for Free
              </a>
            </div>
            {/* end buttons */}
            <NoCreditCard />
          </div>
        </div>

        <div className="-mx-4 flex basis-1/2 justify-center bg-yellow-50 md:mx-0 md:bg-transparent">
          <img
            src={levitateHero}
            alt="data policies on levitate"
            className="hidden h-auto max-w-[300px] md:block"
          />
        </div>
      </div>
      {/* end body */}
    </div>
  )
}

export default Hero
