import React from "react"
import { Link } from "gatsby"
import Balancer from "react-wrap-balancer"
import TimeSeriesDBTable from "../../components/levitate/TimeSeriesDBTable"

const LevitateIsDifferent = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl -mt-14 lg:mt-0 flex-col items-center gap-8">
      <div className="flex w-full max-w-3xl flex-col gap-6">
        <h2 className="text-center text-4xl font-black capitalize tracking-tight text-black md:text-[40px]">
          <Balancer>
            How is Levitate different from other cloud monitoring platforms?
          </Balancer>
        </h2>

        <div className="flex flex-col gap-6 text-center text-lg leading-7 text-slate-600">
          <p>
            <Balancer>
              Levitate is built with warehousing capabilities baked-in to
              mitigate the problems of high cardinality, concurrent access, high
              availability, long term metric storage with fast query
              performance, and proactive alerting.
            </Balancer>
          </p>
          <p>
            Bring precision to your cloud application monitoring strategy. From
            microservice monitoring to system observability, to monitoring the
            cloud, Levitate has got you covered. Focus on what matters, without
            blowing up costs.
          </p>
          <Link
            to="/reduce-monitoring-costs/"
            className="underline decoration-slate-500 underline-offset-2"
          >
            Learn how Levitate reduces 50% of your observability and monitoring
            costs.
          </Link>
        </div>
      </div>

      <TimeSeriesDBTable />
    </div>
  )
}

export default LevitateIsDifferent
