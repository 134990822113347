import React, { useLayoutEffect, useState } from "react"
import PropTypes from "prop-types"

const ReadingProgress = ({ target }) => {
  const [readingProgress, setReadingProgress] = useState(0)
  const scrollListener = () => {
    if (!target.current) return

    const element = target.current
    const totalHeight =
      element.clientHeight - element.offsetTop - window.innerHeight
    const windowScrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    if (windowScrollTop === 0) return setReadingProgress(0)

    if (windowScrollTop > totalHeight) return setReadingProgress(100)
    const progresRatio = windowScrollTop / totalHeight
    setReadingProgress(progresRatio * 100)
  }

  useLayoutEffect(() => {
    window.addEventListener("scroll", scrollListener)
    return () => window.removeEventListener("scroll", scrollListener)
  })

  return (
    <div
      className={`reading-progress-bar z-40 -mx-4 lg:-mx-6`}
      style={{ width: `${readingProgress}%` }}
    />
  )
}

ReadingProgress.propTypes = { target: PropTypes.object }

ReadingProgress.defaultProps = { target: `` }

export default ReadingProgress
