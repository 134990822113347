
import step1 from "./webm/step_1.webm"
import step1mp4 from "./720-mp4/step_1.mp4" 
import step2 from "./webm/step_2.webm"
import step2mp4 from "./720-mp4/step_2.mp4" 
import step3 from "./webm/step_3.webm"
import step3mp4 from "./720-mp4/step_3.mp4" 
import step1To2 from "./webm/step_transition_1.webm"
import step1To2mp4 from "./720-mp4/step_transition_1.mp4"
import step2To3 from "./webm/step_transition_2.webm"
import step2To3mp4 from "./720-mp4/step_transition_2.mp4"
import lastHeroWidemp4 from "./720-mp4/hero_wide.mp4"
import lastHeroWide from "./webm/hero_wide.webm"
import tieredStoragemp4 from "./720-mp4/tiered_storage.mp4"
import tieredStorage from "./webm/tiered_storage.webm"
import levitateEnginesmp4 from "./720-mp4/levitate_engines.mp4"
import levitateEngines from "./webm/levitate_engines.webm"
import lastHeromp4 from "./720-mp4/hero_zoomed.mp4"
import lastHero from "./webm/hero_zoomed.webm"


export const whitepaperDotLottie = {
  cubeFloating: "https://lottie.host/71f0006a-698a-44ab-9aa4-6305e66b460d/cjlAuIRVny.lottie",
  lastHero: lastHeromp4,
  step1: step1mp4,
  step2: step2mp4,
  step3: step3mp4,
  step1To2: step1To2mp4,
  step2To3: step2To3mp4,
  lastHeroWide: lastHeroWidemp4,
  tieredStorage: tieredStoragemp4,
  levitateEngines:levitateEnginesmp4,
  // conveyorS1: baseUrlMp4 + "conveyor1.mp4",
  // conveyorS2: baseUrlMp4 + "conveyor2.mp4",
  // conveyorS3: baseUrlMp4 + "conveyor3.mp4",
  // conveyorS1To2: baseUrlMp4 + "conveyor_transition_1.mp4",
  // conveyorS2To3: baseUrlMp4 + "conveyor_transition_2.mp4",
  step1webm: step1,
  step2webm: step2,
  step3webm: step3,
  step1To2webm: step1To2,
  step2To3webm: step2To3,
  // conveyorS1webm: baseUrl + "conveyor_1.webm",
  // conveyorS2webm: baseUrl + "conveyor_2.webm",
  // conveyorS3webm: baseUrl + "conveyor_3.webm",
  // conveyorS1To2webm: baseUrl + "conveyor_transition_1.webm",
  // conveyorS2To3webm: baseUrl + "conveyor_transition_2.webm",
  lastHeroWidewebm: lastHeroWide,
  lastHerowebm: lastHero,
  tieredStoragewebm: tieredStorage,
  levitateEngineswebm: levitateEngines,
}