import React from "react"
import SLAQuote from "../quotes/SLAQuote"

const Clawbacks = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8">
      <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 text-xl md:flex-row lg:gap-16">
        <div className="flex flex-col items-center gap-8 text-base text-slate-600 md:basis-1/2 md:items-start md:text-xl lg:basis-auto">
          <h3 className="w-full max-w-lg text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
            Superior SLAs with Clawbacks
          </h3>
          <p>
            We’re committed to providing a managed offering that goes above and
            beyond current industry offerings. That’s why we back up our
            offerings with Service Level Agreement (SLA) guarantees and
            clawbacks for both Read and Write workloads.
          </p>
          <div className="flex w-full justify-center gap-6 md:justify-start">
            <div className="flex w-full max-w-[120px] flex-col">
              <span className="whitespace-nowrap text-[32px] font-black leading-8 text-emerald-400">
                99.9%
              </span>
              <span className="text-sm font-bold uppercase leading-4 text-slate-400">
                write availability
              </span>
            </div>
            <div className="flex w-full max-w-[120px] flex-col">
              <span className="whitespace-nowrap text-[32px] font-black leading-8 text-emerald-400">
                99.5%
              </span>
              <span className="text-sm font-bold uppercase leading-4 text-slate-400">
                read availability
              </span>
            </div>
          </div>
          <p>
            By being a fully managed platform, Levitate takes away the toil of
            running and managing an in-house cloud native monitoring system at
            scale.
          </p>
        </div>

        <aside className="flex flex-col items-center gap-8 text-slate-600 md:basis-1/2 md:items-start lg:basis-auto">
          <SLAQuote />
        </aside>
      </div>
    </div>
  )
}

export default Clawbacks
