/* eslint-disable react/prop-types */
import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../../components/common/seo"
import Layout from "../../components/common/layout"
import Avatar from "../../components/common/Avatar"

const Author = ({ author }) => {
  return (
    <Link
      to={`/blog/authors/${author.slug}/`}
      className="flex flex-row items-center gap-6"
    >
      <Avatar src={author.profile_image} size={96} />
      <div className="flex-1">
        <p className="text-xl font-medium leading-7">{author.name}</p>
        <p className="text-base leading-6 text-slate-600 line-clamp-2">
          {author.bio}
        </p>
      </div>
    </Link>
  )
}

const Authors = ({ data }) => {
  const allAuthors = React.useMemo(() => {
    return data.allGhostAuthor.nodes
  }, [data.allGhostAuthor.nodes])

  return (
    <Layout>
      <div className="flex w-full flex-col gap-20 px-4 pb-[120px] pt-10">
        <div className="mx-auto w-full max-w-6xl">
          <h1 className="text-4xl font-black leading-[48px]">Authors</h1>
          <p className="text-xl leading-7 text-slate-500">
            Meet the amazing folks who have shared their learnings and thoughts
            on Last9 blog.
          </p>
        </div>
        <div className="mx-auto grid w-full max-w-6xl grid-flow-row grid-cols-1 gap-10 md:grid-cols-2">
          {allAuthors.map(author => {
            return <Author key={author.id} author={author} />
          })}
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title={`Authors ⏤ Last9`}
    description={`Meet the amazing folks who have shared their learnings and thoughts
    on Last9 blog.`}
  />
)

export const query = graphql`
  query MyQuery {
    allGhostAuthor(filter: { postCount: { gt: 0 } }) {
      nodes {
        id
        slug
        name
        bio
        profile_image
        postCount
      }
    }
  }
`

export default Authors
