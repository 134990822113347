import React from "react"
import Balancer from "react-wrap-balancer"
import Lottie from "react-lottie-player"
import animationData from "../../animations/cricket-scale.json"

const CricketScaleHero = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-0 md:justify-start">
      <div className="flex min-h-[calc(100vh-5rem)] w-full flex-col items-center justify-end gap-20 md:gap-6 md:min-h-screen md:justify-start">
        <h2 className="md:leading-0 w-full max-w-4xl text-center text-5xl font-black leading-[1] md:mx-auto md:text-7xl">
          <Balancer>What in the world is Cricket Scale?</Balancer>
        </h2>
        <div className="mx-auto flex h-[60vh] w-full max-w-7xl items-center justify-center overflow-hidden p-0 md:h-full">
          <Lottie
            animationData={animationData}
            className="block aspect-[791/600] h-full w-auto md:h-auto md:w-full"
            play
            loop
          />
        </div>
      </div>
      <p className="max-w-md px-6 text-center text-3xl font-extrabold text-black md:px-0">
        <Balancer>
          There are only a few events in the world that attract a global
          audience.
        </Balancer>
      </p>
    </div>
  )
}

export default CricketScaleHero
