import React from "react"
import * as tocbot from "tocbot"
import "./TableOfContent.css"

const TableOfContent = () => {
  React.useLayoutEffect(() => {
    tocbot.init({
      tocbotOptions: {
        // Where to render the table of contents.
        tocSelector: ".js-toc",
        // Where to grab the headings to build the table of contents.
        contentSelector: ".js-toc-content",
        // Which headings to grab inside of the contentSelector element.
        headingSelector: "h2, h3",
        // For headings inside relative or absolute positioned containers within content.
        hasInnerContainers: true,
        // How many heading levels should not be collapsed.
        // For example, number 6 will show everything since
        // there are only 6 heading levels and number 0 will collapse them all.
        // The sections that are hidden will open
        // and close as you scroll to headings within them.
        collapseDepth: 0,
        headingsOffset: 120,
        scrollSmoothOffset: -120,
      },
    })
  }, [])
  return (
    <div className="flex w-full flex-col gap-3">
      <h4 className="text-sm font-bold uppercase text-slate-500">Contents</h4>
      <div className="js-toc text-sm"></div>
    </div>
  )
}

export default TableOfContent
