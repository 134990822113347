import React from "react"
import { Link } from "gatsby"
import Balancer from "react-wrap-balancer"
import PropTypes from "prop-types"
import { CloudDownloadIcon } from "@heroicons/react/outline"

import whitepaperPdf from "../../../static/whitepaper/Whitepaper-Scaling-Metrics.pdf"
import levitate from "../../../static/cricket-scale/levitate.svg"
import chart from "../../../static/cricket-scale/chart.svg"
import whitepaper from "../../../static/cricket-scale/whitepaper.svg"
import levitateImage from "../../../static/cricket-scale/levitate-image.svg"
import Thunder from "./Thunder"
import Avatar from "../common/Avatar"

const StackedCard = ({ title, subtitle, children }) => {
  return (
    <div className="relative h-[240px] w-full max-w-[483px]">
      <div className="relative z-10">
        <div className="gradient-border relative h-[230px] w-full rounded-[7px] bg-[#141414] p-6 before:rounded-lg md:rounded-[11px] before:md:rounded-[10px]">
          <div className="flex h-full flex-col justify-between gap-3">
            <h4 className="text-gradient-bg text-6xl font-medium md:text-8xl md:font-extrabold">
              {title}
            </h4>
            <h5 className="text-3xl font-extrabold text-white">{subtitle}</h5>
          </div>
          {children}
        </div>
      </div>
      <div className="absolute left-5 top-2.5 z-0 w-[calc(100%-40px)]">
        <div className="gradient-border relative h-[230px] w-full rounded-[7px] bg-[#141414] p-6 before:rounded-lg md:rounded-[11px] before:md:rounded-[10px]"></div>
      </div>
    </div>
  )
}

StackedCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node,
}

const Point8 = () => {
  const downloadPdf = () => {
    const save = document.createElement("a")
    save.href = whitepaperPdf
    save.download = "Levitate Whitepaper — Scaling Metrics Maturity"
    save.target = "_blank"
    document.body.appendChild(save)
    save.click()
    document.body.removeChild(save)
  }

  return (
    <section className="relative flex w-full flex-col items-center justify-center bg-black px-4 py-12 md:py-28">
      <h2 className="max-w-5xl text-center text-3xl font-extrabold text-white md:text-7xl">
        <Balancer>What makes Last9 special?</Balancer>
      </h2>
      <h3 className="mt-8 max-w-lg text-center text-sm font-bold text-white md:text-xl">
        <Balancer>
          We have Levitate. A Managed Time Series Data Warehouse.
        </Balancer>
      </h3>
      <div className="mt-12 flex w-full flex-col items-center md:mt-20">
        <img src={levitate} className="w-64" alt="" />
      </div>
      <div className="mt-12 flex w-full max-w-[986px] flex-col gap-2.5 md:mt-16">
        <div className="flex w-full flex-col items-center rounded bg-[#141414] px-12 md:flex-row md:rounded-xl">
          <img src={chart} className="w-64 md:w-96" alt="" />
          <div className="py-8 pt-0 md:py-20">
            <p className="text-center text-xl font-extrabold text-white md:text-left md:text-3xl">
              <Balancer>
                Levitate is built from the ground up with warehousing
                capabilities.
              </Balancer>
            </p>
            <ul className="bullet-ticks mt-4 text-xs text-white md:mt-6 md:text-base">
              <li>Scale Reliably</li>
              <li>Slash Costs</li>
              <li>Tame High Cardinality</li>
            </ul>
          </div>
        </div>
        <div className="flex w-full flex-col gap-2.5 md:flex-row">
          <div className="w-full rounded bg-[#141414] px-8 py-6 md:rounded-xl">
            <p className="text-sm font-bold text-[#797979] md:text-xl">
              <span className="text-white">Open Standards</span> We are Prometheus and OpenTelemetry compatible, and work with most Open Standard protocols.
            </p>
          </div>
          <div className="w-full rounded bg-[#141414] px-8 py-6 md:rounded-xl">
            <p className="text-sm font-bold text-[#797979] md:text-xl">
              <span className="text-white">High Cardinality</span> Control data growth with streaming aggregations and slash operational overhead of expensive queries.
            </p>
          </div>
          <div className="w-full rounded bg-[#141414] px-8 py-6 md:rounded-xl">
            <p className="text-sm font-bold text-[#797979] md:text-xl">
              <span className="text-white">Data Tiering</span> Incoming data is automatically tiered to optimize time series storage based on specific metrics use cases.
            </p>
          </div>
        </div>
        <div className="w-full rounded bg-[#141414] px-8 py-6 md:rounded-xl">
          <p className="text-sm font-bold text-[#797979] md:text-xl">
            <span className="text-white">50% Less TCO</span> Levitate is
            available in two models: SaaS and Managed Deployment. With the
            Managed Deployment option, eliminate engineering toil and hidden
            egress data transfer costs.
          </p>
        </div>
        <div className="w-full rounded bg-[#141414] px-8 py-6 md:rounded-xl">
          <p className="text-sm font-bold text-[#797979] md:text-xl">
            <span className="text-white">Global Availability</span> Available across geographies so data never leaves your infrastructure. Levitate commits to a 99.9% write-availability and a 99.5% read-availability.
          </p>
        </div>
        <div className="-z-0 flex w-full flex-col items-center justify-center gap-8 overflow-hidden rounded bg-[#141414] p-8 md:flex-row md:gap-0 md:rounded-xl md:p-0 md:pt-12">
          <img src={whitepaper} className="w-52 md:w-96" alt="" />
          <div className="flex w-fit flex-col items-center gap-8 md:gap-6">
            <p className="text-center text-xl font-extrabold text-white md:text-3xl">
              Read our Whitepaper
              <br />
              to learn more about Levitate.
            </p>
            <button
              onClick={downloadPdf}
              className="gradient-border relative flex w-fit cursor-pointer items-center gap-3 rounded-full bg-[#141414] px-8 py-6 font-semibold text-white before:rounded-full hover:bg-gray-900 md:px-12"
            >
              <CloudDownloadIcon width={20} height={20} />
              Download
            </button>
          </div>
        </div>
      </div>

      {/* case study */}
      <h2 className="mt-[72px] max-w-5xl text-center text-3xl font-extrabold text-white md:mt-24 md:text-7xl">
        <Balancer>How does this help manage Cricket Scale?</Balancer>
      </h2>
      <p className="mt-4 max-w-lg text-center font-extrabold text-white md:mt-9 md:font-normal md:text-[#9A9A9A]">
        <Balancer>
          Levitate is the Time Series Data Warehouse that SREs trust.
        </Balancer>
        <Balancer>Key numbers from the last IPL.</Balancer>
      </p>
      <div className="mt-8 flex w-full flex-col items-center justify-center gap-5 md:mt-12 md:flex-row">
        <StackedCard title="5T" subtitle="Total Data Samples">
          <div className="dot-10 absolute right-6 top-6 h-[60px] w-[120px]"></div>
        </StackedCard>
        <StackedCard title="100ms" subtitle="Max Read Latency">
          <div className="absolute right-6 top-6 h-[60px]">
            <Thunder />
          </div>
        </StackedCard>
      </div>
      <h4 className="mt-16 max-w-lg text-center text-xl font-bold text-white md:mt-24 md:text-3xl md:font-extrabold">
        <Balancer>Case Study</Balancer>
      </h4>
      <div className="mt-12 flex w-full max-w-[986px] flex-col items-center justify-center gap-6 rounded bg-[#141414] px-3 pb-12 md:mt-11 md:flex-row md:gap-28 md:rounded-xl md:px-0 md:pb-0">
        <img
          src={levitateImage}
          className="h-64 -translate-y-4 md:h-72"
          alt=""
        />
        <div className="flex flex-col items-center md:items-start">
          <p className="max-w-md text-center text-sm font-bold text-white md:text-left md:text-xl">
            <Balancer>
              India vs Pakistan, Site Reliability Engineering, and Shannon Limit
            </Balancer>
          </p>
          <div className="mt-4 flex items-center gap-3 md:gap-4">
            <Avatar
              src="https://last9.ghost.io/content/images/2022/11/CF6BBAE5-E3A2-40BA-AF4B-B1BE7B9D4E3C.JPG"
              name="Satyajeet Jadhav"
              size={40}
            />
            <div>
              <p className="text-sm font-bold text-white md:text-base">
                Satyajeet Jadhav
              </p>
              <p className="text-sm text-[#9A9A9A] md:text-base">
                Customer Success, Last9
              </p>
            </div>
          </div>
          <div className="mt-6 flex w-full flex-col items-stretch gap-3 md:flex-row md:items-center md:gap-4">
            <Link
              to="/blog/india-vs-pakistan-site-reliability-engineering-and-shannon-limit/"
              className="w-full rounded-full border border-solid border-transparent bg-brand-green-200 px-4 py-2 text-center font-semibold text-white hover:bg-brand-green-100 md:w-fit md:px-10 md:py-3"
            >
              Read Article
            </Link>
            <Link
              to="/schedule-demo"
              className="w-full rounded-full border border-solid border-white px-4 py-2 text-center font-semibold text-white hover:bg-gray-900 md:w-fit md:px-10 md:py-3"
            >
              Get a Walkthrough
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Point8
