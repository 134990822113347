/* eslint-disable react/no-unescaped-entities */
import * as React from "react"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"

const Privacy = () => (
  <Layout>
    <section className="gradient-blog z-50">
      <div className="mx-auto max-w-6xl px-5 pt-16">
        <h1 className="py-5 text-5xl font-extrabold">Privacy Policy</h1>
        <p className="my-5">
          Effective Date: Nov 08, 2021 • Last Updated On: Nov 08, 2021
        </p>
        <hr className="my-10 border-2" />
        <p className="my-5">
          This privacy policy (“Policy”) explains how Last9 inc. or any of its
          affiliates or subsidiaries (hereby collectively referred to as
          (“Last9”, “We”, “Us”, “Our”) processes Personal Data collected from
          You.
        </p>
        <p className="my-5">
          Capitalized terms not explicitly defined herein shall have the same
          meaning as ascribed to them in the Terms of Service.
        </p>

        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">1. Definitions</h1>
          <p className="my-5">
            1.1 — “Controller” means the natural or legal person, public
            authority, agency, or other body which alone or jointly with
          </p>
          <p className="my-5">
            1.2 — “End-User” means any person or entity with whom the Subscriber
            interacts using the Service(s).
          </p>
          <p className="my-5">
            1.3 — “Personal Data” means any information relating to an
            identified or identifiable natural person; an identifiable natural
            person is one who can be identified, directly or indirectly, in
            particular by reference to an identifier such as a name, an
            identification number, location data, an online identifier or to one
            or more factors specific to the physical, physiological, genetic,
            mental, economic, cultural or social identity of that natural
            person.
          </p>
          <p className="my-5">
            1.4 — “Process” means any operation or set of operations which is
            performed on Personal Data or on sets of Personal Data, whether or
            not by automated means, such as collection, recording, organisation,
            structuring, storage, adaptation or alteration, retrieval,
            consultation, use, disclosure by transmission, dissemination or
            otherwise making available, alignment or combination, restriction,
            erasure or destruction.
          </p>
          <p className="my-5">
            1.5 — “Service(s)” shall have the meaning ascribed to it in the
            Terms of Service.s
          </p>
          <p className="my-5">
            1.6 — “Subscriber” means the natural or legal person that has
            subscribed to the Service(s) by agreeing to the Terms of Service.
          </p>
          <p className="my-5">
            1.7 — “Terms of Service” means the binding contract between Us and
            You which governs the access and use of the Service(s) by You
          </p>
          <p className="my-5">
            1.8 — “Website(s)” means the websites that We operate.
          </p>
          <p className="my-5">
            1.9 — “You” and “Your” means an identified or identifiable natural
            person whose Personal Data We Process as a Controller.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            2. Personal data collected by us
          </h1>
          <p className="my-5">
            2.1 — You directly provide Us with most of the data We collect. We
            collect Personal Data from You directly when You: a) subscribe for
            any of Our Service(s) by agreeing to the Terms of Service, We
            collect sign-up and account information including Your name and
            e-mail address; b) submit web forms on Our Website(s) or as You use
            interactive features of the Website(s), including providing feedback
            or suggestions, making requests, or participation in surveys,
            contests, webinars, events, podcasts, promotions, sweepstakes,
            requesting customer support, or otherwise communicating with Us; c)
            provide testimonials, forms or information to Us in other contexts;
            or d) authorize Us to connect with a Third Party Service(s), We will
            access and store Your Personal Data that the Third Party Service(s)
            makes available to Us, which may include Your email address,
            location or profile information;
          </p>
          <p className="my-5">
            2.2 — We may also receive Your Personal Data indirectly as follows:
            a) from third party sources like marketing lists, databases and
            social media but only where We have checked that these third parties
            either have Your consent or are otherwise legally permitted or
            required to disclose Your Personal Data to Us; b)When You download
            and/or use the Service(s), We automatically collect information on
            the type of device You use, and the operating system version, to
            perform Our agreement with You; e) When You use or view Our
            Website(s), information is collected via Your browser’s cookies as
            described in clause 9 herein; f) We may also collect or receive Your
            Personal Data from other sources such as Our business or channel
            partners through whom You create or access Your Account, publicly
            available sources, email add-ons and/ or through the combining of
            information We obtain from third parties along with the Personal
            Data You provide to Us.
          </p>
          <p className="my-5">
            The Website(s) includes social media features and widgets that are
            either hosted by a third-party or hosted directly on the Website(s)
            and Your interaction with these social media features and widgets is
            governed by the privacy statement of the companies that provide
            them. You should check Your privacy settings on these third-party
            services to understand and change the information sent to Us through
            these Service(s).
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            3. Purposes for which personal data will be processed
          </h1>
          <p className="my-5">
            3.1 — We Process Your Personal Data to: a) facilitate Your access to
            the Website(s) and Service(s); b) process and complete payment
            transactions; c) provide customer service and support; d) send You
            communication on Your use of the Service(s), updates on Our Terms of
            Service or other policies; e) send You communication on new features
            in the Service(s) or new service offerings; f) conduct research,
            analysis and development activities (including but not limited to
            data analytics, surveys and/or profiling) to improve Our Service(s)
            and facilities in order to enhance Your relationship with Us or for
            Your benefit, or to improve any of Our Service(s) for Your benefit;
            e) Organise events or for other marketing/ promotional activities;
            f) investigate and prevent fraudulent transactions, unauthorized
            access to the Websites and Service(s), and other illegal activities;
            g) personalize the Websites and Service(s); and for other purposes
            for which We obtain Your consent.
          </p>
          <p className="my-5">
            3.2 — Legal basis for processing (UK and EEA Region)
          </p>
          <p className="my-5 pl-8">
            3.2.1 — If You are a data subject from the UK or European Economic
            Area, Our legal basis for collecting and using the Personal Data
            described above will depend on the Personal Data concerned and the
            specific context in which Last9 collects it.
          </p>
          <p className="my-5 pl-8">
            3.2.2 — We will normally collect Personal Data from You only where
            it needs it to perform a contract with You, where the processing is
            in Our legitimate interests and not overridden by Your data
            protection interests or fundamental rights and freedoms, or where We
            have Your consent. In some cases, We may also have a legal
            obligation to collect Personal Data from You. If We process Your
            Personal Data with reliance on your consent, You may withdraw Your
            consent at any time.
          </p>
          <p className="my-5 pl-8">
            3.2.3 — If you have questions or need further information concerning
            the legal basis on which We collect and use Your Personal Data,
            please contact Us using the contact details provided under Clause
            12.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            4. Sharing of personal data
          </h1>
          <p className="my-5">
            4.1 — You acknowledge that We will share Your Personal Data with Our
            group companies and third-party service providers so that they may
            offer You Our Service(s) and/or to send information or updates on
            the Service(s) if You are a Subscriber.
          </p>
          <p className="my-5">
            4.2 — When We Process Your order where You are a Subscriber, we may
            send Your Personal Data to and also use the resulting information
            from credit reference agencies to prevent fraudulent purchases.
          </p>
          <p className="my-5">
            4.3 — We share Personal Data with Our third-party service providers
            that host and maintain Our Website(s), applications, backup,
            storage, payment processing, analytics and other service(s). These
            third-party service providers may have access to or Process Your
            Personal Data for the purpose of providing these service(s) to Us.
          </p>
          <p className="my-5">
            4.4 — We may share Your Personal Data with third-party service
            providers who assist Us in marketing and promotions in compliance
            with applicable laws.
          </p>
          <p className="my-5">
            4.5 — We may be required to disclose Your Personal Data in response
            to: a) lawful requests by public authorities, including to meet
            national security or law enforcement requirements and/or b)
            subpoenas, court orders, or legal process, or to establish or
            exercise Our legal rights or defend against legal claims.
          </p>
          <p className="my-5">
            4.6 — We may also share Personal Data to assist investigation and
            prevention of illegal activities, suspected fraud, situations
            involving potential threats to the physical safety of any person,
            violations of Our Terms of Service, or as otherwise required by law.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            5. International Transfer
          </h1>
          <p className="my-5">
            We mainly Process Personal Data in United States. However, we may
            transfer Personal Data outside the United States for the purposes
            referred to in Section 4. We will ensure that the recipient of your
            Personal Data offers an adequate level of protection that is at
            least comparable to that which is provided under applicable data
            protection laws.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            6. Retention of personal data
          </h1>
          <p className="my-5">
            6.1 — We retain the Personal Data collected where an ongoing
            legitimate business requires retention of such Personal Data.
          </p>
          <p className="my-5">
            6.2 — In the absence of a need to retain Personal Data under Section
            6.1. above, We will either delete it or aggregate it, or, if this is
            not possible then We will securely store Your Personal Data and
            isolate it from any further Processing until deletion is possible.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            7. Security of personal data
          </h1>
          <p className="my-5">
            We use appropriate technical and organizational measures to protect
            the Personal Data that We collect and Process. The measures We use
            are designed to provide a level of security appropriate to the risk
            of Processing Your Personal Data. If You have questions about the
            security of Your Personal Data, please contact Us immediately as
            described in this Policy.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">8. Your rights</h1>
          <p className="my-5">You are entitled to the following rights:</p>
          <p className="my-5">
            8.1 — You can request Us for access, correction, update or request
            deletion of Your Personal Data.
          </p>
          <p className="my-5">
            8.2 — You can object to the Processing of Your Personal Data, ask Us
            to restrict processing of Your Personal Data or request portability
            of Your Personal Data.
          </p>
          <p className="my-5">
            8.3 — You have the right to opt-out of marketing communications We
            send You at any time. You can exercise this right by clicking on the
            “unsubscribe” or “opt-out” link in the marketing emails We send You.
            To opt-out of other forms of marketing (such as postal marketing or
            telemarketing), please contact Us.
          </p>
          <p className="my-5">
            8.4 — Similarly, if We have collected and processed Your Personal
            Data with Your consent, then You can withdraw your consent at any
            time. Withdrawing Your consent will not affect the lawfulness of any
            processing We have conducted prior to Your withdrawal, nor will it
            affect processing of Your Personal Data conducted in reliance on
            lawful processing grounds other than consent.
          </p>
          <p className="my-5">
            8.5 — You have the right to complain to a data protection authority
            about Our collection and use of Your Personal Data. For more
            information, please contact Your local data protection authority.
          </p>
          <p className="my-5">
            8.6 — If You seek access to, or wish to correct, update, modify or
            delete Your Personal Data that We process, please contact Us at the
            details provided in clause 12. We respond to all requests We receive
            from individuals wishing to exercise their data protection rights
            within a reasonable timeframe in accordance with applicable data
            protection laws.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">9. Cookie Policy</h1>
          <p className="my-5">
            9.1 — Cookies are text files that are placed on Your computer to
            collect standard internet log information and visitor behaviour
            information by Us. When You visit the Website(s), We may collect
            Personal Data automatically from You through cookies or similar
            technology. We also set cookies to collect information that is used
            either in aggregate form to help Us understand how our Website(s)
            are being used or how effective Our marketing campaigns are, to help
            customise the Website(s) for You or to make advertising messages
            more relevant to You.s
          </p>
          <p className="my-5">
            9.2 — Essential Cookies: We set essential cookies that enable core
            functionality such as security, network management, and
            accessibility. You may not opt-out of these cookies. However, You
            may disable these by changing Your browser settings, but this may
            affect how the Website(s) functions.
          </p>
          <p className="my-5">
            9.3 — Analytics, Customisation, and Advertising Cookies: We set
            these cookies to help Us improve Our Website(s) by collecting and
            reporting information on how You use it. The cookies collect
            information in a way that does not directly identify anyone. The
            list of such cookies will be displayed in a banner on the Website(s)
            as required by applicable law.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            10. Privacy of children
          </h1>
          <p className="my-5">
            We recognize the importance of children's safety and privacy. We do
            not request, or knowingly collect, any Personal Data from children
            under the age of 18. If a parent or guardian becomes aware that his
            or her child has provided Us with Personal Data, they should write
            to us at the email address provided in clause 12.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            11. Notice to end-user and other exclusions
          </h1>
          <p className="my-5">
            11.1 — Our Service(s) are intended for use by enterprises. Where the
            Service(s) are made available to an End-User through a Subscriber,
            that enterprise is the Controller of the End-User’s Personal Data.
            In such a case, the End-User’s data privacy questions and requests
            should be submitted to the Subscriber in its capacity as the
            End-User’s Controller. If the End-User is an individual who
            interacts with a Subscriber using Our Service(s), the End-User will
            be directed to contact Our Subscriber for assistance with any
            requests or questions relating to their Personal Data. We are not
            responsible for Subscribers’ privacy or security practices which may
            be different from this notice. Subscribers to Our Service(s) are
            solely responsible for establishing policies for and ensuring
            compliance with all applicable laws and regulations, as well as any
            and all privacy policies, agreements, or other obligations, relating
            to the collection of Personal Data in connection with the use of Our
            Service(s) by End-Users.
          </p>
          <p className="my-5">
            11.2 — Our Website(s) contain links to other websites. Our Policy
            applies only to our Website(s), so if You click on a link to another
            website, You should read their privacy policy. We encourage You to
            review the privacy statements of any such other websites to
            understand their Personal Data practices.
          </p>
          <p className="my-5">
            11.3 — This Policy does not apply to aggregated information which
            summarises statistical information about groups of members, and
            which does not include name, contact information, or any other
            information that would allow any particular individual to be
            identified.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            12. Contact Information
          </h1>
          <p className="my-5">
            You may contact us if You have any enquiries or feedback on Our
            personal data protection policies and procedures, or if You wish to
            make any request, in the following manner:
          </p>
          <p className="my-5">— Kind Attention: Kuldeep Dhankar, CRO</p>
          <p className="my-5">— Email Address: kuldeep@last9.io</p>
          <p className="my-5">
            — Address: Last9 Inc., 355 Bryant Street, Unit 403, San Francisco,
            CA 94107
          </p>
        </div>
        <div className="pb-10">
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            13. Changes to the policy
          </h1>
          <p className="my-5">
            We keep this Policy under regular review and may update this webpage
            at any time. This Policy may be amended at any time and You shall be
            notified only if there are material changes to this Policy.
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export const Head = () => <Seo title="Privacy Policy" />

export default Privacy
