import React from "react"
import { Link } from "gatsby"
import clsx from "clsx"

const GuideCard = ({ post }) => (
  <Link
    to={`.${post.frontmatter.slug}`}
    className={clsx(
      "flex h-full w-full flex-col gap-4 rounded-2xl border border-solid border-slate-100 bg-white p-6 hover:border-slate-300",
    )}
  >
    {/* tags */}
    <ul className="flex flex-wrap gap-1">
      {post.frontmatter.tags
        .split(",")
        .sort()
        .map(tag => (
          <li className="rounded-full bg-slate-100 px-2 py-1 text-xs text-slate-500">
            {tag.trim()}
          </li>
        ))}
    </ul>
    {/* end tags */}

    {/* title + excerpt */}
    <div className="flex flex-col gap-2">
      <h3 className="text-2xl font-black text-black">
        {post.frontmatter.title}
      </h3>

      <p className="line-clamp-4 text-sm text-slate-600">
        {post.frontmatter.excerpt}
      </p>
    </div>
    {/* end title + excerpt */}
  </Link>
)

export default GuideCard
