import React from "react"
import OpenTelemetry from "../../../static/icons/opentelemetry.inline.svg"
import Prometheus from "../../../static/icons/prometheus.inline.svg"
import SinglePane from "../../../static/icons/single-pane.inline.svg"
import PiggyBank from "../../../static/icons/piggy-bank.inline.svg"

const Features = ({
  features = [
    {
      title: "Open Standards",
      description:
        "Built for Cloud Native environments. Fully compatible with OpenTelemetry and Prometheus.",
      icons: [
        {
          component: <OpenTelemetry className="h-6 w-6" />,
        },
        {
          component: <Prometheus className="h-6 w-6" />,
        },
      ],
    },
    {
      title: "Single Pane",
      description:
        "Correlate and alert on your application & infrastructure metrics, events, logs, and traces in one place.",
      icons: [
        {
          component: <SinglePane className="h-6 w-6" />,
        },
      ],
    },
    {
      title: "Simple Pricing",
      description:
        "Predictable “pay for what you use” plan. No per-host billing. No per-user billing. No separate custom metrics billing.",
      icons: [
        {
          component: <PiggyBank className="h-6 w-6" />,
        },
      ],
    },
  ],
}) => {
  return (
    <section className="w-full px-6 py-12">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-12 md:flex-row">
        {features.map(feature => (
          <div key={feature.title} className="flex w-full flex-col gap-2.5">
            <div className="flex gap-2">
              {feature.icons.map((icon, index) => (
                <div key={index} className="h-6 w-6 rounded-full">
                  {icon.component}
                </div>
              ))}
            </div>
            <h3 className="text-xl font-bold text-slate-600">
              {feature.title}
            </h3>
            <p className="text-gray-500">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Features
