import React from "react"
import dataTiering from "../../../static/header/data-tiering.svg"
import Integrations from "../opentelemetry/Integrations"

const Hero = () => {
  return (
    <div className="mx-auto block w-full max-w-6xl">
      <div className="grid auto-rows-min grid-cols-1">
        {/* icon */}
        <div className="mb-10 hidden h-20 w-20 items-center justify-center rounded-full bg-green-100 p-5 md:flex">
          <img className="h-auto w-auto" src={dataTiering} />
        </div>
        {/* end icon */}

        {/* body */}
        <div className="mb-8 flex flex-col-reverse items-center gap-8 md:flex-row">
          <div className="flex flex-col gap-8 md:basis-1/2">
            <div className="flex flex-col gap-6 text-base text-slate-600 md:text-xl">
              <h2 className="text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
                Compare Time Series Databases
              </h2>
              <div className="flex flex-col gap-4 text-base md:text-xl">
                <p className="text-center md:text-left">
                  Get in depth analysis and comparison of different Time Series
                  Databases such as Prometheus, Levitate, Thanos, Cortex,
                  InfluxDB and much more!
                </p>
              </div>
            </div>
          </div>

          <div className="-mx-4 basis-1/2 bg-transparent md:mx-0">
            <Integrations
              skipIntegrations={["OpenTelemetry", "statsd", "AWS PrivateLink"]}
            />
          </div>
        </div>
        {/* end body */}
      </div>
    </div>
  )
}

export default Hero
