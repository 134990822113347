import React from "react"
import Layout from "../../components/common/layout"
import Seo from "../../components/common/seo"
import Hero from "../../components/customer-stories/index/hero"
import Card from "../../components/customer-stories/index/card"
import streamingThumbnail from "../../../static/customer-stories/thumbnail-customer-story-live-streaming.jpg"
import quickworkThumbnail from "../../../static/customer-stories/thumbnail-customer-story-quickwork.jpg"
import proboThumbnail from "../../../static/customer-stories/thumbnail-customer-story-probo.jpg"
import Quote from "../../components/common/Quote"
import imageSrc from "../../../static/home/hotstar-quote-image.webp"
import PreFooter from "../../components/home/PreFooter"

const CustomerStories = ({
  quote = {
    client: "hotstarcolor",
    quote:
      "Last9 has been an amazing partner in making inroads on what a solid observability platform should be.",
    author: "Akash Saxena, ex-CTO, Disney+ Hotstar",
    accent: "#1C00FF",
    img: <img src={imageSrc} className="h-auto w-full max-w-80" />,
  },
}) => {
  return (
    <Layout>
      <Hero />

      <div className="mx-auto w-full max-w-6xl bg-white py-40">
        <div className="-mt-80 grid grid-cols-1 justify-items-center gap-10 md:grid-cols-2">
          <Card
            bg={proboThumbnail}
            title="Probo Cuts Monitoring Costs by 90% with Last9"
            path="/customer-stories/probo-cuts-monitoring-costs-by-90-per-cent-with-last9/"
          />
          <Card
            bg={quickworkThumbnail}
            title="Quickwork champions platform transparency for its customers with Last9"
            path="/customer-stories/quickwork-champions-platform-transparency-for-its-customers-with-last9/"
          />
          <Card
            bg={streamingThumbnail}
            title="Reliable Observability for 25+ million concurrent live-streaming viewers"
            path="/customer-stories/reliable-observability-for-25-million-concurrent-live-streaming-viewers/"
          />
          {/* <Card /> */}
        </div>
      </div>

      <div className="mx-auto max-w-6xl px-6">
        <Quote {...quote} />
      </div>

      <PreFooter type="customers" />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Customer Stories"
    description="From our customers - stories of how organizations are achieving their Observability goals with Last9"
  />
)

export default CustomerStories
