/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Avatar from "./Avatar"
import ClientLogo from "./ClientLogo"

export const QuoteDarkJustBg = ({ children, className }) => {
  return (
    <div
      className={clsx(
        "relative w-full overflow-hidden rounded-3xl bg-brand-blue-100",
        className
      )}
    >
      <div className="relative z-10 h-full w-full p-10 md:p-20">{children}</div>
      <div className="absolute bottom-0 left-0 right-0 top-0 z-0">
        <div className="relative h-full w-full">
          <svg className="h-full w-full">
            <defs>
              <pattern
                id="polka-dots-on-dark"
                x="0"
                y="0"
                width="8"
                height="8"
                patternUnits="userSpaceOnUse"
              >
                <circle fill="#fff" opacity={0.25} cx="3.5" cy="3.5" r="1" />
              </pattern>
            </defs>
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill="url(#polka-dots-on-dark)"
            />
          </svg>
          <div className="absolute bottom-0 left-0 right-0 top-0 z-0 h-full w-full bg-gradient-to-tl from-brand-blue-100/75 to-brand-blue-100/95 bg-blend-darken"></div>
        </div>
      </div>
      {/*
      <div className="absolute bottom-0 left-0 right-0 top-0 z-20"> */}
      {/* </div> */}
    </div>
  )
}

const QuoteDark = ({ className, author, clientName, quote, children }) => {
  return (
    <QuoteDarkJustBg className={className}>
      <div className="flex flex-col items-center gap-10">
        <div className="flex flex-col items-center gap-5">
          {clientName && (
            <ClientLogo size={64} color="#ffffff" name={clientName} />
          )}
          <p className="text-center text-base leading-6 tracking-[-0.33px] text-white md:text-xl md:leading-7">
            {quote}
          </p>
          <div className="flex items-center gap-3">
            <Avatar size={40} alt={author.name} src={author.img} />
            <div className="flex flex-col">
              {author.link ? (
                <a
                  href={author.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm text-white hover:underline"
                >
                  {author.name}
                </a>
              ) : (
                <p className="text-sm text-white">{author.name}</p>
              )}
            </div>
          </div>
        </div>
        {children}
      </div>
    </QuoteDarkJustBg>
  )
}

QuoteDark.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
}

export default QuoteDark
