import React from "react"
import Balancer from "react-wrap-balancer"
import PropTypes from "prop-types"
import Avatars from "./Avatars"
import { Link } from "gatsby"
import clsx from "clsx"

export const RelatedPost = ({ post }) => {
  const avatars = React.useMemo(() => {
    return post?.authors?.map(author => ({
      src: author.profile_image,
      name: author.name,
    }))
  })

  return (
    <Link
      to={`/blog/${post.slug}/`}
      className="flex flex-col gap-4 md:basis-1/3"
    >
      <img
        src={post.featureImage}
        alt={post.title}
        loading="lazy"
        className="aspect-[3/2] rounded-3xl object-cover"
      />
      <h4 className="text-left text-2xl font-black text-black">{post.title}</h4>
      <h4 className="text-left text-base text-slate-600 line-clamp-2">
        {post.excerpt}
      </h4>
      <div className="flex items-center gap-2">
        <Avatars avatars={avatars} />
        <p className="text-sm text-slate-500">
          {post?.authors?.map((author, index) => {
            return (
              <span key={`${author.name}_${post.slug}`}>
                {author.name}
                {index !== post?.authors.length - 1 && ", "}
              </span>
            )
          })}
        </p>
      </div>
    </Link>
  )
}

RelatedPost.propTypes = {
  post: PropTypes.object.isRequired,
}

const RelatedPosts = ({ relatedPosts, bgColor = "bg-yellow-50" }) => {
  return (
    <div
      className={clsx(
        "-mx-4 w-[calc(100%+2rem)] px-4 lg:-mx-6 lg:w-[calc(100%+3rem)] lg:px-6",
        bgColor
      )}
    >
      <div className="mx-auto grid w-full max-w-6xl grid-cols-12 gap-y-10 lg:gap-y-20">
        <div className="col-start-1 col-end-[13] mx-auto flex w-full max-w-6xl flex-col gap-10 py-14 lg:pb-32 lg:pt-28">
          <h2 className="w-full text-center text-3xl font-bold text-black md:text-5xl">
            <Balancer>Handcrafted Related Posts</Balancer>
          </h2>
          <div className="flex w-full flex-col items-center justify-start gap-12 md:flex-row md:items-start md:justify-center md:gap-10">
            {relatedPosts.map(post => {
              return <RelatedPost key={post.id} post={post} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

RelatedPosts.propTypes = {
  relatedPosts: PropTypes.array.isRequired,
  bgColor: PropTypes.string,
}

export default RelatedPosts
