import React from "react"
import { Link } from "gatsby"
import dataTiering from "../../../static/icons/data-tiering-hero.png"
import dataTieringHero from "../../../static/microsites/data-tiering-hero.png"

const Hero = () => {
  return (
    <div className="mx-auto block w-full max-w-6xl">
      {/* icon */}
      <div className="mb-8 hidden h-16 w-12 items-center justify-center rounded-full md:flex">
        <img className="h-full w-full" src={dataTiering} />
      </div>
      {/* end icon */}

      {/* body */}
      <div className="mb-40 flex flex-col-reverse items-center gap-8 md:flex-row">
        <div className="flex flex-col gap-8 md:basis-1/2">
          <div className="flex flex-col gap-6 text-base text-slate-600 md:text-xl">
            <h2 className="text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
              Data Tiering
            </h2>
            <div className="flex flex-col gap-4 text-base md:text-xl">
              <p>
                Stop worrying about latent dashboards and observability outages
                caused due to concurrent access to data by different teams.
              </p>
              <p>
                With Levitate, incoming data is automatically tiered to optimize
                time series storage based on the specific metrics use case.
              </p>
            </div>
          </div>

          {/* buttons */}
          <div className="flex items-center justify-center gap-4 md:justify-start">
            <Link
              to="/schedule-demo"
              className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-slate-900 px-6 text-center text-base font-bold text-white"
            >
              Talk to Us
            </Link>
            <a
              href="https://app.last9.io/"
              className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-slate-100 px-6 text-center text-base font-bold text-slate-500"
            >
              Start for Free
            </a>
          </div>
          {/* end buttons */}
        </div>

        <div className="-mx-4 basis-1/2 bg-yellow-50 md:mx-0 md:bg-transparent">
          <img
            src={dataTieringHero}
            alt="data policies on levitate"
            className="max-h-full"
          />
        </div>
      </div>
      {/* end body */}
    </div>
  )
}

export default Hero
