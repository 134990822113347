import * as React from "react"
import { useRef } from "react"
import Lottie from "react-lottie-player"
import last9Animation from "../../animations/Last9_Hero_Wide.json"
import firstPlat from "../../animations/1stPlat.json"
import secondPlat from "../../animations/2ndPlat.json"
import thirdPlat from "../../animations/3dPlatNew1.json"
import conveyor1 from "../../animations/Set2_State1.json"
import conveyor2 from "../../animations/Set2_State2.json"
import conveyor3 from "../../animations/Set2_Whole.json"
import conveyor1To2 from "../../animations/Set2_Transition1-2.json"
import conveyor2To3 from "../../animations/Set2_Transition2-3.json"
import tieredStorage from "../../animations/Set4-1.json"
import fullBlockAnimation from "../../animations/4-2_DarkBlue.json"
import cubeFloating from "../../animations/CubeFloating2.json"
import cubsFloatingBg from "../../animations/FloatingCube-1.json"
import { useInView } from "react-intersection-observer"
import { gsap } from "gsap"
import conveyorVideo from "../../animations/Animation2_bg.webm"
import conveyorVideoMp4 from "../../animations/Animation2_bg.mp4"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Levitatevertical from "../../../static/whitepaper/Levitate-50.svg"
import whitepaperPdf from "../../../static/whitepaper/Whitepaper-Scaling-Metrics.pdf"
import ConveyorLottie from "../../animations/Set2_Whole.json"
import step3Dotlottie from "../../animations/step3desktop.lottie"
import Balancer from "react-wrap-balancer"

if (typeof window !== "undefined") import("@dotlottie/player-component")

gsap.registerPlugin(ScrollTrigger)
import "./whitepaper.css"
const WhitepaperScrollContent = ({ completePageLoad }) => {
  const [pageLoaded, setPageLoaded] = React.useState(false)

  const animationController = [
    {
      name: "state1",
      data: conveyor1,
      isTransition: false,
    },
    {
      name: "state2",
      data: conveyor1To2,
      isTransition: true,
    },
    {
      name: "state3",
      data: conveyor2,
      isTransition: false,
    },
    {
      name: "state4",
      data: conveyor2To3,
      isTransition: true,
    },
    {
      name: "state5",
      data: conveyor3,
      isTransition: false,
    },
  ]
  const loadingRef = useRef(null)
  const { ref, inView } = useInView({ trackVisibility: true, delay: 100 })
  const [ref1, inView1] = useInView()
  const [ref2, inView2] = useInView()
  const containerRef = useRef(null)
  const [cubeState, setCubeState] = React.useState(0)
  const [cubeState2, setCubeState2] = React.useState(0)
  const [cubeState3, setCubeState3] = React.useState(0)
  const [currentAnimation, setCurrentAnimation] = React.useState(0)

  React.useEffect(() => {
    if (inView)
      setTimeout(() => {
        setCubeState(2)
        setTimeout(() => {
          setCubeState(3)
        }, 500)
      }, 500)
  }, [inView])

  React.useEffect(() => {
    if (inView1)
      setTimeout(() => {
        setCubeState2(2)
        setTimeout(() => {
          setCubeState2(3)
        }, 500)
      }, 500)
  }, [inView1])

  React.useEffect(() => {
    setTimeout(() => {
      setCubeState3(2)
      setTimeout(() => {
        setCubeState3(3)
      }, 500)
    }, 500)
  }, [inView2])

  React.useEffect(() => {
    let count = 0
    const con = gsap.timeline()
    completePageLoad()
    //Scroll to top on reload
    window.scrollTo(0, 0)

    if (ScrollTrigger) {
      ScrollTrigger.killAll()
    }
    // <--------------------Steps Animation-------------------->
    let obs2
    let onEnterSteps = ""
    let stepsPlaying = false
    let animationState = 0
    const t2 = gsap.timeline()
    t2.set(".stage1", { opacity: 1, display: "block" })
    t2.set(".secondPlat", { opacity: 0, y: 500 }).set(".stage2", {
      opacity: 0,
      display: "none",
    })
    const t3 = gsap.timeline()
    t3.set(".thirdPlat", { opacity: 0, y: 500 }).set(".stage3", {
      opacity: 0,
      display: "none",
    })

    const scrollDownSteps = () => {
      if (animationState === 1) {
        stepsPlaying = true
        const t2New = gsap.timeline()
        t2New
          .to(".stage1", { duration: 0, opacity: 0, display: "none" }, 0)
          .to(".stage2", { opacity: 1, duration: 1, display: "block" }, 0)
          .to(
            ".secondPlat",
            {
              opacity: 1,
              y: 0,
              duration: 1,
              onComplete: () => {
                animationState = 2
                stepsPlaying = false
              },
            },
            0
          )
      }
      if (animationState === 2) {
        stepsPlaying = true
        const t3New = gsap.timeline()
        t3New.to(".stage2", { opacity: 0, duration: 0, display: "none" }, 0)
        t3New.to(".stage3", { opacity: 1, duration: 1, display: "block" }, 0)
        t3New.to(
          ".thirdPlat",
          {
            opacity: 1,
            y: 0,
            duration: 1,
            onComplete: () => {
              animationState = 3
              stepsPlaying = false
            },
          },
          0
        )
      }
    }

    const scrollUpSteps = () => {
      if (animationState === 3) {
        stepsPlaying = true
        const tl1 = gsap.timeline()
        tl1
          .to(".stage3", { duration: 0, opacity: 0, display: "none" }, 0)
          .to(".stage2", { opacity: 1, duration: 1, display: "block" }, 0)
          .to(
            ".thirdPlat",
            {
              opacity: 0,
              duration: 1,
              y: 500,
              onComplete: () => {
                animationState = 2
                stepsPlaying = false
              },
            },
            0
          )
      }
      if (animationState === 2) {
        stepsPlaying = true
        const tl2 = gsap.timeline()
        tl2
          .to(".stage2", { opacity: 0, duration: 0, display: "none" }, 0)
          .to(".stage1", { opacity: 1, duration: 1, display: "block" }, 0)
          .to(
            ".secondPlat",
            {
              opacity: 0,
              duration: 1,
              y: 500,
              onComplete: () => {
                animationState = 1
                // if(onEnterSteps === "") return
                // if (onEnterSteps.scroll() > onEnterSteps.start + 0 * (onEnterSteps.end - onEnterSteps.start))
                // onEnterSteps.scroll(onEnterSteps.start + 0 * (onEnterSteps.end - onEnterSteps.start))
                stepsPlaying = false
              },
            },
            0
          )
      }
    }

    obs2 = ScrollTrigger.observe({
      id: "ob-2",
      type: "wheel,touch,scroll,pointer",
      target: window,
      onUp: () => {
        if (!stepsPlaying) scrollUpSteps()
      },
      onDown: () => {
        if (!stepsPlaying) scrollDownSteps()
      },
      dragMinimum: 5,
      debounce: true,
      tolerance: 40,
    })
    obs2.disable()

    const trigger2 = ScrollTrigger.create({
      trigger: ".pin",
      start: "top top+=69px",
      end: "+=200%",
      pin: true,
      anticipatePin: 1.2,
    })
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".trigger-1",
        start: "top center",
        end: "+=2px",
        anticipatePin: 1,
        toggleActions: "play none reverse none",
        preventOverlaps: true,
      },
    })
    timeline
      // .to(".stage1", { duration: 0, opacity: 0, display: "none" }, 0)
      .to(
        ".secondPlat",
        {
          opacity: 1,
          duration: 0.4,
          y: 0,
          onComplete: () => {
            stepsPlaying = false
          },
        },
        0
      )
      .to(".stage2", { opacity: 1, duration: 0.4, display: "block" }, 0)

    const timeline1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".trigger-2",
        start: "top center",
        end: "+=2px",
        anticipatePin: 1,
        // scrub: 1,
        toggleActions: "play none reverse none",
        preventOverlaps: true,
      },
    })
    timeline1
      // .from(".stage2", { opacity: 1, duration: 0}, 0)
      // .to(".stage2", { opacity: 0, duration: 0, display: "none" }, 0)
      .to(
        ".thirdPlat",
        {
          opacity: 1,
          duration: 0.4,
          y: 0,
          onComplete: () => {
            stepsPlaying = false
          },
        },
        0
      )
      .to(".stage3", { opacity: 1, duration: 0.4, display: "block" }, 0)
    //<--------------------Steps Animation End-------------------->
    // ScrollTrigger.normalizeScroll(true)
    return () => {
      ScrollTrigger.killAll()
    }
  }, [])

  const downloadPdf = () => {
    var save = document.createElement("a")
    save.href = whitepaperPdf
    save.download = "Levitate Whitepaper — Scaling Metrics Maturity"
    save.target = "_blank"
    document.body.appendChild(save)
    save.click()
    document.body.removeChild(save)
  }

  return (
    <>
      <div
        id="scroll-1"
        className="diff-bg relative flex h-[calc(100vh_-_69px)] snap-start items-center justify-center"
        ref={ref2}
      >
        <div className="mx-auto flex max-w-7xl flex-col items-center justify-around py-12 md:flex-row">
          <p className="second-text-w hero-text text-[36px]">
            Technology-focused companies across all industries rely on cloud
            infrastructure & microservices to deliver value to customers, and by
            extension, profits for the business.
            <br /> <br />
            The benefits of a performant infrastructure must be obviously
            apparent, and so must their degradations. The measurement and
            attribution of performance in a complex software environment is
            called observability.
            <br /> <br />
            Therefore, observability is a first-class engineering citizen in an
            org.
          </p>
        </div>
        <Lottie
          animationData={cubsFloatingBg}
          style={{ width: 64 }}
          className="float-animaton absolute top-0 left-[5%] z-[30]"
          play
          loop
        />
        <Lottie
          animationData={cubsFloatingBg}
          style={{ width: 64 }}
          className="float-animaton absolute top-[10%] left-[5%] z-[30]"
          play
          loop
        />
        <Lottie
          animationData={cubsFloatingBg}
          style={{ width: 64 }}
          className="float-animaton absolute top-[20%] left-[90%] z-[30]"
          play
          loop
        />
        <Lottie
          animationData={cubsFloatingBg}
          style={{ width: 64 }}
          className="float-animaton absolute top-[30%] left-[15%] z-[30]"
          play
          loop
        />
        {cubeState3 > 0 && (
          <>
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute top-[80%] left-[10%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute top-[20%] left-0 z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute top-[50%] left-[20%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute top-[10%] left-[80%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute top-[60%] right-[10%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute top-[20%] right-0 z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute top-[42%] right-[20%] z-[30]"
              play
              loop
            />
          </>
        )}
        {cubeState3 > 2 && (
          <>
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute top-[10%] right-[25%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute bottom-[50%] right-[10%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute bottom-[20%] right-0 z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute bottom-[30%] right-[20%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubsFloatingBg}
              style={{ width: 64 }}
              className="float-animaton absolute bottom-[10%] right-[80%] z-[30]"
              play
              loop
            />
          </>
        )}
      </div>
      <div
        id="scroll-2"
        className="conveyor-section panel h-[calc(100vh_-_69px)] w-full overflow-hidden"
      >
        <div className="conveyor-container flex h-[100%] flex-col items-start justify-center md:flex-row">
          <div className="converyor-animation-wrapper h-full">
            <Lottie
              animationData={ConveyorLottie}
              className="float-animaton h-full"
              play
              loop
            />
            {/* <video
              autoPlay={true} muted loop={true}
              playsInline
              className="converyor-animation canimate-1 animation w-full h-full"
            >
              <source src={conveyorVideo} type="video/webm" />
              <source src={conveyorVideoMp4} type="video/mp4" />

            </video> */}
          </div>
          <div className="converyor-text mt-[150px] flex flex-col items-center justify-center px-[40px] py-[20px]">
            <p className="second-text-w  second-text-ws conveyor-text-head">
              As you climb the ladder of reliability, metrics increase and,
              correspondingly, the breadth of questions. Modern Time Series
              systems don’t have to grow along a single axis of Cardinality,
              Coverage, or Retention alone. Instead, the rate of ingestion and
              exploration warrants an expansion on all three axes.
            </p>
            <br /> <br />
            <p className="second-text-ws ctext-1">
              <b> 1. Coverage: </b>More metrics are to be observed
            </p>
            <p className="second-text-ws ctext-2">
              <b> 2. Retention: </b>Save metrics for a longer duration
            </p>
            <p className="second-text-ws ctext-3">
              <b> 3. Cardinality: </b>Same metrics for more entities
            </p>
          </div>
        </div>
      </div>
      <div className="panel relative mt-5" id="steps" ref={containerRef}>
        <div className="responsive sticky top-0 mx-auto flex h-[100vh] flex-col items-center justify-center self-start overflow-x-hidden overflow-y-hidden md:flex-row">
          <div className="relative flex h-[100%] flex-col items-center justify-start pt-[120px]">
            <p className="second-text-ws stage-head">
              The outcomes people want to achieve with Observability differ for
              different company stages. We broadly classified organisations into
              three stages.
              <br /> <br />
            </p>
            <div className="stage-container stage1">
              <p className="stage-text">
                <span className="span-1">
                  <b>🧱 Stage 1 </b> <br />
                </span>
                <span className="span-2">
                  <b>The Initial Stages.</b> <br />
                </span>
                <div className="text-strip text-strip-1">
                  <p>
                    5 engineers &nbsp; • &nbsp; 10 customers &nbsp; • &nbsp; 5
                    services
                  </p>
                </div>
                <div>
                  <p className="second-text-ws text-3">
                    In this initial stage, the scale of data is easily
                    manageable. Small teams, low complexity, and low business
                    maturity mean essential incident detection is the focus,
                    rather than deeper intelligence over extended periods. Data
                    retention needs are also at their lowest. There’s no
                    pressure to do better centralization, or formally
                    democratizing access to time series data.
                  </p>
                </div>
              </p>
            </div>
            <div className="stage-container stage2">
              <p className="stage-text mt-[-3px]">
                <span className="span-1">
                  <b>🏃 Stage 2 </b> <br />
                </span>
                <span className="span-2">
                  <b>Operational Readiness.</b> <br />
                </span>
                <div className="text-strip text-strip-1">
                  <p>
                    30 engineers &nbsp; • &nbsp; 50 customers &nbsp; • &nbsp; 20
                    services
                  </p>
                </div>
              </p>
              <div>
                <p className="second-text-ws text-3">
                  Tolerance towards failures diminishes. There’s more focus
                  towards feature velocity. This is typically where you see an
                  explosion of infra and monitoring bills. To deliver quality
                  customer experiences, teams aspire to find problems before
                  their customers. Teams must implement alerting & dashboarding
                  to detect & diagnose incidents. Friction between business,
                  engineering and infrastructure teams start growing. Formalized
                  DevOps processes are considered at this stage.
                </p>
              </div>
            </div>
            <div className="stage-container stage3">
              <p className="stage-text">
                <span className="span-1 mt-[-2px]">
                  <b>🧠 Stage 3 </b> <br />
                </span>
                <span className="span-2">
                  <b>
                    Complete Organizational
                    <br /> Intelligence.
                  </b>{" "}
                  <br />
                </span>
                <div className="text-strip text-strip-1">
                  <p>
                    100+ engineers &nbsp; • &nbsp; 1000+ customers &nbsp; •
                    &nbsp; 100+ services
                  </p>
                </div>
              </p>
              <div>
                <p className="second-text-ws text-3">
                  At this scale, metric data is needed by different teams -
                  across product, finance, support, & customer success, to
                  properly run a business. Data growth explodes across all
                  dimensions. Enterprises must dedicate full-time engineering
                  resources to manage their time series database. Greater
                  business maturity moves the focus of engineering teams from
                  code quality to customer experience quality.
                </p>
              </div>
            </div>
          </div>
          <div className="stepAnimation">
            <Lottie
              animationData={firstPlat}
              className="firstPlat animation"
              play
              loop
            />
            <Lottie
              animationData={secondPlat}
              className="secondPlat animation"
              play
              loop
            />
            {/* <Lottie animationData={thirdPlat} className="thirdPlat animation" play loop /> */}
            <dotlottie-player
              autoplay
              loop
              mode="normal"
              src={step3Dotlottie}
              class="thirdPlat animation"
              style={{ height: 1290 }}
            ></dotlottie-player>
          </div>
        </div>
        <div className="trigger-1 absolute top-[40%] left-0 z-[-1] h-[2px] w-full bg-[#000] opacity-0"></div>
        <div className="trigger-2 absolute top-[65%] left-0 z-[-1] h-[2px] w-full bg-[#ccc] opacity-0"></div>
        <div className="h-[1200px] w-full"></div>
      </div>

      <div
        className="flex-column relative flex h-[calc(100vh_-_69px)] items-center"
        id="levitate"
        ref={ref}
      >
        <div className="float-wrapper relative">
          <p className="float-text">Introducing</p>
          <div className="float-head">
            <span>
              <img
                src={Levitatevertical}
                className="text-center"
                alt="Levitate"
                style={{ width: "700px", display: "inline-block" }}
              />
            </span>
          </div>
          <p className="float-text">
            A time series warehouse built to manage scale
          </p>
        </div>
        <Lottie
          animationData={cubeFloating}
          style={{ width: 64 }}
          className="float-animaton absolute top-0 left-[5%] z-[30]"
          play
          loop
        />
        <Lottie
          animationData={cubeFloating}
          style={{ width: 64 }}
          className="float-animaton absolute top-[10%] left-[5%] z-[30]"
          play
          loop
        />
        <Lottie
          animationData={cubeFloating}
          style={{ width: 64 }}
          className="float-animaton absolute top-[20%] left-[60%] z-[30]"
          play
          loop
        />
        <Lottie
          animationData={cubeFloating}
          style={{ width: 64 }}
          className="float-animaton absolute top-[80%] left-[60%] z-[30]"
          play
          loop
        />
        {cubeState > 0 && (
          <>
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[80%] left-[10%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[20%] left-0 z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[80%] left-[20%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[10%] left-[80%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[80%] right-[10%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[20%] right-0 z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[80%] right-[20%] z-[30]"
              play
              loop
            />
          </>
        )}
        {cubeState > 2 && (
          <>
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[10%] right-[60%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute bottom-[50%] right-[10%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute bottom-[20%] right-0 z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute bottom-[30%] right-[20%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute bottom-[10%] right-[80%] z-[30]"
              play
              loop
            />
          </>
        )}
      </div>

      <div
        id="tiered-storage"
        className="tiered-storage relative flex h-[calc(100vh_-_20px)] w-full snap-start items-center justify-center overflow-hidden"
      >
        <Lottie
          animationData={tieredStorage}
          className="tiered-storage-animation animation mr-[-200px]"
          play
          loop
        />

        <div className="absolute top-[64px] left-[128px] w-[479px]">
          <h1 className="second-text tiered-head">Tiered Storage</h1>
          <p className="second-regular-text">
            Inspired by principles of Data Warehousing, where Data Tiering is a
            common phenomenon, Levitate introduces tiers for the Time Series
            storage. You don’t just pay extra for what you don’t use; you pay
            extra because of what you don’t use.
          </p>
        </div>
        <div className="tier-float cold">
          <h3 className="tier-float-head">Cold</h3>
          <p>
            Query Depth: <b>3 months</b>
          </p>
          <p>
            Concurrency: <b>100</b>
          </p>
        </div>
        <div className="tier-float hot">
          <h3 className="tier-float-head">Hot</h3>
          <p>
            Query Depth: <b>7 days</b>
          </p>
          <p>
            Concurrency: <b>1000</b>
          </p>
        </div>
        <div className="tier-float blaze">
          <h3 className="tier-float-head">Blaze</h3>
          <p>
            Query Depth: <b>1 hour</b>
          </p>
          <p>
            Concurrency: <b>5000</b>
          </p>
        </div>
      </div>

      <div
        id="tiered-storage-complete"
        className="tiered-storage relative h-[calc(100vh_-_69px)] w-full snap-start overflow-hidden pt-[80px]"
      >
        <h1 className="levitate-head-1 mt-8">What makes Levitate different:</h1>
        <div className="flex h-[calc(100%_-_45px)] items-center justify-center">
          <div className="fullBlockContainer relative flex h-[100%] w-[45%] items-center justify-center">
            <Lottie
              animationData={fullBlockAnimation}
              className="tiered-storage-animation2 animation"
              play
              loop
            />
            <div className="levitate-blocks block-1">
              <div className="mb-[30px] w-[350px]">
                <h3 className="levitate-head">Policy Engine</h3>
                <p className="levitate-text w-full">
                  Capability to express, evaluate data storage, and access
                  rules.
                </p>
              </div>
            </div>
            <div className="levitate-blocks block-2">
              <div className="w-[350px]">
                <h3 className="levitate-head">Query-Routing Engine</h3>
                <p className="levitate-text w-full">
                  Based on tokens + source, a query routing engine routes
                  queries to their rightful tiers. The overhead of evaluation is
                  minimal.
                </p>
              </div>
            </div>
            <div className="levitate-blocks block-3">
              <div className="mb-[30px] w-[350px]">
                <h3 className="levitate-head">Consumption Engine</h3>
                <p className="levitate-text w-full">
                  The consumption engine keeps track of metrics being consumed,
                  and makes them available to all other engines.
                </p>
              </div>
            </div>
            <div className="levitate-blocks block-4">
              <div className="w-[350px]">
                <h3 className="levitate-head">Sync Engine</h3>
                <p className="levitate-text w-full">
                  Separating write and read channels, guaranteeing ingestion no
                  matter how heavy the read loads are.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="relative mt-[1px] flex h-[calc(100vh_-_69px)] flex-wrap items-center justify-center"
        ref={ref1}
      >
        <Lottie
          animationData={cubeFloating}
          style={{ width: 64 }}
          className="float-animaton absolute top-[20%] right-0 z-[30]"
          play
          loop
        />
        <Lottie
          animationData={cubeFloating}
          style={{ width: 64 }}
          className="float-animaton absolute top-[80%] right-[20%] z-[30]"
          play
          loop
        />
        <Lottie
          animationData={cubeFloating}
          style={{ width: 64 }}
          className="float-animaton absolute top-[0%] right-[80%] z-[30]"
          play
          loop
        />
        {cubeState2 > 0 && (
          <>
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-text-largescreen float-animaton absolute bottom-[50%] right-[10%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute bottom-[20%] right-0 z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-text-largescreen float-animaton absolute bottom-[30%] right-[10%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute bottom-[10%] right-[80%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-text-largescreen float-animaton absolute top-0 left-[5%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-text-largescreen float-animaton absolute top-[10%] left-[5%] z-[30]"
              play
              loop
            />
          </>
        )}
        {cubeState2 > 2 && (
          <>
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[5%] left-[40%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[80%] left-[60%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[80%] left-[10%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[20%] left-0 z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[80%] left-[20%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[10%] left-[90%] z-[30]"
              play
              loop
            />
            <Lottie
              animationData={cubeFloating}
              style={{ width: 64 }}
              className="float-animaton absolute top-[80%] right-[10%] z-[30]"
              play
              loop
            />
          </>
        )}
        <div className="mx-auto flex max-w-md flex-col items-center justify-center gap-6 text-center">
          <h1 className="text-3xl font-black text-brand-blue-100">
            <Balancer>Want to know more? Download the Whitepaper.</Balancer>
          </h1>
          <p className="text-lg text-brand-blue-100/75">
            Find out more about how the scale of metrics and breadth of
            questions grow as companies mature, the challenges as your business
            climbs the ladder of reliability and Levitate’s managed time-series
            offering to scale with a software business growth.
          </p>
          <button
            class="downloadWhitepaper rounded-full bg-brand-blue-100 py-4 px-6 font-bold text-white hover:bg-brand-blue-200"
            onClick={downloadPdf}
          >
            Download Whitepaper
          </button>
        </div>
      </div>
    </>
  )
}

export default WhitepaperScrollContent
