import React from "react"
import Balancer from "react-wrap-balancer"

const Hero = () => {
  return (
    <div className="mx-auto block w-full max-w-6xl">
      <div className="grid auto-rows-min grid-cols-1">
        {/* body */}
        <div className="mb-40 flex flex-col items-center gap-8">
          <div className="flex max-w-lg flex-col items-center gap-8">
            <div className="flex flex-col gap-6 text-base text-slate-600 md:text-xl">
              <h2 className="text-center text-4xl font-black tracking-tight text-black md:text-6xl">
                <Balancer>Community and Events</Balancer>
              </h2>
              <div className="flex flex-col gap-4 text-center text-base md:text-xl">
                <p>
                  <Balancer>
                    Stay updated on the latest events we’re hosting, and
                    attending.
                  </Balancer>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
