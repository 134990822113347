let prevTime = new Date().getTime()

export function setPrevTime(value) {
  prevTime = value
}

export function getPrevTime() {
  return prevTime
}

export const oncePerScroll = (function () {
  let canTriggerEvent = true
  let prevWheelTime = new Date().getTime()
  let result

  return function (scrollTrigger, callback, e) {
    const currentTime = new Date().getTime()
    const timeThreshold = scrollTrigger === "wheel" ? 700 : 100
    canTriggerEvent = currentTime - prevWheelTime >= timeThreshold
    if (canTriggerEvent) {
      result = callback(e)
      prevWheelTime = currentTime
    }
    return typeof result !== "undefined" ? result : true
  }
})()

export function clamp(input, min, max) {
  return input < min ? min : input > max ? max : input
}

export function map(current, inMin, inMax, outMin, outMax) {
  const mapped =
    ((current - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
  return clamp(mapped, outMin, outMax)
}
