import * as React from "react"
import { useRef } from "react"
import Layout from "../common/layout"
import Lottie from "react-lottie-player"
import Header from "../common/header"
import Footer from "../common/footer"
import last9HeroZoomed from "../../animations/Hero_Zoomed2.json"
import last9HeroZoomedVideo from "../../animations/Hero_Zoomed_Video (1).mp4"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import LoadingBar from "react-top-loading-bar"
const WhitepaperScrollContent = React.lazy(() =>
  import("./WhitepaperScrollContent")
)
const WhitepaperScrollContentSafari = React.lazy(() =>
  import("./WhitepaperScrollContentSafari")
)

gsap.registerPlugin(ScrollTrigger)
import "./whitepaper.css"
const Whitepaper = () => {
  const [pageLoaded, setPageLoaded] = React.useState(false)
  const [heroVideoLoaded, setHeroVideoLoaded] = React.useState(false)
  const [isSafari, setIsSafari] = React.useState(false)

  function fnBrowserDetect() {
    if (typeof window !== `undefined`) {
      const userAgent = navigator.userAgent
      let browserName
      if (userAgent.match(/chrome|chromium|crios/i)) browserName = "chrome"
      else if (userAgent.match(/firefox|fxios/i)) browserName = "firefox"
      else if (userAgent.match(/safari/i)) browserName = "safari"
      else if (userAgent.match(/opr\//i)) browserName = "opera"
      else if (userAgent.match(/edg/i)) browserName = "edge"
      else browserName = "No browser detection"

      return browserName
    }
  }

  React.useEffect(() => {
    const browser = fnBrowserDetect()
    if (browser === "safari") {
      setIsSafari(true)
    }
  }, [])

  React.useEffect(() => {
    if (loadingRef.current && !pageLoaded) {
      loadingRef.current.continuousStart()
    }
    if (loadingRef.current && pageLoaded) {
      loadingRef.current.complete()
    }
  }, [pageLoaded])

  function completePageLoad() {
    setPageLoaded(true)
  }
  const rootRef = useRef(null)
  const loadingRef = useRef(null)

  return (
    // <Layout>
    <>
      <Header />
      <div
        style={{ backgroundColor: "#ECEAE1", position: "relative" }}
        id="blockContainer"
        ref={rootRef}
      >
        <LoadingBar
          color="#04BF83"
          ref={loadingRef}
          height={5}
          containerStyle={{
            position: "absolute",
            top: 54,
            left: 0,
            zIndex: 49,
          }}
        />
        <div className="hero-container panel relative mt-[10px] flex h-[100vh] w-full snap-start items-start justify-start">
          {pageLoaded ? (
            isSafari ? (
              <video
                autoPlay={true}
                muted
                loop={true}
                playsInline
                className="hero-animation-zoomed-in animation hero-video"
                onPlay={() => {
                  setHeroVideoLoaded(true)
                }}
              >
                <source src={last9HeroZoomedVideo} type="video/mp4" />
              </video>
            ) : (
              <Lottie
                loop
                play
                className={`hero-animation-zoomed-in animation hero-video`}
                onLoad={() => {
                  setHeroVideoLoaded(true)
                }}
                animationData={last9HeroZoomed}
              />
            )
          ) : (
            ""
          )}
          {heroVideoLoaded && (
            <h1 className="second-text hero-head head-1" id="hero-text">
              Scaling Metrics
              <br /> Maturity in a<br /> Cloud-Native World
            </h1>
          )}
        </div>
        <React.Suspense>
          {isSafari ? (
            <WhitepaperScrollContentSafari
              completePageLoad={completePageLoad}
            />
          ) : (
            <WhitepaperScrollContent completePageLoad={completePageLoad} />
          )}
        </React.Suspense>
      </div>
      {/* </Layout> */}
      <Footer />
    </>
  )
}

export default Whitepaper
