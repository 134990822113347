import React from "react"
import PropTypes from "prop-types"
import ClientLogo from "../common/ClientLogo"
import clsx from "clsx"

const CompanyDetails = ({ companyDetails, hideLogo }) => {
  return (
    <div className="divide-y-2 divide-slate-200 rounded-3xl bg-slate-100 p-6 md:px-8">
      {!hideLogo && (
        <div className="flex items-center justify-center pb-6">
          <ClientLogo name={companyDetails.id} size={48} color="#94a3b8" />
        </div>
      )}
      <ul
        className={clsx(
          "space-y-4 text-base leading-6 text-slate-400",
          !hideLogo && "pt-6",
        )}
      >
        {companyDetails.points.map(point => {
          return (
            <li
              key={point.name}
              className="flex items-start gap-3 text-sm leading-5 md:text-base md:leading-6"
            >
              {point.icon}
              <span>{point.name}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

CompanyDetails.propTypes = {
  companyDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.element,
      }),
    ),
  }),
  hideLogo: PropTypes.bool,
}

export default CompanyDetails
