import React from "react"

const comment = `Define streaming aggregate using plain YAML`
const code1 = `'sum by (stack, le) (http_requests_duration_seconds_bucket {(service = "pushnotifs")} [2m])'`
const code2 = `pushnotifs_http_requests_duration:2m`

const StreamAggregationFTW = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col-reverse items-stretch gap-8 md:flex-row lg:gap-16">
      {/* code snippet */}
      <div className="flex w-full flex-col overflow-hidden rounded-3xl border-2 border-solid border-slate-100 md:w-auto md:basis-1/2">
        {/* snippet header */}
        <div className="flex items-center gap-3 bg-slate-100 p-5">
          <div className="flex gap-1">
            <span className="h-2 w-2 rounded-full bg-red-400"></span>
            <span className="h-2 w-2 rounded-full bg-amber-400"></span>
            <span className="h-2 w-2 rounded-full bg-emerald-400"></span>
          </div>
          <span className="text-xs text-slate-500">
            <code>streaming_aggregate.yaml</code>
          </span>
        </div>
        {/* end snippet header */}

        {/* snippet body */}
        <pre className="flex h-full flex-col gap-2 overflow-auto whitespace-pre-wrap bg-white p-10 text-xs text-slate-500 md:text-base">
          <code className="text-slate-400">
            <span className="after:content-['_']">#</span>
            <span dangerouslySetInnerHTML={{ __html: comment }}></span>
          </code>

          <code className="text-slate-500">
            <div className="flex flex-row gap-[1ch]">
              <span className="text-indigo-600 after:content-['_']">-</span>
              <span>
                <code className="text-green-600">promql:</code>
                <code
                  className="whitespace-pre-wrap text-yellow-600"
                  dangerouslySetInnerHTML={{ __html: code1 }}
                />
              </span>
            </div>

            <div className="flex flex-row gap-[1ch]">
              <span className="inline-block w-[1ch]"></span>
              <span>
                <code className="text-green-600 after:content-['_']">as:</code>{" "}
                <code
                  className="whitespace-pre-wrap text-yellow-600"
                  dangerouslySetInnerHTML={{ __html: code2 }}
                ></code>
              </span>
            </div>
          </code>
        </pre>
        {/* end snippet body */}
      </div>
      {/* end code snippet */}

      {/** Right partition */}
      <div className="flex flex-col gap-6 text-slate-600 md:basis-1/2">
        <h3 className="w-full text-center text-4xl font-black tracking-tight text-black md:max-w-md md:text-left md:text-6xl">
          Streaming Aggregation FTW
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          <p>
            Pre-aggregate the most expensive queries ahead of time and
            drastically reduce cardinality.
          </p>
          <p>
            Streaming aggregation, unlike recording rules, aggregates the data
            in real-time as it arrives, massively reducing the query overhead at
            runtime.
          </p>
          <p>
            Leverage the power of{" "}
            <a
              className="underline decoration-slate-500 underline-offset-2"
              href="https://docs.last9.io/docs/streaming-aggregations"
              target="_blank"
            >
              PromQL to create aggregations
            </a>{" "}
            without the complexity of pipelines.
          </p>
        </div>
      </div>
      {/** Right partition ends */}
    </div>
  )
}

export default StreamAggregationFTW
