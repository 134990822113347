import React, { useEffect } from "react"
import bulb from "../../../static/9Page/Bulb.svg"
import animationHero from "../../../static/9Page/animations/hero.json"
import animationSection2 from "../../../static/9Page/animations/section-2.json"
import animationSection3 from "../../../static/9Page/animations/section-3.json"
import animationSection4 from "../../../static/9Page/animations/section-4.json"
import animationSection5 from "../../../static/9Page/animations/section-5.json"
import animationSection6 from "../../../static/9Page/animations/section-6.json"
import animationSection7 from "../../../static/9Page/animations/section-7.json"
import Lottie from "lottie-react"

export default function RtaPage(props) {
  // destroy the animation when the component unmounts
  // useEffect(() => {
  //   return () => {
  //     Lottie.destroy()
  //     console.log("lottie destroyed")
  //   }
  // }, [])

  return (
    <>
      {/* hero animation */}
      <div className="h-fit">
        <Lottie
          animationData={animationHero}
          onDOMLoaded={() => {
            props.completePageLoad()
          }}
        />
      </div>
      {/* end hero animation */}

      {/* section 1 */}
      <section className="mx-auto flex max-w-6xl flex-col gap-10 pt-10 pb-20">
        <p className="px-4 text-center text-5xl font-black md:px-6 lg:px-0 lg:text-7xl">
          The <span className="text-[#50b385]">Last9 </span> Ṛta
        </p>
        <div className="mx-auto flex max-w-4xl flex-col items-center gap-10 bg-[#f7f5ef] px-4 py-10 md:flex-row md:px-6">
          <img src={bulb} alt="" className="h-32 w-32" />
          <div className="flex flex-col text-left">
            <p className="font-medium text-[#899999]">
              Ṛta (/ɹ̩ta/; Sanskrit ऋत ṛta "order, rule; truth")
            </p>
            <p className="text-1xl mt-3">
              The principle of the natural order regulates and coordinates the
              universe’s operation and everything within it. In the Vedas, Ṛta
              is described as ultimately responsible for the proper functioning
              of the natural, moral and sacrificial orders.
            </p>
          </div>
        </div>
        <p className="mx-auto max-w-3xl px-4 text-xl font-extrabold md:px-6 lg:px-0 lg:text-3xl lg:font-black">
          <span className="text-[#50b385]">Last9’s Ṛta</span> is the ethos and
          reasons behind our products. Whether it’s engineers who build or
          customers who use our products, we want people to consistently
          experience our intentions and aspirations behind how we think of
          products.
        </p>
      </section>
      {/* end section 1 */}

      {/* section 2 */}
      <section className="bg-[#f7f5ef] py-20 px-4 md:px-6 lg:px-0">
        <div className="mx-auto flex max-w-4xl flex-col-reverse items-center gap-8 md:flex-row">
          <div className="flex basis-1/2 flex-col gap-4">
            <h1 className="text-3xl font-black md:text-4xl lg:text-5xl">
              9 and never a <div className="text-[#50b385]">perfect 10</div>
            </h1>
            <p>
              The most efficient system is the stalest system. If it’s stale,
              your systems are not improving. In fact, a perfect system is only
              a subset of an imperfect world. Thus, all aspirations for the
              perfection of a 10 are futile.
            </p>
            <p>
              Failures are inevitable. In software engineering, failures are the
              norm. All our energies are being directed toward reducing these
              failures. Embracing 9s means resigning to accepting a simple ethos
              — nothing can be 100%. The space we operate in is chaotic, and
              unpredictable, and requires us to take large amounts of ambiguity
              and deliver clarity.
            </p>
            <p>
              <strong>
                All our products and engineering aspire to help our customers
                realize the economics of this fight for 9s faster. Hence, Last9.
                😊
              </strong>
            </p>
          </div>
          <div className="flex basis-1/2 items-center justify-center">
            <Lottie
              animationData={animationSection2}
              className="h-auto w-9/12 md:w-full"
            />
          </div>
        </div>
      </section>
      {/* end section 2 */}

      {/* section 3 */}
      <section className="mx-auto flex max-w-4xl flex-col items-center gap-8 py-20 px-4 md:flex-row md:px-6 lg:px-0">
        <div className="flex basis-1/2 items-center justify-center">
          <Lottie
            animationData={animationSection3}
            className="h-auto w-9/12 md:w-full"
          />
        </div>
        <div className="flex basis-1/2 flex-col gap-4">
          <h1 className="text-3xl font-black md:text-4xl lg:text-5xl">
            Simple things <div className="text-[#50b385]">fit in a box</div>
          </h1>
          <p>
            Every system has a unique logic. This logic should behave the same
            when run locally, on staging, or in production. The only difference
            between the three stages should be the scale, not the design or its
            dependencies. Products designed this way are quick-to-adopt and easy
            to scale.
          </p>
          <p>
            We design our products with these constraints in mind. We often
            gravitate towards being lockless, stateless, and idempotent. And if
            there’s a need for a state, offload it to another component.
          </p>
        </div>
      </section>
      {/* end section 3 */}

      {/* section 4 */}
      <section className="bg-[#f7f5ef] py-20 px-4 md:px-6 lg:px-0">
        <div className="mx-auto flex max-w-4xl flex-col-reverse items-center gap-8 md:flex-row">
          <div className="flex basis-1/2 flex-col gap-4">
            <h1 className="text-3xl font-black md:text-4xl lg:text-5xl">
              Pluggable <div className="text-[#50b385]">Products</div>
            </h1>
            <p>
              Simple designs cannot be articulated, they can only be
              experienced. The hallmark of well-designed products is to
              seamlessly disappear in existing workflows and toolchains. Some of
              the Unix Philosophy of building tools heavily inspires us:
            </p>
            <ol className="list-decimal space-y-4">
              <li className="ml-8">
                Make each program do one thing well. To do a new job, build
                afresh rather than complicate old programs by adding new
                “features”.
              </li>
              <li className="ml-8">
                Expect the output of every program to become the input to
                another, as yet unknown, program. Don’t clutter the output with
                extraneous information.
              </li>
            </ol>
            <p>
              We strive to create <strong>simple</strong> products that plug
              into, <em>and</em> leverage existing toolchains of developers.
            </p>
          </div>
          <div className="flex basis-1/2 items-center justify-center">
            <Lottie
              animationData={animationSection4}
              className="h-auto w-9/12 md:w-full"
            />
          </div>
        </div>
      </section>
      {/* end section 4 */}

      {/* section 5 */}
      <section className="mx-auto flex max-w-4xl flex-col items-center gap-8 py-20 px-4 md:flex-row md:px-6 lg:px-0">
        <div className="flex basis-1/2 items-center justify-center">
          <Lottie
            animationData={animationSection5}
            className="h-auto w-9/12 md:w-full"
          />
        </div>
        <div className="flex basis-1/2 flex-col gap-4">
          <h1 className="text-3xl font-black md:text-4xl lg:text-5xl">
            Decisions<span className="text-[#50b385]">-as-</span>Code
          </h1>
          <p>
            Systems behave like humans in production. How humans need a
            declaration of independence, constitution, rights, and duties; so do
            systems.
          </p>
          <p>
            Systems that operate under the declarations of duties and
            disciplines behave predictably. Because systems understand only
            code, we need Decisions-as-Code.
          </p>
          <p>
            Infrastructure-as-Code is a testament to this need. All our products
            strive to provide a way to declare guardrails, policies, and
            decisions to manage systems predictably.
          </p>
        </div>
      </section>
      {/* end section 5 */}

      {/* section 6 */}
      <section className="bg-[#f7f5ef] py-20 px-4 md:px-6 lg:px-0">
        <div className="mx-auto flex max-w-4xl flex-col-reverse items-center gap-8 md:flex-row">
          <div className="flex basis-1/2 flex-col gap-4">
            <h1 className="text-3xl font-black md:text-4xl lg:text-5xl">
              Observability
            </h1>
            <p>
              Speed is the only currency startups have. And speed comes from
              confidence. The constant fear of failure leaves you with a
              pedestrian pace. The only way to beat this fear is to be confident
              of failure notifications. Most developers know their code and can
              fix things fast. The biggest challenge is to let them know
              something needs fixing.
            </p>
            <p>
              The onus to detect failures lies outside of the system being
              observed.{" "}
              <strong>
                This love for Outside-In Observability inspires our engineering
                and products.
              </strong>
            </p>
          </div>
          <div className="flex basis-1/2 items-center justify-center">
            <Lottie
              animationData={animationSection6}
              className="h-auto w-9/12 md:w-full"
            />
          </div>
        </div>
      </section>
      {/* end section 6 */}

      {/* section 7 */}
      <section className="mx-auto flex max-w-4xl flex-col items-center gap-8 py-20 px-4 md:flex-row md:px-6 lg:px-0">
        <div className="flex basis-1/2 items-center justify-center">
          <Lottie
            animationData={animationSection7}
            className="h-auto w-9/12 md:w-full"
          />
        </div>
        <div className="flex basis-1/2 flex-col gap-4">
          <h1 className="text-3xl font-black md:text-4xl lg:text-5xl">
            Love solutions, <div className="text-[#50b385]">not code</div>
          </h1>
          <p>
            Focus on the problem you’re solving and buy or build technology
            around it. If the solution drives the situation’s outcome, the code
            is irrelevant. If you can use an excel sheet to solve the problem,
            do that, and proudly so.
          </p>
          <p>Because problems last a lifetime, tools don’t.</p>
        </div>
      </section>
      {/* end section 7 */}
    </>
  )
}
