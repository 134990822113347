import React from "react"
import QuoteLight from "../common/QuoteLight"
import { quotes } from "../../constants/quotes"
import authorImg from "../../../static/home/ranjeet-clevertap.png"
import QuoteDark from "../common/QuoteDark"

const HighCardinalityQuote = () => {
  return (
    <QuoteLight
      quote={
        <>
          <p>{quotes["ranjeet-walunj"]}</p>
        </>
      }
      author={{
        name: (
          <span>
            Ranjeet Walunj (Via{" "}
            <a
              className="underline"
              target="_blank"
              href="https://www.g2.com/products/last9-levitate/reviews/levitate-review-8971744"
              rel="noreferrer"
            >
              G2 Review
            </a>
            )
          </span>
        ),
        img: authorImg,
        company: "Clevertap",
      }}
      clientName="clevertap"
    />
  )
}

export const HighCardinalityQuoteDark = () => {
  return (
    <QuoteDark
      quote={<p>{quotes["ranjeet-walunj"]}</p>}
      author={{
        name: (
          <span>
            Ranjeet Walunj (Via{" "}
            <a
              className="underline"
              target="_blank"
              href="https://www.g2.com/products/last9-levitate/reviews/levitate-review-8971744"
              rel="noreferrer"
            >
              G2 Review
            </a>
            )
          </span>
        ),
        img: authorImg,
        company: "Clevertap",
      }}
      clientName="clevertap"
    />
  )
}

export default HighCardinalityQuote
