import React from "react"

export const features = [
  {
    title: "Long Term Metric Retention",
    description:
      "Solve your Prometheus retention challenges with Levitate. Data retention with up to a year without any rollups are supported by default using automatic data tiering.",
  },
  {
    title: "High Cardinality Metrics",
    description:
      "Are you hitting Prometheus cardinality limits? Never compromise on metric & label cardinality with Levitate. Control your data growth with streaming aggregations, and get early warnings on label & metric cardinality data explosion.",
  },
  {
    title: "Fast Query Performance",
    description:
      "Speed up queries without racking up on Prometheus infrastructure costs while supporting concurrent client access with Levitate's access policies. Delta caching and tiered reader make sure that query performance is maintained even with data growth.",
  },
  {
    title: "Custom Metrics Support",
    description:
      "There is no extra billing for Custom Metrics like Datadog and other managed services for Prometheus providers. Supports all types of custom metrics via agents and manual instrumentation. Levitate is a single source of truth for all metrics such as application, infra, and business.",
  },
  {
    title: "Managed Grafana",
    description:
      "Levitate comes with in-built free Managed Grafana, which is part of Last9's complete Cloud Native Monitoring stack—no need to pay extra for Grafana like other Managed Prometheus Services.",
  },
  {
    title: "Alerting and Notifications at no extra cost",
    description:
      "While other managed prometheus providers charge you extra for running Prometheus alertmanager, Levitate has built-in, highly scalable alerting support with seamless import capabilities from your existing Prometheus alerting YAML.",
  },
  {
    title: "Available on AWS and GCP Marketplace",
    description:
      "Ease your monitoring tools procurement with Levitate on AWS Marketplace and Google Cloud Marketplace. Reduce your observability cost, retire your EDP credits early, and eliminate your billing hassles.",
  },
  {
    title: "Bring Your Own Cloud",
    description:
      "With the Bring Your Own Cloud(BYOC) deployment model, our fully managed Cloud Native Monitoring stack is deployed in your cloud or data center. Leverage cloud credits and discounts to nullify your Prometheus infrastructure costs.",
  },
  {
    title: "On-Premise Support",
    description:
      "Don’t leave the comfort of a Prometheus on-prem deployment with Levitate’s BYOC deployment. We support fully managed deployment in your on-premise environment and data center—no compliance and regulations challenges. Data always stays near your infrastructure.",
  },
  {
    title: "High Availability",
    description:
      "With horizontally scalable, replicated, multi-AZ architecture, Levitate provides HA out of the box eliminating challenges with Prometheus High Availability and Federation.",
  },
  {
    title: "Open Source Data Collectors",
    description:
      "No proprietary agents for collecting data. Levitate supports open standards — OpenMetrics, OpenTelemetry, and integrates with open-source tools such as Prometheus, VictoriaMetrics, InfluxDB, Telegraf, and StatsD.",
  },
  {
    title: "Cloud Native Monitoring",
    description:
      "Built for scale and reliability of today's cloud-native workloads without compromising cost. High Cardinality and Data Tiering Control levers avoid sticker shock.",
  },
]

export const useCases = [
  {
    title: "Infrastructure Monitoring",
    description:
      "Monitor cloud infra without worrying about data growth with Levitate. Support hybrid cloud monitoring with AWS Cloudwatch using AWS Kinesis Firehose stream and GCP Stackdriver integrations. One year of long-term retention allows for understanding infrastructure changes over time and capacity planning.",
  },
  {
    title: "Application Performance Monitoring (APM)",
    description:
      "Monitor application performance with Levitate using open standards-based agents with in-built APM Grafana dashboards and alerting rules. Compare application performance across tenants, teams, and services.",
  },
  {
    title: "Kubernetes Monitoring",
    description:
      "Monitor the health of Kubernetes-based Cloud Native setups using Streaming Aggregations to manage data growth. Slice and dice different high cardinality labels without losing out on visibility into your Kubernetes clusters.",
  },
  {
    title: "CloudWatch Monitoring",
    description:
      "Never pay again for Cloudwatch read queries. Instead of using the CloudWatch Metric Stream, make Levitate a source of all AWS Cloudwatch metrics. Leverage built-in Grafana dashboards for simplified and seamless CloudWatch Monitoring.",
  },
  {
    title: "Real Time Monitoring",
    description:
      "With Alerting working on top of Levitate's optimized Query Engine, real-time monitoring gets unlocked. Concurrent access of time series data gets solved by client separation between Alerting and visualization using Grafana.",
  },
  {
    title: "Business Metrics",
    description:
      "Levitate's full stack monitoring capabilities allow capturing high precision business KPIs and monitoring them along with application and infrastructure metrics. ",
  },
]

export const faqs = [
  {
    question:
      "What are the supported regions for Levitate's managed service for Prometheus?",
    answer:
      "Levitate is globally available across different regions such as North America(us-east-1), APAC India(ap-south-1), APAC Singapore(ap-south-east-1), Europe(eu-west-1). Levitate also supports AWS PrivateLink and VPC peering to eliminate egress costs. The BYOC deployment model applies to all regions.",
  },
  {
    question:
      "How do I migrate from self-hosted Prometheus or other Managed Prometheus provider to Levitate?",
    answer:
      "Levitate needs no complex migration. Levitate is compatible with Prometheus Remote Write API. Create a Levitate Cluster and start writing to it using Prometheus Remote Write from your existing setup within minutes. Once you are satisfied with Levitate, eliminate Prometheus storage and compute if you are using self-hosted Prometheus.",
  },
  {
    question: "How do I collect metrics data and send it to Levitate?",
    answer:
      "Levitate supports open standards — OpenMetrics, OpenTelemetry, and integrates with open-source tools such as Prometheus, VictoriaMetrics, InfluxDB, Telegraf, and StatsD. Additionally, it can also collect metrics from AWS Cloudwatch Stream via OpenTelemetry-based Kinesis Stream integration. It can accept metrics from OpenTelemetry Collector and various other ways using Prometheus Remote Write. No need to run proprietary agents or data collectors.",
  },
  {
    question: "Can I monitor CloudWatch using Levitate?",
    answer:
      "Yes. Monitoring Cloudwatch using Levitate has one distinct advantage over monitoring using Cloudwatch itself. Cloudwatch charges for accessing its read API. Levitate leverages the AWS Cloudwatch metric stream integration, which streams metrics from AWS to Levitate, which does not result in increased AWS Cloudwatch Bill.",
  },
]
