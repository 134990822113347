import React from "react"
import headerStackImg from "../../../static/icons/header-stack.png"
import IconChange from "../../../static/alerting/icon-change.inline.svg"
import IconAnomaly from "../../../static/alerting/icon-anomaly.inline.svg"
import IconAuto from "../../../static/alerting/icon-auto.inline.svg"
import Quote from "../common/Quote"
import imageSrc from "../../../static/home/hotstar-quote-image.webp"

import imageAlertingChangeboards from "../../../static/alerting/alerting-changeboards.png"
import imageAlertingAnomalyDetection from "../../../static/alerting/alerting-anomaly-detection.png"
import imageAlertingGitops from "../../../static/alerting/alerting-gitops.png"

const AlertingDetails = ({
  quote = {
    client: "probo",
    quote:
      "Automation, automation, automation. That’s how we work at Probo and Last9’s alerting just fits like a glove into that workflow. Reliable, easy to integrate, and exceptional customer support.",
    author: "Aditya Chowdhry, Platform Lead, Probo",
    accent: "#262626",
    img: <img src={imageSrc} className="h-auto w-full max-w-80" />,
  },
}) => {
  return (
    <section className="scroll-pt-40 bg-gradient-to-b from-slate-50 to-transparent px-6 py-20">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-16">
        {/* section header */}
        <div className="flex flex-col-reverse items-start justify-between gap-5 md:flex-row md:items-center">
          <h2 className="max-w-2xl text-balance text-4xl font-extrabold text-slate-900 md:text-5xl">
            Open Standards- and PromQL-compatible, but with super powers.
          </h2>
          <img alt="" className="h-[120px]" src={headerStackImg} />
        </div>
        {/* end section header */}

        {/* card 1 */}
        <div className="flex w-full flex-col items-center justify-center gap-4 md:flex-row-reverse md:gap-12">
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageAlertingChangeboards}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconChange className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Change Intelligence
            </h3>

            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                <strong>Changeboards:</strong> A simple view that gives a
                correlated timeline of system health
              </li>
              <li>
                <strong>Change Events:</strong> captures changes such as
                deployment & configuration changes, notify special calendar
                events
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 1 */}

        {/* card 2 */}
        <div className="flex w-full flex-col items-center justify-center gap-4 md:flex-row md:gap-12">
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageAlertingAnomalyDetection}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconAnomaly className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Anomalous Pattern Detection
            </h3>

            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                Reduce alert fatigue due to innumerable static thresholds used
                in setting alert rules
              </li>
              <li>
                Detect spike changes, loss of signal, baseline deviation and
                noisy neighbors using our{" "}
                <a href="https://docs.last9.io/docs/anomalous-pattern-detection-guide">
                  Anomalous Pattern Detection Guide
                </a>
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 2 */}

        {/* card 3 */}
        <div className="flex w-full flex-col items-center justify-center gap-4 md:flex-row-reverse md:gap-12">
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageAlertingGitops}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconAuto className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Agile Configuration Management
            </h3>
            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                Automated workflows with{" "}
                <a href="https://docs.last9.io/docs/alerting-via-iac">
                  Infrastructure as Code (IaC)
                </a>{" "}
                tool for managing alert configurations
              </li>
              <li>Component auto discovery</li>
              <li>
                Version history and peer review of configurations using GitOps
              </li>
              <li>
                Programmatic configuration with Python SDK and first class API
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 3 */}

        <Quote {...quote} />
      </div>
    </section>
  )
}

export default AlertingDetails
