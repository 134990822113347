import React from "react"
import Balancer from "react-wrap-balancer"

const HighCardinalityIsMust = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl -mt-14 lg:mt-0 flex-col items-center gap-8">
      <h3 className="max-w-lg text-center text-4xl font-black tracking-tight text-black md:text-6xl">
        <Balancer>High Cardinality Support is a Must</Balancer>
      </h3>

      <div className="max-w-4xl gap-8 space-y-4 text-left text-base text-slate-600 md:columns-2 md:text-xl [&>p]:break-inside-avoid">
        <p>
          With the advent of cloud-native environments and micro-services-based
          applications, high cardinality metrics are a reality.
        </p>
        <p>
          Monitoring tools have strict restrictions on querying high cardinality
          data, robbing users of accessing their own data.
        </p>
        <p>
          These tools also have limits on ingesting data so high cardinality
          data starts getting dropped beyond a limit.
        </p>
        <p>
          These defaults are fairly pedestrian for today’s modern cloud-native
          applications.
        </p>
      </div>

      <div className="mt-6 flex flex-col items-center gap-6">
        <h4 className="max-w-sm text-center text-2xl font-extrabold tracking-tight text-black md:text-4xl">
          <Balancer>Levitate’s Superior Cardinality Defaults</Balancer>
        </h4>
        <div className="flex max-w-md flex-row gap-10 text-center font-bold uppercase">
          <div className="flex flex-1 flex-col items-center">
            <span className="text-3xl font-black text-brand-green-200 md:text-[32px]">
              1M
            </span>
            <span className="text-xs text-slate-400 md:text-sm">
              Cardinality per hour per metric
            </span>
          </div>
          <div className="flex flex-1 flex-col items-center">
            <span className="text-3xl font-black text-brand-green-200 md:text-[32px]">
              20M
            </span>
            <span className="text-xs text-slate-400 md:text-sm">
              Cardinality per day <br /> per metric
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HighCardinalityIsMust
