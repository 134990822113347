import React from "react"
import Layout from "../../components/common/layout"
import Hero from "../../components/events/Hero"
import Seo from "../../components/common/seo"
import EventCard, { EventCardSkeleton } from "../../components/events/EventCard"

const Events = () => {
  const [loading, setLoading] = React.useState(true)
  const [events, setEvents] = React.useState([])

  React.useEffect(() => {
    // Fetch the events
    ;(async () => {
      try {
        const res = await fetch("/api/get_events_calendar")
        const data = await res.json()
        setEvents(data)
        setLoading(false)
      } catch (err) {
        setEvents([])
        setLoading(false)
      }
    })()
  }, [])

  const featuredEvent = React.useMemo(() => {
    if (!Array.isArray(events)) return null
    return events?.filter(event => event.isFeatured)?.[0] ?? null
  }, [events])

  const eventsToShow = React.useMemo(() => {
    return events.sort((a, b) => {
      const aTime = new Date(a?.date?.end ?? a?.date?.start).getTime()
      const bTime = new Date(b?.date?.end ?? b?.date?.start).getTime()

      return bTime - aTime
    })
  }, [events, featuredEvent])

  return (
    <Layout>
      <section className="flex flex-col px-4 py-10 md:px-6 md:py-14 lg:py-20 xl:px-0">
        <Hero />
      </section>
      <section className="flex flex-col items-center gap-28 bg-yellow-50 to-white px-4 pt-0 md:gap-40 md:px-6 md:pt-0 lg:mx-0 xl:px-0">
        <div className="mx-auto -mt-40 flex w-full max-w-6xl flex-col items-center gap-8">
          <div className="flex w-full max-w-[960px] flex-col gap-10 text-left text-base text-slate-600 md:flex-row md:text-xl">
            {featuredEvent?.title ? (
              <EventCard event={featuredEvent} horizontal />
            ) : (
              <EventCardSkeleton horizontal />
            )}
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center bg-yellow-50 px-4 pb-28 pt-10 md:px-6 md:pb-40 md:pt-28 lg:mx-0 xl:px-0">
        <div className="grid w-full max-w-6xl grid-flow-row grid-cols-1 gap-10 md:grid-cols-2">
          {!loading ? (
            eventsToShow
              .filter(event => event !== featuredEvent)
              .map(event => {
                return <EventCard key={event.title} event={event} />
              })
          ) : (
            <>
              <EventCardSkeleton />
              <EventCardSkeleton />
              <EventCardSkeleton />
              <EventCardSkeleton />
            </>
          )}
        </div>
      </section>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Community and Events"
    description="Join us on our community Discord to get inspired by the amazing work done by SRE/DevOps practitioners and leaders."
    image="/thumbnails/thumbnail-last9-events.jpg"
  />
)

export default Events
