/* eslint-disable react/prop-types */
import React from "react"
import Layout from "../../../components/common/layout"
import Seo from "../../../components/common/seo"
import { Link, graphql } from "gatsby"

const AllTags = ({ data }) => {
  return (
    <Layout>
      {/* all tags */}
      <div className="flex w-full flex-col gap-20 px-4 pb-[120px] pt-10">
        <div className="mx-auto w-full max-w-6xl">
          <h1 className="text-4xl font-black leading-[48px]">Topics</h1>
          <p className="text-xl leading-7 text-slate-500">
            A collection of all topics that we have on Last9 blog.
          </p>
        </div>
        <div className="mx-auto grid w-full max-w-6xl grid-flow-row grid-cols-1 gap-10 md:grid-cols-2">
          {data.allGhostTag.nodes.map(tag => {
            return (
              <Link key={tag.slug} to={`/blog/tag/${tag.slug}/`}>
                <p className="text-xl font-medium leading-7">{tag.name}</p>
                <p className="text-base leading-6 text-slate-600">
                  {tag.description}
                </p>
              </Link>
            )
          })}
        </div>
      </div>
      {/* end all tags */}
    </Layout>
  )
}

export const query = graphql`
  query {
    allGhostTag(
      filter: {
        slug: {
          nin: [
            "changelog"
            "changelog-new"
            "changelog-fix"
            "changelog-improvement"
          ]
          regex: "/^(?!hash-).*/"
        }
      }
    ) {
      nodes {
        id
        slug
        name
        description
      }
    }
  }
`

export const Head = () => (
  <Seo
    title="Blog"
    description="MTTR obsession > Uptime illusionary fatigue. Want to know more on how we think of Change Intelligence, and SRE? Freshly minted from our stables."
  />
)

export default AllTags
