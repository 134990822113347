import React, { useMemo, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/common/layout"
import Seo from "../../components/common/seo"
import clsx from "clsx"
import GuideCard from "../../components/guides/GuideCard"

const OpenTelemetryResources = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const [filter, setFilter] = useState(null)

  const posts = useMemo(() => {
    if (!filter) return edges
    return edges.filter(edge => edge.node.frontmatter.tags.includes(filter))
  }, [filter])

  const tags = useMemo(() => {
    return Array.from(
      new Set(
        edges.flatMap(edge =>
          edge.node.frontmatter.tags.split(",").map(tag => tag.trim()),
        ),
      ),
    ).sort()
  }, [edges])

  return (
    <Layout>
      <div className="mx-auto flex w-full flex-col gap-10">
        <div className="w-full gap-10 bg-yellow-50 px-4 py-8">
          <div className="mx-auto flex w-full max-w-6xl flex-col items-stretch gap-6">
            <div className="flex w-full max-w-3xl flex-col">
              <h1 className="text-4xl font-black leading-[48px]">Guides</h1>
              <p className="text-xl text-slate-600">
                How-to guides and tutorials
              </p>
            </div>
            <ul className="flex flex-wrap gap-2">
              {tags.map(tag => (
                <li
                  className={clsx(
                    "cursor-pointer select-none rounded-full border border-solid border-slate-600 px-3 py-1 text-sm font-bold text-slate-600 md:px-6 md:py-2 md:text-base md:hover:border-black md:hover:bg-black md:hover:text-white",
                    filter === tag ? "bg-black text-white" : "",
                  )}
                  onClick={() => setFilter(filter === tag ? null : tag)}
                >
                  {tag}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-full px-4">
          <div className="mx-auto grid w-full max-w-6xl grid-flow-row grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
            {posts.map(post => (
              <GuideCard key={post.node.id} post={post.node} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Guides"
    description="How-to guides and tutorials"
    image="/thumbnails/thumbnail-last9-home.jpg"
  />
)

export default OpenTelemetryResources

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date
            slug
            title
            tags
            excerpt
          }
        }
      }
    }
  }
`
