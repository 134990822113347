/* eslint-disable react/prop-types */
import React from "react"
import Layout from "../components/common/layout"
import Hero from "../components/newrelic-alternative/Hero"
import LevitateIsDifferent from "../components/newrelic-alternative/LevitateIsDifferent"
import APMWithZeroHeadaches from "../components/newrelic-alternative/APMWithZeroHeadaches"
import Clawbacks from "../components/reduce-observability-costs/Clawbacks"
import MonitoringStack from "../components/newrelic-alternative/MonitoringStack"
import Seo from "../components/common/seo"
import PreFooter from "../components/newrelic-alternative/PreFooter"
import { faqs, features, useCases } from "../constants/newrelic-alternative.jsx"

const Group = ({ title, children }) => {
  return (
    <div className="flex w-full flex-col gap-6">
      <h4 className="text-2xl font-black leading-7">{title}</h4>
      <p className="text-lg leading-7 text-slate-600">{children}</p>
    </div>
  )
}

const QnA = ({ question, children }) => {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-xl font-semibold leading-8">{question}</h3>
      <p className="text-lg leading-6 text-slate-600">{children}</p>
    </div>
  )
}

const NewRelicAlternative = () => {
  return (
    <Layout>
      <section className="flex flex-col px-4 md:px-6 md:pt-4 lg:pt-0 xl:px-0">
        <Hero />
      </section>

      <section className="flex flex-col items-center gap-28 bg-gradient-to-b from-yellow-50 to-white px-4 py-28 md:gap-40 md:px-6 md:py-40 md:pb-20 lg:mx-0 xl:px-0">
        <APMWithZeroHeadaches />
        <LevitateIsDifferent />
        <MonitoringStack />
        <Clawbacks />
      </section>

      {/* Divider */}
      <div className="h-0.5 w-full bg-gradient-to-r from-transparent via-brand-green-100 to-transparent opacity-25"></div>

      <section className="flex flex-col items-center gap-28 px-4 py-28 md:gap-40 md:px-6 md:py-20 lg:mx-0 xl:px-0">
        <div className="flex w-full max-w-6xl flex-col gap-20">
          <h2 className="text-center text-3xl font-black leading-9">
            The Levitate Advantage
          </h2>
          <div className="grid grid-cols-1 gap-20 sm:grid-cols-2 md:grid-cols-3">
            {useCases.map(feature => {
              return (
                <Group key={feature.title} title={feature.title}>
                  {feature.description}
                </Group>
              )
            })}
          </div>
        </div>
      </section>

      {/* Divider */}
      <div className="h-0.5 w-full bg-gradient-to-r from-transparent via-brand-green-100 to-transparent opacity-25"></div>

      <section className="flex flex-col items-center gap-28 px-4 py-28 md:gap-40 md:px-6 md:py-20 lg:mx-0 xl:px-0">
        <div className="flex w-full max-w-6xl flex-col gap-20">
          <h2 className="text-center text-3xl font-black leading-9">
            More than just Applicatioon Performance Monitoring
          </h2>
          <div className="grid grid-cols-1 gap-20 sm:grid-cols-2 md:grid-cols-3">
            {features.map(feature => {
              return (
                <Group key={feature.title} title={feature.title}>
                  {feature.description}
                </Group>
              )
            })}
          </div>
        </div>
      </section>

      {/*
      <section className="flex flex-col items-center gap-28 px-4 py-28 md:gap-40 md:px-6 md:py-20 lg:mx-0 xl:px-0">
        <div className="mx-auto flex w-full max-w-2xl flex-col gap-8">
          <h2 className="text-center text-3xl font-black leading-9">
            Frequently Asked Questions
          </h2>
          <div className="flex flex-col gap-6">
            {faqs.map(faq => {
              return (
                <QnA key={faq.question} question={faq.question}>
                  {faq.answer}
                </QnA>
              )
            })}
          </div>
        </div>
      </section>
      */}

      <PreFooter />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="New Relic Alternative ⏤ Levitate By Last9"
    description="Reduce your monitoring cost by 60% as compared to New Relic with Levitate By Last9. Metrics and Events based Application Performance Monitoring Platform built on Open Standards and No Vendor Lock-in"
    image="/thumbnails/thumbnail-last9-levitate.jpg"
  />
)

export default NewRelicAlternative
