import { Link } from "gatsby"
import React from "react"
import newRelicCost from "../../../static/newrelic-alternative/new_relic_cost.png"

import Balancer from "react-wrap-balancer"

const APMWithZeroHeadaches = () => {
  return (
    <div className="items-left mx-auto flex w-full max-w-6xl flex-col gap-6">
      <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 text-lg md:flex-row-reverse lg:gap-16">
        <div className="flex w-full flex-col items-center gap-8 text-base text-slate-600 md:basis-1/2 md:items-start md:text-lg lg:basis-auto">
          <h2 className="max-w-lg text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
            <Balancer>APM with Zero Billing Headaches</Balancer>
          </h2>
          <p className="max-w-2xl text-left text-base text-slate-600 md:text-lg">
            Levitate ensures monitoring costs don't blow up at any scale.
          </p>
          <ul className="grid grid-flow-row grid-cols-1 gap-3 gap-x-8 gap-y-6 text-base md:grid-cols-2">
            <li className="text-slate-500">
              <Link
                to="/opentelemetry/"
                className="font-bold text-slate-600 underline md:text-xl"
              >
                Open Protocols
              </Link>
              <br />
              No vendor lockin, supports OpenTelemetry, Prometheus and PromQL
              for querying. Grafana for visualization.
            </li>
            <li className="text-slate-500">
              <Link
                to="/opentelemetry/"
                className="font-bold text-slate-600 underline md:text-xl"
              >
                Auto Instrumentation
              </Link>
              <br />
              Auto-instrument applications without changing application code.
              Custom metrics support.
            </li>
            <li className="text-slate-500">
              <Link
                to="/data-tiering/"
                className="font-bold text-slate-600 underline md:text-xl"
              >
                Data Retention
              </Link>
              <br />
              Long term, high cardinality data retention support without
              downsampling.
            </li>
            <li className="text-slate-500">
              <Link
                to="/high-cardinality/"
                className="font-bold text-slate-600 underline md:text-xl"
              >
                Predictable spends
              </Link>
              <br />
              Zero billing headaches. Use your preferred billing method. No user
              based pricing.
            </li>
          </ul>
        </div>

        <div className="flex w-full flex-col items-center gap-8 text-slate-600 md:basis-1/2 md:items-start lg:basis-auto">
          <img src={newRelicCost} />
        </div>
      </div>
    </div>
  )
}

export default APMWithZeroHeadaches
