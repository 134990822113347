import React from "react"
import clsx from "clsx"
import Balancer from "react-wrap-balancer"

const icons = {
  blaze: (
    <svg
      className="h-6 w-6 fill-red-600"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143z"
        clipRule="evenodd"
      />
    </svg>
  ),
  hot: (
    <svg
      className="h-6 w-6 fill-amber-600"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M12.963 2.286a.75.75 0 0 0-1.071-.136 9.742 9.742 0 0 0-3.539 6.177A7.547 7.547 0 0 1 6.648 6.61a.75.75 0 0 0-1.152-.082A9 9 0 1 0 15.68 4.534a7.46 7.46 0 0 1-2.717-2.248zM15.75 14.25a3.75 3.75 0 1 1-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 0 1 1.925-3.545 3.75 3.75 0 0 1 3.255 3.717z"
        clipRule="evenodd"
      />
    </svg>
  ),
  warm: (
    <svg
      className="h-6 w-6 fill-yellow-500"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M12 .75a8.25 8.25 0 0 0-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 0 0 .577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 0 1-.937-.171.75.75 0 1 1 .374-1.453 5.261 5.261 0 0 0 2.626 0 .75.75 0 1 1 .374 1.452 6.712 6.712 0 0 1-.937.172v4.66a.54.54 0 0 0 .6.545 10.58 10.58 0 0 0 1.074-.195.75.75 0 0 0 .577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0 0 12 .75z" />
      <path
        fillRule="evenodd"
        d="M9.013 19.9a.75.75 0 0 1 .877-.597 11.319 11.319 0 0 0 4.22 0 .75.75 0 1 1 .28 1.473 12.819 12.819 0 0 1-4.78 0 .75.75 0 0 1-.597-.876zm.741 2.444a.75.75 0 0 1 .824-.668 13.682 13.682 0 0 0 2.844 0 .75.75 0 1 1 .156 1.492 15.156 15.156 0 0 1-3.156 0 .75.75 0 0 1-.668-.824z"
        clipRule="evenodd"
      />
    </svg>
  ),
  cold: (
    <svg
      className="h-6 w-6 fill-sky-600"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M21 11h-3.17l2.54-2.54a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0L15 11h-2V9l3.95-3.95c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0L13 6.17V3c0-.55-.45-1-1-1s-1 .45-1 1v3.17L8.46 3.63a.996.996 0 0 0-1.41 0c-.39.39-.39 1.03 0 1.42L11 9v2H9L5.05 7.05c-.39-.39-1.03-.39-1.42 0a.996.996 0 0 0 0 1.41L6.17 11H3c-.55 0-1 .45-1 1s.45 1 1 1h3.17l-2.54 2.54a.996.996 0 0 0 0 1.41c.39.39 1.03.39 1.42 0L9 13h2v2l-3.95 3.95c-.39.39-.39 1.03 0 1.42.39.39 1.02.39 1.41 0L11 17.83V21c0 .55.45 1 1 1s1-.45 1-1v-3.17l2.54 2.54c.39.39 1.02.39 1.41 0 .39-.39.39-1.03 0-1.42L13 15v-2h2l3.95 3.95c.39.39 1.03.39 1.42 0a.996.996 0 0 0 0-1.41L17.83 13H21c.55 0 1-.45 1-1s-.45-1-1-1Z" />
    </svg>
  ),
  glacial: (
    <svg
      className="h-6 w-6 fill-blue-600"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M13.2 7.07 10.25 11l2.25 3c.33.44.24 1.07-.2 1.4a.994.994 0 0 1-1.4-.2c-1.05-1.4-2.31-3.07-3.1-4.14-.4-.53-1.2-.53-1.6 0l-4 5.33c-.49.67-.02 1.61.8 1.61h18c.82 0 1.29-.94.8-1.6l-7-9.33a.993.993 0 0 0-1.6 0Z" />
    </svg>
  ),
}

const TierCard = ({ title, description, icon, iconBg }) => {
  return (
    <div className="flex flex-col items-start gap-2 rounded-lg border border-blue-50 bg-white p-4">
      <span
        className={clsx(
          "flex h-12 w-12 items-center justify-center rounded-full",
          iconBg
        )}
      >
        {icons[icon]}
      </span>
      <span className="mt-2 font-bold text-brand-blue-100">{title}</span>
      <p>{description}</p>
    </div>
  )
}

const AutoDataTiering = () => {
  return (
    <div className="mx-auto mt-14 flex w-full flex-col items-center gap-6 md:mt-20">
      <h4 className="max-w-sm text-center text-2xl font-extrabold tracking-tight text-black md:text-4xl">
        <Balancer>Unlocking the Power of Data Tiers</Balancer>
      </h4>
      {/* cards */}
      <div className="grid max-w-7xl grid-cols-1 gap-2 text-slate-500 sm:grid-cols-3 lg:grid-cols-5">
        <TierCard
          title="Blaze Tier"
          description="Ideal for Alert Manager, real-time, shallow consumption of data."
          icon="blaze"
          iconBg="bg-red-100"
        />
        <TierCard
          title="Hot Tier"
          description="Ideal for quick daily trend and impact history of recent changes and fixes."
          icon="hot"
          iconBg="bg-amber-100"
        />
        <TierCard
          title="Warm Tier"
          description="Ideal for debugging incidents, troubleshooting, and root cause analysis."
          icon="warm"
          iconBg="bg-yellow-100"
        />
        <TierCard
          title="Cold Tier"
          description="Ideal for Data Science teams for long-term data exploration and analysis."
          icon="cold"
          iconBg="bg-sky-100"
        />
        <TierCard
          title="Glacial Tier"
          description="Ideal for capacity planning, compliance, and business strategy."
          icon="glacial"
          iconBg="bg-blue-100"
        />
      </div>
      {/* end cards */}
    </div>
  )
}

export default AutoDataTiering
