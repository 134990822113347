import React from "react"
import { Link } from "gatsby"
import Lottie from "lottie-react"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"

import rtaPageAnimation from "../../static/9Page/animations/hero.json"
import box from "../../static/about-us/box.png"
import sign from "../../static/about-us/nm-sign.png"
import nm from "../../static/about-us/nm.png"
import x from "../../static/icons/x.svg"

const AboutUs = props => {
  return (
    <Layout>
      <div className="w-full bg-yellow-50">
        <div className="mx-auto w-full max-w-6xl py-20 px-6 xl:px-0 md:py-[120px] flex flex-col gap-[120px]">
          <div className="bg-white p-5 moving-shadow z-10 lg:p-20 rounded-3xl lg:rounded-[36px] border border-solid border-transparent relative">
            <div className="mx-auto max-w-3xl flex flex-col gap-8 md:gap-16">
              <div className="flex flex-col-reverse md:flex-row gap-8 md:items-center">
                <h1 className="md:text-5xl text-4xl leading-[1.167] font-black">
                  To make running systems at scale — fun and embarrassingly
                  easy.
                </h1>
                <img src={box} alt="box" className="md:h-56 w-32 md:w-auto" />
              </div>
              <div className="text-slate-600 text-lg flex flex-col gap-6">
                <p>
                  Software runs the world. It’s all around us, and it’s
                  inescapable.
                </p>

                <p>
                  Bad software can leave us stranded in an airport. Evil
                  software can topple governments. Good software can help save
                  lives. Whatever you do, and wherever you are, software is more
                  essential than we can fathom.
                </p>

                <p>
                  As software became ubiquitous, it’s practitioners grew
                  manifold. Talented engineers worked really hard to abstract
                  complex code into simple clickable buttons for users. With a
                  simple click of a button, you could get food delivered, and
                  cars navigate themselves.
                </p>

                <p>
                  Software’s outward simplicity and inward complexity attracted
                  hundreds of people who wanted to build products that helped
                  advance our lives.
                </p>

                <p>
                  This prolific growth meant lots of servers, systems, and
                  complex backend machines that powered millions of applications
                  that made our lives easier.
                </p>

                <p>
                  As more practitioners entered the fray, newer kinds of
                  software emerged. We could do more with less. We could
                  accommodate more engineers and build things faster, better. In
                  the process, the underlying infrastructure got so massive, it
                  was getting harder to identify where software was not
                  performing as intended.
                </p>

                <p>
                  This sprawling infrastructure of machines were not resilient
                  to failures. After all, human cognition and interpretation is
                  varied and subjective. The language and emotion I have writing
                  this might not be interpreted by you, the reader, in the exact
                  100% emotion I want to convey. We’re whimsical, subjective
                  beings, and it’s what makes us unique.
                </p>

                <p>
                  But for the world of software, this meant things could fail.
                  And when it did, it left a stinky mess. There was only one
                  solution. The performance of software had to be measured, and
                  acted on. Without the measurement of software, it’s impossible
                  to interpret it and make it more resilient.
                </p>

                <p className="font-bold">That’s how 9’s came to be.</p>

                <p>
                  A 99% performance score for a piece of software means it’s not
                  available for 3.7 days in a year.
                  <br />
                  A 99.9% performance score means it’s not available for 8.7
                  hours in a year.
                  <br />A 99.99% performance score means it’s not available for
                  52.6 minutes in a year. See how by adding 9s you reduce the
                  downtime?
                </p>

                <p className="font-bold">
                  Hence, Last9. Our goal is to add to your 9s to the last 9, so
                  your software is reliable.
                </p>

                <p>
                  Our mission is to make running systems at scale, fun, and{" "}
                  <sup className="font-bold">*</sup>embarrassingly easy.
                </p>

                <p>
                  <sup className="font-bold">*</sup>Embarrassingly parallel,
                  funnily enough, is how the space we operate in can succeed.
                  :) For that conversation, I’m on{" "}
                  <a
                    className="underline"
                    href="https://twitter.com/nishantmodak"
                    target="_blank"
                  >
                    X
                  </a>
                  .
                </p>
              </div>
              <div className="pt-14 text-slate-600 text-lg">
                <img src={sign} className="max-w-52" />
                <div className="flex gap-6">
                  <img src={nm} className="w-[75px] h-[75px]" />
                  <div>
                    <p className="font-bold">Nishant Modak</p>
                    <p>Founder, CEO</p>
                    <div className="flex mt-1 gap-4">
                      <a
                        href="https://www.linkedin.com/in/modak/"
                        target="_blank"
                        className="w-4 h-4 bg-slate-600 rounded-sm"
                      >
                        <img src="https://last9.github.io/assets-docs/linkedin.svg" />
                      </a>
                      <a
                        href="https://twitter.com/nishantmodak"
                        target="_blank"
                        className="w-4 h-4"
                      >
                        <img src={x} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <hr className="border border-slate-200" /> */}
          <div className="w-full flex flex-col items-center">
            <h1 className="md:text-5xl text-4xl leading-[1.167] font-black text-center">
              Help us build our 9s
            </h1>
            <Lottie animationData={rtaPageAnimation} />
            <div className="flex items-center gap-4">
              <Link
                to="/schedule-demo"
                className="w-fit rounded-full border-2 border-solid border-brand-green-200 px-6 py-3.5 text-center text-base font-bold text-brand-green-200"
              >
                Book a Demo
              </Link>
              <Link
                href="/9"
                className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-brand-green-200 px-6 text-center text-base font-bold text-white"
              >
                Our Ṛta
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <>
    <Seo
      title="Hi, we’re Last9"
      description="Our mission to make running systems at scale — fun and embarrassingly easy"
      image="/thumbnails/thumbnail-last9-about-us.jpg"
    />
  </>
)

export default AboutUs
