import React from "react"
import Seo from "../components/common/seo"
import Hero from "../components/metrics/Hero"
import LogoScroll from "../components/common/logoscroll"
import StickySubheader from "../components/metrics/StickySubheader"
import MetricsDetails from "../components/metrics/MetricDetails"
import OtherCapabilities from "../components/common/OtherCapabilities"
import PreFooter from "../components/home/PreFooter"
import Layout from "../components/common/layout"

const MetricsPage = () => {
  const subheaderLinks = [
    { id: "metrics-cardinality-workflows", title: "Cardinality Workflows" },
    { id: "metrics-correlated-telemetry", title: "Correlated Telemetry" },
    { id: "metrics-open-standards", title: "Open Standards" },
  ]

  return (
    <Layout>
      <main className="flex flex-col [&_a]:underline [&_a]:decoration-blue-300 [&_a]:underline-offset-2 hover:[&_a]:decoration-blue-500">
        <Hero />
        <LogoScroll />
        <StickySubheader links={subheaderLinks} />
        <MetricsDetails />
        <OtherCapabilities page="Metrics" />
        <PreFooter />
      </main>
    </Layout>
  )
}

// This gets added in <head> tag
export const Head = () => (
  <Seo
    title="High Cardinality Metrics Monitoring"
    description="An end-to-end alerting tool built to tackle high cardinality use cases. Designed to reduce alert fatigue and improve Mean Time to Detect."
    image="/thumbnails/thumbnail-last9-logs.jpg"
  />
)

export default MetricsPage
