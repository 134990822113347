export const integrations = [
  {
    src: "https://cdn.simpleicons.org/prometheus/fff",
    bg: "#E6522C",
    name: "Prometheus",
    href: "/docs/integrations-prometheus",
  },
  {
    src: "https://cdn.simpleicons.org/amazonwebservices/fff",
    bg: "#FF4F8B",
    name: "AWS Cloudwatch Metric Stream",
    href: "/docs/how-to-send-cloudwatch-metrics-to-levitate-via-aws-cloudstream",
  },
  {
    src: "https://cdn.simpleicons.org/opentelemetry/fff",
    bg: "#425CC7",
    name: "OpenTelemetry",
    href: "/docs/integration-opentelemetry",
  },
  {
    src: "https://cdn.simpleicons.org/victoriametrics/fff",
    bg: "#E94600",
    name: "vmagnet",
    href: "/docs/integrations-vmagent",
  },
  {
    src: "https://cdn.simpleicons.org/influxdb/fff",
    bg: "#22ADF6",
    name: "Telegraf",
    href: "/docs/integrations-telegraf",
  },
  {
    src: "https://last9.github.io/assets-docs/integration-statsd.png",
    invert: false,
    bg: "#3DB98F",
    name: "statsd",
    href: "/docs/integrations-statsd",
  },
  {
    href: "/docs/connect-to-levitate-over-aws-privatelink",
    src: "https://cdn.simpleicons.org/amazonwebservices/fff",
    name: "AWS PrivateLink",
    bg: "#232F3E",
  },
  {
    src: "https://last9.github.io/assets-docs/integration-jmxtrans.png",
    invert: false,
    name: "jmxtrans",
    href: "/docs/integrations-jmxtrans",
    class: "text-slate-600",
  },
  {
    src: "https://last9.github.io/assets-docs/integration-confluent.svg",
    bg: "#0074A1",
    name: "Confluent",
    href: "/docs/integrations-confluent",
  },
]
