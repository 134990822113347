exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-9-index-jsx": () => import("./../../../src/pages/9/index.jsx" /* webpackChunkName: "component---src-pages-9-index-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-alerting-jsx": () => import("./../../../src/pages/alerting.jsx" /* webpackChunkName: "component---src-pages-alerting-jsx" */),
  "component---src-pages-blog-all-js": () => import("./../../../src/pages/blog/all.js" /* webpackChunkName: "component---src-pages-blog-all-js" */),
  "component---src-pages-blog-authors-jsx": () => import("./../../../src/pages/blog/authors.jsx" /* webpackChunkName: "component---src-pages-blog-authors-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-subscribe-index-jsx": () => import("./../../../src/pages/blog/subscribe/index.jsx" /* webpackChunkName: "component---src-pages-blog-subscribe-index-jsx" */),
  "component---src-pages-blog-tag-all-js": () => import("./../../../src/pages/blog/tag/all.js" /* webpackChunkName: "component---src-pages-blog-tag-all-js" */),
  "component---src-pages-changelog-jsx": () => import("./../../../src/pages/changelog.jsx" /* webpackChunkName: "component---src-pages-changelog-jsx" */),
  "component---src-pages-control-plane-jsx": () => import("./../../../src/pages/control-plane.jsx" /* webpackChunkName: "component---src-pages-control-plane-jsx" */),
  "component---src-pages-cricket-scale-tsx": () => import("./../../../src/pages/cricket-scale.tsx" /* webpackChunkName: "component---src-pages-cricket-scale-tsx" */),
  "component---src-pages-customer-stories-index-jsx": () => import("./../../../src/pages/customer-stories/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-index-jsx" */),
  "component---src-pages-customer-stories-probo-cuts-monitoring-costs-by-90-per-cent-with-last-9-jsx": () => import("./../../../src/pages/customer-stories/probo-cuts-monitoring-costs-by-90-per-cent-with-last9.jsx" /* webpackChunkName: "component---src-pages-customer-stories-probo-cuts-monitoring-costs-by-90-per-cent-with-last-9-jsx" */),
  "component---src-pages-customer-stories-quickwork-champions-platform-transparency-for-its-customers-with-last-9-jsx": () => import("./../../../src/pages/customer-stories/quickwork-champions-platform-transparency-for-its-customers-with-last9.jsx" /* webpackChunkName: "component---src-pages-customer-stories-quickwork-champions-platform-transparency-for-its-customers-with-last-9-jsx" */),
  "component---src-pages-customer-stories-reliable-observability-for-25-million-concurrent-live-streaming-viewers-jsx": () => import("./../../../src/pages/customer-stories/reliable-observability-for-25-million-concurrent-live-streaming-viewers.jsx" /* webpackChunkName: "component---src-pages-customer-stories-reliable-observability-for-25-million-concurrent-live-streaming-viewers-jsx" */),
  "component---src-pages-data-tiering-jsx": () => import("./../../../src/pages/data-tiering.jsx" /* webpackChunkName: "component---src-pages-data-tiering-jsx" */),
  "component---src-pages-events-index-jsx": () => import("./../../../src/pages/events/index.jsx" /* webpackChunkName: "component---src-pages-events-index-jsx" */),
  "component---src-pages-friends-jsx": () => import("./../../../src/pages/friends.jsx" /* webpackChunkName: "component---src-pages-friends-jsx" */),
  "component---src-pages-guides-index-jsx": () => import("./../../../src/pages/guides/index.jsx" /* webpackChunkName: "component---src-pages-guides-index-jsx" */),
  "component---src-pages-guides-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/guides/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-guides-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-high-cardinality-jsx": () => import("./../../../src/pages/high-cardinality.jsx" /* webpackChunkName: "component---src-pages-high-cardinality-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-levitate-tsdb-jsx": () => import("./../../../src/pages/levitate-tsdb.jsx" /* webpackChunkName: "component---src-pages-levitate-tsdb-jsx" */),
  "component---src-pages-levitate-whitepaper-jsx": () => import("./../../../src/pages/levitate-whitepaper.jsx" /* webpackChunkName: "component---src-pages-levitate-whitepaper-jsx" */),
  "component---src-pages-logs-jsx": () => import("./../../../src/pages/logs.jsx" /* webpackChunkName: "component---src-pages-logs-jsx" */),
  "component---src-pages-managed-prometheus-jsx": () => import("./../../../src/pages/managed-prometheus.jsx" /* webpackChunkName: "component---src-pages-managed-prometheus-jsx" */),
  "component---src-pages-metrics-jsx": () => import("./../../../src/pages/metrics.jsx" /* webpackChunkName: "component---src-pages-metrics-jsx" */),
  "component---src-pages-monitoring-jsx": () => import("./../../../src/pages/monitoring.jsx" /* webpackChunkName: "component---src-pages-monitoring-jsx" */),
  "component---src-pages-newrelic-alternative-jsx": () => import("./../../../src/pages/newrelic-alternative.jsx" /* webpackChunkName: "component---src-pages-newrelic-alternative-jsx" */),
  "component---src-pages-opentelemetry-jsx": () => import("./../../../src/pages/opentelemetry.jsx" /* webpackChunkName: "component---src-pages-opentelemetry-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-reduce-monitoring-costs-jsx": () => import("./../../../src/pages/reduce-monitoring-costs.jsx" /* webpackChunkName: "component---src-pages-reduce-monitoring-costs-jsx" */),
  "component---src-pages-schedule-demo-jsx": () => import("./../../../src/pages/schedule-demo.jsx" /* webpackChunkName: "component---src-pages-schedule-demo-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-traces-jsx": () => import("./../../../src/pages/traces.jsx" /* webpackChunkName: "component---src-pages-traces-jsx" */),
  "component---src-pages-tsdb-compare-index-jsx": () => import("./../../../src/pages/tsdb/compare/index.jsx" /* webpackChunkName: "component---src-pages-tsdb-compare-index-jsx" */),
  "component---src-pages-whitepapers-scaling-metrics-maturity-in-a-cloud-native-world-index-jsx": () => import("./../../../src/pages/whitepapers/scaling-metrics-maturity-in-a-cloud-native-world/index.jsx" /* webpackChunkName: "component---src-pages-whitepapers-scaling-metrics-maturity-in-a-cloud-native-world-index-jsx" */),
  "component---src-templates-author-jsx": () => import("./../../../src/templates/Author.jsx" /* webpackChunkName: "component---src-templates-author-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/Blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-changelog-jsx": () => import("./../../../src/templates/Changelog.jsx" /* webpackChunkName: "component---src-templates-changelog-jsx" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/Event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/Tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}

