import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Balancer from "react-wrap-balancer"
import AutoDataTiering from "./AutoDataTiering"

const HowTieringWorks = () => {
  return (
    <div>
      <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-10 text-brand-blue-100">
        <div className="flex w-full flex-col items-center gap-8">
          <h3 className="max-w-md text-center text-4xl font-black tracking-tight text-black md:text-6xl">
            <Balancer>Automatic Data Tiering</Balancer>
          </h3>
        </div>

        {/* tiering flow */}
        <div className="flex flex-col gap-6 sm:flex-row">
          <div className="flex basis-1/3 items-center justify-center">
            <StaticImage
              src="../../../static/levitate-tsdb/data-tiering.png"
              alt="data tiering on levitate"
              className="max-h-full"
            />
          </div>

          {/* points */}
          <div className="flex basis-1/3 flex-col items-center justify-center gap-8 text-center text-base text-slate-600 md:text-xl">
            <p>Teams write time series data to a single endpoint.</p>
            <span className="h-10 w-0.5 rounded bg-green-600"></span>
            <p>All tiers have all the data, but their data retention varies.</p>
            <span className="h-10 w-0.5 rounded bg-green-600"></span>
            <p>
              Treat metrics as per their use case instead of treating all of
              them as one.
            </p>
          </div>
          {/* end points */}

          <div className="flex basis-1/3 items-center justify-center">
            <StaticImage
              src="../../../static/levitate-tsdb/unused-metrics.png"
              alt="data policies on levitate"
              className="max-h-full"
            />
          </div>
        </div>
        {/* end tiering flow */}
      </div>
      <AutoDataTiering />
    </div>
  )
}

export default HowTieringWorks
