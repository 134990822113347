/* eslint-disable react/prop-types */
import React from "react"
import { Link } from "gatsby"
import QuoteLight from "../common/QuoteLight"
import authorImg from "../../../static/levitate-tsdb/akash-saxena.jpeg"
import { quotes } from "../../constants/quotes"
import QuoteDark from "../common/QuoteDark"

const AkashQuote = () => {
  return (
    <QuoteLight
      clientName="hotstar"
      author={{
        img: authorImg,
        name: "Akash Saxena",
      }}
      quote={quotes["akash-saxena"]}
    />
  )
}

export const AkashQuoteDark = ({ hideChildren }) => {
  return (
    <QuoteDark
      author={{
        img: authorImg,
        name: "Akash Saxena",
        link: "https://www.linkedin.com/in/akisaxena/",
      }}
      quote={
        <div className="mx-auto max-w-3xl">
          <p className="text-center text-base tracking-normal text-white md:text-xl">
            {quotes["akash-saxena"]}{" "}
            <Link
              to="/blog/observability-and-sre-at-hotstar-with-last9/"
              className="text-xs hover:underline"
            >
              Read More <sup>↗</sup>
            </Link>
          </p>
        </div>
      }
    >
      {!hideChildren && (
        <>
          {/* Divider */}
          <div className="h-0.5 w-full bg-gradient-to-r from-transparent via-brand-green-100 to-transparent opacity-25"></div>

          {/* stats */}
          <div className="flex flex-col items-center gap-6">
            <p className="max-w-md text-center text-slate-400">
              Levitate is the ultimate solution for Disney+ Hotstar’s scaling
              requirements — providing a flawless user experience and
              uninterrupted delivery of content, ensuring seamless streaming for
              its users.
            </p>

            {/** stat numbers */}
            <div className="flex max-w-md flex-row gap-6 text-center font-bold uppercase">
              <div className="flex flex-1 flex-col items-center">
                <span className="text-2xl font-black text-brand-green-100 md:text-[32px]">
                  750M
                </span>
                <span className="text-xs text-slate-400 md:text-sm">
                  Samples per minute
                </span>
              </div>
              <div className="flex flex-1 flex-col items-center">
                <span className="text-2xl font-black text-brand-green-100 md:text-[32px]">
                  50M
                </span>
                <span className="text-xs text-slate-400 md:text-sm">
                  Read Requests per minute
                </span>
              </div>
              <div className="flex flex-1 flex-col items-center">
                <span className="text-2xl font-black text-brand-green-100 md:text-[32px]">
                  1B
                </span>
                <span className="text-xs text-slate-400 md:text-sm">
                  Active Time Series
                </span>
              </div>
            </div>
            {/** end stat numbers */}
          </div>
          {/* end stats */}
        </>
      )}
    </QuoteDark>
  )
}

export default AkashQuote
