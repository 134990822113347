import React from "react"
import { Link } from "gatsby"
import FeaturedPost from "./featuredPost"
import Post from "./post"
import FeaturedThumbnail from "./featuredthumbnail"
import { PropTypes } from "prop-types"
import { ArrowRightIcon } from "@heroicons/react/outline"

const Posts = ({
  posts,
  heading,
  viewAllLink = false,
  showFeaturedposts = false,
  featured = false,
  news = false,
}) => {
  return (
    <div>
      {/* other sections */}
      {!featured && (
        <div className="px-4 md:px-6 xl:px-0">
          {/* section header */}
          <div className="flex items-center justify-between">
            <div className="my-7 text-3xl font-extrabold lg:my-10 lg:text-5xl">
              {heading}
            </div>
            {viewAllLink && (
              <Link
                to={viewAllLink}
                className="flex flex-nowrap items-center gap-2 font-bold uppercase"
              >
                View All
                <ArrowRightIcon className="h-5" />
              </Link>
            )}
          </div>
          {!news && posts !== null && posts.length > 0 && (
            <hr className="mb-8 text-white text-opacity-25" />
          )}
          {/* end section header */}

          <div className="flex flex-col gap-12">
            {showFeaturedposts ? (
              <FeaturedPost key={posts[0].id} post={posts[0]} />
            ) : null}

            <div className="-mx-4 flex snap-x scroll-px-4 flex-row flex-nowrap gap-x-6 gap-y-12 overflow-x-scroll px-4 pb-6 group-[.is-stacked]:flex-col md:mx-0 md:grid md:snap-none md:grid-cols-12 md:px-0 md:pb-0 xl:gap-x-12 xl:gap-y-24">
              {posts.slice(showFeaturedposts ? 1 : 0).map(post => (
                <Post key={post.id} news={news} post={post} />
              ))}
            </div>
          </div>
        </div>
      )}
      {/* end other sections */}

      {/* featured section */}
      {featured && (
        <div className="px-4 md:px-6 xl:px-0">
          <div className="my-7 text-3xl font-extrabold lg:my-10 lg:text-5xl">
            {heading}
          </div>
          {posts !== null && posts.length > 0 && (
            <hr className="mb-8 text-white text-opacity-25" />
          )}
          <div className="flex flex-col gap-6 md:flex-row lg:gap-12">
            <div className="-mx-4 flex snap-x scroll-px-4 flex-row flex-nowrap gap-x-6 gap-y-12 overflow-x-scroll px-4 pb-6 group-[.is-stacked]:flex-col md:mx-0 md:grid md:snap-none md:grid-cols-12 md:px-0 md:pb-0 xl:gap-x-12 xl:gap-y-24">
              {posts.map(post => (
                <React.Fragment key={post.id}>
                  {!post.is_featured && <FeaturedThumbnail post={post} />}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* end featured section */}

      {/* No data found if posts.length = 0 */}
      {posts !== null && posts.length === 0 && (
        <span className="my-10 px-4 text-xl md:px-6 lg:text-3xl xl:px-0">
          No matching posts.
        </span>
      )}

      {/* Loading  */}
      {posts === null && (
        <span className="my-10 px-4 text-center text-xl md:px-6 lg:text-3xl xl:px-0">
          Loading...
        </span>
      )}
    </div>
  )
}

Posts.propTypes = {
  posts: PropTypes.array,
  heading: PropTypes.string,
  viewAllLink: PropTypes.string,
  showFeaturedposts: PropTypes.bool,
  featured: PropTypes.string,
  searched: PropTypes.string,
  showSlider: PropTypes.bool,
  news: PropTypes.string,
}

Posts.defaultProps = {
  posts: [],
  heading: ``,
  viewAllLink: ``,
  showFeaturedposts: false,
  featured: ``,
  searched: ``,
  showSlider: false,
  news: ``,
}

export default Posts
