import React from "react"
import PropTypes from "prop-types"
import Avatar from "../common/Avatar"
import clsx from "clsx"

const Avatars = ({ avatars }) => {
  const isFirst = index => {
    return index === 0
  }

  return (
    <div className="">
      {avatars.map((avatar, index) => {
        return (
          <Avatar
            key={avatar.src}
            className={clsx(
              !isFirst(index) && "-ml-3",
              "border-[3px] border-solid border-white"
            )}
            src={avatar.src}
            size={32}
            name={avatar.name}
          />
        )
      })}
    </div>
  )
}

Avatars.propTypes = {
  avatars: PropTypes.array.isRequired,
}

export default Avatars
