import React from "react"
import Balancer from "react-wrap-balancer"

const UnusedMetrics = () => {
  return (
    <div className="mx-auto -mt-14 flex w-full max-w-6xl flex-col items-center gap-8 lg:mt-0 lg:gap-16">
      <h3 className="max-w-lg text-center text-4xl font-black tracking-tight text-black md:text-6xl">
        <Balancer>80% of Metrics are Unused</Balancer>
      </h3>

      <div className="max-w-4xl gap-8 space-y-4 text-left text-base text-slate-600 md:columns-2 md:text-xl">
        <p>Yet you store them and pay for what you don’t use.</p>
        <p>
          The dashboards are constantly slow and crashing because they can’t
          handle concurrent access across teams over a huge amount of data.
        </p>
        <p>
          Fast alerting needs faster but shallow storage and exploration needs
          long term storage. So why treat all metrics the same?
        </p>
        <p>
          With Last9, you get automatic data tiering based on retention
          policies, just like in a data warehouse. Your data is stored in the
          most efficient and cost-effective manner possible, without the need
          for manual intervention.
        </p>
        <p>
          Access to tiers is managed via policies and with advanced controls on
          which client can access which tier, you can ensure that the right data
          is available to the right team at the right time.
        </p>
      </div>
    </div>
  )
}

export default UnusedMetrics
