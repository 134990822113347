import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

const Card = ({ post, ...rest }) => {
  return (
    <button
      {...rest}
      className={clsx("flex basis-[33%] flex-col gap-4", rest?.className)}
    >
      <img
        src={post?.feature_image}
        alt={post?.title}
        loading="lazy"
        className="aspect-[3/2] rounded-3xl object-cover"
      />
      <h4 className="line-clamp-2 text-left text-2xl font-black text-black">
        {post?.title}
      </h4>
    </button>
  )
}

Card.propTypes = {
  post: PropTypes.object.isRequired,
}

export default Card
