import React from "react"
import headerVisualizationImg from "../../../static/icons/header-visualization.png"
import IconSpark from "../../../static/traces/icon-spark.inline.svg"
import IconQuery from "../../../static/traces/icon-query.inline.svg"
import IconDistributed from "../../../static/traces/icon-distributed.inline.svg"
import Quote from "../common/Quote"
import imageSrc from "../../../static/home/hotstar-quote-image.webp"

import imageTracesRapidRCA from "../../../static/traces/traces-rapid-rca.png"
import imageTracesImprovePerformance from "../../../static/traces/traces-improve-performance.png"
import imageTracesSystemVisibility from "../../../static/traces/traces-system-visibility.png"

const LogExplorer = ({
  quote = {
    client: "tacitbasecolor",
    quote:
      "Last9 has been crucial for us. We’ve been able to find interesting bugs, that was not possible for us with New Relic.",
    author: "Shekhar Patil, Founder & CEO, Tacitbase",
    accent: "#F26046",
    img: <img src={imageSrc} className="h-auto w-full max-w-80" />,
  },
}) => {
  return (
    <section className="scroll-pt-40 bg-gradient-to-b from-yellow-50 to-transparent px-6 py-20">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-16">
        {/* section header */}
        <div className="flex flex-col-reverse items-start justify-between gap-5 md:flex-row md:items-center">
          <h2 className="max-w-xl text-balance text-4xl font-extrabold text-slate-900 md:text-5xl">
            Unblock bottlenecks. Unlock performance.
          </h2>
          <img alt="" className="h-[120px]" src={headerVisualizationImg} />
        </div>
        {/* end section header */}

        {/* card 1 */}
        <div
          id="traces-explorer-rapid-rca"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row-reverse md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageTracesRapidRCA}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconQuery className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Identify root causes quickly with correlated telemetry
            </h3>

            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                Improve MTTR with single pane for distributed traces with
                correlated logs and metrics without changing context or tools
              </li>
              <li>
                Fast searching and filtering on attributes and resources with
                support for all operators
              </li>
              <li>
                Understand upstream and downstream impacts with service
                relationships and execution times
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 1 */}

        {/* card 2 */}
        <div
          id="traces-explorer-improve-performance"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageTracesImprovePerformance}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconSpark className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Improve application performance and user experience
            </h3>

            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                Latency hotspot identification with waterfall charts to discover
                bottlenecks and throttled connections causing slowdowns
              </li>
              <li>
                Use span events to identify key stages in a request’s lifecycle
                and highlight state changes
              </li>
              <li>
                Create real-time dashboards and set proactive alerts with
                anomaly detection using the Traces to Metrics pipeline
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 2 */}

        {/* card 3 */}
        <div
          id="traces-explorer-system-visibility"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row-reverse md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageTracesSystemVisibility}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconDistributed className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Get visibility into your distributed systems
            </h3>
            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                Monitor async and parallel interactions between services, APIs,
                database queries, network calls, and more
              </li>
              <li>
                Increase visibility between teams for faster development,
                troubleshooting, and system management
              </li>

              <li>Isolate environment and deployment information</li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 3 */}

        <Quote {...quote} />
      </div>
    </section>
  )
}

export default LogExplorer
