import React from "react"
import QuoteLight from "../common/QuoteLight"
import authorImg from "../../../static/home/gaurav-hotstar.jpeg"
import { quotes } from "../../constants/quotes"

const CostQuote = () => {
  return (
    <QuoteLight
      clientName="hotstar"
      author={{
        img: authorImg,
        name: "Gaurav Kamboj",
        company: "Cloud Architect",
      }}
      quote={quotes["gaurav-kamboj"]}
    />
  )
}

export default CostQuote
