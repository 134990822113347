import React from "react"
import { Link } from "gatsby"
import Balancer from "react-wrap-balancer"

const LevitatePreFooter = () => {
  return (
    <section className="flex flex-col items-center justify-center gap-10 bg-yellow-50 px-4 py-14 md:px-6 lg:py-[120px] xl:px-0">
      <p className="w-full max-w-4xl text-center text-3xl font-black leading-none text-black md:text-5xl">
        <Balancer>
          Do away with the toil of managing your own Prometheus
        </Balancer>
      </p>
      <p className="max-w-xl text-center text-base text-slate-600 md:text-xl">
        <Balancer>
          Start your monitoring journey today with Levitate. A Managed Time
          Series Data Platform that SREs trust.
        </Balancer>
      </p>
      <div className="flex items-center gap-4">
        <Link
          to="/schedule-demo"
          className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-slate-900 px-6 text-center text-base font-bold text-white"
        >
          Talk to Us
        </Link>
        <a
          href="https://app.last9.io/"
          className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-slate-100 px-6 text-center text-base font-bold text-slate-500"
        >
          Start for Free
        </a>
      </div>
    </section>
  )
}

export default LevitatePreFooter
