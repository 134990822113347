import React from "react"
import { Link } from "gatsby"
import { PropTypes } from "prop-types"

const FeaturedThumbnail = ({ post }) => {
  return (
    <Link
      to={`/blog/${post.slug}/`}
      // className="flex basis-1/3 items-center gap-4 md:flex-col md:items-start"
      className="col-span-4 flex snap-proximity snap-start flex-col gap-2 max-md:min-w-[75%]"
    >
      <img
        src={post.feature_image}
        className="h-48 w-full rounded-2xl border border-slate-100 object-cover"
        loading="lazy"
      />
      <span className="font-bold lg:text-2xl lg:font-extrabold">
        {post.title}
      </span>
    </Link>
  )
}

FeaturedThumbnail.propTypes = { post: PropTypes.object }

FeaturedThumbnail.defaultProps = { post: `` }

export default FeaturedThumbnail
