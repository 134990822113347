import React from "react"
import Balancer from "react-wrap-balancer"
import HighCardinalityQuote from "../quotes/HighCardinalityQuote"


const NeverLoseData = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 md:flex-row lg:gap-16">
      {/** left section */}
      <div className="flex flex-col gap-6 text-slate-600 md:basis-1/2 lg:basis-auto">
        <h3 className="max-w-full text-center text-4xl font-black tracking-tight text-black md:max-w-md md:text-left md:text-6xl">
          <Balancer>Never Lose High Cardinality Data</Balancer>
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          <p>
            Metrics with very high cardinality can overflow beyond the default
            limits.
          </p>
          <p>
            But Levitate makes sure that they are never dropped. They are always
            available for inspection so that you can take informed decisions to
            control them instead of losing them blindly.
          </p>
          <p>
            Decide to drop them at the source, reduce labels, or tame the
            cardinality explosion{" "}
            <a
              className="underline decoration-slate-500 underline-offset-2"
              href="https://last9.io/blog/high-cardinality-no-problem-stream-aggregation-ftw/"
              target="_blank"
              rel="noreferrer"
            >
              using streaming aggregations
            </a>
            .
          </p>
        </div>
      </div>
      {/** end left section */}

      {/** right section */}
      {/* proof */}
      <HighCardinalityQuote />
      {/* end proof */}
      {/** end right section */}
    </div>
  )
}

export default NeverLoseData
