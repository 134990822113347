/* eslint-disable react/no-unescaped-entities */
import * as React from "react"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"

const Terms = () => (
  <Layout>
    <section className="gradient-blog z-50">
      <div className="mx-auto max-w-6xl px-5 pt-16">
        <h1 className="py-5 text-5xl font-extrabold">Terms of Service</h1>
        <p className="my-5">
          Effective Date: Sep 07, 2021 • Last Updated On: Sep 07, 2021
        </p>
        <hr className="my-10 border-2" />
        <p className="my-5">
          By clicking on the “I Agree” (or similar button) or downloading,
          installing or using the platform or by using the services, you
          indicate your assent to the terms and conditions of this end user
          license agreement.
        </p>
        <p className="my-5">
          This terms of service (“TOS”) is a legal agreement between you, the
          user of the platform (“You” or “Your” or “User”) and Last9 Inc
          (“Licensor”, “We”, “Us” and “Our”).
        </p>
        <p className="my-5">
          This TOS provides and captures important legal understanding regarding
          the license to the Last9 Platform (the “Platform”), your usage,
          installation, download and access of the platform and the services (as
          defined below) and liability of the users. This TOS is based upon the
          subscription agreement executed between Licensor and Your Employer
          (herein referred to as “Employer”) for the use of services as per the
          terms agreed therin.
        </p>
        <p className="my-5">
          If you do not agree to be bound by the terms of this TOS, we encourage
          you not to access the Platform, or use the services.
        </p>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">1. Definitions</h1>
          <p className="my-5">
            1.1 — “Services” shall mean the internet accessible service offered
            by Licensor through a Subscription (defined below) under which
            access to the Platform hosted by Licensor on a cloud is made
            available to the Employer and the Users pursuant to the terms of the
            Subscription Agreement (defined below) along with the support and
            maintenance services for the Platform as mentioned therein.
          </p>
          <p className="my-5">
            1.2 — “Subscription” means the limited license to use and access the
            Platform and its related Services in accordance with the
            Subscription Agreement executed between the Employer and Us.
          </p>
          <p className="my-5">
            1.3 — “Subscription Agreement” means the agreement signed between
            the Licensor and Employer in order to obtain Subscription to the
            Services.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">2. Grant of License</h1>
          <p className="my-5">
            Licensor hereby grants You a fixed term, non-exclusive,
            non-assignable/non-transferable, revocable, limited license to use,
            access, download, and install the Platform and use and access
            Services in accordance with this TOS and the documentation (if any)
            solely for the User’s internal business purposes.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            3. Restriction on Use
          </h1>
          <p className="my-5">
            Except to the extent permitted under Section 2 (Grant of License),
            You shall not directly or indirectly:
          </p>
          <p className="my-5">
            3.1 — offer the Platform for rent, lease, or otherwise transfer,
            sub-license, distribute, facilitate the distribution of or transfer
            the Platform in any manner;
          </p>
          <p className="my-5">
            3.2 — copy, modify, adapt, translate, reverse engineer, decompile,
            disassemble, alter, reproduce or otherwise make any changes to the
            Platform;
          </p>
          <p className="my-5">
            3.3 — export or re-export the Platform in any form in violation of
            any applicable laws and regulations regarding export control;
          </p>
          <p className="my-5">
            3.4 — use the Platform to create, develop, promote or market any
            competing or similar software/platform.
          </p>
          <p className="my-5">
            3.5 — defame, abuse, harass, stalk, threaten or otherwise violate
            the legal rights of others and Licensor, directors, employees or
            representatives, by using the Platform;
          </p>
          <p className="my-5">
            3.6 — try to hack, crash or sabotage the Platform;
          </p>
          <p className="my-5">
            3.7 — use any of the Platform’s components, add-ons, files, modules,
            externals, contents including associated license material separately
            from the Platform;
          </p>
          <p className="my-5">
            3.8 — use the Platform for performing comparisons, time-sharing,
            benchmarking activities or other computer services to third, either
            alone or in connection with any other software (and You will not
            publish or disclose any such performance information or comparisons)
            or allow any third party to access or benefit from the functionality
            of the Platform;
          </p>
          <p className="my-5">
            3.9 — exercise any right not specifically and unequivocally granted
            to User and are reserved by Licensor or should act in a manner
            detrimental to the Licensor.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            4. Account Management
          </h1>
          <p className="my-5">
            As a condition for Your use of the Services, You will be required to
            register with the Licensor and select a password and enter Your
            email address on the Licensor’s Platform. This account management
            will be facilitated through an admin appointed by the Employer. You
            must ensure to provide the Licensor with accurate, complete, and
            updated registration information. Failure to do so shall constitute
            a breach of this TOS, which may result in immediate termination of
            Your account. You shall be responsible for maintaining the
            confidentiality of Your account and password. By registering Your
            details with Licensor, You agree that we may contact You with
            important information relating to the Platform.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            5. Compliance with Laws
          </h1>
          <p className="my-5">
            The User shall comply with all applicable local, state, national and
            foreign laws in connection with its use of the Services, including
            those laws related to data privacy, international communications,
            and the transmission of technical or personal data. The User
            acknowledges that the Licensor exercises no control over the content
            of the information transmitted by the User through the Platform. The
            User shall not upload, post, reproduce or distribute any
            information, software or other material protected by copyright,
            privacy rights, or any other intellectual property right without
            first obtaining the permission of the owner of such rights.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            6. Unauthorized Use; False Information
          </h1>
          <p className="my-5">
            The User shall: (i) notify the Licensor immediately of any
            unauthorized use of password or user id or any other known or
            suspected breach of security, (ii) report to the Licensor
            immediately and use reasonable efforts to stop any unauthorized use
            of the Services that is known or suspected by the User, and (iii)
            not provide false identity information to gain access to or use the
            Services.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">7. Access</h1>
          <p className="my-5">
            The User shall be solely responsible for its acts and omissions. The
            Licensor shall not be liable for any loss of data or functionality
            caused directly or indirectly by the User.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">8. User Content</h1>
          <p className="my-5">
            The User is solely responsible for collecting, uploading and
            updating all the User content uploaded on the Platform, and for
            ensuring that the User content does not (i) include anything that
            actually or potentially infringes or misappropriates the copyright,
            trade secret, trademark or other intellectual property right of any
            third party, or (ii) contain anything that is obscene, defamatory,
            harassing, offensive or malicious. User acknowledges and agrees that
            Licensor uses certain third-party tools for analytical purposes and
            may use User’s content and track User’s usage of the Services for
            any purpose including but not limited to research, analytics, and to
            improve the Services.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            9. Intellectual Property Rights
          </h1>
          <p className="my-5">
            9.1 — Any and all rights to the Services, Platform, documentation,
            enhancements, and branding thereof including title, ownership rights
            and intellectual property rights such as copyrights, trademarks,
            service marks and patents therein is the sole and exclusive property
            of Licensor. This Agreement does not grant User any rights, title
            and interest in and to the Platform, documentation, enhancements,
            its contents and branding thereof including Licensor’s intellectual
            property rights except where expressly and unequivocally licensed
            herein.
          </p>
          <p className="my-5">
            9.2 — From time to time, User may provide feedback, suggestions,
            requirements or recommendations (“Feedback”) regarding the Platform
            or the Subscription. User hereby assigns to Licensor all right,
            title and interest into such Feedback and an exclusive right to
            create any developments based on such Feedback.
          </p>
          <p className="my-5">
            9.3 — User shall retain title to and all ownership rights in User
            content. User shall grant to Licensor a worldwide, non-exclusive,
            and non-transferable limited-term license to host, copy, transmit,
            analyze, process, display, store, configure, and perform User
            content solely as necessary to provide the Services to User.
          </p>
          <p className="my-5">
            9.4 — User acknowledges and agrees the Platform may generate certain
            reports (“Reports”) with the use of User content provided by User
            while availing the Services. All intellectual property rights
            including the ownership rights in all such Reports generated during
            the provision of Subscription shall vest solely with Licensor.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">10. Confidentiality</h1>
          <p className="my-5">
            10.1 — The Platform provided under this TOS contains valuable trade
            secrets and is the confidential information of the Licensor. You
            shall not disclose, provide or otherwise make available the Platform
            to any third party, except as expressly granted under this TOS.
          </p>
          <p className="my-5">
            10.2 — You agree to use all commercially reasonable precautions to
            protect the confidentiality of the Platform and shall secure from
            all third parties having access to the Platform, as per the terms of
            this TOS, and commit to maintain the Platform in confidence.
          </p>
          <p className="my-5">
            10.3 — You agree that under no circumstances the Licensor or its
            affiliates or subsidiaries shall be held responsible or liable for
            any loss, damage or harm caused due to Your reliance on information
            obtained from the Platform.
          </p>
          <p className="my-5">
            10.4 — The confidentiality obligations stated herein shall survive
            for a period of five (5) years from the date of termination or
            expiration of the Subscription Agreement along with this TOS.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            11. Warranty Disclaimer and Liability
          </h1>
          <p className="my-5">
            User acknowledges that the platform and its subscription are
            provided “as is” without any representations, conditions, warranties
            or covenants whatsoever, including without limitation, any express,
            statutory or implied representations, warranties or conditions of
            merchantability, merchantable quality, satisfactory quality or
            fitness for a particular purpose, or arising otherwise in law or
            equity or from a course of dealing or usage of trade, all of which
            are expressly disclaimed and excluded. The platform and its
            subscription may contain defects, bugs or errors. Licensor does not
            warrant that the functions contained in any of the platform and its
            subscription will meet user’s requirements, that the operation of
            the subscription will be uninterrupted or error-free or that defects
            in the subscription will be corrected. User acknowledges that
            licensor does not control the accuracy, transfer of data over
            communications facilities, including the internet, and that the
            subscription may be subject to limitations, delays, and other
            problems inherent in the use of such communications facilities.
          </p>
          <p className="my-5">
            Neither party shall be liable to the other for any direct or
            indirect, consequential, incidental, exemplary or special damages,
            howsoever caused in connection with this tos even if the other party
            has been advised of the possibility of such damage.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">12. Reporting</h1>
          <p className="my-5">
            You acknowledge that the Platform may contain automated reporting
            routines that will automatically identify and analyze certain
            aspects of Your use and performance of Platform and/or the systems
            through which the Platform is accessed (including problems and
            issues that arise in connection therewith) and provide information
            back to Licensor. Licensor will be free to use for development,
            diagnostic, compliance monitoring, and corrective purposes any such
            data and information it so collects.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            13. Third Party Platform
          </h1>
          <p className="my-5">
            Third-party product/platform (if any) used through the Platform
            shall be subject to separate terms and conditions provided by such
            third-party product/platform. The license restrictions contained in
            this TOS do not apply to third-party product/platform to the extent
            they are inconsistent with such third-party product/platform terms.
            The Licensor shall not be responsible for any third-party
            product/platform.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">14. Indemnity</h1>
          <p className="my-5">
            You agree to defend, indemnify and hold harmless Licensor and its
            affiliates, and their employees, contractors, agents, officers and
            directors, from and against any and all claims, damages,
            obligations, losses, liabilities, costs or debt, and expenses
            (including but not limited to attorney’s fees) arising from: (i)
            Your use of and access to the Platform and/or Services, including
            any data or work transmitted or received by You; (ii) Your violation
            of any term of this TOS; (iii) Your violation of any third-party
            right, including without limitation any right of privacy, publicity
            rights or intellectual property rights; (iv) Your violation of any
            applicable law, rule or regulation; (v) any claim of damages that
            arise as a result of any of Your content shared by You or the other
            participants of the Conversation; or (vi) any other party’s access
            and use of the Platform or Services with Your unique username,
            password or other appropriate security code.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">15. Termination</h1>
          <p className="my-5">
            This TOS shall get terminated as soon as the Subscription Agreement
            gets terminated between Employer and the Licensor or upon deletion
            of the account by the User on the Platform, whichever is earlier.
            Upon the expiry or any termination of this TOS, Users’ right to use
            the Services shall immediately cease and at Licensor’s discretion,
            User will return/ delete/ destroy any materials provided by Licensor
            to Users as per the terms of the Subscription Agreement.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            16. Audit and Inspection
          </h1>
          <p className="my-5">
            Licensor shall have the right to audit User’s use of the Services in
            order to verify that it is being used in compliance with this TOS.
            Notwithstanding anything to the contrary contained herein, Licensor
            shall be entitled to introduce patches and / or other programs to
            monitor User’s usage of the Services, in order to ensure that User’s
            usage of the Services is in accordance with the terms of this TOS.
            In the event of User’s usage exceeds permitted usage as set out
            under this TOS, the patches and / or other programs introduced by
            Licensor might make the Platform unusable and, in such event,
            Licensor shall not be liable for any damages, expenses, losses, or
            any other consequence caused to User as a result thereof.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">17. Governing Laws</h1>
          <p className="my-5">
            This TOS shall be construed and governed by and construed and
            enforced in accordance with the laws of State of Delaware and the
            courts of New Castle County, Delaware shall have an exclusive
            jurisdiction to adjudicate any subject matter under this TOS.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">18. Force Majeure</h1>
          <p className="my-5">
            Neither party shall be in default or liable for any delay or failure
            to comply with this TOS due to any act beyond the control.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">19. Interpretation</h1>
          <p className="my-5">
            This TOS will in all events be construed as a whole, according to
            its fair meaning, and not strictly for or against a party merely
            because that party (or the party's legal representative) drafted the
            TOS. The headings, titles, and captions contained in this TOS are
            merely for reference and do not define, limit, extend, or describe
            the scope of this TOS or any provision herein. Unless the context
            requires otherwise, (a) the gender (or lack of gender) of all words
            used in this TOS includes the masculine, feminine, and neuter, and
            (b) the word "including"means "including, without limitation”.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">20. Entire Agreement</h1>
          <p className="my-5">
            This TOS constitutes the entire agreement between the parties
            pertaining to the subject matter hereof, and any and all written or
            oral agreements previously existing between the parties pertaining
            to the subject matter hereof are inapplicable. Neither the rights
            nor the obligations arising under this TOS are assignable or
            transferable by You, and any such attempted assignment or transfer
            shall be void and without effect.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            21. Independent Contract
          </h1>
          <p className="my-5">
            No agency, partnership, joint venture, or employment is created
            between You and the Licensor as a result of this TOS, and You do not
            have any authority of any kind to bind Licensor in any respect
            whatsoever.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">22. Severability</h1>
          <p className="my-5">
            In the event that any of the provisions of this TOS shall be held by
            a court or other tribunal of competent jurisdiction to be
            unenforceable, such provisions shall be limited or eliminated to the
            minimum extent necessary so that this TOS shall otherwise remain in
            full force and effect and enforceable.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">23. Waiver</h1>
          <p className="my-5">
            Any waivers and modifications must be in writing and signed by both
            parties. No delay or omission by either party in exercising any
            right or remedy under this TOS or existing at law or equity shall be
            considered a waiver of such right or remedy.
          </p>
        </div>
        <div>
          <h1 className="pt-8 pb-3 text-3xl font-bold">24. General</h1>
          <p className="my-5">
            You agree to use the Platform and Services at Your own risk. If You
            have any questions, complaints or claims with respect to the
            Platform or Services, You should contact Mr. Kuldeep Dhankar (CRO -
            kuldeep@last9.io).
          </p>
        </div>
        <div className="pb-10">
          <h1 className="pt-8 pb-3 text-3xl font-bold">
            25. Changes to this TOS
          </h1>
          <p className="my-5">
            We may update the terms and conditions of this TOS. We encourage You
            to check these terms and conditions on a regular basis to be aware
            of the changes made to it.
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export const Head = () => <Seo title="Privacy Policy" />

export default Terms
