import React from "react"
import * as AvatarPrimitive from "@radix-ui/react-avatar"
import PropTypes from "prop-types"
import clsx from "clsx"

const getInitials = name => {
  const [firstName, lastName] = name.split(" ")
  return `${firstName?.[0]}${lastName?.[0]}`
}

const Avatar = ({ src, size, name = "A", className }) => {
  return (
    <AvatarPrimitive.Root
      style={size ? { width: size, height: size } : {}}
      className={clsx(
        "inline-flex select-none items-center justify-center overflow-hidden rounded-full align-middle",
        className
      )}
    >
      <AvatarPrimitive.Image
        className="h-full w-full rounded-[inherit] object-cover"
        src={src}
        alt={name}
      />
      <AvatarPrimitive.Fallback
        className="leading-1 flex h-full w-full items-center justify-center bg-white text-[15px] font-medium"
        delayMs={600}
      >
        {getInitials(name)}
      </AvatarPrimitive.Fallback>
    </AvatarPrimitive.Root>
  )
}

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.number,
  name: PropTypes.string,
  className: PropTypes.string,
}

export default Avatar
