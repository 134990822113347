import React, { useState, useEffect, useCallback } from "react"

const StickySubheader = ({ links }) => {
  const [activeSection, setActiveSection] = useState("")
  // Check if banner exists by looking at main element's margin
  const [hasBanner, setHasBanner] = useState(false)

  useEffect(() => {
    const mainElement = document.querySelector("main")
    if (mainElement) {
      const style = window.getComputedStyle(mainElement)
      setHasBanner(style.marginTop !== "64px") // 64px is the non-banner margin (mt-16)
    }
  }, [])

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY
    const headerHeight = 320 // Adjust this value to match your header height

    // Find all sections
    const sections = links
      .map(link => {
        const element = document.getElementById(link.id)
        if (element) {
          return {
            id: link.id,
            top: element.offsetTop - headerHeight,
            bottom: element.offsetTop + element.offsetHeight - headerHeight,
          }
        }
        return null
      })
      .filter(Boolean)

    // Check if we're above the first section or below the last section
    if (sections.length === 0 || scrollPosition < sections[0].top) {
      setActiveSection("")
      return
    }

    if (scrollPosition >= sections[sections.length - 1].bottom) {
      setActiveSection("")
      return
    }

    // Find the current section
    let newActiveSection = activeSection
    for (let i = 0; i < sections.length; i++) {
      if (
        scrollPosition >= sections[i].top &&
        scrollPosition < sections[i].bottom
      ) {
        newActiveSection = sections[i].id
        break
      }
    }

    setActiveSection(newActiveSection)
  }, [links, activeSection])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    handleScroll() // Call once to set initial active section

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  return (
    <nav
      className={`sticky z-50 hidden h-16 bg-slate-50 px-6 shadow-md md:block ${
        hasBanner ? "top-[calc(64px+56px)] md:top-[calc(64px+40px)]" : "top-16"
      }`}
    >
      <div className="mx-auto flex max-w-6xl items-center justify-center">
        <ul className="flex gap-6">
          {Array.isArray(links) &&
            links.map(link => (
              <li key={link.id}>
                <a
                  href={`#${link.id}`}
                  className={`flex h-16 items-center border-b-4 border-solid text-center text-sm !no-underline ${
                    activeSection === link.id
                      ? "border-blue-500 text-blue-500"
                      : "border-transparent text-slate-600"
                  }`}
                >
                  {link.title}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </nav>
  )
}

export default StickySubheader
