import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/common/layout"
import Seo from "../../components/common/seo"
import TableOfContent from "../../components/blog/TableOfContent"

export default function GuideMarkdown({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <section className="gradient-blog z-50 px-4 pb-16 pt-14 lg:gap-y-16 lg:px-6 lg:pb-32 lg:pt-20">
        <div className="mx-auto grid w-full max-w-6xl grid-cols-12">
          {/* header */}
          <div className="col-span-12 flex flex-col gap-10">
            <Link
              to="/guides/"
              className="text-slate-500 underline decoration-slate-300 underline-offset-2 hover:decoration-slate-400"
            >
              Guides
            </Link>

            <h1 className="text-balance text-3xl font-black leading-none md:text-4xl lg:text-6xl">
              {frontmatter.title}
            </h1>

            {/* tags */}
            {frontmatter.tags && (
              <ul className="flex flex-wrap gap-2">
                {frontmatter.tags.split(",").map(tag => (
                  <li
                    key={tag.trim()}
                    className="rounded-full bg-slate-100 px-2 py-1 text-xs text-slate-500"
                  >
                    {tag.trim()}
                  </li>
                ))}
              </ul>
            )}
            {/* end tags */}
          </div>
          {/* end header */}

          {/* body */}
          <div className="col-start-1 col-end-13 lg:col-start-1 lg:col-end-9 lg:row-start-2 lg:row-end-4">
            <div
              className="js-toc-content prose mt-8 max-w-none prose-li:text-base"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
          {/* end body */}

          {/* sticky sidebar */}
          <div className="relative col-start-1 col-end-13 row-start-2 row-end-4 lg:col-start-10">
            {/* change top-32 to top-28 once toc is fixed */}
            <div className="sticky top-32 mt-8 flex w-full flex-col gap-6">
              <span className="md:px-8">
                <TableOfContent />
              </span>

              <div className="flex flex-col divide-y-2 divide-emerald-200 rounded-3xl bg-emerald-50 px-8 py-6">
                <div className="flex flex-col gap-4 pb-8">
                  <span className="space-y-3">
                    <span className="text-sm font-bold uppercase text-slate-500">
                      See How Last9 Works
                    </span>
                    <p className="text-sm text-slate-500">
                      Understand how Last9 can unlock a single pane of
                      observability for all your telemetry data. Open Standards
                      compatible. Simple pricing.
                    </p>
                  </span>
                  <Link
                    to="/schedule-demo"
                    className="w-fit rounded-full bg-emerald-500 px-6 py-2.5 font-bold text-white !no-underline"
                  >
                    Talk to us
                  </Link>
                </div>

                <div className="flex gap-4 pt-6">
                  <a
                    href="https://app.last9.io/"
                    className="text-sm text-emerald-600"
                  >
                    <span className="underline decoration-emerald-400 underline-offset-2">
                      Get started
                    </span>{" "}
                    ↗
                  </a>
                  <a
                    href="https://docs.last9.io/"
                    className="text-sm text-emerald-600"
                  >
                    <span className="underline decoration-emerald-400 underline-offset-2">
                      View docs
                    </span>{" "}
                    ↗
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* end sticky sidebar */}
        </div>
      </section>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark } }) => (
  <Seo
    title={`Guide — ${markdownRemark.frontmatter.title}`}
    description={markdownRemark.frontmatter.excerpt}
    image="/thumbnails/thumbnail-last9-home.jpg"
  />
)

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(
      id: { eq: $id }
      fileAbsolutePath: { regex: "/static/markdown/guides/" }
    ) {
      html
      frontmatter {
        date
        slug
        title
        tags
        excerpt
      }
    }
  }
`
