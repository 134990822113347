import React, { useState, useEffect } from "react"
import Layout from "../components/common/layout"
import Posts from "../components/changelog/posts"
import Subscription from "../components/blog/subscription"
import axios from "axios"
import Seo from "../components/common/seo"

const Changelog = () => {
  const [posts, setPosts] = useState(null)
  const POSTS_PER_PAGE = 250

  useEffect(() => {
    // eslint-disable-next-line max-len
    const stringQuery = `&filter=tag:changelog`
    const query = {
      order: "published_at DESC",
      include: "tags,authors",
      limit: POSTS_PER_PAGE,
    }

    dataFetch(query, stringQuery).then(data => {
      // Latest posts
      setPosts(data)
    })
  }, [])

  const dataFetch = (query, string = "", tag = "") => {
    tag !== "" && (query.filter = `tag:${tag}`)
    return axios
      .get(
        `${process.env.GATSBY_GHOST_API_URL}/ghost/api/content/posts/?key=${process.env.GATSBY_GHOST_API_KEY}${string}`,
        { params: query },
      )
      .then(res => {
        return res.data.posts
      })
  }

  return (
    <Layout>
      <section className="gradient-blog z-50 pb-10 md:pb-20">
        <div className="space-y-10 md:space-y-20">
          <div className="mx-auto max-w-6xl">
            {/* section header */}
            <div className="px-4 md:px-6 xl:px-0">
              <div className="flex items-center justify-between">
                <div className="my-7 flex flex-col gap-6 lg:my-10">
                  <h2 className="text-3xl font-extrabold md:text-5xl">
                    Changelog
                  </h2>
                  <a
                    href="https://x.com/last9io"
                    className="text-sm text-blue-500 underline-offset-2 hover:underline md:text-base"
                  >
                    Follow us on X
                  </a>
                </div>
              </div>
              {posts !== null && posts.length > 0 && (
                <hr className="mb-8 text-white text-opacity-25" />
              )}
            </div>
            {/* end section header */}

            {/* subscription */}
            <div className="mx-auto mb-20 max-w-6xl px-4 md:px-6 xl:px-0">
              <Subscription subType="changelog" />
            </div>
            {/* end subscription end */}

            <Posts
              posts={posts}
              heading="Changelog"
              showFeaturedposts={true}
              clicked={true}
              showSlider={true}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Changelog"
    description="New updates and improvements to Last9"
    image="/thumbnails/thumbnail-last9-changelog.jpg"
  />
)

export default Changelog
