import React from "react"
import ClientLogo from "../../../components/common/ClientLogo"

const Hero = () => {
  return (
    <div className="w-full bg-yellow-50 px-4 pb-60 pt-20">
      <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-16">
        <div className="mx-auto flex w-full max-w-2xl flex-col items-center gap-6 text-center">
          {/* pill */}
          <span className="flex items-center justify-center gap-1 rounded-full border border-solid border-slate-600 bg-transparent px-2 py-1 text-xs font-semibold uppercase text-slate-600">
            Customers
          </span>
          {/* end pill */}

          <h1 className="max-w-full text-3xl font-extrabold text-slate-900 md:text-5xl md:font-bold">
            The preferred observability choice for Cloud Native companies.
          </h1>
          <p className="text-base text-slate-500 md:text-xl">
            Learn how some of the best engineering teams in the world are
            achieving their observability goals with Last9.
          </p>
        </div>

        {/* logos */}
        <div className="mx-auto grid max-w-4xl grid-cols-2 place-content-center place-items-center gap-x-10 gap-y-6 md:flex md:flex-wrap">
          <ClientLogo name="replit" size={48} color="#1e293b" />
          <ClientLogo name="hotstar" size={48} color="#1e293b" />
          <ClientLogo name="clevertap" size={48} color="#1e293b" />
          <ClientLogo name="probo" size={48} color="#1e293b" />
          <ClientLogo name="quickwork" size={48} color="#1e293b" />
          <ClientLogo name="circle" size={48} color="#1e293b" />
          <ClientLogo name="nxtjob" size={48} color="#1e293b" />
          <ClientLogo name="tacitbase" size={48} color="#1e293b" />
          <ClientLogo name="tooljet" size={48} color="#1e293b" />
          <ClientLogo name="huzzle" size={48} color="#1e293b" />
          <ClientLogo name="dotconn" size={48} color="#1e293b" />
        </div>
        {/* end logos */}
      </div>
    </div>
  )
}

export default Hero
