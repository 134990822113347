import * as React from "react"

import Layout from "../components/common/layout"
import Seo from "../components/common/seo.jsx"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => (
  <Layout>
    <main className="flex h-full w-full flex-1 flex-col items-center justify-center bg-amber-50">
      <div className="mx-4 flex h-full max-w-6xl flex-col items-center gap-20 py-32 md:mx-auto md:w-full">
        <StaticImage src="../../static/404.png" />
        <div className="flex max-w-lg flex-col items-center gap-4 text-center">
          <p className="text-xl font-bold leading-6 text-slate-400 md:text-2xl md:leading-8">
            Looks kind of deserted here...
          </p>
          <p className="text-base leading-5 text-slate-600 md:text-lg md:leading-7">
            The page you’re looking for couldn’t be found. Sorry about that. Try
            one of the links in the header or footer?
          </p>
        </div>
      </div>
    </main>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
