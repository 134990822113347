import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const DataTiering = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 md:flex-row lg:gap-16">
      {/** Left partition */}
      <div className="flex h-full w-full items-center justify-center overflow-hidden md:w-auto md:basis-1/2">
        <StaticImage
          src="../../../static/microsites/data-tiering-hero.png"
          alt="data policies on levitate"
          loading="eager"
          className="h-auto w-full"
        />
      </div>
      {/** Left partition ends */}
      {/** Right partition */}
      <div className="flex flex-col items-center gap-8 text-slate-600 md:basis-1/2 md:items-start">
        <div className="flex flex-col gap-6 text-base md:text-xl">
          <h3 className="text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl lg:max-w-[50%]">
            Data Tiering
          </h3>
          <div className="flex flex-col gap-4 text-base md:text-xl">
            <p>
              Stop worrying about latent dashboards and monitoring outages
              caused due to concurrent access to data by different teams.
            </p>
            <p>
              With Levitate, incoming data is automatically tiered to optimize
              time series storage based on the specific metrics use case.
            </p>
          </div>
        </div>
        <span className="flex gap-2">
          <Link
            to="/data-tiering"
            className="w-fit rounded-full bg-brand-green-200 px-6 py-4 text-center text-base font-bold text-white"
          >
            See the Results
          </Link>
        </span>
      </div>
      {/** Right partition ends */}
    </div>
  )
}

export default DataTiering
