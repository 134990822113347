import React, { useState } from "react"
import Cookies from "js-cookie"

const Subscription = ({ subType }) => {
  // subscriber email
  const [email, setEmail] = useState()

  // subscriber form error
  const [subError, setSubError] = useState()

  const handleFormSubmit = evt => {
    evt.preventDefault()

    // Create the new request
    const xhr = new XMLHttpRequest()
    let url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HS_ACCOUNT_ID}/${process.env.GATSBY_HS_BLOG_SUB_FORM_ID}`

    if (subType === "changelog") {
      url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HS_ACCOUNT_ID}/${process.env.GATSBY_HS_CHANGELOG_SUB_FORM_ID}`
    }

    // Example request JSON:
    const data = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
      ],
      context: {
        hutk: Cookies.get("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: window && window.location.href,
        pageName: document && document.title,
      },
    }

    const final_data = JSON.stringify(data)

    xhr.open("POST", url)
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json")

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        setSubError("success")
      } else if (xhr.readyState == 4 && xhr.status >= 400) {
        setSubError("failed")
      }
    }

    // Sends the request
    xhr.send(final_data)
  }

  return (
    /* subscription */
    <div className="flex w-full flex-col items-center justify-center gap-4 rounded bg-slate-100 px-6 py-5 md:flex-row lg:gap-8">
      <span className="basis-1/4 font-medium text-slate-600">
        Stay updated on the latest from Last9.
      </span>

      <form
        className="relative flex h-14 basis-3/4 items-center justify-between rounded-full bg-white py-2 px-2"
        onSubmit={handleFormSubmit}
      >
        <input
          className="h-10 w-full border-none p-0 pl-4 text-brand-blue-100 placeholder:text-slate-400 focus:border-none focus:shadow-none focus:ring-0"
          type="email"
          placeholder="Enter your email..."
          onChange={evt => setEmail(evt.currentTarget.value)}
        />
        <button
          className="flex h-10 items-center justify-center rounded-full bg-brand-green-200 px-4 text-sm font-semibold text-white"
          type="submit"
        >
          Subscribe
        </button>

        {/* form submission messages */}
        {subError === "success" ? (
          <div className="absolute left-0 right-0 pl-3 flex h-full items-center justify-center rounded-full bg-brand-green-200 text-white">
            Thanks! We’ll email you when the next{" "}
            {subType === "changelog" ? "release" : "post"} is out.
          </div>
        ) : null}

        {subError === "failed" ? (
          <div className="absolute left-0 right-0 flex h-full items-center justify-center rounded-full bg-red-600 text-white">
            Something went wrong. Please reload the page and try again?
          </div>
        ) : null}
        {/* end form submission messages */}
      </form>
    </div>
    /* end subscription end */
  )
}

export default Subscription
