import React, { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import WhitepaperMobile from "../../../components/whitepaper-mobile"
import WhitepaperDesktop from "../../../components/whitepaper"
import Seo from "../../../components/common/seo"
const Whitepapers = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1224 })
  const isPortrait = useMediaQuery({ orientation: "portrait" })

  const [windowSize, setWindowSize] = useState()

  function resizeEvent() {
    setWindowSize([window.innerWidth, window.innerHeight])
  }

  useEffect(() => {
    if (window === "undefined") return
    resizeEvent()
    let timer
    window.onresize = function () {
      clearTimeout(timer)
      timer = setTimeout(function () {
        resizeEvent()
      }, 100)
    }
  }, [])
  // tablet and potrait Mobile
  // tablet and !potrait desktop
  return (
    <>
      <div>
        {isDesktop && !isPortrait && <WhitepaperDesktop />}
        {isMobile && !isTablet && <WhitepaperMobile />}
        {isTablet && isPortrait && <WhitepaperMobile />}
      </div>
    </>
  )
}
export const Head = () => (
  <Seo
    title="Levitate - Scaling Metrics Maturity in a Cloud-Native World"
    description="Levitate — a fully managed, OpenMetrics, PromQL, and OpenTelemetry-compatible time series warehouse for your metrics."
    image="/thumbnails/thumbnail-last9-levitate-whitepaper.jpg"
  />
)

export default Whitepapers
