/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/common/layout"
import Seo from "../components/common/seo"

import GhostPostProvider from "../contexts/GhostPostContext"
import Header from "../components/blog/Header"
import HeroImage from "../components/blog/HeroImage"
import Content from "../components/events/Content"
import PreFooter from "../components/home/PreFooter"

const EventDetails = ({ data }) => {
  return (
    <GhostPostProvider post={data.ghostPost}>
      <Layout>
        <>
          <div className="px-4 lg:px-6">
            {/* blog post */}
            <div className="mx-auto grid w-full max-w-6xl grid-cols-12 gap-y-10 pb-16 pt-14 lg:gap-y-6 lg:pb-32 lg:pt-20">
              <Header minimal />
              <div className="col-start-1 col-end-13 grid md:col-start-3 md:col-end-11">
                <HeroImage />
              </div>
              <div className="col-start-1 col-end-13 grid md:col-start-3 md:col-end-11">
                <Content />
              </div>
            </div>
          </div>
          <PreFooter />
        </>
      </Layout>
    </GhostPostProvider>
  )
}

export default EventDetails

export const query = graphql`
  query ($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      id
      title
      slug
      html
      excerpt
      reading_time
      feature_image
      published_at
      primary_author {
        id
        bio
        name
        profile_image
        slug
        twitter
      }
      primary_tag {
        id
        name
        slug
        url
        visibility
      }
      authors {
        id
        bio
        name
        profile_image
        slug
        twitter
        url
      }
      tags {
        id
        name
        slug
        url
        visibility
      }
    }
  }
`

export const Head = ({
  data: {
    ghostPost: { title, excerpt, feature_image: featureImage },
  },
}) => <Seo title={title} description={excerpt} image={featureImage} />
