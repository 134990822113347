import "../styles/cricket-scale.css"

import React from "react"
import Seo from "../components/common/seo"
import "../components/cricket-scale/styles.css"
import Layout from "../components/common/layout"
import CricketScaleHero from "../components/cricket-scale/Hero"
import Point2 from "../components/cricket-scale/2"
import Point4 from "../components/cricket-scale/4"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
import Point3 from "../components/cricket-scale/3"
import Point6 from "../components/cricket-scale/6"
import Point5 from "../components/cricket-scale/5"
import Point7 from "../components/cricket-scale/7"
import Point8 from "../components/cricket-scale/8"
import Point9 from "../components/cricket-scale/9"

gsap.registerPlugin(ScrollTrigger)

const CricketScale = () => {
  return (
    <Layout>
      <div className="flex h-fit flex-col items-center pt-20">
        <CricketScaleHero />
        {/** Context setting */}
        <Point2 />

        {/** What does tis mean? */}
        <Point3 />

        {/** Akash's Quote */}
        <Point4 />
        {/** How crazy do things get? */}
        <Point5 />

        {/** Subhash's Quote */}
        <Point6 />

        {/** How do SREs manage Cricket scale? */}
        <Point7 />

        {/** What makes Last9 special compared to others? */}
        <Point8 />

        {/** Pre footer */}
        <Point9 />
      </div>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Understanding Cricket Scale"
    description="What in the world is Cricket Scale? What challenges do SRE teams run into to manage it? And how does Last9 help them?"
    image="/thumbnails/thumbnail-last9-cricket-scale.jpg"
  />
)

export default CricketScale
