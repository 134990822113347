import React from "react"
import openStandardsHero from "../../../static/microsites/open-standards-hero.png"

const OpenCompatible = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-stretch gap-8 md:flex-row lg:gap-16">
      <div className="flex w-full flex-col overflow-hidden rounded-3xl md:w-auto md:basis-1/2">
        <img className="h-auto w-auto" src={openStandardsHero} />
      </div>

      {/** Right partition */}
      <div className="flex flex-col gap-6 text-slate-600 md:basis-1/2">
        <h3 className="w-full text-center text-4xl font-black tracking-tight text-black md:max-w-md md:text-left md:text-6xl">
          Open Compatible
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          <p>
            If you’re locked-in with a monitoring vendor, you’re likely missing
            out on Open Source innovations.
          </p>
          <p>
            Over time, legacy monitoring systems become hard to maintain due to
            a lack of Open Standards. It takes away precious engineering time,
            increases onboarding time, and adds to costs.
          </p>
          <p>
            Levitate is Open Compatible with OpenTelemetry and the latest
            innovations in the monitoring world.
          </p>
        </div>
      </div>
      {/** Right partition ends */}
    </div>
  )
}

export default OpenCompatible
