import React from "react"

export const features = [
  {
    title: "Kubernetes Monitoring",
    description:
      "Kubernetes plays a pivotal role in automating the deployment and management of microservices. Achieve comprehensive insights into the health and performance of your Kubernetes cluster and k8s environments with Levitate. Get real-time analytics and comprehensive insights, ensuring that any discrepancies or potential issues are promptly identified. ",
  },
  {
    title: "SaaS environment monitoring",
    description:
      "SaaS infrastructure monitoring provides a comprehensive performance monitoring solution, offering complete visibility into all facets of your enterprise's digital ecosystem such as multi-tenancy, geos, and performance monitoring. This includes both cloud-hosted and off-the-shelf Software as a Service (SaaS) applications.",
  },
  {
    title: "Cloud Server Monitoring",
    description:
      "Efficiently monitor server performance in the cloud with Levitate. Leverage cloud-based server monitoring to keep track of your dynamic cloud systems. Our monitoring solution enables quick analysis and optimization of resource usage, real-time issue notifications to teams, and more, extending beyond standard infrastructure monitoring for your cloud server environments.",
  },
  {
    title: "AWS Cloud Monitoring",
    description:
      "Tailor your AWS cloud environment monitoring to align with your business objectives using Levitate. Guarantee that your infrastructure and applications operate as intended, meeting the performance benchmarks required by your cloud-native applications. Gain visibility in your AWS cloud servers without incurring the additional costs associated with AWS CloudWatch monitoring.",
  },
  {
    title: "GCP Cloud Monitoring",
    description:
      "Utilize Levitate for real-time monitoring of your Google Cloud infrastructure. Detect and address issues promptly, safeguarding your business metrics. Our monitoring solution offers full visibility into your GCP resources and application workloads. Respond swiftly to critical incidents with built-in alerting, and optimize resource utilization to enhance both performance and cost efficiency for GCP monitoring.",
  },
  {
    title: "Microservice Monitoring",
    description:
      "Effectively track customer-impacting issues using Levitate's microservices monitoring solutions. Our monitoring solution seamlessly correlates infrastructure, application, and cloud environment metrics, allowing you to monitor real-time performance issues. Levitate provides highly scalable alerting and metric storage capabilities, simplifying the monitoring of complex microservices-based application environments.",
  },
  {
    title: "Infrastructure Monitoring",
    description:
      "Adopt proactive infrastructure monitoring to guarantee that your distributed, cloud-native applications, services, servers, and environments consistently meet the availability and performance targets aligned with your business objectives. Levitate allows you to gather infrastructure metrics from various cloud-native environments, including Kubernetes, distributed microservices deployments, and cloud servers. Get real-time insights, delivering proactive alerts to empower your organization to attain its business metrics and avoid downtime.",
  },
  {
    title: "Full Stack Monitoring",
    description:
      "Levitate's full-stack monitoring and observability empowers you to correlate application metrics, transaction metrics, custom metrics, and infrastructure metrics, providing valuable insights into the performance of your cloud-native applications. Our full-stack monitoring and alerting solution enable comprehensive resource monitoring within your cloud environment, covering applications, infrastructure, and cloud services. Gain visibility into the health and performance of each environment layer at a glance, fostering improved collaboration and alignment within your team.",
  },
  {
    title: "CDN Monitoring",
    description:
      "Swiftly pinpoint and address performance bottlenecks at the source with Levitate's CDN Monitoring. Real-time monitoring of CDN metrics allows you to grasp your customers' true experience. Streamline and unify your monitoring dashboard to pinpoint the exact layer in your stack causing issues with our CDN monitor solution. Levitate seamlessly integrates with content delivery networks (such as Akamai, Fastly, and Cloudflare) with our out-of-the-box support for metric-based CDN monitoring, CDN performance tracking, and CDN monitoring solutions.",
  },
  {
    title: "Container Monitoring",
    description:
      "Levitate's monitoring solution helps your team track performance of containers in cloud-native applications and get visibility into your microservice architectures - by gathering and analyzing container performance metrics at scale. The ephimercal nature of containers introduces additional complexity to your monitoring efforts. Levitate enables real-time monitoring of your containerized, cloud-native applications, ensuring optimal app performance.",
  },
  {
    title: "Proactive Monitoring",
    description:
      "Stay ahead of potential issues through Levitate's proactive monitoring and alerting capabilities. Proactively monitor your cloud infrastructure and applications, empowering you to make strategic, data-driven business decisions rather than reactive ones. With our long-term metric storage, you can unlock capacity planning and optimization without incurring overages or high monitoring costs.",
  },
  {
    title: "Application Monitoring",
    description:
      "Application monitoring is a critical component in ensuring the performance and availability of your applications. Levitate enables end-to-end monitoring of your applications, guaranteeing that your enterprise solutions consistently meet performance and reliability standards. With our hosted Grafana, you can effortlessly create and display dashboards featuring real-time metric data, allowing you to closely observe the performance and health of your applications and environments.",
  },
  // {
  //   title: "Uptime, DNS & SSL Monitoring",
  //   description:
  //     "Ensure your digital presence remains robust with our comprehensive monitoring solutions. Monitor uptime to guarantee uninterrupted service availability. Our SSL expiration monitoring keeps your security certificates up to date, preventing security lapses. Plus, our DNS monitor ensures seamless domain resolution, enhancing your online reliability. Trust us to safeguard your online performance and security.",
  // },
]

export const useCases = [
  {
    title: "Long Term Metric Retention",
    description:
      "Solve your Prometheus retention challenges with Levitate. Data retention with upto an year without any rollups are supported by default using automatic data tiering.",
  },
  {
    title: "Alerting and Notifications at no extra cost",
    description:
      "While other managed prometheus providers charge you extra for running Prometheus alertmanager, Levitate has built-in, highly scalable alerting support with seamless import capabilities from your existing Prometheus alerting YAML.",
  },
  {
    title: "Available on AWS and GCP Marketplace",
    description:
      "Ease your monitoring tools procurement with Levitate on AWS Marketplace and Google Cloud Marketplace. Reduce your observability cost, retire your EDP credits early, and eliminate your billing hassles.",
  },
  {
    title: "Open Source Data Collectors",
    description:
      "No proprietary agents for collecting data. Levitate supports open standards — OpenMetrics, OpenTelemetry, and integrates with open-source tools such as Prometheus, VictoriaMetrics, InfluxDB, Telegraf, and StatsD.",
  },
  {
    title: "High Availability",
    description:
      "With horizontally scalable, replicated, multi-AZ architecture, Levitate provides HA out of the box eliminating challanges with Prometheus High Availability and Federation.",
  },
  {
    title: "On-Premise Monitoring Support",
    description:
      "Don't leave the comfort of a Prometheus on-prem deployment with Levitate’s BYOC deployment. We support fully managed deployment in your on-premise environment and data center—no compliance and regulations challenges. Data always stays near your infrastructure.",
  },
]

export const faqs = [
  {
    question: "How does Levitate pricing work?",
    answer:
      "Unlike New Relic, Levitate does not charge per user billing. The pricing is based on your ingestion, read workloads and retention perid. Contact us to know more.",
  },
  {
    question:
      "What are the supported regions for Levitate's managed service for Prometheus?",
    answer:
      "Levitate is globally available across different regions such as North America(us-east-1), APAC India(ap-south-1), APAC Singapore(ap-south-east-1), Europe(eu-west-1). Levitate also supports AWS PrivateLink and VPC peering to elimitate egress costs. The BYOC deployment model is applicable to all regions.",
  },
  {
    question: "How do I migrate from New Relic to Levitate?",
    answer:
      "Levitate needs no complex migration. Levitate is compatible with Prometheus Remote Write API. Create a Levitate Cluster and start writing to it using Prometheus Remote Write from your existing setup within minutes. Once you are satisfied with Levitate, eliminate Prometheus storage and compute if you are using self-hosted Prometheus.",
  },
  {
    question: "How do I collect metrics data and send to Levitate?",
    answer:
      "Levitate supports open standards — OpenMetrics, OpenTelemetry, and integrates with open-source tools such as Prometheus, VictoriaMetrics, InfluxDB, Telegraf, and StatsD. Additionally, it can also collect metrics from AWS Cloudwatch Stream via OpenTelemetry based Kinesis Stream integration. It can accept metrics from OpenTelemetry Collector and various other ways using Prometheus Remote Write. No need to run proprietary agents or data collectors.",
  },
  {
    question: "Can I monitor AWS CloudWatch using Levitate?",
    answer:
      "Yes. Monitoring Cloudwatch using Levitate has one distint advantage over monitoring using Cloudwatch itself. Cloudwatch charges for accessing its read API. Levitate leverages the AWS Cloudwatch metric stream integration which streams metrics from AWS to Levitate which does not result into increased AWS Cloudwatch Bill.",
  },
]
