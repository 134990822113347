import React from "react"
import { Link } from "gatsby"
import HighCardinalityQuote from "../quotes/HighCardinalityQuote"

const HighCardinality = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col-reverse items-center gap-8 md:flex-row lg:gap-16">
      {/** left section */}
      <div className="flex flex-col items-center gap-8 text-slate-600 md:basis-1/2 md:items-start lg:basis-auto">
        <div className="flex flex-col gap-6 text-base md:text-xl">
          <h3 className="text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl lg:max-w-[75%]">
            High Cardinality
          </h3>
          <div className="flex flex-col gap-4 text-base md:text-xl">
            <p>Never compromise on high cardinality data again.</p>
            <p>
              Get deep visibility and early warnings about cardinality explosion
              before it happens. Control data growth with streaming aggregations
              and slash the operational overhead of expensive queries.
            </p>
          </div>
        </div>
        <span className="flex gap-2">
          <Link
            to="/high-cardinality"
            className="w-fit rounded-full bg-brand-green-200 px-6 py-4 text-center text-base font-bold text-white"
          >
            See it in Action
          </Link>
        </span>
      </div>
      {/** end left section */}

      {/** right section */}
      <HighCardinalityQuote />
      {/** end right section */}
    </div>
  )
}

export default HighCardinality
