import React from "react"
import openStandardsHero from "../../../static/microsites/open-standards-hero.png"

const MonitoringStack = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 text-lg md:flex-row-reverse lg:gap-16">
      <div className="flex w-full flex-col items-center gap-8 text-base text-slate-600 md:basis-1/2 md:items-start md:text-lg lg:basis-auto">
        <h3 className="w-full max-w-lg text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
          Complete Monitoring Stack
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          <p>
            Levitate is one-stop cloud monitoring & observability solution with
            superior alerting, hosted Grafana and a highly scalable Time Series
            Metrics & Events Warehouse that supports{" "}
            <a
              href="/high-cardinality"
              className="underline decoration-slate-500 underline-offset-2"
            >
              High Cardinality
            </a>
            .
          </p>
          <p>
            In built alert management, with proactive monitoring across multiple
            domains from business KPIs, product workflows, application
            performance management and server monitoring alerts.
          </p>
        </div>
      </div>

      <div className="flex w-full flex-col items-center gap-8 text-slate-600 md:basis-1/2 md:items-start lg:basis-auto">
        <img src={openStandardsHero} />
      </div>
    </div>
  )
}

export default MonitoringStack
