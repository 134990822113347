import React from "react"
import Balancer from "react-wrap-balancer"

const Why = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl -mt-14 lg:mt-0 flex-col items-center gap-8">
      <h3 className="max-w-lg text-center text-4xl font-black tracking-tight text-black md:text-6xl">
        <Balancer>Why Open Standards?</Balancer>
      </h3>
      <div className="flex max-w-4xl flex-col gap-10 text-left text-base text-slate-600 md:flex-row md:text-xl">
        <div className="space-y-4 md:basis-1/2">
          <p>Today’s monitoring tooling is divided into two extremes.</p>
          <p>
            While OSS tools are easier to start with, there is inherent toil of
            managing and scaling them for engineering teams.
          </p>
          <p>
            Proprietary platforms take away the pain of managing but may fall
            short on keeping up with open source innovation and have a
            possibility of vendor lock-in.
          </p>
        </div>
        <div className="space-y-4 md:basis-1/2">
          <p>
            We understand the importance of open standards and the value of
            being a managed offering simultaneously.
          </p>
          <p>
            That’s why Levitate is managed and supports open standards such as
            OpenTelemetry, OpenMetrics, and Prometheus Exposition for faster
            integration, quick onboarding, and keeping up with the industry
            trends.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Why
