import * as React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import Banner from "./Banner"

const Layout = ({ children }) => {
  // Determine if we want to show the banner
  const shouldShowBanner = false

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Header banner={shouldShowBanner ? <Banner /> : null} />
      <main
        className={`flex-1 scroll-pt-16 ${
          shouldShowBanner
            ? "mt-[calc(64px+56px)] md:mt-[calc(64px+40px)]"
            : "mt-16"
        }`}
      >
        {children}
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
