import React, { useMemo } from "react"
import uniqBy from "lodash/uniqBy"
import isEqual from "lodash/isEqual"
import capitalize from "lodash/capitalize"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../../../components/common/layout"
import Hero from "../../../components/compare/Hero"
import Card from "../../../components/compare/Card"

import Header from "../../../components/blog/Header"

import GhostPostProvider from "../../../contexts/GhostPostContext"
import Content from "../../../components/blog/Content"
import { ArrowLeftIcon } from "@heroicons/react/solid"
import Seo from "../../../components/common/seo"

const getAllTags = edges => {
  let tags = []
  edges.forEach(({ node }) => {
    const { tags: nodeTags } = node
    tags = [...tags, ...nodeTags]
  })
  return uniqBy(tags, "slug")
}

const getTSDBs = tags => {
  const tsdbs = tags.filter(({ slug }) => slug.startsWith("hash-tsdb-"))
  return tsdbs.map(({ slug }) => {
    const realSlug = slug.replace("hash-tsdb-", "")
    const realTag = tags.find(({ slug }) => slug === realSlug)

    if (!realTag)
      return {
        slug: realSlug,
        name: realSlug.split("-").map(capitalize).join(" "),
      }

    return realTag
  })
}

const Compare = ({ data }) => {
  const allTags = useMemo(() => getAllTags(data.allGhostPost.edges), [data])
  const tsdbs = useMemo(() => getTSDBs(allTags), [allTags])
  const [filters, setFilters] = React.useState([])
  const [selected, setSelected] = React.useState(null)

  const showAll = useMemo(() => {
    return isEqual(filters.sort(), tsdbs.map(({ slug }) => slug).sort())
  }, [filters])

  const handleFilterChange = slug => {
    const alreadyExists = filters.includes(slug)
    const newNames = [...filters]
    if (alreadyExists) setFilters(newNames.filter(f => f !== slug))
    else setFilters(newNames.concat([slug]))
  }

  const filteredPosts = useMemo(() => {
    if (filters.length === 0) return data.allGhostPost.edges

    return data.allGhostPost.edges
      .slice()
      .sort((a, b) => {
        const aPublishedAt = new Date(a.node.published_at)
        const bPublishedAt = new Date(b.node.published_at)

        if (aPublishedAt > bPublishedAt) return -1
        if (aPublishedAt < bPublishedAt) return 1
        return 0
      })
      .filter(({ node }) => {
        const { tags } = node
        const tagSlugs = tags.map(({ slug }) => slug)
        return tagSlugs.some(slug => filters.includes(slug))
      })
  }, [data, filters])

  const getPostBySlug = slug => {
    if (slug === "time-series-database-comparison") return data.generalPost1
    if (slug === "introducing-levitate") return data.generalPost2
    if (slug === "prometheus-alternatives") return data.generalPost3

    return data.allGhostPost.edges.find(({ node }) => node.slug === slug).node
  }

  return (
    <Layout>
      <section className="flex flex-col px-4 pt-16 md:px-6 md:pt-14 lg:pt-20 xl:px-0">
        <Hero />
      </section>
      <section className="flex flex-col px-4 md:px-6 md:pt-14 lg:pt-20 xl:px-0">
        <div className="mx-auto grid w-full max-w-6xl grid-flow-row grid-cols-12 gap-y-16 pb-16 pt-14 md:gap-y-10 lg:gap-y-16 lg:pb-32 lg:pt-20">
          {selected === null && typeof selected !== "string" && (
            <>
              {/* filter checkboxes */}
              <div className="col-start-1 col-end-13 flex flex-col md:sticky md:top-32 md:col-end-3">
                <div className="flex flex-row items-center py-2">
                  <label className="flex cursor-pointer select-none items-center text-sm">
                    <input
                      type="checkbox"
                      style={{ boxShadow: "none" }}
                      key={`all-${showAll}`}
                      className="checkboxBlog mr-2 cursor-pointer rounded border-gray-300 checked:bg-brand-green-100 checked:outline-none"
                      name=""
                      data-id="all"
                      checked={showAll}
                      onChange={e => {
                        e.preventDefault()
                        if (showAll) {
                          setFilters([])
                          return
                        }
                        setFilters(tsdbs.map(({ slug }) => slug))
                      }}
                    />
                    All
                  </label>
                </div>
                {tsdbs
                  .sort((a, b) => {
                    return a.name.localeCompare(b.name)
                  })
                  .map(({ slug, name }) => (
                    <div key={slug} className="flex flex-row items-center py-2">
                      <label className="flex cursor-pointer select-none items-center text-sm">
                        <input
                          key={`${slug}-${filters.includes(slug)}`}
                          type="checkbox"
                          style={{ boxShadow: "none" }}
                          className="checkboxBlog mr-2 cursor-pointer rounded border-gray-300 checked:bg-brand-green-100 checked:outline-none"
                          name=""
                          data-id={slug}
                          checked={filters.includes(slug)}
                          onChange={e => {
                            e.preventDefault()
                            handleFilterChange(slug)
                          }}
                        />
                        {name}
                      </label>
                    </div>
                  ))}
              </div>
              {/* end filter checkboxes */}

              {/* featured cards */}
              <div className="col-start-1 col-end-13 grid grid-cols-1 gap-10 border-b border-solid border-slate-200 pb-16 md:col-start-3 md:col-end-13 md:grid-cols-3 md:gap-x-4">
                <Card
                  key={data.generalPost1?.slug}
                  post={data.generalPost1}
                  className="md:max-w-xs"
                  onClick={() => setSelected(data.generalPost1?.slug)}
                />
                <Card
                  key={data.generalPost2?.slug}
                  post={data.generalPost2}
                  className="md:max-w-xs"
                  onClick={() => setSelected(data.generalPost2?.slug)}
                />
                <Card
                  key={data.generalPost3?.slug}
                  post={data.generalPost3}
                  className="md:max-w-xs"
                  onClick={() => setSelected(data.generalPost3?.slug)}
                />
              </div>
              {/* end featured cards */}

              {/* cards */}
              <div className="col-start-1 col-end-13 grid grid-cols-1 gap-10 md:col-start-3 md:col-end-13 md:grid-cols-3 md:gap-x-4">
                {filteredPosts.map(({ node }) => (
                  <Card
                    key={node.slug}
                    post={node}
                    className="md:max-w-xs"
                    onClick={() => setSelected(node.slug)}
                  />
                ))}
              </div>
              {/* end cards */}
            </>
          )}
          {typeof selected === "string" && selected.length > 0 && (
            <GhostPostProvider post={getPostBySlug(selected)}>
              <div className="col-start-1 col-end-13 flex flex-col rounded-3xl border border-solid border-slate-200 p-6">
                <div className="w-full">
                  <button
                    onClick={() => setSelected(null)}
                    className="flex items-center text-sm text-slate-600"
                  >
                    <ArrowLeftIcon className="mr-1 inline-block h-4 w-4" />
                    Go Back
                  </button>
                </div>
                <div className="col-start-1 col-end-13 flex flex-col gap-16">
                  <Header minimal />
                  <Content showToc={false} />
                </div>
              </div>
            </GhostPostProvider>
          )}
        </div>
      </section>
    </Layout>
  )
}

const postType = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      // eslint-disable-next-line camelcase
      profile_image: PropTypes.string.isRequired,
    }),
  ).isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
}).isRequired

Compare.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: postType,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    generalPost1: postType,
    generalPost2: postType,
    generalPost3: postType,
  }).isRequired,
}

export const query = graphql`
  query {
    generalPost1: ghostPost(slug: { eq: "time-series-database-comparison" }) {
      slug
      title
      feature_image
      excerpt
      html
      reading_time
      published_at
      authors {
        name
        profile_image
      }
    }
    generalPost3: ghostPost(slug: { eq: "prometheus-alternatives" }) {
      slug
      title
      feature_image
      excerpt
      html
      reading_time
      published_at
      authors {
        name
        profile_image
      }
    }
    generalPost2: ghostPost(slug: { eq: "introducing-levitate" }) {
      slug
      title
      feature_image
      excerpt
      html
      reading_time
      published_at
      authors {
        name
        profile_image
      }
    }
    allGhostPost(
      filter: { tags: { elemMatch: { slug: { eq: "tsdb-compare" } } } }
      sort: { order: DESC, fields: title }
    ) {
      edges {
        node {
          slug
          title
          feature_image
          excerpt
          html
          reading_time
          published_at
          authors {
            name
            profile_image
          }
          tags {
            name
            slug
          }
        }
      }
    }
  }
`

export const Head = () => (
  <Seo
    title="Compare Time Series Databases"
    description="Get in depth analysis and comparison of different Time Series Databases such as Prometheus, Levitate, Thanos, Cortex, InfluxDB and much more!."
    image="/thumbnails/thumbnail-last9-home.jpg"
  />
)

export default Compare
