/* eslint-disable */
import * as React from "react"
import Seo from "../components/common/seo"
import Hero from "../components/home/Hero"
import Features from "../components/home/Features"
import Clients from "../components/home/Clients"
import Warehouse from "../components/home/Warehouse"
import HighCardinality from "../components/home/HighCardinality"
import Alerting from "../components/home/Alerting"
import Tooling from "../components/home/Tooling"
import PreFooter from "../components/home/PreFooter"
import Layout from "../components/common/layout"

const IndexPage = () => {
  return (
    <Layout>
      <main className="flex flex-col [&_a]:underline [&_a]:decoration-blue-300 [&_a]:underline-offset-2 hover:[&_a]:decoration-blue-500">
        <Hero />
        <Features />
        <Clients />
        <Warehouse />
        <section className="bg-gradient-to-b from-slate-50 to-slate-50/0 px-6">
          <HighCardinality />
          <Alerting />
          <Tooling />
        </section>
        <PreFooter />
      </main>
    </Layout>
  )
}

// This gets added in <head> tag
export const Head = () => (
  <Seo title="Last9: High Cardinality Observability at Scale" />
)

export default IndexPage
