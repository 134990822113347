import React from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Balancer from "react-wrap-balancer"
import PropTypes from "prop-types"

import football from "../../../static/cricket-scale/football.svg"
import rugbyBall from "../../../static/cricket-scale/rugby-ball.svg"
import cricketBall from "../../../static/cricket-scale/cricket-ball.svg"

gsap.registerPlugin(ScrollTrigger)

const ProgressSticky = () => {
  return (
    <div className="sticky-progress sticky top-20 z-10 w-fit max-w-[281px] rounded-full bg-white px-4 py-2 text-center shadow-[0_4px_0_rgba(0_0_0_0.03)] md:max-w-[420px]">
      <div className="flex w-full items-center justify-center gap-4">
        <img src={football} width={30} height={30} alt="" />
        <div className="dotted-progress progress-1"></div>
        <img src={rugbyBall} width={30} height={30} alt="" />
        <div className="dotted-progress progress-2"></div>
        <img src={cricketBall} width={30} height={30} alt="" />
      </div>
    </div>
  )
}

const BallBar = props => {
  const { ball, ...rest } = props
  const bar = React.useRef(null)
  const ballRef = React.useRef(null)

  React.useLayoutEffect(() => {
    gsap.to(ballRef.current, {
      y: bar.current.offsetHeight,
      rotate: 360,
      scrollTrigger: {
        trigger: bar.current,
        start: "top bottom-=25%",
        end: "bottom 30%",
        scrub: 0.9,
      },
    })
  }, [])

  return (
    <div className="relative flex w-8 justify-center">
      <div
        ref={bar}
        className="gradient-bg-vertical relative z-0 h-20 w-[1px] overflow-visible md:h-60"
        {...rest}
      ></div>
      <img
        ref={ballRef}
        src={ball}
        className="absolute left-1/2 top-0 h-8 w-8 -translate-x-1/2 -translate-y-1/2"
        width={30}
        height={30}
        alt=""
      />
    </div>
  )
}

BallBar.propTypes = {
  ball: PropTypes.string.isRequired,
}

BallBar.displayName = "BallBar"

const Point2 = () => {
  const progress1Trigger = React.useRef(null)
  const progress2Trigger = React.useRef(null)

  React.useLayoutEffect(() => {
    gsap.to(".dotted-progress.progress-1", {
      "--progress": "100%",
      scrollTrigger: {
        trigger: progress1Trigger.current,
        start: "20px center",
        end: "bottom 10%",
        scrub: true,
      },
    })

    gsap.to(".dotted-progress.progress-2", {
      "--progress": "100%",
      scrollTrigger: {
        trigger: progress2Trigger.current,
        start: "top center",
        end: "bottom 10%",
        scrub: true,
      },
    })
  }, [])

  return (
    <section className="relative mx-auto flex w-full max-w-7xl flex-col items-center px-4 py-14 md:py-28">
      <ProgressSticky />
      <div className="mt-11 flex w-full max-w-4xl flex-col items-center gap-4">
        {/** Section 1 */}
        <div
          className="flex w-full flex-col items-center gap-4"
          ref={progress1Trigger}
        >
          <div className="w-full">
            <span className="text-center text-3xl font-extrabold md:text-7xl">
              <Balancer>
                <span className="text-gradient-bg">Half the world </span>
                watched the 2022 FIFA World Cup.
              </Balancer>
            </span>
          </div>
          <BallBar ball={football} />
        </div>
        {/** Section 2 */}
        <div className="w-full">
          <span className="flex flex-col items-center gap-4 text-center text-3xl font-extrabold md:text-7xl">
            <span className="text-gradient-bg block text-7xl md:text-9xl">
              113M
            </span>
            <Balancer>People watched the Superbowl.</Balancer>
          </span>
        </div>
        <BallBar ball={rugbyBall} />
        <div
          className="flex w-full flex-col items-center gap-4"
          ref={progress2Trigger}
        >
          <div className="w-full">
            {/** Section 3 */}
            <div className="flex w-full flex-col items-center gap-5">
              <span className="text-center text-3xl font-extrabold md:text-7xl">
                <Balancer>And then, there’s Cricket!</Balancer>
              </span>
              <span className="flex flex-col items-center gap-4 text-center text-xl font-bold md:hidden">
                <span className="text-gradient-bg block text-7xl font-extrabold">
                  450M
                </span>
                <Balancer>
                  People watched the last big cricketing event; the Indian
                  Premier League.
                </Balancer>
              </span>
            </div>
          </div>
          <BallBar ball={cricketBall} />
          <div className="flex w-full justify-center md:justify-between">
            <span className="hidden max-w-sm flex-col items-center gap-4 text-center text-xl font-bold md:flex md:text-2xl">
              <span className="text-gradient-bg block text-7xl font-extrabold md:text-9xl">
                450M
              </span>
              <Balancer>
                People watched the last big cricketing event; the Indian Premier
                League.
              </Balancer>
            </span>
            <span className="flex flex-col items-center gap-4 text-center text-xl font-bold md:text-2xl">
              <span className="text-gradient-bg block text-7xl font-extrabold md:text-9xl">
                55M
              </span>
              <Balancer>
                Highest concurrent viewers ever on an OTT platform.
              </Balancer>
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Point2
