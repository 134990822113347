export const quotes = {
  "matt-iselin":
    "With Levitate, we eliminated the toil of self-hosted Thanos for long-term storage. We no longer have to worry about complex dashboards and alerts failing to load in critical moments. We can actually use the metrics we generate with Levitate. It just works.",
  "akash-saxena":
    "Observability is a foundational building block and can unlock much goodness — however, it’s deviously complex to get right. The founders at Last9, aptly named, have been amazing partners in trying to make inroads on what a solid observability platform should be and hit most, if not all, of the building blocks.",
  "akash-saxena-light":
    "Observability is a foundational building block and can unlock much goodness — however, it’s deviously complex to get right. The founders at Last9, aptly named, have been amazing partners in trying to make inroads on what a solid observability platform should be and hit most, if not all, of the building blocks.",
  "ranjeet-walunj": `“Levitate stood the test of scale, accuracy, and real-time
  monitoring with our growing business. Using High Cardinality
  workflows from Levitate, we accurately measured customer SLAs across
  dimensions such as geo region and channels. We could extract
  knowledge about our systems and measure customer impact proactively
  in real time using Levitate.”`,
  "gaurav-kamboj": `“Levitate has greatly improved our operations at Hotstar, allowing us to focus on providing live sports and entertainment to our customers. It has eliminated the need for tedious metrics database management tasks and is an essential part of our observability efforts.”`,
  "alvaro-uría": `“Atlan runs a large fleet of Prometheus instances dedicated to
  each customer. Levitate has proven to be incredibly valuable in
  managing our highly parallel metric workloads. We’re now able to
  process over 50 million samples per minute significantly
  reducing our costs, thanks to its warehousing capabilities.”`,
  "subhash-choudhary": `“Our global infrastructure generates millions of events
  per minute at peak, which we ingest to Levitate for
  analysis, monitoring and alerting. This thing scales.”`,
}
