import { Link } from "gatsby"
import React from "react"
import Balancer from "react-wrap-balancer"
import book from "../../../static/reduce-observability-costs/book.svg"

const PrometheusCompatible = () => {
  return (
    <div className="items-left mx-auto flex w-full max-w-6xl flex-col gap-6">
      <div className="mx-auto flex w-full max-w-6xl flex-col items-end gap-8 lg:flex-row lg:gap-16">
        <div className="flex flex-col items-center gap-8 text-slate-600 md:basis-1/2 md:items-start lg:basis-auto">
          <h2 className="max-w-lg text-left text-4xl font-black tracking-tight text-black md:text-6xl">
            <Balancer>End to End Cloud Monitoring & Observability</Balancer>
          </h2>
          <p className="max-w-2xl text-left text-base text-slate-600 md:text-lg">
            Levitate comes with control levers that help reduce monitoring
            costs. These levers are four-fold:
          </p>
          <ul className="grid grid-flow-row grid-cols-1 gap-3 gap-x-8 gap-y-6 text-base md:grid-cols-2 ">
            <li className="text-slate-500">
              <Link
                to="/data-tiering/"
                className="font-bold text-slate-600 underline md:text-xl"
              >
                Data Tiering
              </Link>
              <br />
              Automatic data tiering avoids latent dashboards, and keeps queries
              across teams blazing fast.
            </li>
            <li className="text-slate-500">
              <Link
                to="/high-cardinality/"
                className="font-bold text-slate-600 underline md:text-xl"
              >
                High Cardinality
              </Link>
              <br />
              Solve for cardinality at the ingestion layer to reduce storage &
              costs, using Streaming Aggregations.
            </li>
            <li className="text-slate-500">
              <Link
                to="/data-tiering/"
                className="font-bold text-slate-600 underline md:text-xl"
              >
                Client Separation
              </Link>
              <br />
              Control which tier can serve alertmanager queries and Grafana
              workloads, using access tokens and policies.
            </li>
            <li className="text-slate-500">
              <Link
                to="/data-tiering/"
                className="font-bold text-slate-600 underline md:text-xl"
              >
                Concurrency Control
              </Link>
              <br />
              Limit the active number of queries the tier can handle
              simultaneously.
            </li>
          </ul>
        </div>
        <aside className="flex w-full flex-col items-center gap-8 rounded-3xl bg-emerald-300 px-8 text-slate-600 md:h-80 md:basis-1/2 md:flex-row md:items-start lg:basis-auto">
          <div className="flex h-full w-full flex-col justify-between gap-4 py-8 md:w-40">
            <p>
              Learn how these control levers help build reliable monitoring
              solution for 25+ million concurrent live-streaming viewers.
            </p>
            <Link
              to="/customer-stories/reliable-observability-for-25-million-concurrent-live-streaming-viewers/"
              className="w-fit rounded-full bg-blue-500 px-4 py-1.5 font-bold leading-7 text-white"
            >
              Read More
            </Link>
          </div>
          <div className="flex w-72 flex-col justify-end md:h-full">
            <img src={book} />
          </div>
        </aside>
      </div>
    </div>
  )
}

export default PrometheusCompatible
