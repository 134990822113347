import clsx from "clsx"
import React from "react"
/**
 *
 * @param {{
 *  title: string,
 * description: string,
 * icon: string,
 * cta: React.ReactNode,
 * align: "left" | "right" | "top" | "bottom",
 * className: string,
 * }} param0
 */
const InfoCard = ({
  title = "Governance as a first-class citizen.",
  description = "With a Control Plane for your data, its configurations, and its lifecycle — manage access, understand usage and quotas, define routing paths and more.",
  icon = "bank",
  cta = (
    <>
      Learn about{" "}
      <a href="#" title="">
        Control Plane
      </a>
      .
    </>
  ),
  align = "left",
  className,
  img,
}) => {
  return (
    <div
      className={clsx(
        "group flex gap-8 rounded-lg border border-slate-100 bg-white p-8 md:p-10",
        align === "left" && "flex-col md:flex-row md:items-center",
        align === "right" && "flex-col md:flex-row-reverse md:items-center",
        align === "top" && "flex-col",
        align === "bottom" && "flex-col-reverse",
        className,
      )}
    >
      <div className="flex basis-1/2 flex-col gap-4">
        {icon}
        <h3 className="text-balance text-xl font-bold text-slate-600 md:text-2xl">
          {title}
        </h3>
        <p className="text-base text-slate-500">{description}</p>
        <p className="font-medium text-blue-500">{cta}</p>
      </div>
      <div className="w-full basis-1/2 rounded-lg">{img}</div>
    </div>
  )
}

export default InfoCard
