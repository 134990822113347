import React from "react"
import Balancer from "react-wrap-balancer"
import PropTypes from "prop-types"

import pads from "../../../static/cricket-scale/pads.svg"
import books2 from "../../../static/cricket-scale/books2.svg"
import catchingBall from "../../../static/cricket-scale/catching-ball.svg"
import { Link } from "gatsby"
import CustomerPatternChart from "./CustomerPatternChart"
import Thunder from "./Thunder"
import Books from "./Books"
import clsx from "clsx"

const StackedCard = ({ title, subtitle, children }) => {
  return (
    <div className="relative h-40 w-72">
      <div className="z-10">
        <div className="gradient-border before:rounded-[13px] relative h-36 w-full rounded-xl bg-white p-6">
          <div className="flex flex-col gap-3">
            <h4 className="text-gradient-bg text-5xl font-extrabold">
              {title}
            </h4>
            <h5>{subtitle}</h5>
          </div>
          {children}
        </div>
      </div>
      <div className="absolute left-5 top-3.5 -z-[5] w-[calc(100%-40px)]">
        <div className="gradient-border before:rounded-[13px] h-36 w-full rounded-xl bg-white p-6"></div>
      </div>
    </div>
  )
}

StackedCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node,
}

const DangerListItem = ({ children, className }) => {
  return (
    <li
      style={{ animationDelay: `${Math.random() * 500}ms` }}
      className={clsx(
        "shake-horizontal flex w-full items-center gap-3 rounded-full border border-solid border-slate-400/75 p-1.5 md:w-fit md:pr-6",
        className
      )}
    >
      <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#E50019]">
        <svg
          width="3"
          height="14"
          viewBox="0 0 3 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.524017 9V0H2.52402V9H0.524017ZM0.524017 14V12H2.52402V14H0.524017Z"
            fill="white"
          />
        </svg>
      </div>
      <p className="text-xs font-extrabold text-black md:text-base">
        {children}
      </p>
    </li>
  )
}

DangerListItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

const Point5 = () => {
  return (
    <section className="relative z-0 flex w-full flex-col items-center bg-[#F7F3DE] px-4 py-12 md:py-24 md:pb-80">
      {/** Section 1 */}
      <h2 className="text-center text-3xl font-extrabold md:text-7xl">
        <Balancer>How crazy do things get?</Balancer>
      </h2>
      {/** Cards */}
      <div className="md:mt-[72px] mt-8 flex flex-col items-center gap-2.5 md:flex-row md:gap-6">
        <StackedCard title="600+ M" subtitle="Metrics">
          <div className="dot-10 absolute bottom-2 right-7 h-[60px] w-[120px]" />
        </StackedCard>
        <div className="gradient-bg-horizontal md:h-[1px] h-12 w-[1px] md:w-32"></div>
        <StackedCard title="200 ms" subtitle="Ingestion">
          <div className="absolute bottom-6 right-7 flex gap-[1px]">
            <Thunder />
            <Thunder />
            <Thunder />
          </div>
        </StackedCard>
      </div>
      {/** Cards end */}
      {/** Section 1 end */}

      {/** Section 2 */}
      <h3 className="mt-10 max-w-3xl text-center text-xl font-bold md:text-3xl md:font-extrabold">
        <Balancer>
          Think 600+ million metrics a minute at 200 ms of ingestion.
        </Balancer>
      </h3>
      <div className="md:mt-[72px] mt-11 flex w-full max-w-[986px] flex-col items-center gap-10 rounded-md bg-white px-4 py-12 md:gap-11 md:rounded-xl">
        <div className="flex flex-col items-center gap-5 md:flex-row md:justify-center md:gap-16">
          <div className="h-40 w-40 md:h-80 md:w-80">
            <Books />
          </div>
          <ul className="flex w-full flex-col gap-1.5 md:w-fit">
            <DangerListItem>Chaotic war rooms</DangerListItem>
            <DangerListItem className="delay-200">
              Crashing dashboards due to Concurrent access
            </DangerListItem>
            <DangerListItem className="delay-75">
              Static alerting not keeping up with scale
            </DangerListItem>
            <DangerListItem className="delay-100">
              Unstable Observability Infrastructure
            </DangerListItem>
          </ul>
        </div>
        <p className="text-center text-xl font-extrabold md:text-3xl">
          There are no playbooks on how to serve that scale.
        </p>
      </div>
      {/** Section 2 end */}
      <div className="flex flex-col items-center gap-5 py-12 md:py-24">
        <Link
          to="/schedule-demo"
          className="rounded-full bg-brand-green-200 px-12 py-6 font-semibold text-white hover:bg-emerald-500"
        >
          How does Last9 do this?
        </Link>
      </div>
      <div className="md:rounded-[31px] relative flex w-full max-w-[1488px] flex-col items-center rounded-xl bg-black px-4 py-12  md:py-24">
        <img
          src={pads}
          className="absolute -top-14 right-0 w-28 md:-top-36 md:right-11 md:w-56"
          alt=""
        />
        <h2 className="max-w-4xl text-center text-3xl font-extrabold text-white md:text-7xl">
          <Balancer>Understanding Customer Patterns</Balancer>
        </h2>
        <p className="mt-5 max-w-lg text-center text-sm font-extrabold text-white md:mt-10 md:text-xl">
          <Balancer>
            Engineers need a precise vocabulary to talk to a system, and spot
            customer patterns.
          </Balancer>
        </p>
        <CustomerPatternChart />
        <div className="mt-10 flex gap-6 md:mt-32">
          <img src={books2} className="hidden h-36 md:block" alt="" />
          <p className="mt-5 max-w-lg text-center text-sm font-extrabold text-white md:mt-10 md:text-left md:text-xl">
            <Balancer>
              This vocabulary helps detect{" "}
              <span className="text-brand-green-100">when</span> something is
              not right, and <span className="text-brand-green-100">where</span>{" "}
              things are not right.
            </Balancer>
          </p>
        </div>
      </div>
      <img
        src={catchingBall}
        className="absolute -bottom-2 -left-2 hidden md:block"
        alt=""
      />
    </section>
  )
}

export default Point5
