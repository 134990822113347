import React from "react"
import { Link } from "gatsby"
import dateFormat from "dateformat"
import { PropTypes } from "prop-types"

const Post = ({ post, news = false }) => {
  const slugPage = news ? "news" : "blog"
  return (
    <Link
      to={`/${slugPage}/${post.slug}/`}
      className="col-span-4 flex snap-proximity snap-start flex-col gap-2 max-md:min-w-[75%]"
    >
      <img
        src={post.feature_image}
        alt={post.title}
        className="h-48 w-full rounded-2xl border border-slate-100 object-cover"
      />
      <span className="font-bold lg:text-2xl lg:font-extrabold">
        {post.title}
      </span>
      <p className="line-clamp-3">{post.excerpt}</p>
      <div className="flex flex-col gap-1 opacity-50">
        <p>
          {post?.authors.map((author, index) => {
            return (
              <span
                key={author.name}
                className="after:content-[',_'] last-of-type:after:content-none"
              >
                {author.name}
              </span>
            )
          })}
        </p>
        <p className="text-sm">
          {dateFormat(post.published_at, "mmm dS, ‘yy")} | {post.reading_time}{" "}
          min read
        </p>
      </div>
    </Link>
  )
}

Post.propTypes = {
  post: PropTypes.object,
  news: PropTypes.string,
}

Post.defaultProps = {
  post: {},
  news: ``,
}

export default Post
