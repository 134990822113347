import React from "react"
import Balancer from "react-wrap-balancer"
import PropTypes from "prop-types"

const Section = ({ children, heading, icon }) => {
  return (
    <section className="flex flex-col gap-8">
      <div className="flex flex-col items-center gap-5">
        {icon}
        <h2 className="mx-auto w-full max-w-xl text-center text-3xl font-bold leading-none md:text-4xl">
          <Balancer>{heading}</Balancer>
        </h2>
      </div>
      <div className="flex flex-col gap-5">{children}</div>
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.element,
  icon: PropTypes.element,
  heading: PropTypes.string,
}

export default Section
