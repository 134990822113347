import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const OpenStandards = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 md:flex-row lg:gap-16">
      {/** Left partition */}
<div className="flex flex-col items-center gap-8 text-slate-600 md:basis-1/2 md:items-start">
        <div className="flex flex-col gap-6 text-base md:text-xl">
          <span className="text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl lg:max-w-[75%]">
            Open Standards
          </span>
          <div className="flex flex-col gap-4 text-base md:text-xl">
            <p>Like you, we hate being vendor-locked into closed platforms.</p>
            <p>
              That’s why Levitate supports open standards — OpenMetrics,
              OpenTelemetry, and integrates with open-source tools such as
              InfluxDB, Telegraf, and StatsD.
            </p>
          </div>
        </div>
        <span className="flex items-center gap-2 md:items-start">
          <Link
            to="/opentelemetry"
            className="w-fit rounded-full bg-brand-green-200 px-6 py-4 text-center text-base font-bold text-white"
          >
            Get Started in 2 Easy Steps
          </Link>
        </span>
      </div>
      {/** Left partition ends */}

        {/** Right partition */}
        <div className="flex h-full w-full items-center justify-center overflow-hidden md:w-auto md:basis-1/2">
            <StaticImage
                src="../../../static/microsites/open-standards-hero.png"
                alt="data policies on levitate"
                loading="eager"
                className="h-auto w-full"
            />
        </div>
      {/** Right partition ends */}
    </div>
  )
}

export default OpenStandards
