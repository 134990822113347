import React from "react"
import Button from "../common/Button"
import clsx from "clsx"
import ClientLogo from "../common/ClientLogo"
import { Link } from "gatsby"
import nineIllustration from "../../../static/home/nine-illustration.webp"

const CaseStudy = ({ className, title, client, href }) => {
  return (
    <Link
      to={href}
      className={clsx(
        "flex aspect-[216/280] h-[280px] transform-gpu flex-col items-start justify-between rounded-xl border border-solid p-4 !no-underline md:rounded-l-sm",
        className,
      )}
    >
      <ClientLogo size={32} name={client} />
      <span className="max-w-40 font-bold no-underline">{title}</span>
    </Link>
  )
}

const PreFooter = ({
  type = "default",
  title = "Do more with less.",
  description = "Unlock high cardinality monitoring for your teams.",
}) => {
  const renderButtons = () => {
    if (type === "customers") {
      return (
        <div className="flex flex-col gap-4 xl:flex-row">
          <Button
            as="a"
            href="/schedule-demo/"
            className="w-fit bg-emerald-500 text-white"
          >
            Talk to us
          </Button>
          <Button
            as="a"
            href="https://app.last9.io"
            className="w-fit border-2 border-slate-400 bg-transparent !leading-[52px] text-slate-400"
          >
            Start for free
          </Button>
        </div>
      )
    }

    return (
      <div className="flex flex-col gap-4 xl:flex-row">
        <Button
          as="a"
          href="https://app.last9.io"
          className="w-fit bg-emerald-500 text-white"
        >
          Start for free
        </Button>
        <Button
          as="link"
          to="/customer-stories/"
          className="w-fit border-2 border-slate-400 bg-transparent !leading-[52px] text-slate-400"
        >
          How teams use Last9
        </Button>
      </div>
    )
  }

  return (
    <section className="w-full px-6 pt-10 sm:pt-20">
      <div className="group mx-auto flex w-full max-w-6xl flex-col items-center gap-10 rounded-3xl bg-slate-700 p-10 sm:gap-16 sm:p-20 lg:flex-row">
        <div className="flex w-full flex-col gap-4">
          <h2 className="max-w-60 text-4xl font-extrabold leading-[48px] text-white md:text-5xl">
            {title}
          </h2>
          <p className="max-w-72 text-xl text-slate-400 md:text-2xl">
            {description}
          </p>
          {renderButtons()}
        </div>
        <div className="relative flex flex-col gap-4 perspective-[1000px] md:flex-row">
          <img
            src={nineIllustration}
            className="absolute right-0 top-0 z-20 w-24 -translate-y-1/4"
          />
          <CaseStudy
            title="Probo Cuts Monitoring Costs by 90% with Last9"
            client="probo"
            className="border-emerald-600 bg-emerald-700 text-emerald-200 transition-transform duration-300 group-hover:translate-x-0 group-hover:translate-y-0 group-hover:rotate-0 md:-translate-y-5 md:translate-x-8 md:-rotate-2 [&>svg_path]:fill-emerald-300"
            href="/customer-stories/probo-cuts-monitoring-costs-by-90-per-cent-with-last9/"
          />
          <CaseStudy
            title="Quickwork champions platform transparency for its customers with Last9"
            client="quickwork"
            className="border-purple-600 bg-purple-700 text-purple-200 transition-transform duration-300 group-hover:translate-x-0 group-hover:translate-y-0 group-hover:rotate-0 md:translate-y-0.5 md:rotate-2 [&>svg_path]:fill-purple-300"
            href="/customer-stories/quickwork-champions-platform-transparency-for-its-customers-with-last9/"
          />
        </div>
      </div>
    </section>
  )
}

export default PreFooter
