import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function Seo({ description, title, children, image }) {
  // Use the location hook to get current page URL
  const location = useLocation()

  const { site } = useStaticQuery(
    // eslint-disable-next-line no-use-before-define
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const thumbnail = image || site.siteMetadata.image
  const url = `${site?.siteMetadata?.siteUrl}${location.pathname}`
  const isExternalResource = new RegExp("/(blog|events|changelog)/(.+)")
  const type = isExternalResource.test(location.pathname)
    ? "article"
    : "website"
  const img = isExternalResource.test(location.pathname)
    ? image || site.siteMetadata.image
    : `https://last9.io${thumbnail}`

  const pageTitle = defaultTitle ? `${title} | ${defaultTitle}` : title

  return (
    <>
      <title>{pageTitle} </title>
      <meta name="description" content={metaDescription} />
      <meta name="image" content={img} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={img} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta property="twitter:site" content="@last9io" />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={img} />

      {children}
    </>
  )
}

Seo.defaultProps = { description: `` }

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default Seo
