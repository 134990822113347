import React from "react"
import headerVisualizationImg from "../../../static/icons/header-visualization.png"
import IconSpark from "../../../static/logs/icon-spark.inline.svg"
import IconQuery from "../../../static/logs/icon-query.inline.svg"
import IconMonitor from "../../../static/logs/icon-monitor.inline.svg"
import Quote from "../common/Quote"
import imageSrc from "../../../static/home/hotstar-quote-image.webp"

import imageLogsQuickMTTR from "../../../static/logs/logs-quick-mttr.png"
import imageLogsQueryEverything from "../../../static/logs/logs-query-everything.png"
import imageLogsRealtimeMonitoring from "../../../static/logs/logs-realtime-monitoring.png"

const LogExplorer = ({
  quote = {
    client: "tacitbasecolor",
    quote:
      "Last9 has been crucial for us. We’ve been able to find interesting bugs, that was not possible for us with New Relic.",
    author: "Shekhar Patil, Founder & CEO, Tacitbase",
    accent: "#F26046",
    img: <img src={imageSrc} className="h-auto w-full max-w-80" />,
  },
}) => {
  return (
    <section className="scroll-pt-40 bg-gradient-to-b from-yellow-50 to-transparent px-6 py-20">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-16">
        {/* section header */}
        <div className="flex flex-col-reverse items-start justify-between gap-5 md:flex-row md:items-center">
          <h2 className="max-w-2xl text-balance text-4xl font-extrabold text-slate-900 md:text-5xl">
            Accelerated insights with real-time visualizations and alerting.
          </h2>
          <img alt="" className="h-[120px]" src={headerVisualizationImg} />
        </div>
        {/* end section header */}

        {/* card 1 */}
        <div
          id="logs-explorer-quick-mttr"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row-reverse md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageLogsQuickMTTR}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconSpark className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Troubleshoot issues faster for quick resolutions
            </h3>

            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                Connect dots while analyzing logs to view related traces and
                metrics without changing context or tools
              </li>
              <li>Analyze log data with transaction-level insights</li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 1 */}

        {/* card 2 */}
        <div
          id="logs-explorer-query-everything"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageLogsQueryEverything}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconQuery className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Query everything, with ease
            </h3>

            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                No sampling, all generated logs available at your fingertips
              </li>
              <li>
                Search and filter using natural language or LogQL — keyword
                search, filters for severity, services, resources, attributes
              </li>
              <li>Create indexes for even faster scoped logs views</li>
              <li>
                Look back in to historical logs with on-demand rehydration
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 2 */}

        {/* card 3 */}
        <div
          id="logs-explorer-real-time-monitoring"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row-reverse md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageLogsRealtimeMonitoring}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconMonitor className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Real-time monitoring, with dashboards and alerting
            </h3>
            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                Logs to Metrics pipeline to power logs monitoring in real-time,
                with customizable dashboards and alerts
              </li>
              <li>
                Use anomaly algorithms to detect patterns like spikes and trends
              </li>
              <li>Get notified for continuous log pattern matching</li>
              <li>
                Live tail view for instant debugging of in-time system changes
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 3 */}

        <Quote {...quote} />
      </div>
    </section>
  )
}

export default LogExplorer
