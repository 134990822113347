import React from "react"
import Balancer from "react-wrap-balancer"
import CostQuote from "../quotes/CostQuote"

const BYOC = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8">
      <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 text-base md:flex-row-reverse md:text-xl lg:gap-16">
        <div className="flex flex-col items-center gap-8 text-slate-600 md:basis-1/2 md:items-start lg:basis-auto">
          <h3 className="max-w-lg text-center text-4xl font-black tracking-tight text-black md:text-6xl">
            <Balancer>Bring Your Own Cloud</Balancer>
          </h3>
          <p>
            With the Bring Your Own Cloud (BYOC) deployment model, Levitate is
            deployed in your Cloud, or on-premise.
          </p>

          <div className="flex flex-col gap-4">
            <p>Save costs in three categories:</p>
            <ol className="flex flex-col gap-2 marker:text-emerald-500 marker:content-['—'] [&>li]:ml-5 [&>li]:pl-2">
              <li>
                Customers can retire their Enterprise Discount Program (EDP)
                commitment when applicable, massively reducing infra costs.
              </li>
              <li>Zero Egress costs and no worries about overages.</li>
              <li>
                Purchase Levitate from AWS or Google Cloud Marketplaces to ease
                procurement, avail cloud credits and discounts.
              </li>
            </ol>
            <p>
              Additionally, data never leaves your infrastructure, solving
              compliance & regulatory concerns.
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center gap-8 text-slate-600 md:basis-1/2 md:items-start lg:basis-auto">
          <CostQuote />
        </div>
      </div>
    </div>
  )
}

export default BYOC
