import { Link } from "gatsby"
import React from "react"
import { AkashQuoteDark } from "../quotes/AkashQuote"

const MOI = () => {
  return (
    <div className="mx-auto flex flex-col items-center gap-10 sm:gap-20">
      <div className="mx-auto max-w-3xl text-center text-4xl font-black text-black md:text-center md:text-6xl">
        Cloud Native Monitoring
      </div>
      {/** Partitions starts */}
      <div className="mx-auto flex w-full max-w-6xl flex-col-reverse items-start gap-8 md:flex-row lg:gap-16">
        {/** Left Partition */}
        <div className="flex basis-1/2 flex-col gap-4 text-base text-slate-600 md:gap-6 md:text-xl">
          <h4 className="text-2xl font-black text-black md:text-[40px]">
            50% Less TCO
          </h4>
          <p>
            Levitate is available in two models: SaaS and Bring Your Own
            Cloud(BYOC) deployment. With the BYOC Deployment option, eliminate
            engineering toil and hidden egress data transfer costs.
          </p>
          <p>
            With the{" "}
            <Link
              to="/data-tiering"
              className="underline decoration-slate-500 underline-offset-2"
            >
              tiered storage
            </Link>
            , Prometheus agents can become just lightweight transmitters. Say
            goodbye to storage and data retention concerns.
          </p>
          <p>
            This not only improves the efficiency and reliability of your
            monitoring tooling but also{" "}
            <strong>
              reduces the{" "}
              <Link
                to="/reduce-monitoring-costs"
                className="underline decoration-slate-500 underline-offset-2"
              >
                Total Cost of Ownership (TCO)
              </Link>{" "}
              by 50%
            </strong>
            .
          </p>
        </div>
        {/** Left Partition ends */}

        {/** Right Partition */}
        <div className="flex basis-1/2 flex-col gap-4 text-base text-slate-600 md:gap-6 md:text-xl">
          <h4 className="text-2xl font-black text-black md:text-[40px]">
            Global Availability
          </h4>
          <p>
            Available across geographies so the data never leaves your
            infrastructure proximity.
          </p>
          <p>
            Our commitment to providing a managed offering based on Open
            Standards means that we go above and beyond to ensure your
            satisfaction. That's why we back up our offering with Service Level
            Agreement (SLA) guarantees and Clawbacks for both Read and Write
            workloads.
          </p>
          <p>
            Levitate commits to a <strong>99.9% write-availability</strong> and
            a <strong>99.5% read-availability</strong>.
          </p>
        </div>
        {/** Right Partition ends */}
      </div>
      {/** Partitions ends */}
      <Link
        to="/schedule-demo"
        className="mx-auto w-fit rounded-full bg-brand-green-200 px-6 py-4 text-center text-base font-bold text-white"
      >
        Get a Walkthrough
      </Link>

      <AkashQuoteDark />
    </div>
  )
}

export default MOI
