import React from "react"
import PropTypes from "prop-types"

const GhostPostContext = React.createContext({})

const GhostPostProvider = ({ children, post }) => {
  return (
    <GhostPostContext.Provider value={{ ...post }}>
      {children}
    </GhostPostContext.Provider>
  )
}

GhostPostProvider.propTypes = {
  children: PropTypes.node.isRequired,
  post: PropTypes.object.isRequired,
}

export const useGhostPost = () => {
  const context = React.useContext(GhostPostContext)

  if (typeof context === "undefined")
    throw new Error("useGhostPost must be used within a GhostPostProvider")

  return context
}

export default GhostPostProvider
