import React from "react"
import { PropTypes } from "prop-types"
import Post from "./post"

const Posts = ({ posts, heading }) => {
  return (
    <>
      {/* Loading  */}
      {posts === null && (
        <span className="my-10 px-4 text-center text-xl md:px-6 lg:text-3xl xl:px-0">
          Loading...
        </span>
      )}

      {posts !== null && posts.length > 0 && (
        <div className="flex flex-col px-4 md:px-6 xl:px-0">
          {posts.map(post => (
            <React.Fragment key={post.id}>
              <Post post={post} />
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  )
}

Posts.propTypes = {
  posts: PropTypes.array,
  heading: PropTypes.string,
}

Posts.defaultProps = {
  posts: [],
  heading: ``,
}

export default Posts
