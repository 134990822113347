import React from "react"
import { Link } from "gatsby"
import Balancer from "react-wrap-balancer"
import ReplitQuote from "../quotes/ReplitQuote"

const MonitoringScale = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 md:flex-row lg:gap-16">
      <div className="flex flex-col gap-6 text-base text-slate-600 md:basis-1/2 md:text-xl">
        <h3 className="text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
          <Balancer>Built for Cloud Native</Balancer>
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          <p>
            Cloud Native Kubernetes workloads are ubiquitous today but come with
            their own data growth challenges which affect their observability.
          </p>
          <p>
            Levitate’s in-built support for{" "}
            <Link
              to="/high-cardinality"
              className="underline decoration-slate-500 underline-offset-2"
            >
              High Cardinality
            </Link>{" "}
            makes it a unique choice for monitoring cloud native applications at
            scale.
          </p>
        </div>
      </div>

      <ReplitQuote />
    </div>
  )
}

export default MonitoringScale
