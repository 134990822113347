import React from "react"
import Balancer from "react-wrap-balancer"

/**
 * @note Couldn't find the stack icon in the heroicons library that's why I'm
 * using the svg from the official website.
 */
const icons = {
  stack: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path d="M11.644 1.59a.75.75 0 01.712 0l9.75 5.25a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.712 0l-9.75-5.25a.75.75 0 010-1.32l9.75-5.25z" />
      <path d="M3.265 10.602l7.668 4.129a2.25 2.25 0 002.134 0l7.668-4.13 1.37.739a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.71 0l-9.75-5.25a.75.75 0 010-1.32l1.37-.738z" />
      <path d="M10.933 19.231l-7.668-4.13-1.37.739a.75.75 0 000 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 000-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 01-2.134-.001z" />
    </svg>
  ),
  "archive-box": (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375z" />
      <path
        fillRule="evenodd"
        d="M3.087 9l.54 9.176A3 3 0 006.62 21h10.757a3 3 0 002.995-2.824L20.913 9H3.087zM12 10.5a.75.75 0 01.75.75v4.94l1.72-1.72a.75.75 0 111.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 111.06-1.06l1.72 1.72v-4.94a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      />
    </svg>
  ),
  adjustments: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
    </svg>
  ),
}

const PolicyCard = ({ title, description, icon }) => {
  return (
    <div className="min-h-56 flex flex-1 flex-col gap-4 rounded-2xl border border-blue-50 bg-white p-4">
      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-green-100 p-3 text-brand-green-200">
        {icons[icon]}
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-base font-bold leading-6 text-brand-blue-100">
          {title}
        </p>
        <p className="text-base leading-6 text-slate-500">{description}</p>
      </div>
    </div>
  )
}

const Policies = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8">
      {/* lede */}
      <div className="flex max-w-xl flex-col items-center gap-6 text-center text-base text-slate-600 md:text-xl">
        <h3 className="text-4xl font-black tracking-tight text-black md:text-6xl">
          <Balancer>Take Charge with Policies</Balancer>
        </h3>
        <p>
          Control the settings for concurrency, retention, and query depth via
          policies for data retention and access control.
        </p>
      </div>
      {/* end lede */}

      {/* cards */}
      <div className="flex flex-col items-stretch gap-4 md:flex-row">
        <PolicyCard
          title="Concurrency Control"
          description="Limit the active number of queries a tier can handle simultaneously. This is the most trustworthy indicator of performance."
          icon="stack"
        />
        <PolicyCard
          title="Retention Limit"
          description="Limit the data available in a tier - i.e. months, days, or even hours."
          icon="archive-box"
        />
        <PolicyCard
          title="Range Control"
          description="Limit the number of days of data allowed to be looked up for a single query. This directly impacts the number of data points or series loaded into the memory."
          icon="adjustments"
        />
        {/* end cards */}
      </div>
    </div>
  )
}

export default Policies
