import React from "react"
import { graphql, Link } from "gatsby"
import dateFormat from "dateformat"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Content from "../components/changelog/Content"
import GhostPostProvider from "../contexts/GhostPostContext"

const ChangelogDetails = ({ data }) => {
  const post = data.ghostPost

  // Define tag order and sort tags
  const tagOrder = ["changelog-new", "changelog-improvement", "changelog-fix"]
  const relevantTags = post.tags
    ?.filter(tag =>
      ["changelog-new", "changelog-fix", "changelog-improvement"].includes(
        tag.name.toLowerCase(),
      ),
    )
    .sort((a, b) => {
      const indexA = tagOrder.indexOf(a.name.toLowerCase())
      const indexB = tagOrder.indexOf(b.name.toLowerCase())
      return indexA - indexB
    })

  const getDisplayName = tagName => {
    switch (tagName.toLowerCase()) {
      case "changelog-new":
        return "Feature"
      case "changelog-fix":
        return "Fixes"
      case "changelog-improvement":
        return "Improvements"
      default:
        return tagName
    }
  }

  const getTagStyle = tagName => {
    switch (tagName.toLowerCase()) {
      case "changelog-new":
        return "bg-emerald-50 text-emerald-700 ring-1 ring-emerald-600/10"
      case "changelog-fix":
        return "bg-rose-50 text-rose-700 ring-1 ring-rose-600/10"
      case "changelog-improvement":
        return "bg-sky-50 text-sky-700 ring-1 ring-sky-600/10"
      default:
        return "bg-gray-50 text-gray-700 ring-1 ring-gray-600/10"
    }
  }

  return (
    <GhostPostProvider post={post}>
      <Layout>
        <section className="gradient-blog z-50 pb-10 md:pb-20">
          <div className="mx-auto max-w-6xl">
            {/* Header */}
            <div className="px-4 md:px-6 xl:px-0">
              <div className="flex items-center justify-between">
                <div className="my-7 flex flex-col gap-6 lg:my-10">
                  <h2 className="text-3xl font-extrabold md:text-5xl">
                    Changelog
                  </h2>
                  <span className="flex gap-4 text-sm md:text-base">
                    <Link
                      to="/changelog"
                      className="text-blue-500 underline-offset-2 hover:underline"
                    >
                      ← Back to changelog
                    </Link>
                    <span className="text-slate-400">•</span>
                    <a
                      href="https://x.com/last9io"
                      className="text-blue-500 underline-offset-2 hover:underline"
                    >
                      Follow us on X
                    </a>
                  </span>
                </div>
              </div>
              <hr className="mb-8 text-white text-opacity-25" />
            </div>

            {/* Post Content */}
            <div className="grid grid-cols-12 gap-6 px-4 md:px-6 xl:px-0">
              {/* left col with date */}
              <div className="col-span-2 hidden text-right sm:block">
                <span className="py-0.5 text-sm text-slate-500">
                  {dateFormat(post.published_at, "mmm dS, 'yy")}
                </span>
              </div>

              {/* Timeline dot and line */}
              <div className="relative col-span-1 hidden justify-center sm:flex">
                <div className="absolute mt-[8.5px] h-full w-px bg-slate-200" />
                <div className="relative z-10 mt-[8.5px] size-[7px] rounded-full bg-slate-400" />
              </div>

              {/* right col with content */}
              <div className="col-span-12 flex flex-col gap-3 sm:col-span-9">
                {/* Tags */}
                {relevantTags && relevantTags.length > 0 && (
                  <div className="flex gap-2">
                    {relevantTags.map(tag => (
                      <span
                        key={tag.id}
                        className={`rounded-full px-2 py-1 text-xs font-medium ${getTagStyle(tag.name)}`}
                      >
                        {getDisplayName(tag.name)}
                      </span>
                    ))}
                  </div>
                )}

                {/* Title */}
                <h1 className="text-2xl font-medium text-slate-800">
                  {post.title}
                </h1>

                {post.feature_image && (
                  <img
                    src={post.feature_image}
                    alt={post.title}
                    className="my-3 w-full rounded-2xl border border-slate-100"
                  />
                )}

                {/* Content - using the existing Content component */}
                <Content />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </GhostPostProvider>
  )
}

export default ChangelogDetails

// Keep your existing GraphQL query and Head export
export const query = graphql`
  query ($slug: String!, $primaryTagSlug: String!) {
    ghostPost(slug: { eq: $slug }) {
      id
      title
      slug
      html
      excerpt
      reading_time
      feature_image
      published_at
      primary_author {
        id
        bio
        name
        profile_image
        slug
        twitter
      }
      primary_tag {
        id
        name
        slug
        url
        visibility
      }
      authors {
        id
        bio
        name
        profile_image
        slug
        twitter
        url
      }
      tags {
        id
        name
        slug
        url
        visibility
      }
    }
    relatedPosts: allGhostPost(
      filter: {
        primary_tag: { slug: { eq: $primaryTagSlug } }
        slug: { ne: $slug }
      }
      limit: 3
    ) {
      edges {
        node {
          authors {
            name
            profile_image
          }
          slug
          title
          excerpt
          feature_image
        }
      }
    }
  }
`

export const Head = ({
  data: {
    ghostPost: { title, excerpt, feature_image: featureImage },
  },
}) => <Seo title={title} description={excerpt} image={featureImage} />
