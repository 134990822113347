/* eslint-disable react/prop-types */
import clsx from "clsx"
import React from "react"
import Balancer from "react-wrap-balancer"

const NoCreditCard = ({ keepLeft }) => {
  return (
    <span
      className={clsx(
        "mb-8 flex w-72 items-center whitespace-pre-wrap pl-1 text-sm font-medium text-slate-400 md:mb-0 md:text-left",
        keepLeft ? "text-left" : "text-center"
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        className="mr-1 inline h-4 w-4 fill-slate-400"
      >
        <path d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z" />
      </svg>
      <Balancer>No credit card required</Balancer>
    </span>
  )
}

export default NoCreditCard
