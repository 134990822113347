import React from "react"
import Seo from "../components/common/seo"
import Hero from "../components/alerting/Hero"
import LogoScroll from "../components/common/logoscroll"
import CoreTenets from "../components/alerting/CoreTenets"
import AlertingDetails from "../components/alerting/AlertingDetails"
import OtherCapabilities from "../components/common/OtherCapabilities"
import PreFooter from "../components/home/PreFooter"
import Layout from "../components/common/layout"

const LogsPage = () => {
  return (
    <Layout>
      <main className="flex flex-col [&_a]:underline [&_a]:decoration-blue-300 [&_a]:underline-offset-2 hover:[&_a]:decoration-blue-500">
        <Hero />
        <LogoScroll />
        <CoreTenets />
        <AlertingDetails />
        <OtherCapabilities page="Alerting" />
        <PreFooter />
      </main>
    </Layout>
  )
}

// This gets added in <head> tag
export const Head = () => (
  <Seo
    title="Alerting for High Cardinality Environments"
    description="An end-to-end alerting tool built to tackle high cardinality use cases. Designed to reduce alert fatigue and improve Mean Time to Detect."
    image="/thumbnails/thumbnail-last9-logs.jpg"
  />
)

export default LogsPage
