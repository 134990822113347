import React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import Hero from "../components/reduce-observability-costs/Hero"
import ManageCost from "../components/reduce-observability-costs/ManageCost"
import ControlLevers from "../components/reduce-observability-costs/ControlLevers"
import BYOC from "../components/reduce-observability-costs/BYOC"
import Clawbacks from "../components/reduce-observability-costs/Clawbacks"
import OpenCompatible from "../components/reduce-observability-costs/OpenCompatible"
import PreFooter from "../components/home/PreFooter"
import ClientLogosQueue from "../components/common/ClientLogosQueue"

const ReduceObservabilityCosts = () => {
  return (
    <Layout>
      <section className="flex flex-col px-4 md:px-6 md:pt-14 lg:pt-20 xl:px-0">
        <Hero />
      </section>

      <section className="flex flex-col items-center gap-28 bg-gradient-to-b from-yellow-50 to-white px-4 pb-28 md:gap-40 md:px-6 md:pb-40 lg:mx-0 xl:px-0">
        <div className="w-full lg:h-0">
          <ClientLogosQueue />
        </div>

        <ManageCost />
        <ControlLevers />
        <BYOC />
        <Clawbacks />
        <OpenCompatible />
      </section>

      <PreFooter />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Reduce Monitoring Costs"
    description="How Levitate helps reduce Monitoring costs by reining Cardinality and eliminating the toil of managing a TSDB while helping scale business growth"
    image="/thumbnails/thumbnail-last9-levitate.jpg"
  />
)

export default ReduceObservabilityCosts
