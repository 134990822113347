import React from "react"
import { Link } from "gatsby"
import IconControlPlane from "../../../static/icons/capability-control-plane.inline.svg"
import IconMetrics from "../../../static/icons/capability-metrics.inline.svg"
import IconLogs from "../../../static/icons/capability-logs.inline.svg"
import IconTraces from "../../../static/icons/capability-traces.inline.svg"
import IconAlerting from "../../../static/icons/capability-alerting.inline.svg"

import CPHero from "../../../static/control-plane/learn-more-cp.png"
import LogsHero from "../../../static/logs/learn-more-logs.png"
import TracesHero from "../../../static/traces/learn-more-traces.png"
import MetricsHero from "../../../static/metrics/learn-more-metrics.png"
import AlertingHero from "../../../static/alerting/learn-more-alerting.png"

const OtherCapabilities = ({ page }) => {
  const capabilities = [
    {
      name: "Control Plane",
      icon: IconControlPlane,
      image: CPHero,
      link: "/control-plane",
      sub: "A first-class experience for a single pane of glass with end-to-end telemetry management to control your observability data and its costs.",
    },
    {
      name: "Logs",
      icon: IconLogs,
      image: LogsHero,
      link: "/logs",
      sub: "Stream, store, and analyze millions of logs per minute without worrying about things breaking, including your bank account.",
    },
    {
      name: "Traces",
      icon: IconTraces,
      image: TracesHero,
      link: "/traces",
      sub: "Gain real-time insights into performance issues and root causes by correlating traces with logs, metrics, and events in a single pane.",
    },
    {
      name: "Metrics",
      icon: IconMetrics,
      image: MetricsHero,
      link: "/metrics",
      sub: "Stream, store, and analyze millions of logs per minute without worrying about things breaking, including your bank account.",
    },
    {
      name: "Alerting",
      icon: IconAlerting,
      image: AlertingHero,
      link: "/alerting",
      sub: "An end-to-end alerting tool built to tackle high cardinality use cases. Designed to reduce alert fatigue and improve Mean Time to Detect.",
    },
  ]

  const visibleCapabilities = capabilities.filter(cap => cap.name !== page)

  return (
    <section className="w-full px-6">
      <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-16">
        {/* header */}
        <div className="flex w-full items-center gap-2 md:gap-6">
          <hr className="flex-1 border-slate-200" />
          <span className="w-1/2 text-balance text-center text-base font-bold text-slate-500 md:w-fit">
            Learn more about Last9's platform
          </span>
          <hr className="flex-1 border-slate-200" />
        </div>
        {/* end header */}

        {/* cards */}
        {/* add grid col when new capability is added */}
        {/* change aspect ratio to 4/3 when there are 4 capabilities */}
        <div className="grid w-full grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4 lg:gap-6">
          {visibleCapabilities.map((capability, index) => (
            <Link
              key={index}
              to={capability.link}
              className="flex flex-row gap-3 overflow-hidden rounded-lg border border-solid border-slate-200 bg-white p-3 !no-underline hover:border-blue-500 lg:flex-col lg:pb-0"
            >
              <capability.icon className="h-6 w-6 text-blue-500" />
              <span className="text-base font-bold text-slate-600">
                {capability.name} →
              </span>
              <span className="hidden flex-1 text-sm text-slate-500 lg:block">
                {capability.sub}
              </span>

              <img
                src={capability.image}
                className="hidden aspect-[320/240] w-64 min-w-64 rounded-tl-lg border-l border-t border-solid border-slate-200 lg:block"
              />
            </Link>
          ))}
        </div>
        {/* end cards */}
      </div>
    </section>
  )
}

export default OtherCapabilities
