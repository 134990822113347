import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ClientLogo from "../common/ClientLogo"

const LevitateIsDifferent = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 text-lg md:flex-row lg:gap-16">
      <div className="flex w-full flex-col items-center gap-8 text-base text-slate-600 md:basis-1/2 md:items-start md:text-lg lg:basis-auto">
        <h3 className="w-full max-w-lg text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
          Your New Observability <del>Vendor</del> Partner
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          <p>Observability for all sizes.</p>
          <p>
            Levitate is a single tool for application, infrastructure, product
            and business metrics with exceptional SRE support. We work with you
            to build your Observability strategy and execute it to meet your
            business goals.
          </p>
          {/*
          <p>
            Store your data in your choice of region including India, Asia
            Pacific, Europe and USA. Retain data for upto an year without
            downsampling.
            </p>
             */}
        </div>
      </div>

      <aside className="flex flex-col items-center gap-8 text-slate-600 md:basis-1/2 md:items-start lg:basis-auto">
        {/* proof */}
        <div className="bg-brand-gradient w-full rounded-3xl p-0.5 md:basis-1/2 lg:w-[488px] lg:min-w-[488px] lg:basis-auto">
          <div className="relative flex flex-col items-start justify-center gap-5 overflow-hidden rounded-[22px] bg-white p-10 text-sm text-white md:text-base xl:h-96">
            {/* background pattern */}
            <div className="absolute bottom-0 left-0 right-0 top-0 z-0">
              {/* prettier-ignore */}
              <svg className="h-full w-full pointer-events-none">
                  <defs>
                    <pattern id="polka-dots-on-light" x="0" y="0" width="8" height="8" patternUnits="userSpaceOnUse">
                      <circle fill="#273747" opacity={0.25} cx="3.5" cy="3.5" r="1" />
                    </pattern>
                  </defs>
                  <rect x="0" y="0" width="100%" height="100%" fill="url(#polka-dots-on-light)" />
                </svg>
              <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full bg-gradient-to-tl from-white/75 to-white/95 bg-blend-lighten"></div>
            </div>
            {/* end background pattern */}

            <div className="z-10 flex h-10 items-center justify-center">
              {/* prettier-ignore */}
              <ClientLogo name="probo" size={64} color="#94A3B8" className="z-10" />
            </div>
            <p className="z-10 text-slate-500">
              <strong>
                “Reliable, easy to integrate with exceptional support“
              </strong>
              <br />
              We just fire and forget about our metrics and Levitate takes care
              about the rest. No headache of maintaining our own time series
              database. Prompt customer support with a very good turnaround time
              for requested features.
            </p>
            <div className="z-10 flex gap-3">
              <StaticImage
                src="../../../static/newrelic-alternative/aditya-c-probo.jpg"
                alt="Aditya Chowdhry, Platform Lead, Probo"
                className="h-10 w-10 rounded-full border border-brand-blue-100/10 [&>picture>img]:rounded-full"
              />
              <div className="flex flex-col">
                <span className="text-sm text-slate-500">
                  Aditya Chowdhry (Via{" "}
                  <a
                    href="https://www.g2.com/products/last9-levitate/reviews/levitate-review-8576233"
                    className="underline decoration-slate-500 underline-offset-2"
                  >
                    G2 review
                  </a>
                  )
                </span>
                <span className="text-sm text-brand-green-200">
                  Platform Lead
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* end proof */}
      </aside>
    </div>
  )
}

export default LevitateIsDifferent
