import React from "react"
import { Link } from "gatsby"
import { ChevronRightIcon } from "@heroicons/react/solid"

const Resources = ({ resources }) => {
  return (
    <div className="grid w-full sm:w-[512px] sm:grid-cols-2 sm:gap-2 sm:p-3">
      {resources.map(item => (
        <Link
          key={item.name}
          to={item.href}
          target={item.target || ""}
          className="group relative -left-3 flex w-[calc(100%+24px)] items-center gap-2 rounded-lg p-3 hover:bg-slate-50 hover:shadow-sm sm:left-0 sm:w-auto sm:hover:shadow-none"
        >
          <span className="size-5 text-blue-500">{item.icon}</span>
          <span className="flex-1 text-sm font-medium text-slate-600">
            {item.name}
          </span>
          <ChevronRightIcon className="h-5 w-5 text-slate-400" />
        </Link>
      ))}
    </div>
  )
}

export default Resources
