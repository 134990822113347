import React from "react"
import headerMagnifyGlassImg from "../../../static/icons/header-magnify-glass.png"
import IconSpark from "../../../static/control-plane/icon-spark.inline.svg"
import IconTune from "../../../static/control-plane/icon-tune.inline.svg"
import IconStandard from "../../../static/control-plane/icon-standard.inline.svg"
import IconHelp from "../../../static/control-plane/icon-help.inline.svg"

import Quote from "../common/Quote"
import imageSrc from "../../../static/home/hotstar-quote-image.webp"
import { Link } from "gatsby"

const VisibilityAndCost = ({
  quote = {
    client: "clevertapcolor",
    quote:
      "Using Last9’s high cardinality workflows, we accurately measured customer SLAs across dimensions, extract knowledge about our systems, and measure customer impact proactively.",
    author: "Ranjeet Walunj, SVP Engineering, CleverTap",
    accent: "#F04444",
    img: <img src={imageSrc} className="h-auto w-full max-w-80" />,
  },
}) => {
  return (
    <section className="scroll-pt-40 bg-gradient-to-b from-slate-50 to-transparent px-6 py-20">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-16">
        {/* section header */}
        <div className="flex flex-col-reverse items-start justify-between gap-5 md:flex-row md:items-center">
          <h2 className="max-w-xl text-balance text-4xl font-extrabold text-slate-900 md:text-5xl">
            Don’t sacrifice visibility for cost optimization.
          </h2>
          <img alt="" className="h-[120px]" src={headerMagnifyGlassImg} />
        </div>
        {/* end section header */}

        {/* body */}
        <div className="grid w-full grid-cols-1 gap-12 md:grid-cols-3">
          {/* col 1 */}
          <div className="col-span-1 flex flex-col gap-2">
            <IconSpark className="h-6 w-6" />
            <h3 className="text-balance text-base font-bold text-slate-600 lg:w-3/4 xl:w-2/3">
              Improve MTTR and team productivity.
            </h3>
            <p className="text-base text-slate-500">
              With tools like Cardinality Explorer and Streaming Aggregation,
              remove constraints on your engineering teams.
            </p>
          </div>
          {/* end col 1 */}

          {/* col 2 */}
          <div className="col-span-1 flex flex-col gap-2">
            <IconTune className="h-6 w-6" />
            <h3 className="text-balance text-base font-bold text-slate-600 lg:w-3/4 xl:w-2/3">
              Know how your data is used and is performing.
            </h3>
            <p className="text-base text-slate-500">
              Observability on your telemetry data along with a simple pricing
              model keeps costs predictable & manageable, even at scale.
            </p>
          </div>
          {/* end col 2 */}

          {/* col 3 */}
          <div className="col-span-1 flex flex-col gap-2">
            <IconStandard className="h-6 w-6" />
            <h3 className="text-balance text-base font-bold text-slate-600 lg:w-3/4 xl:w-2/3">
              Your data is there, especially when you need it the most.
            </h3>
            <p className="text-base text-slate-500">
              Ingestion controls and cost-effective storage with rehydration
              ensure all, but only, the necessary data is always available to
              your team.
            </p>
          </div>
          {/* end col 3 */}
        </div>
        {/* end body */}

        {/* cta */}
        <div className="flex flex-col gap-4 md:flex-row md:items-center lg:w-2/3 xl:w-1/2">
          <Link
            to="/schedule-demo"
            className="flex w-fit shrink-0 gap-2 rounded-full bg-blue-500 py-4 pl-4 pr-6 text-base font-bold text-white !no-underline hover:bg-blue-600"
          >
            <IconHelp className="h-6 w-6" />
            Talk to Us
          </Link>
          <span className="text-balance text-base font-medium text-slate-400">
            Questions? Need a demo? Help with migration? Need information you
            couldn’t find?
          </span>
        </div>
        {/* end cta */}

        <Quote {...quote} />
      </div>
    </section>
  )
}

export default VisibilityAndCost
