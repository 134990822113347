import React from "react"
import { Link } from "gatsby"
import { gsap } from "gsap"
import Balancer from "react-wrap-balancer"
import PropTypes from "prop-types"

import ballAndStumps from "../../../static/cricket-scale/ball-and-stumps.svg"
import showcard from "../../../static/cricket-scale/showcard.svg"

const Card = ({ title, subtitle, number }) => {
  return (
    <div className="stacking-card flex w-full max-w-2xl gap-3.5 rounded-xl border border-solid border-[#FFAF20] bg-black p-8 md:absolute md:h-[223px] md:gap-10 md:opacity-0">
      <div className="relative h-20 w-[61px] md:h-40 md:w-[125px]">
        <p className="text-stroke-shadow absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 text-[44px] font-extrabold text-brand-green-100 md:text-[90px]">
          {number}
        </p>
        <svg
          className="absolute top-0 z-10 h-full w-auto"
          viewBox="0 0 61 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="4"
            y1="38.5077"
            x2="58"
            y2="38.5077"
            stroke="#141414"
            strokeWidth="0.984615"
          />
        </svg>
        <svg
          className="absolute top-0 h-20 w-full md:h-full"
          viewBox="0 0 61 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.0738462"
            y="43.0738"
            width="60.8523"
            height="35.8523"
            rx="5.83385"
            fill="#E9EBED"
            fillOpacity="0.92"
          />
          <rect
            x="0.0738462"
            y="43.0738"
            width="60.8523"
            height="35.8523"
            rx="5.83385"
            stroke="black"
            strokeWidth="0.147692"
          />
          <g clipPath="url(#clip0_519_24817)">
            <rect width="61" height="72" rx="5.90769" fill="#FBFBFB" />
            <rect
              x="57"
              y="35"
              width="15"
              height="8"
              rx="1.47692"
              fill="#141414"
            />
            <rect
              x="-10"
              y="35"
              width="15"
              height="8"
              rx="1.47692"
              fill="#141414"
            />
          </g>
          <rect
            x="0.0738462"
            y="0.0738462"
            width="60.8523"
            height="71.8523"
            rx="5.83385"
            stroke="black"
            strokeWidth="0.147692"
          />
          <defs>
            <filter
              id="filter0_d_519_24817"
              x="22.5963"
              y="20.7762"
              width="15.1678"
              height="34.193"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.96923" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.686275 0 0 0 0 0.12549 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_519_24817"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_519_24817"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_519_24817">
              <rect width="61" height="72" rx="5.90769" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="flex h-full w-full flex-col justify-between gap-6">
        <div className="flex w-full justify-between">
          <p className="text-sm font-extrabold text-white md:text-xl">
            {title}
          </p>
          <ul className="bullet-ticks">
            <li></li>
          </ul>
        </div>
        <p className="text-sm text-white/70 md:text-xl md:font-bold">
          {subtitle}
        </p>
      </div>
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  number: PropTypes.number,
}

const Point7 = () => {
  const stackingCardTrigger = React.useRef(null)

  React.useLayoutEffect(() => {
    const mm = gsap.matchMedia()
    const tl = gsap.timeline()
    mm.add("(min-width: 768px)", () => {
      tl.fromTo(
        ".stacking-card",
        {
          opacity: 0,
          scale: 0.5,
          yPercent: 100,
          rotateX: 45,
        },
        {
          opacity: 1,
          stagger: 0.5,
          scale: i => 1 - (3 - i) * 0.1,
          yPercent: i => (i - 2) * 30,
          rotateX: 0,
          scrollTrigger: {
            trigger: stackingCardTrigger.current,
            start: "top bottom-=40%",
            end: "80% bottom+=20%",
            scrub: true,
          },
        }
      )
    })
  }, [])

  return (
    <section
      ref={stackingCardTrigger}
      className="relative flex w-full flex-col justify-center gap-8 bg-brand-green-100 px-4 pt-12 md:h-[400vh] md:flex-row-reverse md:gap-16 md:pt-24"
    >
      <div className="relative flex h-full w-full flex-col items-center pb-36 md:pb-96">
        <h2 className="max-w-3xl text-center text-3xl font-extrabold md:text-7xl">
          <Balancer>How do SREs manage Cricket scale?</Balancer>
        </h2>
        <p className="mt-9 max-w-lg text-center text-xl font-bold text-slate-800">
          <Balancer>
            Mapping out your service dependencies as critical vs non-critical is
            the first task.
          </Balancer>
        </p>
        <div className="top-24 flex flex-col items-center md:sticky">
          <h3 className="mt-16 max-w-3xl text-center text-xl font-extrabold text-black md:text-5xl">
            <Balancer>
              Here’s a simplified understanding of how to go about this
            </Balancer>
          </h3>
          <div className="mb-8 mt-6 flex h-fit w-full flex-col items-center justify-center gap-6 md:my-16 md:h-[427px]">
            <Card
              number={1}
              title="Instrumentation"
              subtitle="Get your instrumentation right. You want to declare your services that need to be ‘Observed.’ Measure only what matters."
            />
            <Card
              number={2}
              title="External Dependencies"
              subtitle="Map out External Dependencies such as CDN configurations, 3rd-party APIs and Payment services."
            />
            <Card
              number={3}
              title="Identify Metrics"
              subtitle="Identify key critical metrics that can give early warnings."
            />
            <Card
              number={4}
              title="SLOs"
              subtitle="Understand latencies and availability baselines to write SLOs that matter."
            />
          </div>
          <p className="mt-9 max-w-lg text-center text-xl font-bold text-slate-800">
            <Balancer>
              These are the absolute four basics that need to come together days
              before the match starts so one can load test and prepare for the
              Cricket scale.
            </Balancer>
          </p>
          <h4 className="mt-11 max-w-lg text-center text-xl font-extrabold md:text-3xl">
            <Balancer>
              Want to know how we help teams prepare for Cricket Scale?
            </Balancer>
          </h4>
          <Link
            to="/schedule-demo"
            className="mt-6 rounded-full bg-black px-12 py-6 font-semibold text-white hover:bg-gray-900"
          >
            Slide into our DMs
          </Link>
        </div>
      </div>
      <img
        src={ballAndStumps}
        className="pointer-events-none absolute bottom-0 left-0 w-full"
        alt=""
      />
      <img
        src={showcard}
        className="absolute -bottom-16 left-[10%] z-10 hidden w-24 md:block"
        alt=""
      />
    </section>
  )
}

export default Point7
