/* eslint-disable react/prop-types */
import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Avatar from "../components/common/Avatar"
import { RelatedPost } from "../components/blog/RelatedPosts"

const AuthorDetails = ({ author }) => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-stretch gap-3">
      <p className="text-xs font-bold uppercase text-slate-400">
        <Link to="/blog/authors/" className="underline">
          All Authors
        </Link>
        <span className="text-slate-200"> / </span>
        <span>{author.name}</span>
      </p>
      <div className="flex w-full flex-col-reverse items-start justify-start gap-6 md:flex-row md:justify-between">
        <div className="flex w-full max-w-3xl flex-col gap-6">
          <h1 className="text-4xl font-black leading-[48px]">{author.name}</h1>
          {author?.bio && (
            <p className="text-xl text-slate-600">{author?.bio}</p>
          )}
          {(!!author.twitter || !!author.website) && (
            <div className="flex flex-col">
              <p className="text-xs font-bold uppercase leading-4 text-slate-500">
                Find {author.name} elsewhere ⏤
              </p>
              <div className="leading-6 text-slate-500">
                {!!author.twitter && (
                  <a
                    className="underline decoration-slate-200"
                    href={`https://twitter.com/${author.twitter}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitter
                  </a>
                )}
                {!!author.website && !!author.twitter && (
                  <span className="text-slate-200"> / </span>
                )}
                {!!author.website && (
                  <a
                    className="underline decoration-slate-200"
                    href={author.website}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Website
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
        <Avatar
          className="h-24 w-24 md:h-32 md:w-32"
          name={author.name}
          src={author?.profile_image}
        />
      </div>
    </div>
  )
}

const Author = ({ data }) => {
  return (
    <Layout>
      <div className="mx-auto flex w-full flex-col gap-10 pb-[120px]">
        <div className="w-full gap-10 bg-yellow-50 px-4 py-8">
          <AuthorDetails author={data.allGhostAuthor.nodes[0]} />
        </div>
        <div className="w-full gap-10 px-4">
          <div className="mx-auto grid w-full max-w-6xl grid-flow-row grid-cols-1 gap-x-10 gap-y-14 sm:grid-cols-2 md:grid-cols-3">
            {data.allGhostPost.nodes.map(node => {
              return <RelatedPost key={node.id} post={node} />
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data: { allGhostAuthor } }) => (
  <Seo
    title={`${allGhostAuthor.nodes[0].name} ⏤ Last9`}
    description={`Post by ${allGhostAuthor.nodes[0].name} ⏤ Last9 Blog`}
  />
)

export const query = graphql`
  query ($slug: String!) {
    allGhostPost(
      filter: {
        authors: { elemMatch: { slug: { eq: $slug } } }
        tags: {
          elemMatch: { slug: { nin: ["changelog", "hash-last9-events"] } }
        }
      }
    ) {
      nodes {
        featureImage: feature_image
        id
        slug
        authors {
          name
          id
          profile_image
        }
        title
        excerpt
      }
    }
    allGhostAuthor(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        slug
        name
        bio
        profile_image
        postCount
        meta_description
        twitter
        website
      }
    }
  }
`

export default Author
