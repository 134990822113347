import React from "react"
import { HandIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"

const Banner = () => {
  return (
    <Link
      to="/events/join-last9-at-aws-re-invent-2024/"
      className="relative block h-14 bg-slate-800 px-6 py-2 text-white transition-colors hover:bg-slate-700 md:h-10"
    >
      <div className="mx-auto flex h-full max-w-6xl items-center justify-center gap-2">
        <HandIcon
          className="size-8 -rotate-12 text-yellow-300 md:size-5"
          aria-hidden="true"
        />
        <span className="text-balance text-sm font-medium">
          Meet the Last9 team at AWS re:Invent 2024!
        </span>
        <span className="shrink-0 text-sm font-semibold text-white underline">
          Join us →
        </span>
      </div>
    </Link>
  )
}

export default Banner
