import React from "react"
import Balancer from "react-wrap-balancer"
import { StaticImage } from "gatsby-plugin-image"
import MobileScreen from "./MobileScreen"

const Point3 = () => {
  return (
    <section className="bg-hard-radial-green flex w-full flex-col items-center px-4 pb-20">
      <div className="md:-translate-y-1/4 h-full max-h-48 w-full max-w-5xl md:max-h-full">
        <MobileScreen />
      </div>
      <div className="mt-9 flex flex-col gap-6 md:mt-0">
        <h2 className="text-center text-3xl font-extrabold md:text-7xl">
          <Balancer>What does this mean?</Balancer>
        </h2>
        <p className="text-center text-sm font-extrabold text-[#363636] md:text-2xl">
          <Balancer>
            This is a BIG deal for a Site Reliability Engineers.
          </Balancer>
        </p>
        <p className="text-center text-xl font-bold md:hidden">
          The team has to prepare for tackling unknowns at
          such an unprecedented scale.
        </p>
      </div>
      <div className="flex w-full flex-col items-center">
        <p className="hidden w-full max-w-md translate-y-40 text-center text-2xl font-extrabold md:block">
          <Balancer>
            The team has to prepare for tackling unknowns at
            such an unprecedented scale.
          </Balancer>
        </p>
        <StaticImage
          src="../../../static/cricket-scale/data-boxes.png"
          className="md:mt-3.5 mt-8 max-w-5xl md:max-h-full"
          alt=""
        />
      </div>
    </section>
  )
}

export default Point3
