import React from "react"
import PropTypes from "prop-types"

const Note = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.5817 5.15506C19.4687 4.72945 19.2459 4.341 18.9355 4.02861C18.6252 3.71622 18.2382 3.49084 17.8133 3.37506C16.2542 2.95422 10 2.95422 10 2.95422C10 2.95422 3.74583 2.95422 2.18583 3.37506C1.76117 3.49105 1.37441 3.7165 1.06423 4.02888C0.754051 4.34125 0.531326 4.72959 0.418333 5.15506C0 6.72506 0 10.0001 0 10.0001C0 10.0001 0 13.2751 0.418333 14.8451C0.531322 15.2707 0.754146 15.6591 1.06449 15.9715C1.37484 16.2839 1.76181 16.5093 2.18667 16.6251C3.74583 17.0459 10 17.0459 10 17.0459C10 17.0459 16.2542 17.0459 17.8142 16.6251C18.2391 16.5094 18.6261 16.284 18.9364 15.9716C19.2468 15.6592 19.4696 15.2707 19.5825 14.8451C20 13.2751 20 10.0001 20 10.0001C20 10.0001 20 6.72506 19.5817 5.15506ZM7.95417 12.9734V7.02672L13.1817 10.0001L7.95417 12.9734Z"
        fill="white"
      />
    </svg>
  )
}

Note.propTypes = {
  className: PropTypes.string,
}

export default Note
