import React from "react"
import headerVisualizationImg from "../../../static/icons/header-visualization.png"
import IconCardinality from "../../../static/metrics/icon-cardinality.inline.svg"
import IconCorrelated from "../../../static/metrics/icon-correlated.inline.svg"
import IconOpen from "../../../static/metrics/icon-open.inline.svg"
import Quote from "../common/Quote"
import imageSrc from "../../../static/home/hotstar-quote-image.webp"

import imageMetricsCardinality from "../../../static/metrics/metrics-cardinality.png"
import imageMetricsCorrelated from "../../../static/metrics/metrics-correlated.png"
import imageMetricsIntegrations from "../../../static/metrics/metrics-integrations.png"

const MetricsDetails = ({
  quote = {
    client: "clevertapcolor",
    quote:
      "Using Last9’s high cardinality workflows, we accurately measured customer SLAs across dimensions, extract knowledge about our systems, and measure customer impact proactively.",
    author: "Ranjeet Walunj, SVP Engineering, CleverTap",
    accent: "#F04444",
    img: <img src={imageSrc} className="h-auto w-full max-w-80" />,
  },
}) => {
  return (
    <section className="scroll-pt-40 bg-gradient-to-b from-yellow-50 to-transparent px-6 py-20">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-16">
        {/* section header */}
        <div className="flex flex-col-reverse items-start justify-between gap-5 md:flex-row md:items-center">
          <h2 className="max-w-xl text-balance text-4xl font-extrabold text-slate-900 md:text-5xl">
            For the needs of modern-day micro-services architectures.
          </h2>
          <img alt="" className="h-[120px]" src={headerVisualizationImg} />
        </div>
        {/* end section header */}

        {/* card 1 */}
        <div
          id="metrics-cardinality-workflows"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row-reverse md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageMetricsCardinality}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconCardinality className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Built for High Cardinality
            </h3>

            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                Superior defaults of 20M cardinality per day per metric,
                customizable on request
              </li>
              <li>
                Use Cardinality Explorer to identify impacted metrics and their
                label values.
              </li>
              <li>
                Control cardinality explosion with Streaming Aggregations during
                run-time by dropping unwanted labels or creating new scoped
                metrics with reduced cardinality
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 1 */}

        {/* card 2 */}
        <div
          id="metrics-correlated-telemetry"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageMetricsCorrelated}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconCorrelated className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Quick MTTD with correlated telemetry
            </h3>

            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                Debug issues and identify the root cause faster by correlating
                your infrastructure and service metrics with logs and traces
              </li>
              <li>
                Logs to Metrics and Traces to Metrics pipelines for auto metrics
                generation without instrumentation changes
              </li>
              <li>
                30-days retention with cold storage upto 13 months enables
                long-term reporting
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 2 */}

        {/* card 3 */}
        <div
          id="metrics-open-standards"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row-reverse md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageMetricsIntegrations}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconOpen className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Open Standards and Cloud Native compatible
            </h3>
            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                Ingest metrics via OpenTelemetry, Prometheus, VictoriaMetrics,
                and more. View all our{" "}
                <a href="https://docs.last9.io/docs/integrations">
                  integrations
                </a>
              </li>
              <li>
                Out of the box support for metrics generated by Cloud Native
                Kubernetes workloads
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 3 */}

        <Quote {...quote} />
      </div>
    </section>
  )
}

export default MetricsDetails
