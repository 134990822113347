import React from "react"
import Balancer from "react-wrap-balancer"
import Avatar from "../common/Avatar"

import subhashDukaan from "../../../static/home/subhash-dukaan.jpeg"
import cricketBall from "../../../static/cricket-scale/cricket-ball.svg"

const DukaanLogoInWhite = () => (
  <svg
    width="136"
    height="37"
    viewBox="0 0 136 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64.1069 9.13768V26.9362C64.1069 27.3534 63.8558 27.5827 63.4127 27.5827H61.025C60.577 27.5827 60.3308 27.3488 60.3308 26.9362V25.9734C59.2871 27.2067 57.7166 27.954 55.5799 27.954C51.6709 27.954 48.4413 24.8226 48.4413 20.7649C48.4413 16.7073 51.6709 13.5758 55.5799 13.5758C57.7215 13.5758 59.2871 14.3186 60.3308 15.5565V9.13768C60.3308 8.72046 60.5819 8.49121 61.025 8.49121H63.4127C63.8608 8.49121 64.1069 8.72504 64.1069 9.13768ZM60.3308 20.7649C60.3308 18.4725 58.6028 16.9182 56.2741 16.9182C53.9454 16.9182 52.2174 18.4725 52.2174 20.7649C52.2174 23.0574 53.9454 24.6117 56.2741 24.6117C58.6028 24.6117 60.3308 23.0574 60.3308 20.7649Z"
      fill="white"
    />
    <path
      d="M77.8254 13.9424C78.2734 13.9424 78.5196 14.1762 78.5196 14.5889V26.9314C78.5196 27.3486 78.2685 27.5778 77.8254 27.5778H75.4377C74.9896 27.5778 74.7435 27.344 74.7435 26.9314V26.1794C73.9213 27.3348 72.5822 27.9492 70.7606 27.9492C68.0381 27.9492 65.9999 26.0006 65.9999 22.6582V14.5934C65.9999 14.1762 66.251 13.947 66.694 13.947H69.0818C69.5298 13.947 69.776 14.1808 69.776 14.5934V22.3969C69.776 23.9604 70.7705 24.6939 72.0948 24.6939C73.5816 24.6939 74.7386 23.8962 74.7386 21.8421V14.5934C74.7386 14.1762 74.9896 13.947 75.4327 13.947H77.8205L77.8254 13.9424Z"
      fill="white"
    />
    <path
      d="M92.9857 27.5827H90.2041C89.8447 27.5827 89.574 27.4589 89.3672 27.1884L84.5572 21.4252V26.9362C84.5572 27.3534 84.3061 27.5827 83.8631 27.5827H81.4753C81.0273 27.5827 80.7811 27.3488 80.7811 26.9362V9.13768C80.7811 8.72046 81.0322 8.49121 81.4753 8.49121H83.8631C84.3111 8.49121 84.5572 8.72504 84.5572 9.13768V19.9442L89.0767 14.3415C89.2835 14.071 89.5543 13.9472 89.9137 13.9472H92.7199C93.291 13.9472 93.483 14.2957 93.1384 14.7312L88.2004 20.6962L93.3944 26.7986C93.7538 27.2296 93.5618 27.5827 92.9907 27.5827H92.9857Z"
      fill="white"
    />
    <path
      d="M100.759 13.5759C103.994 13.5759 106.721 15.3686 106.721 18.9218V26.9362C106.721 27.3534 106.47 27.5827 106.027 27.5827H103.728C103.28 27.5827 103.034 27.3489 103.034 26.9362V26.4044C102.192 27.3626 100.922 27.9541 99.0659 27.9541C96.4615 27.9541 94.1328 26.5282 94.1328 23.6626C94.1328 20.7971 96.4615 19.3712 99.0659 19.3712C100.922 19.3712 102.192 19.8893 103.034 20.577V19.1236C103.034 17.5051 102.153 16.8311 100.764 16.8311C99.6566 16.8311 98.8443 17.2621 98.5982 17.9957C98.4455 18.3625 98.2929 18.5734 97.8744 18.5734H95.531C95.083 18.5734 94.8073 18.3396 94.8368 17.8994C95.2012 15.1118 97.7661 13.5713 100.769 13.5713L100.759 13.5759ZM103.029 23.6626C103.029 22.6127 102.005 21.9662 100.341 21.9662C98.6769 21.9662 97.904 22.6815 97.904 23.6626C97.904 24.6438 98.6819 25.359 100.341 25.359C102 25.359 103.029 24.6438 103.029 23.6626Z"
      fill="white"
    />
    <path
      d="M115.084 13.5759C118.318 13.5759 121.046 15.3686 121.046 18.9218V26.9362C121.046 27.3534 120.795 27.5827 120.351 27.5827H118.052C117.604 27.5827 117.358 27.3489 117.358 26.9362V26.4044C116.516 27.3626 115.246 27.9541 113.39 27.9541C110.786 27.9541 108.457 26.5282 108.457 23.6626C108.457 20.7971 110.786 19.3712 113.39 19.3712C115.246 19.3712 116.516 19.8893 117.358 20.577V19.1236C117.358 17.5051 116.477 16.8311 115.089 16.8311C113.981 16.8311 113.169 17.2621 112.922 17.9957C112.77 18.3625 112.617 18.5734 112.199 18.5734H109.855C109.407 18.5734 109.132 18.3396 109.161 17.8994C109.525 15.1118 112.09 13.5713 115.094 13.5713L115.084 13.5759ZM117.353 23.6626C117.353 22.6127 116.329 21.9662 114.665 21.9662C113.001 21.9662 112.228 22.6815 112.228 23.6626C112.228 24.6438 113.006 25.359 114.665 25.359C116.324 25.359 117.353 24.6438 117.353 23.6626Z"
      fill="white"
    />
    <path
      d="M130.716 13.5763C133.439 13.5763 135.477 15.5249 135.477 18.8672V26.9366C135.477 27.3539 135.226 27.5831 134.783 27.5831H132.395C131.947 27.5831 131.701 27.3493 131.701 26.9366V19.1332C131.701 17.5697 130.706 16.8361 129.377 16.8361C127.89 16.8361 126.733 17.6339 126.733 19.6879V26.9366C126.733 27.3539 126.482 27.5831 126.039 27.5831H123.651C123.203 27.5831 122.957 27.3493 122.957 26.9366V14.5941C122.957 14.1769 123.208 13.9477 123.651 13.9477H126.039C126.487 13.9477 126.733 14.1815 126.733 14.5941V15.3461C127.555 14.1907 128.894 13.5763 130.716 13.5763Z"
      fill="white"
    />
    <path
      d="M27.7993 13.8005C27.5089 17.3813 24.2546 20.1873 20.3948 20.1873C16.535 20.1873 13.2759 17.3813 12.9805 13.8005C12.951 13.4246 13.0839 13.0532 13.3497 12.7643C13.6156 12.4755 13.9848 12.3013 14.3885 12.2738C14.7972 12.2462 15.1861 12.3655 15.5012 12.613C15.8113 12.8606 15.9984 13.2045 16.028 13.5805C16.2052 15.6941 18.1203 17.3538 20.3998 17.3538C22.6792 17.3538 24.5943 15.6941 24.7716 13.5805C24.8306 12.8056 25.5642 12.2142 26.4061 12.2692C27.243 12.3334 27.8732 13.0165 27.8043 13.8005H27.7993Z"
      fill="white"
    />
    <path
      d="M20.3884 0C9.41462 0 0.523315 8.2803 0.523315 18.5C0.523315 28.7197 9.41462 37 20.3884 37C31.3623 37 40.2536 28.7197 40.2536 18.5C40.2536 8.2803 31.3623 0 20.3884 0ZM30.9881 25.5057C30.9881 27.0004 29.6834 28.22 28.0785 28.22H12.6984C11.0885 28.22 9.78386 27.0004 9.78386 25.5057V11.4943C9.78386 9.99963 11.0885 8.78463 12.6984 8.78463H28.0785C29.6834 8.78463 30.9881 9.99963 30.9881 11.4943V25.5011V25.5057Z"
      fill="white"
    />
  </svg>
)

const Point6 = () => {
  return (
    <section className="relative flex w-full flex-col items-center justify-center gap-8 bg-black px-4 py-12 md:flex-row-reverse md:gap-16 md:py-24">
      <img
        className="absolute left-3/4 top-full z-10 hidden h-16 -translate-y-1/2 md:block"
        src={cricketBall}
        alt=""
      />
      <p className="max-w-2xl pt-2.5 text-center text-2xl font-extrabold text-white md:pt-0 md:text-left">
        <Balancer>
          “Our global infrastructure generates{" "}
          <span className="text-[#E8B551]">millions of events per minute</span>,
          which we ingest into Last9 for analysis, monitoring and alerting.
          <span className="text-[#E8B551]">This thing scales.</span>”
        </Balancer>
      </p>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col items-center gap-5">
          <DukaanLogoInWhite />
          <div className="gradient-bg-horizontal h-fit w-fit rounded-full p-[0.7px]">
            <Avatar size={80} src={subhashDukaan} name="Subhash Choudhary" />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-center text-white">Subhash Choudhary</p>
          <p className="text-center text-slate-500">
            <Balancer>Co-Founder & CTO</Balancer>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Point6
