import React from "react"
import ExternalScripts from "./src/components/common/ExternalScripts"
import "./src/styles/global.css"

export const wrapPageElement = ({ element, props }) => {
  return <ExternalScripts {...props}>{element}</ExternalScripts>
}

export const onRouteUpdate = () => {
  window.loadPixel && window.loadPixel("9ad9c871999b50bf339b677f6927ca8c")
}
