import React from "react"
import { Dialog } from "@headlessui/react"
import Cookies from "js-cookie"
import PropTypes from "prop-types"
import { ENV } from "../../constants/env"

const SubscribeDialog = ({ open, setOpen }) => {
  const URL = `https://api.hsforms.com/submissions/v3/integration/submit/${ENV.HS_ACCOUNT_ID}/${ENV.HS_BLOG_SUB_FORM_ID}`
  const [isLoading, setIsLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)

  const handleFormSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)
    const formData = new FormData(event.target)

    // Building request body
    const data = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: formData.get("email"),
        },
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: formData.get("firstname"),
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: formData.get("lastname"),
        },
      ],
      context: {
        hutk: Cookies.get("hubspotutk"),
        pageUri: window && window.location.href,
        pageName: document && document.title,
      },
    }

    try {
      const response = await fetch(URL, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.ok) {
        setSuccess(true)
        setIsLoading(false)
      } else throw new Error("Something went wrong")
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      setIsLoading(false)
      setError(true)
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <div
        className="fixed inset-0 z-50 bg-black/30"
        aria-hidden="true"
        onClick={() => setOpen(false)}
      />
      <div className="fixed bottom-0 left-0 z-[9999] flex w-full items-center justify-center p-0 md:inset-0 md:p-4">
        <Dialog.Panel className="z-[60] flex w-full flex-col items-center gap-4 overflow-hidden rounded-t-3xl bg-white p-6 md:max-w-xl md:rounded-3xl">
          <Dialog.Title className="max-w-xs text-balance text-center text-3xl font-extrabold">
            Stay updated on the latest from Last9.
          </Dialog.Title>
          <div
            onSubmit={handleFormSubmit}
            className="relative flex w-full gap-4"
          >
            {!success && !error && (
              <form className="flex w-full flex-col justify-between gap-4 md:gap-2">
                <div className="flex w-full flex-col gap-4 md:flex-row md:gap-2">
                  <input
                    className="w-full rounded-full border border-solid border-slate-200 px-4 py-3"
                    placeholder="First name"
                    name="firstname"
                    type="text"
                    disabled={isLoading}
                    required
                  />
                  <input
                    className="w-full rounded-full border border-solid border-slate-200 px-4 py-3"
                    placeholder="Last name"
                    name="lastname"
                    type="text"
                    disabled={isLoading}
                    required
                  />
                </div>
                <input
                  type="email"
                  name="email"
                  disabled={isLoading}
                  placeholder="Enter your email..."
                  className="w-full rounded-full border border-solid border-slate-200 px-4 py-3"
                />
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full rounded-full bg-brand-green-200 px-4 py-2.5 text-sm font-bold text-white md:px-6"
                >
                  Subscribe
                </button>
              </form>
            )}
            {success && (
              <div className="flex h-full w-full items-center justify-center rounded-full bg-brand-green-200 p-4 text-sm font-medium text-white transition-transform md:p-2">
                Thanks! We&apos;ll keep you posted.
              </div>
            )}
            {error && (
              <div className="flex h-full w-full items-center justify-center rounded-full bg-red-600 p-4 text-sm font-medium text-white transition-transform md:p-2">
                Something went wrong. Please reload the page and try again?
              </div>
            )}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

SubscribeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default SubscribeDialog
