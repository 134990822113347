import React from "react"
import { Link } from "gatsby"
import dateFormat from "dateformat"
import { PropTypes } from "prop-types"

const Post = ({ post, news = false }) => {
  const slugPage = news ? "news" : "changelog"

  // Define the desired order of tags
  const tagOrder = ["changelog-new", "changelog-improvement", "changelog-fix"]

  // First filter the relevant tags
  const relevantTags = post.tags?.filter(tag =>
    ["changelog-new", "changelog-fix", "changelog-improvement"].includes(
      tag.name.toLowerCase(),
    ),
  )

  // Then sort them according to the desired order
  const sortedTags = relevantTags?.sort((a, b) => {
    const indexA = tagOrder.indexOf(a.name.toLowerCase())
    const indexB = tagOrder.indexOf(b.name.toLowerCase())
    return indexA - indexB
  })

  const getDisplayName = tagName => {
    switch (tagName.toLowerCase()) {
      case "changelog-new":
        return "Feature"
      case "changelog-fix":
        return "Fixes"
      case "changelog-improvement":
        return "Improvements"
      default:
        return tagName
    }
  }

  const getTagStyle = tagName => {
    switch (tagName.toLowerCase()) {
      case "changelog-new":
        return "bg-emerald-50 text-emerald-700 ring-1 ring-emerald-600/10"
      case "changelog-fix":
        return "bg-rose-50 text-rose-700 ring-1 ring-rose-600/10"
      case "changelog-improvement":
        return "bg-sky-50 text-sky-700 ring-1 ring-sky-600/10"
      default:
        return "bg-gray-50 text-gray-700 ring-1 ring-gray-600/10"
    }
  }

  return (
    <div className="grid grid-cols-12 gap-6">
      {/* left col with date */}
      <div className="col-span-2 hidden text-right sm:block">
        <Link
          to={`/${slugPage}/${post.slug}/`}
          className="py-0.5 text-sm text-slate-500"
        >
          {dateFormat(post.published_at, "mmm dS, 'yy")}
        </Link>
      </div>

      {/* Timeline dot and line */}
      <div className="relative col-span-1 flex justify-center">
        <div className="absolute mt-[8.5px] h-full w-px bg-slate-200" />
        <div className="relative z-10 mt-[8.5px] size-[7px] rounded-full bg-slate-400" />
      </div>

      {/* right col with content */}
      <div className="col-span-11 flex flex-col gap-3 pb-16 sm:col-span-9">
        {sortedTags && sortedTags.length > 0 && (
          <div className="flex gap-2">
            {sortedTags.map(tag => (
              <span
                key={tag.id}
                className={`rounded-full px-2 py-1 text-xs font-medium ${getTagStyle(tag.name)}`}
              >
                {getDisplayName(tag.name)}
              </span>
            ))}
          </div>
        )}

        <Link
          to={`/${slugPage}/${post.slug}/`}
          className="text-lg font-medium text-slate-800 hover:text-slate-900"
        >
          {post.title}
        </Link>

        {/* {post.feature_image && (
          <img
            src={post.feature_image}
            alt={post.title}
            className="h-48 w-full rounded-2xl border border-slate-100 object-cover"
          />
        )} */}

        <p className="text-slate-600">{post.excerpt}</p>
        <Link
          to={`/${slugPage}/${post.slug}/`}
          className="text-sm font-medium text-slate-500 hover:text-slate-700"
        >
          Read more →
        </Link>
      </div>
    </div>
  )
}

Post.propTypes = {
  post: PropTypes.object,
  news: PropTypes.bool,
}

Post.defaultProps = {
  post: {},
  news: false,
}

export default Post
