import React from "react"
import { Link } from "gatsby"
import Balancer from "react-wrap-balancer"

import last9 from "../../../static/cricket-scale/9.svg"

const Point9 = () => {
  return (
    <section className="relative z-0 flex w-full flex-col items-center justify-center gap-8 bg-[#F7F3DE] px-4 pt-12 md:max-h-[483px] md:flex-row-reverse md:gap-16 md:pt-0">
      <div className="flex w-full flex-col items-center md:w-fit">
        <h2 className="text-center text-3xl font-extrabold md:text-7xl">
          Our goal
        </h2>
        <h3 className="mt-8 max-w-xl text-center text-xl font-bold md:text-3xl md:font-extrabold">
          <Balancer>
            Making Observability a first-class citizen so you can build your
            reliability to the last 9.
          </Balancer>
        </h3>
        <Link
          to="/schedule-demo"
          className="mt-6 rounded-full bg-brand-green-200 px-16 py-6 font-semibold text-white hover:bg-emerald-500"
        >
          Talk to us
        </Link>
      </div>
      <img
        src={last9}
        className="translate-y-4 md:h-[528px] md:translate-y-0"
      />
    </section>
  )
}

export default Point9
