import React, { useEffect, useRef, useState } from "react"
import Layout from "../common/layout"
import PropTypes from "prop-types"
import Levitatevertical from "../../../static/img/products/levitatevertical.svg"
import { gsap } from "gsap"
import Lottie from "react-lottie-player"
import cubeFloating from "../../animations/CubeFloating2.json"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { Observer } from "gsap/Observer"
import { whitepaperDotLottie } from "../../animations/dotlottieData.js"
import ReactPlayer from "react-player"
import { oncePerScroll } from "../../lib/utils"
import conveyorVideo from "../../animations/Animation2_.1_Vp8_compressed.webm"
import conveyorVideoMp4 from "../../animations/Animation2.1_E1DED1_compressed.mp4"
import Shimmer from "react-shimmer-effect"
import backToTop from "../../../static/whitepaper-mobile/backToTop.svg"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import whitepaperPdf from "../../../static/whitepaper/Whitepaper-Scaling-Metrics.pdf"
import Balancer from "react-wrap-balancer"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Observer)

import "./whitepaper-mobile.css"

function FullpageReact() {
  const obj = [
    { src: whitepaperDotLottie.lastHerowebm, type: "video/webm" },
    { src: whitepaperDotLottie.lastHero, type: "video/mp4" },
  ]
  const videoReft1 = useRef()
  const videoReft2 = useRef()
  const conveyorRef1 = useRef()
  const conveyorRef2 = useRef()
  const conveyorRef3 = useRef()

  const stepsref1 = useRef()
  const stepsref2 = useRef()
  const stepsref3 = useRef()
  const stepsref1to2 = useRef()
  const stepsref2to3 = useRef()
  const [resetPage, setResetPage] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)

  useEffect(() => {
    if (window !== "undefined") {
      window.scrollTo(0, 0)
      document.querySelector("#blockContainer").scrollIntoView()
    }

    if (!pageLoaded) return
    function playVideo(video) {
      video.seekTo(0, "seconds")
    }
    const textTransitions = gsap.timeline()
    function createObserver(target, name, upFn, downFn) {
      return ScrollTrigger.observe({
        id: name,
        type: "wheel,touch,scroll",
        target: target,
        onUp: () => {
          oncePerScroll("wheel", upFn, triggerSteps)
        },
        wheelSpeed: -1,
        onDown: () => {
          oncePerScroll("wheel", downFn, triggerSteps)
        },
        dragMinimum: 5,
        debounce: true,
        tolerance: 40,
      })
    }
    let obsSteps = createObserver(
      "#steps",
      "ob-2",
      scrollDownSteps,
      scrollUpSteps
    )
    obsSteps.disable()

    // <--------------------Steps Animation-------------------->

    const t2 = gsap.timeline()
    t2.set(".stage1", { opacity: 1, x: 0 }).set(".steps-1", { opacity: 1 })
    t2.set(".stage2", { opacity: 0, x: "100%" })
    const t3 = gsap.timeline()
    t3.set(".stage3", { opacity: 0, x: "100%" })
    t2.set(".steps-1to2", { opacity: 0 })
      .set(".steps-2", { opacity: 0 })
      .set(".steps-2to3", { opacity: 0 })
      .set(".steps-3", { opacity: 0 })
    let animationState = 0
    let animationPlaying = false
    function scrollDownSteps(e) {
      if (animationState === 1 && !animationPlaying) {
        animationPlaying = true
        const t2New = gsap.timeline()
        const stepsT1 = stepsref1to2.current
        const d1 = stepsT1.getDuration()
        playVideo(stepsT1)
        setTimeout(() => {
          gsap
            .timeline()
            .to(".steps-1to2", { opacity: 1, duration: 0 }, 0)
            .to(".steps-1", { opacity: 0, duration: 0 }, 0)
        }, 500)
        setTimeout(() => {
          playVideo(stepsref2.current)
        }, d1 * 1000)

        t2New
          .to(".steps-1to2", { opacity: 0, duration: 0 }, d1)
          .to(".steps-2", { opacity: 1, duration: 0 }, d1)

        t2New.to(".stage1", { duration: 1, opacity: 0 }, 0).to(
          ".stage2",
          {
            opacity: 1,
            duration: 1,
            x: 0,
            onComplete: () => {
              setTimeout(() => {
                animationState = 2
                animationPlaying = false
              }, 1000)
            },
          },
          0
        )
      }
      if (animationState === 2 && !animationPlaying) {
        animationPlaying = true
        const t3New = gsap.timeline()

        const stepsT2 = stepsref2to3.current
        const d2 = stepsT2.getDuration()
        playVideo(stepsT2)
        setTimeout(() => {
          gsap
            .timeline()
            .to(".steps-2to3", { opacity: 1, duration: 0 }, 0)
            .to(".steps-2", { opacity: 0, duration: 0 }, 0)
        }, 500)
        setTimeout(() => {
          playVideo(stepsref3.current)
        }, d2 * 1000)

        t3New
          .to(".steps-2to3", { opacity: 0, duration: 0 }, d2)
          .to(".steps-3", { opacity: 1, duration: 0 }, d2)
        t3New.to(".stage2", { opacity: 0, duration: 1 }, 0)
        t3New.to(
          ".stage3",
          {
            opacity: 1,
            duration: 1,
            x: 0,
            onComplete: () => {
              animationState = 3
              animationPlaying = false
              obsSteps.disable()
              if (e.scroll() < e.start + 1 * (e.end - e.start))
                e.scroll(e.start + 1 * (e.end - e.start))
            },
          },
          0
        )
      }
    }
    function scrollUpSteps(e) {
      if (animationState === 3 && !animationPlaying) {
        animationPlaying = true
        const tl1 = gsap.timeline()
        tl1.to(".stage3", { duration: 1, opacity: 0, x: "100%" }, 0).to(
          ".stage2",
          {
            opacity: 1,
            duration: 1,
            onComplete: () => {
              animationState = 2
              animationPlaying = false
            },
          },
          0
        )

        tl1
          .to(".steps-3", { opacity: 0, duration: 1 }, 0)
          .to(".steps-2", { opacity: 1, duration: 0 }, 0)
      }
      if (animationState === 2 && !animationPlaying) {
        animationPlaying = true
        const tl2 = gsap.timeline()
        tl2.to(".stage2", { opacity: 0, duration: 1, x: "100%" }, 0).to(
          ".stage1",
          {
            opacity: 1,
            duration: 1,
            onComplete: () => {
              animationState = 1
              if (e.scroll() > e.start + 0 * (e.end - e.start))
                e.scroll(e.start + 0 * (e.end - e.start))
              obsSteps.disable()
              animationPlaying = false
            },
          },
          0
        )
        tl2
          .to(".steps-2", { opacity: 0, duration: 1 }, 0)
          .to(".steps-1", { opacity: 1, duration: 0 }, 0)
      }
    }
    let triggerSteps = ScrollTrigger.create({
      id: "st-2",
      trigger: "#steps",
      start: "top top+=69px",
      end: "+=4000px",
      pin: true,
      anticipatePin: 1,
      onEnter: e => {
        animationState = 1
        obsSteps.enable()
      },
      onEnterBack: e => {
        obsSteps.enable()
      },
    })
    ScrollTrigger.normalizeScroll(true)
    //<--------------------Steps Animation End-------------------->

    return () => {
      if (triggerSteps) {
        ScrollTrigger.normalizeScroll(false)
        ScrollTrigger.killAll()
        if (obsSteps) obsSteps.kill()
        if (Observer.getById("ob-2")) Observer.getById("ob-2").kill()
      }
    }
  }, [resetPage, pageLoaded])

  const handleBackToTop = () => {
    if (window !== "undefined") {
      ScrollTrigger.getById("st-2").scroll(0)
      ScrollTrigger.killAll()
      setResetPage(!resetPage)
    }
  }

  const downloadPdf = () => {
    const save = document.createElement("a")
    save.href = whitepaperPdf
    save.download = "Levitate Whitepaper — Scaling Metrics Maturity"
    save.target = "_blank"
    document.body.appendChild(save)
    save.click()
    document.body.removeChild(save)
  }

  return (
    <Layout>
      <div
        style={{ backgroundColor: "#E1DED1" }}
        className=""
        id="blockContainer"
      >
        <div className="section-panel overflow-section">
          <div className="hero-container  panel flex snap-start items-start justify-center">
            <div
              className={`hero-wrapper h-[237px] ${
                pageLoaded ? "opacity-1" : "absolute z-[-1] opacity-0"
              }`}
            >
              <video
                autoPlay
                muted
                playsInline
                className={`hero-animation-zoomed-in animation first-animation`}
                loop={true}
                onPlay={() => setPageLoaded(true)}
              >
                <source src={obj[0].src} type={obj[0].type} />
                <source src={obj[1].src} type={obj[1].type} />
              </video>
            </div>
          </div>

          <div className={`hero-text ${pageLoaded ? "block" : "hidden"}`}>
            <h1 className="hero-head">
              Scaling Metrics
              <br /> Maturity in a<br /> Cloud-Native World
            </h1>
            <div className="mb-[30px]">
              <p className="font-normal">
                Today, technology-focused companies across all industries -
                SaaS, Financial Services, Media, and E-commerce - rely on cloud
                infrastructure and Microservices to deliver value to the
                customer and, by extension, profits for the business.
              </p>
            </div>
            <div>
              <p className="font-normal">
                Building observable systems help manage, scale and predict
                customer experiences for business continuity. Observability is
                about questions that vary from uptime checks, to complex query
                profitability. Therefore, observability is a first-class
                engineering citizen in an org.
              </p>
            </div>
          </div>

          <div className={`shimmer ${pageLoaded ? "hidden" : "block"}`}>
            <Shimmer>
              <div className="shimmer-video"></div>
            </Shimmer>
            <div className="skeleton">
              <Skeleton
                width={"100%"}
                height={70}
                style={{ marginBottom: 30 }}
              />
              <Skeleton
                width={"100%"}
                height={30}
                style={{ marginBottom: 5 }}
                count={5}
              />
              <div style={{ marginTop: 30 }}>
                <Skeleton
                  width={"100%"}
                  height={30}
                  style={{ marginBottom: 5 }}
                  count={5}
                />
              </div>
            </div>
          </div>
        </div>
        {pageLoaded ? (
          <>
            <div className="conveyor-section">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div className="relative min-h-[52vh]">
                  <div className="videojs-3 video-wrapper">
                    <ReactPlayer
                      playing
                      autoPlay={true}
                      muted
                      loop={true}
                      playsinline
                      ref={conveyorRef3}
                      id="video-s3"
                      url={[
                        { src: conveyorVideo, type: "video/webm" },
                        { src: conveyorVideoMp4, type: "video/mp4" },

                        // { src: whitepaperDotLottie.conveyorS3, type: "video/mp4" }
                      ]}
                      className="conveyor-video animation"
                    ></ReactPlayer>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <p className="hero-text convey-txt block">
                As an organization climbs the ladder of reliability, metrics and
                breadth of questions increase. Modern Time Series systems don’t
                have to grow along a single axis of Cardinality, Coverage, or
                Retention alone. Instead, the rate of ingestion and exploration
                warrants an expansion on all three axes.
              </p>
              <div className="hero-text relative h-fit w-full">
                <p
                  className="hero-text no-padding"
                  style={{ marginBottom: "0px" }}
                >
                  <b> 1. Coverage: </b>More metrics are to be observed
                </p>
                <p
                  className="hero-text no-padding"
                  style={{ marginBottom: "0px" }}
                >
                  <b> 2. Retention: </b>Save metrics for a longer duration
                </p>
                <p
                  className="hero-text no-padding"
                  style={{ marginBottom: "0px" }}
                >
                  <b> 3. Cardinality: </b>Same metrics for more entities
                </p>
              </div>
            </div>

            <div
              className=""
              id="steps"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
              }}
            >
              <div className=" h-[100%] max-w-6xl items-center">
                <div className="items-center">
                  <p className="hero-text no-margin">
                    The outcomes people want to achieve with Observability
                    differ for different company stages. We broadly classified
                    organisations into three stages.
                  </p>
                </div>
                <div className="relative h-[100%] w-full overflow-hidden">
                  <div className="text-stages stage1">
                    <p className="third-text" style={{ marginTop: "20px" }}>
                      🧱 STAGE 1: The Initial Stages.
                    </p>
                    <div className="px-[3%]">
                      <p className="text-strip1" style={{ marginTop: "10px" }}>
                        &nbsp;•&nbsp;5 engineers&nbsp;•&nbsp;10
                        customers&nbsp;•&nbsp;5 services
                      </p>
                    </div>
                  </div>
                  <div className="text-stages stage2">
                    <p className="third-text" style={{ marginTop: "20px" }}>
                      🏃 STAGE 2: Operational Readiness.
                    </p>
                    <div className="px-[3%]">
                      <p className="text-strip1" style={{ marginTop: "10px" }}>
                        &nbsp;•&nbsp;30 engineers &nbsp;•&nbsp;50 customers
                        &nbsp;•&nbsp;20 services
                      </p>
                    </div>
                  </div>
                  <div className="text-stages stage3">
                    <p className="third-text" style={{ marginTop: "20px" }}>
                      🧠 STAGE 3: Complete Organizational Intelligence
                    </p>
                    <div className="px-[3%]">
                      <p className="text-strip1" style={{ marginTop: "10px" }}>
                        &nbsp;•&nbsp;100+ engineers&nbsp;•&nbsp;1000+
                        customers&nbsp;•&nbsp;100+ services
                      </p>
                    </div>
                  </div>
                </div>

                <div className="steps">
                  <ReactPlayer
                    playing
                    muted
                    playsinline
                    id="steps-1"
                    ref={stepsref1}
                    url={[
                      {
                        src: whitepaperDotLottie.step1webm,
                        type: "video/webm",
                      },
                      { src: whitepaperDotLottie.step1, type: "video/mp4" },
                    ]}
                    loop={true}
                    className="steps-1 steps-animation animation"
                  ></ReactPlayer>
                  <ReactPlayer
                    playing={true}
                    muted
                    playsinline
                    id="steps-1to2"
                    ref={stepsref1to2}
                    url={[
                      {
                        src: whitepaperDotLottie.step1To2webm,
                        type: "video/webm",
                      },
                      {
                        src: whitepaperDotLottie.step1To2,
                        type: "video/mp4",
                      },
                    ]}
                    className="steps-1to2 steps-animation animation"
                    loop={true}
                  ></ReactPlayer>
                  <ReactPlayer
                    playing
                    muted
                    playsinline
                    id="steps-2"
                    ref={stepsref2}
                    className="steps-2 steps-animation animation"
                    url={[
                      {
                        src: whitepaperDotLottie.step2webm,
                        type: "video/webm",
                      },
                      { src: whitepaperDotLottie.step2, type: "video/mp4" },
                    ]}
                    loop={true}
                  ></ReactPlayer>
                  <ReactPlayer
                    playing={true}
                    muted
                    playsinline
                    id="steps-2to3"
                    ref={stepsref2to3}
                    className="steps-2to3 steps-animation animation"
                    url={[
                      {
                        src: whitepaperDotLottie.step2To3webm,
                        type: "video/webm",
                      },
                      {
                        src: whitepaperDotLottie.step2To3,
                        type: "video/mp4",
                      },
                    ]}
                    loop={true}
                  ></ReactPlayer>
                  <ReactPlayer
                    playing
                    muted
                    playsinline
                    id="steps-3"
                    ref={stepsref3}
                    url={[
                      {
                        src: whitepaperDotLottie.step3webm,
                        type: "video/webm",
                      },
                      { src: whitepaperDotLottie.step3, type: "video/mp4" },
                    ]}
                    className="steps-3 steps-animation animation"
                    loop={true}
                  ></ReactPlayer>
                </div>
              </div>
            </div>
            <div className="section-panel" id="levitate">
              <div className="float-wrapper">
                <h3 className="float-text text-center text-lg">Introducing</h3>
                <img
                  src={Levitatevertical}
                  className="mb-2 text-center"
                  alt="Levitate"
                  style={{ width: "250px", margin: "0 auto", display: "block" }}
                />
                <p
                  className="float-text text-center"
                  style={{ fontSize: "28px", marginTop: "40px" }}
                >
                  A time series warehouse built to manage scale
                </p>
                {/* <Lottie
                  animationData={cubeFloating}
                  style={{ width: 44 }}
                  play loop
                  className="float-animaton absolute top-0 left-[10%] z-[30]"
                />

                <Lottie
                  animationData={cubeFloating}
                  style={{ width: 44 }}
                  play loop
                  className="float-animaton absolute top-[80%] right-[60%] z-[30]"
                />
                <Lottie
                  animationData={cubeFloating}
                  style={{ width: 44 }}
                  play loop
                  className="float-animaton absolute bottom-[10%] left-[5%] z-[30]"
                />
                <Lottie
                  animationData={cubeFloating}
                  style={{ width: 44 }}
                  play loop
                  className="float-animaton absolute bottom-[20%] right-[6%] z-[30]"
                />
                <Lottie
                  animationData={cubeFloating}
                  style={{ width: 44 }}
                  play loop
                  className="float-animaton absolute top-[4%] right-[40%] z-[30]"
                />
                <Lottie
                  animationData={cubeFloating}
                  style={{ width: 44 }}
                  play loop
                  className="float-animaton absolute bottom-[12%] right-[40%] z-[30]"
                />
                <Lottie
                  animationData={cubeFloating}
                  style={{ width: 44 }}
                  play loop
                  className="float-animaton absolute top-[2%] right-[4%] z-[30]"
                />
                <Lottie
                  animationData={cubeFloating}
                  style={{ width: 44 }}
                  play loop
                  className="float-animaton absolute bottom-[25%] left-[20%] z-[30]"
                /> */}
              </div>
            </div>
            <div className="section-panel tablet-height">
              <h1 className="tiered-head">Tiered Storage</h1>
              <p className="hero-text1">
                Inspired by principles of Data Warehousing, where Data Tiering
                is a common phenomenon, Levitate introduces tiers for the Time
                Series storage. You don’t just pay extra for what you don’t use;
                you pay extra because of what you don’t use.
              </p>
              <div className="relative mt-[60px]">
                <ReactPlayer
                  playing
                  muted
                  playsinline
                  className="hero-animation-zoomed-in animation tiered-storage block-float"
                  url={[
                    {
                      src: whitepaperDotLottie.tieredStoragewebm,
                      type: "video/webm",
                    },
                    {
                      src: whitepaperDotLottie.tieredStorage,
                      type: "video/mp4",
                    },
                  ]}
                  loop={true}
                ></ReactPlayer>
                <div className="tier-float cold">
                  <h3 className="tier-float-head">Cold</h3>
                  <p>
                    Query Depth: <b>3 months</b>
                  </p>
                  <p>
                    Concurrency: <b>100</b>
                  </p>
                </div>
                <div className="tier-float hot">
                  <h3 className="tier-float-head">Hot</h3>
                  <p>
                    Query Depth: <b>7 days</b>
                  </p>
                  <p>
                    Concurrency: <b>1000</b>
                  </p>
                </div>
                <div className="tier-float blaze">
                  <h3 className="tier-float-head">Blaze</h3>
                  <p>
                    Query Depth: <b>1 hour</b>
                  </p>
                  <p>
                    Concurrency: <b>5000</b>
                  </p>
                </div>
              </div>
              <br />
            </div>
            <div className="">
              <div style={{ verticalAlign: "middle", display: "table-cell" }}>
                <div>
                  <h1 className="tiered-head">Levitate Engines</h1>
                  <div className="hero-text">
                    <div className="">
                      <div className="">
                        <h3 className="levitate-head"> Policy Engine</h3>
                        <p className="levitate-head-1">
                          Capability to express, evaluate data storage, and
                          access rules.
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <h3 className="levitate-head">Query-Routing Engine</h3>
                        <p className="levitate-head-1">
                          Based on tokens + source, a query routing engine
                          routes queries to their Rightful tiers. The overhead
                          of evaluation is minimal.
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <h3 className="levitate-head">Consumption Engine</h3>
                        <p className="levitate-head-1">
                          The consumption engine keeps track of metrics being
                          consumed, and makes Them available to all other
                          engines.
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <h3 className="levitate-head">Sync Engine</h3>
                        <p className="levitate-head-1">
                          Separating write and read channels, guaranteeing
                          ingestion no matter how heavy The read loads are.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div className="flex w-full items-center justify-center">
                  <ReactPlayer
                    playing
                    muted
                    playsinline
                    className="hero-animation-zoomed-in animation block-float levitate-engine"
                    url={[
                      {
                        src: whitepaperDotLottie.levitateEngineswebm,
                        type: "video/webm",
                      },
                      {
                        src: whitepaperDotLottie.levitateEngines,
                        type: "video/mp4",
                      },
                    ]}
                    loop={true}
                  ></ReactPlayer>
                </div>
              </div>
            </div>
            <div className="mt-auto flex w-full flex-col items-center justify-center gap-6 px-4 pb-20 pt-10 text-center">
              <h1 className="text-2xl font-black text-brand-blue-100">
                <Balancer>Want to know more? Download the Whitepaper.</Balancer>
              </h1>
              <p className="text-brand-blue-100/75">
                Find out more about how the scale of metrics and breadth of
                questions grow as companies mature, the challenges as your
                business climbs the ladder of reliability and Levitate’s managed
                time-series offering to scale with a software business growth.
              </p>
              <button
                class="downloadWhitepaper w-fit rounded-full bg-brand-blue-100 px-5 py-3 font-bold text-white hover:bg-brand-blue-200"
                onClick={downloadPdf}
              >
                Download Whitepaper
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </Layout>
  )
}

export default FullpageReact
