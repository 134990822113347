import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

export const SkeletonLine = ({ className }) => {
  return <div className={clsx("shine rounded", className)}></div>
}

SkeletonLine.propTypes = {
  className: PropTypes.string,
}
