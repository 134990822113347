import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const AlertStudio = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 md:flex-row-reverse lg:gap-16">
      {/** Left partition */}
      <div className="flex flex-col items-center gap-8 text-slate-600 md:basis-1/2 md:items-start">
        <div className="flex flex-col gap-6 text-base md:text-xl">
          <h3 className="text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl lg:max-w-[50%]">
            Alert Studio
          </h3>
          <div className="flex flex-col gap-4 text-base md:text-xl">
            <p>
              A monitoring experience optimized for reducing alert noise, faster
              isolation, and faster recovery.
            </p>
            <p>
              Built on the tenets of Actionability, Correlations, and
              Reliability.
            </p>
          </div>
        </div>
        <span className="flex gap-2">
          <Link
            to="/alert-studio"
            className="w-fit rounded-full bg-brand-green-200 px-6 py-4 text-center text-base font-bold text-white"
          >
            Elevate your Monitoring
          </Link>
        </span>
      </div>
      {/** Left partition ends */}
      {/** Right partition starts */}
      <div className="flex h-full w-full items-center justify-center overflow-hidden md:w-auto md:basis-1/2">
        <StaticImage
          src="../../../static/microsites/alert-studio-hero.png"
          alt="data policies on levitate"
          loading="eager"
          className="h-auto w-full"
        />
      </div>
      {/** Right partition ends */}
    </div>
  )
}

export default AlertStudio
