import React from "react"

const Thunder = () => (
  <svg width="21" height="31" viewBox="0 0 21 31" fill="none">
    <path
      d="M16.7062 0.132598C16.8931 0.242523 17.0377 0.413501 17.1164 0.6176C17.1951 0.821699 17.2033 1.04685 17.1396 1.25628L13.7014 12.5939H20.0451C20.2315 12.5938 20.4139 12.6491 20.5697 12.753C20.7255 12.857 20.8479 13.0049 20.9218 13.1786C20.9957 13.3523 21.0179 13.5441 20.9856 13.7305C20.9532 13.9168 20.8679 14.0894 20.74 14.2271L5.46782 30.6949C5.32005 30.8544 5.12341 30.9584 4.90995 30.9899C4.6965 31.0214 4.47881 30.9787 4.29238 30.8685C4.10594 30.7584 3.96175 30.5875 3.88331 30.3836C3.80487 30.1796 3.7968 29.9548 3.86042 29.7456L7.29857 18.4061H0.954892C0.768466 18.4061 0.586099 18.3508 0.430304 18.2469C0.274508 18.143 0.152104 17.9951 0.0782022 17.8214C0.00430023 17.6477 -0.0178645 17.4558 0.014444 17.2695C0.0467524 17.0832 0.13212 16.9105 0.260008 16.7728L15.5322 0.305026C15.6798 0.145771 15.8761 0.0418677 16.0893 0.0102106C16.3024 -0.0214465 16.5199 0.0210044 16.7062 0.130661V0.132598Z"
      fill="#FFAF20"
    />
  </svg>
)

export default Thunder
