import React from "react"

const Books = () => {
  return (
    <svg viewBox="0 0 162 159" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_546_7326)">
        <path
          d="M96.8956 74.8511L55.284 105.964C54.9271 106.174 54.6023 106.191 54.3679 106.056L111.84 139.762C112.075 139.902 112.399 139.881 112.756 139.67L154.368 115.35L96.8956 74.8511Z"
          fill="black"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M152.368 115.02L111.368 138.851V124.767C111.368 122.86 112.382 121.093 114.024 120.137L152.368 97.8511V115.026V115.02Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M53.9845 107.212C53.6071 106.991 53.3732 106.518 53.3679 105.851L92.7788 127.097L110.858 139.549C110.858 140.216 110.991 140.631 111.368 140.851L53.9845 107.212Z"
          fill="#273747"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M111.254 124.851L90.3206 114.484L53.3679 91.4877C53.3679 90.1513 54.3099 88.522 55.4821 87.8511L96.8987 105.507L113.368 121.214C112.196 121.885 111.248 123.52 111.254 124.851Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M112.792 121.851L55.3679 88.1601L96.9443 63.8511L154.368 97.542L112.792 121.851Z"
          fill="#273747"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M110.352 125.113L53.3679 91.8511L53.3785 105.589L110.368 138.851L110.352 125.113Z"
          fill="#273747"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M153.342 96.8511V97.7951L112.431 121.855C111.73 122.265 111.169 123.263 111.169 124.079L111.18 137.882C111.18 138.698 111.75 139.028 112.452 138.618L153.368 114.558V115.502L112.457 139.562C111.311 140.234 110.378 139.695 110.378 138.356L110.368 124.553C110.368 123.215 111.29 121.583 112.436 120.911L153.352 96.8511H153.342Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M124.368 126.851C126.316 125.877 130.047 124.256 133.368 121.851C129.905 123.041 126.349 125.641 124.368 126.851Z"
          fill="black"
        />
        <path
          d="M128.344 123.858L126.368 125.03C133.287 125.046 135.518 129.652 136.302 131.851L136.826 130.107L138.368 130.712C138.368 130.712 134.967 123.591 128.35 123.858H128.344Z"
          fill="#FFAF1F"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M143.368 106.851L151.368 101.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M119.368 120.851L139.368 108.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M144.368 109.851L151.368 105.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M119.368 124.851L136.368 114.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M111.368 128.851L117.368 125.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M149.368 108.851L151.368 107.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M141.368 114.851L148.368 110.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M149.368 113.851L151.368 112.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M136.368 120.851L145.368 115.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M116.368 131.851L118.368 130.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M96.8948 52.8511L55.2841 83.9639C54.9272 84.1742 54.6023 84.1904 54.3679 84.0556L111.841 117.762C112.075 117.902 112.4 117.881 112.757 117.67L154.368 93.3517L96.8948 52.8511Z"
          fill="black"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M152.368 93.0258L111.368 116.851V102.767C111.368 100.86 112.382 99.0929 114.025 98.1366L152.368 75.8511V93.0204V93.0258Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M53.9847 85.2119C53.6072 84.9914 53.3732 84.518 53.3679 83.8511L92.7807 105.097L110.858 117.549C110.858 118.216 110.99 118.631 111.368 118.851L53.9793 85.2119H53.9847Z"
          fill="#FFAF1F"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M111.259 102.846L90.3219 92.4828L53.3679 69.4882C53.3679 68.1516 54.3101 66.5221 55.4825 65.8511L96.9011 83.5044L113.368 99.2139C112.201 99.8849 111.253 101.52 111.253 102.851L111.259 102.846Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M112.79 99.8511L55.3679 66.1592L96.9457 41.8511L154.368 75.5429L112.79 99.8511Z"
          fill="#FFAF1F"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M96.8948 36.8511L55.2841 67.9639C54.9272 68.1742 54.6023 68.1904 54.3679 68.0556L111.841 101.762C112.075 101.902 112.4 101.881 112.757 101.67L154.368 77.3517L96.8948 36.8511Z"
          fill="black"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M152.368 77.0258L111.368 100.851V86.7666C111.368 84.8595 112.382 83.0929 114.025 82.1366L152.368 59.8511V77.0204V77.0258Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M53.9847 69.2119C53.6072 68.9914 53.3732 68.518 53.3679 67.8511L92.7807 89.0974L110.858 101.549C110.858 102.216 110.99 102.631 111.368 102.851L53.9793 69.2119H53.9847Z"
          fill="#FFAF1F"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M111.259 86.8458L90.3219 76.4828L53.3679 53.4882C53.3679 52.1516 54.3101 50.5221 55.4825 49.8511L96.9011 67.5044L113.368 83.2139C112.201 83.8849 111.253 85.5198 111.253 86.8511L111.259 86.8458Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M112.79 83.8511L55.3679 50.1592L96.9457 25.8511L154.368 59.5429L112.79 83.8511Z"
          fill="#FFAF1F"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M93.3484 27.6465L47.5059 51.743C47.1214 51.8938 46.7982 51.8579 46.5875 51.6872L98.1583 94.1795C98.3682 94.3555 98.6922 94.3861 99.0768 94.2353L143.878 76.8527L93.3484 27.6465Z"
          fill="black"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M142.21 76.3759L98.0881 93.3673L100.245 79.449C100.537 77.5644 101.809 75.9807 103.577 75.2983L144.841 59.4039L142.211 76.3706L142.21 76.3759Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M46.7682 52.2944C46.4294 52.0161 46.271 51.511 46.3679 50.8511L82.0192 78.1439L97.9561 93.3372C97.8539 93.9963 97.9216 94.4268 98.2605 94.7051L46.763 52.2935L46.7682 52.2944Z"
          fill="#26D7A0"
          stroke="black"
          strokeWidth="0.248427"
          strokeMiterlimit="10"
        />
        <path
          d="M99.8472 79.6592L80.767 66.0731L47.8107 37.4453C48.0155 36.1244 49.1952 34.6647 50.4553 34.189L88.6373 58.2519L102.486 76.4072C101.231 76.8838 100.045 78.348 99.8411 79.6636L99.8472 79.6592Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248427"
          strokeMiterlimit="10"
        />
        <path
          d="M102.166 76.3409L50.6436 33.8722L95.4116 16.4941L146.934 58.9628L102.166 76.3409Z"
          fill="#26D7A0"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M143.368 84.8511L151.368 79.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M119.368 98.8511L139.368 86.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M144.368 87.8511L151.368 83.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M137.368 90.8511L144.368 86.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M111.368 106.851L117.368 103.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M149.368 86.8511L151.368 85.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M141.368 91.8511L148.368 87.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M125.368 102.851L142.368 92.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M149.368 91.8511L151.368 90.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M136.368 98.8511L145.368 93.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M116.368 109.851L118.368 108.851"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M110.352 102.11L53.3679 68.8511L53.3785 82.5868L110.368 115.851L110.352 102.11Z"
          fill="#E8B551"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M153.342 74.8511V75.7952L112.432 99.8524C111.73 100.263 111.169 101.261 111.169 102.077L111.18 115.882C111.18 116.698 111.751 117.028 112.452 116.618L153.368 92.5605V93.5047L112.458 117.562C111.316 118.234 110.378 117.695 110.378 116.356L110.368 102.551C110.368 101.213 111.29 99.5803 112.437 98.9082L153.352 74.8511H153.342Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M27.5393 31.1541C25.568 30.3425 23.9148 31.2181 23.8671 33.1029L23.369 87.6339C23.3213 89.5187 24.9004 91.7292 26.8716 92.5408L68.8873 109.851L75.3679 50.9418L69.5549 48.4696L27.5393 31.1594V31.1541Z"
          fill="black"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M24.9647 31.8511L24.3679 90.8727L67.8189 112.851L71.0322 110.154L71.3679 52.198L67.9255 51.385L24.9647 31.8511Z"
          fill="#BEBE8C"
        />
        <path
          d="M20.3679 36.8511V97.5696L65.7735 114.851L66.3679 56.0008L20.3679 36.8511Z"
          fill="#D4D4D4"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M68.2741 115.634L72.3679 111.873L25.75 92.8511L20.3679 97.9346C30.0248 103.034 54.5076 121.759 68.2741 115.634Z"
          fill="#BEBE8C"
        />
        <path
          d="M67.5792 54.6287L74.3679 52.4774L25.6719 31.8511L20.3679 36.9346C29.8847 42.0341 54.0123 60.7587 67.5792 54.634V54.6287Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M55.3679 51.8511C59.8482 53.5073 64.3392 54.2386 70.3679 54.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M50.3679 49.8511C51.3851 50.225 52.3817 50.5554 53.3679 50.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M27.3679 37.8511C28.2886 38.4622 29.2991 39.1289 30.3679 39.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M26.3679 36.8511C26.99 37.1598 27.6589 37.4954 28.3679 37.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M51.3679 52.8511C56.4445 55.094 61.8669 56.3785 67.3679 55.6436"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M47.3679 50.8511C48.0256 51.1966 48.6922 51.5317 49.3679 51.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M28.3679 39.8511C29.5849 40.4406 30.9315 41.1043 32.3679 41.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M21.3679 35.8511C21.3679 35.8511 24.1308 37.2514 28.3679 39.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M54.3679 48.8511C59.9342 51.1876 65.3603 52.6588 71.3679 52.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M51.3679 47.8511C52.0374 48.2009 52.7027 48.5327 53.3679 48.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M38.3679 40.8511C40.4659 41.9133 42.6112 43.0597 44.6619 44.2376C46.2656 45.1631 47.8325 46.036 49.3679 46.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M28.3679 35.8511C30.3837 36.6762 32.7983 37.7 35.3679 38.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M23.3679 33.8511C23.3679 33.8511 24.506 34.2135 26.3679 34.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M61.3679 49.8511C66.0022 51.6672 70.3529 52.8568 73.3679 52.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M59.3679 49.8511C60.0392 50.1984 60.7059 50.5337 61.3679 50.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M55.3679 47.8511C56.691 48.5552 58.0346 49.2298 59.3679 49.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M24.3679 32.8511C24.3679 32.8511 31.3365 35.7073 38.3679 38.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M34.6291 94.6947C34.6239 92.4613 34.6661 89.2939 34.7188 85.6218C34.856 76.0604 35.046 62.9666 34.4867 53.883C34.3178 51.1719 34.3494 48.2675 35.7109 45.3792C36.5975 43.5056 37.9431 41.8521 39.8059 40.3704C40.4708 39.8443 41.1991 39.3396 41.9959 38.8511L49.3679 42.0615C48.5711 42.5446 47.8429 43.0546 47.1779 43.5808C45.3152 45.0625 43.9695 46.7106 43.083 48.5896C41.7215 51.4779 41.6898 54.3823 41.8587 57.0934C42.4181 66.1823 42.2281 79.2708 42.0909 88.8322C42.0381 92.4989 41.9959 95.6717 42.0012 97.9051C42.0328 108.186 40.7558 112.803 40.74 112.851L33.3679 109.641C33.3838 109.598 34.6608 104.975 34.6291 94.6947Z"
          fill="#273747"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M55.4724 58.1977C65.0262 57.2982 67.3679 54.6854 67.3679 54.6854V115.24C67.3679 115.24 65.0262 117.853 55.4724 118.753C45.9185 119.652 33.0741 114.293 23.3679 106.406V45.8511C33.0741 53.7377 45.9185 59.0971 55.4724 58.1977Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M75.9182 52.719L74.3679 50.8511L75.8968 116.027L101.16 141.249C102.347 142.434 103.341 141.815 103.368 139.872L103.293 83.6309C103.32 81.6883 102.368 79.1265 101.182 77.947L75.9182 52.7244V52.719Z"
          fill="black"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M25.5308 30.417C25.8149 30.1546 26.1727 29.9725 26.5831 29.8975C27.1409 29.7904 27.7933 29.8654 28.4879 30.1546C28.4879 30.1546 71.4398 48.0905 72.9867 48.8189C74.5337 49.5472 76.1648 50.3827 77.3171 51.5717L102.216 76.9947C102.81 77.5999 103.342 78.5585 103.721 79.6082C104.089 80.6312 104.305 81.7398 104.294 82.6984L104.368 139.136C104.352 140.244 104.236 140.625 103.542 141.085L102.29 141.851C102.621 141.557 102.826 140.951 102.842 140.073L102.768 83.6357C102.779 82.677 102.563 81.563 102.195 80.5455C101.816 79.4904 101.285 78.5318 100.69 77.9319L75.8228 52.6214L75.6755 52.4714C74.1496 50.9183 68.9457 48.76 68.9457 48.76L27.325 31.0972C26.6305 30.808 25.978 30.733 25.4203 30.8401C25.0099 30.9205 24.6521 31.0972 24.3679 31.3596L25.5308 30.417Z"
          fill="#E50019"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M74.3679 52.8511L74.384 113.749L75.9226 115.289L102.368 141.851L102.346 80.958L75.9011 54.391L74.3679 52.8511Z"
          fill="#BEBE8C"
        />
        <path
          d="M64.3679 59.8511L92.9878 83.2927L93.0035 132.328L97.2878 136.698C99.2357 138.684 98.4324 142.068 95.8125 142.925L94.6206 143.316C94.0956 143.487 93.5443 143.535 93.0088 143.46V143.851L92.3315 143.3C92.0532 143.203 91.7854 143.08 91.5387 142.919C78.5807 134.668 64.3312 127.295 64.3732 120.393V59.8511H64.3679Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M93.3899 143.851L102.368 141.061L102.346 80.8511L93.3679 83.6409L93.3899 143.851Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M92.846 83.8457L101.368 81.0177L78.634 57.541L74.0987 52.8511L67.3375 55.0422C56.8144 63.7421 76.382 73.041 92.8512 83.8511L92.846 83.8457Z"
          fill="white"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M33.3654 154.211L33.1529 140.447C33.1529 140.447 33.1025 139.436 32.5658 140.048C32.0291 140.659 32.7207 157.87 33.0304 158.732C33.3402 159.594 33.3798 155.546 33.3654 154.211Z"
          fill="white"
        />
        <path
          d="M67.3679 81.8511C71.6358 86.5689 80.0097 91.5271 88.3679 96.8511"
          stroke="#8F9E9E"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3679 86.8511C71.6358 91.5689 80.0097 96.5271 88.3679 101.851"
          stroke="#8F9E9E"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3679 90.8511C71.6358 95.569 80.0097 100.527 88.3679 105.851"
          stroke="#8F9E9E"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3679 95.8511C71.6358 100.569 80.0097 105.527 88.3679 110.851"
          stroke="#8F9E9E"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3679 99.8511C71.6358 104.569 80.0097 109.527 88.3679 114.851"
          stroke="#8F9E9E"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3679 104.851C71.6358 109.569 80.0097 114.527 88.3679 119.851"
          stroke="#8F9E9E"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3679 108.851C71.6358 113.569 80.0097 118.527 88.3679 123.851"
          stroke="#8F9E9E"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3679 113.851C71.6358 118.569 80.0097 123.527 88.3679 128.851"
          stroke="#8F9E9E"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M86.3679 76.8511C89.306 78.8062 92.3646 80.8044 95.3679 82.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M71.3679 65.8511C71.9868 66.5096 72.6518 67.1742 73.3679 67.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M68.3739 54.8511C64.8594 58.2421 66.2395 61.9181 70.3679 65.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M92.3679 78.8511C94.0693 80.1624 95.7488 81.4977 97.3679 82.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M73.3679 64.8511C73.6843 65.1798 74.0203 65.5132 74.3679 65.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M70.3679 61.8511C70.678 62.1844 71.0113 62.5177 71.3679 62.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M70.3679 61.8511C70.6777 62.1821 71.0139 62.52 71.3679 62.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M70.06 53.8511C67.5367 56.0065 67.9985 58.3415 70.3679 60.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M93.3679 77.8511C94.6965 79.1332 96.0393 80.463 97.3679 81.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M78.3679 64.8511C79.3143 65.4943 80.3164 66.1626 81.3679 66.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M71.0985 53.8511C69.0326 56.4576 71.523 59.709 76.3679 63.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M96.3679 78.8511C97.3344 79.8093 98.3344 80.8073 99.3679 81.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M85.3679 67.8511C87.8995 70.3056 90.8826 73.2434 94.3679 76.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M78.3679 61.8511C79.9816 63.4924 81.9669 65.4357 84.3679 67.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M75.3679 58.8511C75.6692 59.1683 75.9997 59.5017 76.3679 59.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M72.3679 53.8511C72.586 55.5763 73.5172 57.0484 75.3679 58.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M95.3679 75.8511L100.368 80.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M93.3679 74.8511L95.3679 76.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M85.3679 65.8511L91.3679 71.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M73.3679 52.8511L82.3679 61.8511"
          stroke="black"
          strokeWidth="0.248428"
          strokeMiterlimit="10"
        />
        <path
          d="M31.9996 85.0835L32.1159 83.9204L42.4201 83.0002L42.234 84.8612L34.3496 85.3692L34.4223 85.2768L34.3932 85.5676L34.3438 85.4274L41.5359 91.8397L41.3497 93.7007L31.9996 85.0835ZM55.9896 94.4784L45.6854 95.3986L45.8715 93.5376L53.7559 93.0296L53.6832 93.122L53.7123 92.8312L53.7617 92.9714L46.5696 86.5591L46.7558 84.6981L56.1059 93.3153L55.9896 94.4784Z"
          fill="url(#paint0_linear_546_7326)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_546_7326"
          x1="35.8841"
          y1="85.9826"
          x2="62.9078"
          y2="104.897"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_546_7326">
          <rect
            width="161"
            height="158"
            fill="white"
            transform="translate(0.36792 0.851074)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Books
