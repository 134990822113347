import React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import Hero from "../components/high-cardinality/Hero"
import HighCardinalityIsMust from "../components/high-cardinality/HighCardinalityIsMust"
import NeverLoseData from "../components/high-cardinality/NeverLoseData"
import StreamAggregationFTW from "../components/high-cardinality/StreamingAggregationFTW"
import PreFooter from "../components/home/PreFooter"
import ClientLogosQueue from "../components/common/ClientLogosQueue"

export default function LandingPage() {
  return (
    <Layout>
      {/** Hero section */}
      <section className="flex flex-col px-4 md:px-6 md:pt-14 lg:pt-20 xl:px-0">
        <Hero />
      </section>

      {/** Content section*/}
      <section className="flex flex-col items-center gap-28 bg-gradient-to-b from-yellow-50 to-white px-4 pb-28 md:gap-40 md:px-6 md:pb-40 lg:mx-0 xl:px-0">
        <div className="w-full lg:h-0">
          <ClientLogosQueue />
        </div>

        <HighCardinalityIsMust />
        <NeverLoseData />
        <StreamAggregationFTW />
      </section>

      <PreFooter />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Levitate — High Cardinality"
    description="Tame High Cardinality metrics with Cardinality Explorer, Cardinality Calculator and Streaming Aggregation Workflows"
    image="/thumbnails/thumbnail-last9-levitate.jpg"
  />
)
