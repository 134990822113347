import React from "react"
import { Link } from "gatsby"
import Balancer from "react-wrap-balancer"
import Integrations from "./Integrations"

const UnifiedDataWarehouse = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 md:flex-row lg:gap-16">
      {/** Left partition */}
      <div className="flex h-full w-full items-center justify-center overflow-hidden md:w-auto md:basis-1/2">
        <Integrations skipIntegrations={["Cortex", "Thanos", "Levitate"]} />
      </div>
      {/** Left partition ends */}

      {/** Right partition */}
      <div className="flex flex-col gap-6 text-base text-slate-600 md:basis-1/2 md:text-xl">
        <h3 className="max-w-md text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
          <Balancer>Unified Data Warehouse</Balancer>
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          <p>
            Send data via OpenTelemetry, OpenMetrics and from Prometheus,
            VictoriaMetrics, InfluxDB, Telegraf, Kafka, StatsD, and many more!
          </p>
          <p>
            With AWS CloudWatch, DataDog and other proprietary integrations, our
            customers move existing workloads to{" "}
            <Link
              to="/levitate-tsdb"
              className="underline decoration-slate-500 underline-offset-2"
            >
              Levitate
            </Link>{" "}
            with ease.
          </p>
          <p>
            Go through the growing list of{" "}
            <a
              className="underline decoration-slate-500 underline-offset-2"
              href="https://docs.last9.io/docs/integrations"
              target="_blank"
              rel="noreferrer"
            >
              Levitate’s integrations
            </a>
            .
          </p>
        </div>
      </div>
      {/** Right partition ends */}
    </div>
  )
}

export default UnifiedDataWarehouse
