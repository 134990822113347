import React from "react"
import Button from "../common/Button"
import SectionHead from "../common/SectionHead"
import Cube from "../../../static/icons/cube.inline.svg"
import Secure from "../../../static/icons/secure.inline.svg"
import CloudTick from "../../../static/icons/cloud-tick.inline.svg"
import Badge from "../../../static/icons/badge.inline.svg"
import QuestionMark from "../../../static/icons/question-mark.inline.svg"

import headerCupImg from "../../../static/icons/header-cup.png"

const Tooling = ({
  title = "Industry-standard tooling for everyone.",
  icon = headerCupImg,
  features = [
    {
      icon: <Cube className="h-6 w-6" />,
      title: "Managed to take the toil away",
      description:
        "We regularly handle 400M samples/min and 35M concurrent users for one of our biggest customers.",
    },
    {
      icon: <Secure className="h-6 w-6" />,
      title: "Compliance ready",
      description:
        "SOC2 Type 2 approved, with access controls, multi-region support, and OAuth-based SSO.",
    },
    {
      icon: <CloudTick className="h-6 w-6" />,
      title: "Deploy in your cloud",
      description:
        "Use AWS or GCP marketplaces to ease procurement, avail cloud credits, and forget about egress costs.",
    },
    {
      icon: <Badge className="h-6 w-6" />,
      title: "SLAs with clawbacks",
      description: "We commit to a 99.9% write and 99.5% read availability.",
    },
  ],
  quote = {
    text: "Reliable, easy to integrate, and exceptional customer support.",
    author: "— Aditya Chowdhry, Platform Lead, Probo",
  },
}) => {
  return (
    <section className="mx-auto flex w-full max-w-6xl flex-col gap-16 py-20">
      <SectionHead title={title} icon={icon} />
      <div className="flex flex-col gap-24 md:flex-row">
        <div className="grid basis-1/2 grid-flow-row grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <div className="col-span-2 flex flex-col md:col-span-1">
              {feature.icon}
              <h4 className="pt-4 text-xl font-bold text-slate-600">
                {feature.title}
              </h4>
              <p className="pt-2 text-base text-slate-500">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
        <div className="flex basis-1/2 flex-col justify-between gap-24 rounded-lg bg-blue-50 p-8">
          <div className="flex flex-col gap-1">
            <p className="translate-y-[65%] font-serif text-[40px] leading-5 text-blue-600">
              “
            </p>
            <p className="max-w-[368px] font-serif text-xl text-blue-600">
              {quote.text}
            </p>
            <p className="text-sm text-blue-400">{quote.author}</p>
          </div>
          <div className="flex flex-col items-start gap-4">
            <p className="max-w-[368px] text-slate-400">
              Questions? Need a demo? Help with migration? Need information you
              couldn’t find? Our customers think our support is fanatical.
            </p>
            <Button
              to="/schedule-demo"
              as="link"
              className="flex items-center gap-2 bg-blue-500 pl-4 pr-6 text-white"
            >
              <QuestionMark className="h-6 w-6" />
              <span>Talk to us</span>
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tooling
