import * as React from "react"
import Button from "../common/Button"
import heroImage from "../../../static/logs/logs-hero.png"
import IconLogs from "../../../static/icons/capability-logs.inline.svg"

const Hero = ({
  /**
   * Default title written in jsx. To control from cms pass string
   */
  capability = "Logs",
  title = "Cloud Native and OpenTelemetry Native Log Analytics",
  subtitle = "Stream, store, and analyze millions of logs per minute without worrying about things breaking, including your bank account.",
  primaryCta = {
    label: "Talk to Us",
    href: "/schedule-demo",
  },
  secondaryCta = {
    label: "Start for free",
    href: "https://app.last9.io",
  },
}) => {
  return (
    <section className="w-full bg-gradient-to-b from-emerald-100/25 to-emerald-100/0 px-6 pt-20">
      <div className="mx-auto flex w-full max-w-6xl flex-col items-center justify-center gap-20">
        {/* hero body */}
        <div className="relative z-10 flex w-full max-w-3xl flex-col items-center justify-center gap-6 text-center">
          {/* pill */}
          <span className="flex items-center justify-center gap-1 rounded-full border border-solid border-blue-600 bg-blue-50 px-2 py-1 text-xs font-semibold uppercase text-blue-600">
            <IconLogs className="h-4 w-4" />
            {capability}
          </span>
          {/* end pill */}

          <h1 className="max-w-full text-3xl font-extrabold text-slate-900 md:text-5xl md:font-bold">
            {title}
          </h1>
          <p className="max-w-full text-lg font-medium text-slate-500 md:text-2xl">
            {subtitle}
          </p>

          {/* buttons */}
          <div className="flex gap-4">
            <Button
              as="a"
              className="bg-slate-900 text-white"
              href={primaryCta.href}
            >
              {primaryCta.label}
            </Button>
            <Button
              as="a"
              className="bg-slate-100 text-slate-500"
              href={secondaryCta.href}
            >
              {secondaryCta.label}
            </Button>
          </div>
          {/* end buttons */}
        </div>
        {/* end hero body */}

        {/* hero image */}
        <div className="relative h-40 w-full overflow-hidden sm:h-60 xl:w-[calc(100%+80px)]">
          <img
            src={heroImage}
            className="w-full rounded-lg border border-solid border-slate-200"
          />
          <div className="absolute top-0 z-10 h-full w-full bg-gradient-to-b from-transparent to-white"></div>
        </div>
        {/* end hero image */}
      </div>
    </section>
  )
}

export default Hero
