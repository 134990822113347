import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { Link as ScrollLink } from "react-scroll"
import { SearchIcon, XIcon } from "@heroicons/react/outline"
import { PropTypes } from "prop-types"

export const CategoriesList = props => {
  const location = useLocation()

  const CategoryLink = ({ tag }) => (
    <Link to={`/blog/tag/${tag.slug}/`} key={tag.id}>
      <p
        key={tag.id}
        className={`cursor-pointer rounded-full border px-6 py-2 font-bold capitalize hover:bg-black hover:text-white
           ${
             location.pathname.split("/")[3] === tag.slug
               ? "border-black bg-black text-white"
               : "border-slate-300 bg-slate-100 text-black"
           }`}
      >
        {tag.name}
      </p>
    </Link>
  )

  return (
    <section className="bg-slate-50">
      <div className="mx-auto flex max-w-6xl flex-wrap gap-2 px-4 py-4 md:px-6 lg:py-8 xl:px-0">
        {props.tags.map(tag => (
          // Scroll in the same page or redirect to other page based on
          <CategoryLink tag={tag} key={tag.slug} />
        ))}
      </div>
      {/* Search & Tags - mobile */}
      {/* <div className="mx-auto ml-5 mr-5 mt-10 max-w-6xl md:hidden ">
        <div className="flex flex-row">
          <div className="flex flex-wrap">
            {props.tags.map(tag => (
              // Scroll in the same page or redirect to other page based on prop
              <CategoryLink tag={tag} key={tag.slug} />
            ))}
          </div>
        </div>
      </div> */}
      {/* Search & Tags - mobile */}
    </section>
  )
}

CategoriesList.propTypes = {
  clicked: PropTypes.bool,
  scrollType: PropTypes.string,
  active: PropTypes.string,
  tag: PropTypes.string,
  handleChange: PropTypes.func,
  tags: PropTypes.array,
  handleClick: PropTypes.func,
  inputRef: PropTypes.object,
}

CategoriesList.defaultProps = {
  clicked: false,
  scrollType: ``,
  active: ``,
  tag: ``,
  handleChange: () => {},
  tags: ``,
  handleClick: () => {},
  inputRef: {},
}

export const Categories = props => {
  const location = useLocation()

  const { clicked, scrollType = "link" } = props
  // On change of keyword, call handleChange function from parent to update the keyword
  const handleKeywordChange = e => {
    props.handleChange(e.target.value)
  }

  const CategoryLink = ({ tag }) => {
    if (scrollType === "link")
      return (
        <Link
          to={`/blog/tag/${tag.slug}/`}
          key={tag.id}
          className={`cursor-pointer whitespace-nowrap rounded-full border border-slate-300 bg-slate-100 px-6 py-2 text-sm font-bold capitalize text-black hover:bg-black hover:text-white lg:text-base
        ${
          location.pathname.split("/")[3] === tag.slug
            ? `bg-black text-white`
            : ""
        }`}
        >
          {tag.name}
        </Link>
      )
    else
      return (
        <ScrollLink
          className="flex-shrink-0 cursor-pointer whitespace-nowrap rounded-full border border-slate-300 bg-slate-100 px-6 py-2 text-sm font-bold capitalize text-black hover:bg-black hover:text-white lg:text-base"
          to={tag.slug}
          spy={true}
          smooth={true}
          duration={500}
        >
          {tag.name}
        </ScrollLink>
      )
  }

  return (
    <section className="bg-slate-50">
      {/* desktop category tags + search */}
      <div className="mx-auto flex max-w-6xl flex-col flex-nowrap justify-between px-4 py-4 md:flex-row md:px-6 lg:py-8 xl:px-0">
        {/* category tags */}
        <div className="-m-4 flex gap-2 overflow-x-auto p-4 md:m-0 md:p-0">
          {/* If search is clicked, hide tags else show all tags with search icon */}
          {!clicked &&
            props.tags.map(tag => (
              // Scroll in the same page or redirect to other page based on
              <CategoryLink tag={tag} key={tag.slug} />
            ))}

          {!clicked && (
            <Link
              to={`/blog/all/#articles`}
              className={`cursor-pointer rounded-full border px-6 py-2 text-sm font-bold capitalize hover:bg-black hover:text-white lg:text-base
            ${
              location.pathname.split("/")[2] === "all"
                ? "border-black bg-black text-white"
                : "border-slate-300 bg-slate-100 text-black"
            }`}
            >
              All
            </Link>
          )}
        </div>
        {/* end category tags */}

        {/* search */}
        <div
          className={`flex items-center transition-all duration-500 ${
            clicked ? "w-full" : "w-auto"
          }`}
        >
          {/* show search icon only if clicked is false */}
          {!clicked && (
            <div
              className="mt-6 flex cursor-pointer items-center gap-2 md:mt-0"
              onClick={() => props.handleClick(true)}
            >
              <SearchIcon className="h-5 w-5" />

              <span className="font-bold">Search</span>
            </div>
          )}

          {/* show text input if cliked is true */}
          {clicked && (
            <div
              className={`-mt-2 w-full items-center justify-between border-b-2 border-slate-400 ${
                clicked ? "flex" : "hidden"
              }`}
            >
              <input
                type="text"
                className={`${
                  clicked
                    ? "search-input grow border-none bg-slate-50 px-0 text-lg font-medium placeholder:text-slate-400 focus:shadow-none focus:ring-0 md:text-xl"
                    : "hidden"
                }`}
                placeholder="Search for a specific article"
                onChange={handleKeywordChange}
                ref={props.inputRef}
                autoFocus
              />
              <XIcon
                className="h-6 cursor-pointer px-2 text-slate-400"
                onClick={() => props.handleClick(false)}
              />
            </div>
          )}
        </div>
        {/* end search */}
      </div>
      {/* end desktop category tags + search */}
    </section>
  )
}

Categories.propTypes = {
  clicked: PropTypes.bool,
  scrollType: PropTypes.string,
  active: PropTypes.string,
  tag: PropTypes.string,
  handleChange: PropTypes.func,
  tags: PropTypes.array,
  handleClick: PropTypes.func,
  inputRef: PropTypes.object,
}

Categories.defaultProps = {
  clicked: false,
  scrollType: ``,
  active: ``,
  tag: ``,
  handleChange: () => {},
  tags: ``,
  handleClick: () => {},
  inputRef: {},
}
