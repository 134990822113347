import React from "react"
import ClientLogo from "./ClientLogo"

const ClientLogosQueue = () => {
  return (
    <div className="mx-auto grid w-full max-w-6xl -translate-y-12 grid-cols-2 divide-y divide-black/10 border border-black/10 bg-white lg:grid-cols-7 lg:divide-x lg:divide-y-0">
      <div className="col-span-2 grid grid-cols-2 divide-x divide-black/10">
        {/* TODO: Fix CSS */}
        <div className="flex h-24 items-center justify-center">
          <ClientLogo name="replit" size={48} />
        </div>

        <div className="flex h-24 items-center justify-center">
          <ClientLogo name="hotstar" size={48} />
        </div>
      </div>

      <div className="col-span-2 grid grid-cols-2 divide-x divide-black/10">
        <div className="flex h-24 items-center justify-center">
          <ClientLogo name="clevertap" size={48} />
        </div>

        <div className="flex h-24 items-center justify-center">
          <ClientLogo name="probo" size={48} />
        </div>
      </div>

      {/* <div className="col-span-2 grid grid-cols-1 divide-x divide-black/10 lg:col-span-1 lg:grid-cols-1"> */}
      <div className="col-span-2 grid grid-cols-2 divide-x divide-black/10">
        <div className="flex h-24 items-center justify-center">
          <ClientLogo name="quickwork" size={48} />
        </div>
        <div className="flex h-24 items-center justify-center">
          <ClientLogo name="circle" size={48} />
        </div>
      </div>

      <div className="col-span-2 grid grid-cols-1 divide-x divide-black/10 lg:col-span-1">
        <div className="flex h-24 items-center justify-center">
          <ClientLogo name="nxtjob" size={48} />
        </div>
      </div>
    </div>
  )
}

export default ClientLogosQueue
