/* eslint-disable react/prop-types */
import React from "react"
import Balancer from "react-wrap-balancer"

const Card = ({ title, children }) => {
  return (
    <div className="flex w-full flex-col gap-2 rounded-lg border border-solid border-red-50 bg-white p-6">
      <p className="text-base font-bold leading-6 text-red-500">{title}</p>
      <p className="text-base leading-6 text-slate-500">{children}</p>
    </div>
  )
}

const ManageCost = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl -mt-14 lg:mt-0 flex-col items-center gap-8">
      <h3 className="w-full max-w-2xl text-center text-4xl font-black tracking-tight text-black md:text-6xl">
        <Balancer>Manage the C.O.S.T. of Monitoring</Balancer>
      </h3>
      <p className="max-w-2xl text-center text-base text-slate-600 md:text-xl">
        All monitoring systems run into four main challenges:
      </p>

      <div className="grid max-w-5xl grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-4">
        <Card title="Cardinality">
          As data grows, it becomes harder to query, visualize, resulting into
          latent dashboards, and alerting downtimes.
        </Card>
        <Card title="Operations">
          Managing performance, uptime, security, and reliability of monitoring
          and alerting becomes a challenge.
        </Card>
        <Card title="Scale">
          As users and product adoption grows, the monitoring infrastructure
          needs to support the growth.
        </Card>
        <Card title="Toil">
          The time & effort from engineers to manage monitoring infrastructure
          become unnecessary distractions.
        </Card>
      </div>
      <p className="tex-base max-w-xl text-center text-slate-600 md:text-xl">
        <Balancer>
          Levitate enables engineering teams reduce COSTs by taming High
          Cardinality data, comes as a Fully Managed Platform, supports Cricket
          Scale, and removes the Toil that comes with running your own TSDB and
          Monitoring setup.
        </Balancer>
      </p>
    </div>
  )
}

export default ManageCost
