import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"

const SectionPointCard = ({ color, title, description }) => {
  const colors = {
    red: {
      bg: "bg-red-50",
      text: "text-red-600",
    },
    emerald: {
      bg: "bg-emerald-50",
      text: "text-emerald-600",
    },
    blue: {
      bg: "bg-blue-50",
      text: "text-blue-600",
    },
  }
  return (
    <div
      className={clsx(
        "flex w-full flex-col items-start gap-2 self-stretch rounded-xl p-4 md:max-w-[183px]",
        colors[color].bg,
        colors[color].text,
      )}
    >
      <h4 className="font-bold leading-5">{title}</h4>
      <p className="text-xs leading-4">{description}</p>
    </div>
  )
}

SectionPointCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SectionPointCard
