export const ENV = {
  GHOST_API_URL: process.env.GATSBY_GHOST_API_URL ?? "",
  GHOST_API_KEY: process.env.GATSBY_GHOST_API_KEY ?? "",
  HS_ACCOUNT_ID:
    process.env.GATSBY_HS_ACCOUNT_ID ?? process.env.HS_ACCOUNT_ID ?? "",
  HS_BLOG_SUB_FORM_ID:
    process.env.GATSBY_HS_BLOG_SUB_FORM_ID ??
    process.env.HS_BLOG_SUB_FORM_ID ??
    "",
}
