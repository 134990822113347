import React from "react"
import Seo from "../components/common/seo"
import Hero from "../components/traces/Hero"
import LogoScroll from "../components/common/logoscroll"
import StickySubheader from "../components/traces/StickySubheader"
import TracesExplorer from "../components/traces/TracesExplorer"
import TracesManagement from "../components/traces/TracesManagement"
import OtherCapabilities from "../components/common/OtherCapabilities"
import PreFooter from "../components/home/PreFooter"
import Layout from "../components/common/layout"

const TracesPage = () => {
  const subheaderLinks = [
    { id: "traces-explorer-rapid-rca", title: "Rapid RCA" },
    { id: "traces-explorer-improve-performance", title: "Improve Performance" },
    { id: "traces-explorer-system-visibility", title: "System Visibility" },
    {
      id: "traces-management-trace-everything",
      title: "Trace Everything",
    },
    { id: "traces-management-pipelines", title: "Pipelines" },
    {
      id: "traces-management-industry-standards",
      title: "Industry Standards",
    },
  ]

  return (
    <Layout>
      <main className="flex flex-col [&_a]:underline [&_a]:decoration-blue-300 [&_a]:underline-offset-2 hover:[&_a]:decoration-blue-500">
        <Hero />
        <LogoScroll />
        <StickySubheader links={subheaderLinks} />
        <TracesExplorer />
        <TracesManagement />
        <OtherCapabilities page="Traces" />
        <PreFooter />
      </main>
    </Layout>
  )
}

// This gets added in <head> tag
export const Head = () => (
  <Seo
    title="Cloud Native End-to-End Distributed Tracing"
    description="Gain real-time insights into performance issues and root causes by correlating traces with logs, metrics, and events in a single pane."
    image="/thumbnails/thumbnail-last9-traces.jpg"
  />
)

export default TracesPage
