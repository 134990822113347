import React from "react"
import QuoteLight from "../common/QuoteLight"
import { quotes } from "../../constants/quotes"
import authorImg from "../../../static/home/alvaro-atlan.jpeg"

const SLAQuote = () => {
  return (
    <QuoteLight
      clientName="atlan"
      quote={quotes["alvaro-uría"]}
      author={{
        img: authorImg,
        name: "Alvaro Uría",
        company: "Engineering Manager",
      }}
    />
  )
}

export default SLAQuote
