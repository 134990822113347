import React from "react"
import PropTypes from "prop-types"

const Stats = ({ heading, stats }) => {
  return (
    <div className="flex flex-col items-center gap-4 py-5">
      <h5 className="text-center text-base font-bold leading-6 text-slate-400">
        {heading}
      </h5>
      <div className="flex flex-row justify-center gap-4">
        {stats?.map(({ stat, title }) => {
          return (
            <div
              className="flex w-full max-w-[120px] flex-col items-center"
              key={title}
            >
              <span className="whitespace-nowrap text-2xl font-bold leading-8 text-emerald-400">
                {stat}
              </span>
              <span className="text-xs font-bold uppercase leading-4 text-slate-400">
                {title}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

Stats.propTypes = {
  heading: PropTypes.string,
  stats: PropTypes.arrayOf({
    title: PropTypes.string,
    stat: PropTypes.string,
  }),
}

export default Stats
