import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const MetadataSplits = ({ splits }) => {
  const isLast = index => index === splits.length - 1

  return (
    <p className="flex gap-2 text-sm text-slate-500">
      {splits.map((split, index) => (
        <React.Fragment key={index}>
          {split?.href ? (
            <Link class="underline" to={split.href}>{split.label}</Link>
          ) : (
            <span>{split.label}</span>
          )}
          {!isLast(index) && <span className="text-slate-300">/</span>}
        </React.Fragment>
      ))}
    </p>
  )
}

MetadataSplits.propTypes = {
  splits: PropTypes.array.isRequired,
}

export default MetadataSplits
