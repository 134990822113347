import React from "react"
import headerControlImg from "../../../static/icons/header-control.png"
import IconIngestion from "../../../static/control-plane/icon-ingestion.inline.svg"
import IconStorage from "../../../static/control-plane/icon-storage.inline.svg"
import IconQuery from "../../../static/control-plane/icon-query.inline.svg"
import IconAnalytics from "../../../static/control-plane/icon-analytics.inline.svg"
import imageCPIntro from "../../../static/control-plane/control-plane-flow.png"

const sections = [
  {
    icon: IconIngestion,
    title: "Ingestion",
    description:
      "Shape telemetry data during ingestion, even before it is stored, without code changes or app redeployment.",
    learnMoreLink: "https://docs.last9.io/docs/control-plane-ingestion",
    cards: [
      {
        title: "Tokens & Access Policies",
        image: "/control-plane/cp-ingestion-token-access.png",
        description:
          "Define guard rails for which data tiers do certain teams or query clients have access to. Eg: Alerting workloads should be served from the Blaze Tier only.",
      },
      {
        title: "Streaming Aggregations",
        image: "/control-plane/cp-ingestion-sap.png",
        description:
          "Transform incoming data to control cardinality explosion by defining aggregated queries on a metric. Derive new metrics with reduced cardinality for specific use cases.",
      },
      {
        title: "Sensitive Information",
        image: "/control-plane/cp-ingestion-pii.png",
        description:
          "Redact sensitive data from your telemetry data at time of ingestion, with pre-built templates for emails, phone numbers, and credit card numbers.",
      },
      {
        title: "Filtering & Routing",
        image: "/control-plane/cp-ingestion-filter-route.png",
        description:
          "Configure rules to forward without ingesting, drop, or extract and remap your telemetry data at runtime. Ingest only the data that you need.",
      },
    ],
  },
  {
    icon: IconStorage,
    title: "Storage",
    description:
      "Manage how your data, after the default retention periods, is stored and accessed.",
    learnMoreLink: "https://docs.last9.io/docs/control-plane-storage",
    cards: [
      {
        title: "Cold Storage",
        image: "/control-plane/cp-storage-cold-storage.png",
        description:
          "Post default retention periods, retain your data on your own AWS S3 in a cost-effective manner, always accessible as needed.",
      },
      {
        title: "Rehydration",
        image: "/control-plane/cp-storage-rehydration.png",
        description:
          "With on-demand rehydration, your telemetry data is never far away. 24-hours of data gets rehydrated in under an hour.",
      },
    ],
  },
  {
    icon: IconQuery,
    title: "Query",
    description:
      "Define and optimize how your data is interacted with at a query-level.",
    learnMoreLink: "https://docs.last9.io/docs/control-plane-query",
    cards: [
      {
        title: "Macros",
        image: "/control-plane/cp-query-macros.png",
        description:
          "Write complex PromQL queries as abstracted reusable functions and use them as metric names across Alerting, Embedded Grafana, or the CLI.",
      },
      {
        title: "Scheduled Search",
        image: "/control-plane/cp-query-scheduled-search.png",
        description:
          "Create periodic searches on telemetry data and set alerts when patterns are found or missing.",
      },
    ],
  },
  {
    icon: IconAnalytics,
    title: "Analytics",
    description:
      "Get visibility on usage with per-telemetry breakdown, cardinality quotas, and query performance.",
    learnMoreLink: "https://docs.last9.io/docs/control-plane-analytics",
    cards: [
      {
        title: "Cardinality Explorer",
        image: "/control-plane/cp-analytics-cardinality-explorer.png",
        description:
          "Identify high cardinality metrics to refactor instrumentation or use Streaming Aggregations to optimize queries. Last9 supports 20M cardinality per metric per day by default.",
      },
      {
        title: "Slow Query Logs",
        image: "/control-plane/cp-analytics-slow-query-logs.png",
        description:
          "View queries with a latency of 1s or higher along with which tier was data fetched from and the token used.",
      },
    ],
  },
]

const TelemetryWarehouse = () => {
  return (
    <section className="scroll-pt-40 bg-gradient-to-b from-yellow-50 to-transparent px-6 py-20">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-16">
        {/* section header */}
        <div className="flex flex-col-reverse items-start justify-between gap-5 md:flex-row md:items-center">
          <h2 className="max-w-2xl text-balance text-4xl font-extrabold text-slate-900 md:text-5xl">
            A no-compromise Telemetry Data Platform. With the control levers it
            deserves.
          </h2>
          <img alt="" className="h-[120px]" src={headerControlImg} />
        </div>
        {/* end section header */}

        {/* intro */}
        <div className="flex w-full flex-col gap-6 md:flex-row md:gap-12">
          <div className="flex flex-col gap-4 md:basis-4/12 lg:basis-3/12">
            <p className="text-lg font-medium text-slate-600">
              Stop spending 10%-12% of your total cloud budget on observability.
            </p>
            <ul className="list-disc space-y-1 pl-6 text-base text-slate-600 marker:mr-1 marker:text-base marker:font-bold marker:text-emerald-500 marker:content-['✓__']">
              <li>Control how data flows, is stored, and is used.</li>
              <li>No sampling, but backed by pre-ingestion workflows.</li>
              <li>Don't make tradeoffs between visibility and cost.</li>
            </ul>
          </div>
          <div className="flex max-w-full flex-col rounded-lg border border-solid border-slate-200 bg-white sm:aspect-[2/1] md:basis-8/12 lg:basis-9/12">
            <img alt="" className="rounded-lg" src={imageCPIntro} />
          </div>
        </div>
        {/* end intro */}

        {/* steps */}
        <div className="flex w-full flex-col">
          {sections.map((section, index) => (
            // step
            <div
              key={section.title}
              className="relative flex w-full flex-col gap-3 lg:flex-row lg:gap-12"
            >
              {/* left col */}
              <div className="flex gap-4 lg:basis-3/12">
                {/* count + connector */}
                <div className="flex flex-col items-center">
                  {index == 0 && (
                    <div className="h-3 w-0.5 bg-transparent"></div>
                  )}
                  {index !== 0 && (
                    <div className="h-3 w-0.5 bg-emerald-500"></div>
                  )}
                  <span className="rounded-full bg-emerald-500 p-2 text-white">
                    <section.icon className="h-5 w-5" />
                  </span>
                  {index !== sections.length - 1 && (
                    <div className="absolute bottom-0 top-12 h-full w-0.5 bg-emerald-500"></div>
                  )}
                </div>
                {/* end count + connector */}

                {/* step intro */}
                <div className="flex flex-col gap-2 py-4 text-base text-slate-600 lg:gap-4">
                  <h3 className="text-lg font-bold">{section.title}</h3>
                  <p>{section.description}</p>
                  <a
                    href={section.learnMoreLink}
                    className="text-blue-500"
                    target="_blank"
                  >
                    Learn more
                  </a>
                </div>
                {/* end step intro */}
              </div>
              {/* end left col */}

              {/* right col */}
              <div className="-mx-6 mb-8 flex gap-3 overflow-x-auto pb-8 lg:mx-0 lg:grid lg:basis-9/12 lg:grid-cols-2 lg:gap-6 lg:overflow-auto lg:pl-0">
                {section.cards.map(card => (
                  <div
                    key={card.title}
                    className="relative z-10 flex w-3/5 shrink-0 flex-col gap-3 rounded-lg border border-solid border-slate-100 bg-white p-3 first-of-type:ml-[72px] last-of-type:mr-6 md:w-1/2 lg:w-auto lg:first-of-type:ml-0 lg:last-of-type:mr-0"
                  >
                    <img
                      src={card.image}
                      className="aspect-[1187/480] w-full rounded border border-solid border-slate-100 bg-slate-100"
                    />

                    <span className="space-y-2">
                      <h4 className="text-base font-bold text-slate-600">
                        {card.title}
                      </h4>
                      <p className="text-sm text-slate-500">
                        {card.description}
                      </p>
                    </span>
                  </div>
                ))}
              </div>
              {/* end right col */}
            </div>
            // end step
          ))}
        </div>
        {/* end steps */}
      </div>
    </section>
  )
}

export default TelemetryWarehouse
