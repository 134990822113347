import React from "react"
import { Link } from "gatsby"
import dateFormat from "dateformat"
import { PropTypes } from "prop-types"

const FeaturedPost = ({ post }) => {
  return (
    <Link
      to={`/blog/${post.slug}/`}
      className="flex flex-col gap-4 lg:grid lg:grid-cols-12 xl:gap-12"
    >
      <img
        src={post.feature_image}
        alt={post.title}
        className="h-60 w-full rounded-2xl border border-slate-100 object-cover md:h-96 lg:col-span-8"
      />
      <div className="flex flex-col justify-center gap-4 lg:col-span-4">
        <span className="text-2xl font-extrabold md:text-4xl">
          {post.title}
        </span>
        <p className="line-clamp-3">{post.excerpt}</p>
        <div className="flex flex-col gap-1 opacity-50">
          <p>
            {post?.authors.map(author => {
              return (
                <span
                  key={author.name}
                  className="after:content-[',_'] last-of-type:after:content-none"
                >
                  {author.name}
                </span>
              )
            })}
          </p>
          <p className="text-sm">
            {dateFormat(post.published_at, "mmm dS, ‘yy")} / {post.reading_time}{" "}
            min read
          </p>
        </div>
      </div>
    </Link>
  )
}

FeaturedPost.propTypes = { post: PropTypes.object }

FeaturedPost.defaultProps = { post: `` }

export default FeaturedPost
