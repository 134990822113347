/* eslint-disable react/prop-types */
import React from "react"
import { Link } from "gatsby"

const Card = ({ path, bg, title }) => {
  return (
    <Link to={path} className="relative flex max-w-xl flex-col gap-8">
      <img
        className="aspect-[1044/960] h-full max-h-[480px] w-full rounded-2xl border border-slate-200 object-cover"
        src={bg}
      />
      {title && <h3 className="text-[32px] font-bold leading-10 ">{title}</h3>}
    </Link>
  )
}

export default Card
